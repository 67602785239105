import React from 'react'
import styles from './Legend.module.css'

export default function Legend({color,title}){
    return(
        <div className={styles.container} >
            <div className={styles.circle} style={{background:color}}/>
            <p>{title}</p>
        </div>
    )
}