import React, { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import en from '../../locales/phone-input/en.json';
import 'react-phone-input-2/lib/style.css';
import styles from './phoneInput.module.scss';

const locales = {
  ru,
  en,
}

export const PhoneInputField = forwardRef((props, ref) => {
  const {
    value,
    onChange,
    pending = false,
    error = false,
    label,
    required,
    placeholder,
    postfix,
    ...otherProps
  } = props;

  const { t, i18n } = useTranslation();

  const localization = locales[i18n.language];

  return (
    <div className={styles.container}>
      {!!label && <p className={styles.label}>{label}{required ? <i>*</i> : null}</p>}
      <div className={styles.inputContainer} ref={ref}>
        <PhoneInput
          {...otherProps}
          searchPlaceholder={t('search')}
          enableSearch
          placeholder={placeholder}
          containerClass={styles.inputContainer}
          inputClass={styles.input}
          localization={localization}
          country='kz'
          disabled={pending}
          value={value}
          onChange={(value) => onChange(value)}
          areaCodes={{kz: ['7', '6']}}
          isValid={(value, country) => {
            //@ts-ignore
            if (country.countryCode == 7 && value.length < 11) {
              return false
            } else if (value.length < 10) {
              return false;
            } else {
              return true;
            }
          }}
        />
        {!!postfix && (
          <div className={styles.postfix}>
            {postfix()}
          </div>
        )}
      </div>
      {!!error && (
        <p className={styles.error}>
          {error}
        </p>
      )}
    </div>
  )
})

