import instance from "../../api";

const notificationsApi = {
    getNotificationsData: async (id, page) => {
        const skip = (page - 1) * 5;
        return await instance().get(`/chats/${id}/messages/list?skip=${skip}&limit=5`).then(res => res.data)
    },
    sendMessage: async (id, data) => {
        return await instance().post(`chats/${id}/messages`, data).then(res => res.data)
    },
    sendOnlyImage: async (id, formData) => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        return await instance().post(`chats/${id}/messages/file`, formData, config).then(res => res.data)
    }
}

export default notificationsApi