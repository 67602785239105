import clientInfoTypes from "./clientInfoTypes";
import clientInfoApi from "./clientInfoApi";
import {Roles} from "../../config";

const clientInfoActions = {
    getClientInfo: (id,info, currentRole, params) => async dispatch => {
        dispatch({
            type: clientInfoTypes.GET_CLIENT_INFO_PENDING
        })

        if (params?.groupId && currentRole === Roles.ANALYTIC) {
            await clientInfoApi.getDatabaseClientInfo(id,params.groupId)
                .then(res => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_INFO_SUCCESS,
                        payload: res.data
                    })
                })
                .catch(() => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_INFO_ERROR
                    })
                })
        } else {
            await clientInfoApi.getClientInfo(id,info)
                .then(res => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_INFO_SUCCESS,
                        payload: res.data
                    })
                })
                .catch(() => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_INFO_ERROR
                    })
                })
        }
    },

    getClientAward: (id,info) => async dispatch => {
        dispatch({
            type: clientInfoTypes.GET_CLIENT_INFO_AWARD_PENDING
        })

        await clientInfoApi.getClientAward(id,info)
          .then(res => {
              if (res.data && res.data.stamp_count) {
                  dispatch({
                      type: clientInfoTypes.GET_CLIENT_INFO_AWARD_SUCCESS,
                      payload: res.data
                  })
              }
          })
          .finally(() => {
              dispatch({
                  type: clientInfoTypes.GET_CLIENT_INFO_AWARD_ERROR
              })
          })
    },

    getClientTransactions: (id,page,info, currentRole, params) => async dispatch => {
        dispatch({
            type: clientInfoTypes.GET_CLIENT_TRANSACTION_PENDING
        })

        if (currentRole === Roles.ANALYTIC && params.groupId) {
            await clientInfoApi.getDatabaseClientTransactions(id,page,params.groupId)
                .then(res => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_TRANSACTION_SUCCESS,
                        payload: res.data
                    })
                })
                .catch(() => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_TRANSACTION_ERROR
                    })
                })
        } else {
            await clientInfoApi.getClientTransactions(id,page,info)
                .then(res => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_TRANSACTION_SUCCESS,
                        payload: res.data
                    })
                })
                .catch(() => {
                    dispatch({
                        type: clientInfoTypes.GET_CLIENT_TRANSACTION_ERROR
                    })
                })
        }
    },


    changeTransactionPage: (page) => {
        return {
            type: clientInfoTypes.CHANGE_CLIENT_TRANSACTION_PAGE,
            payload: page
        }
    },


    resetClientInfo: () => {
        return {
            type: clientInfoTypes.RESET_CLIENT_INFO
        }
    }
}

export default clientInfoActions