import React, { useEffect } from 'react'
import { Route, Switch } from "react-router-dom";
import Administration from "./components/Administration";
import Analytics from "./components/Analytics";
import AnalyticsInfo from "./components/AnalyticsInfo";
import crossActions from "../../store/crossStore/crossActions";
import { useDispatch, useSelector } from "react-redux";
import styles from './Ads.module.css'

export default function Ads() {
  const dispatch = useDispatch();
  const {info} = useSelector(state => state.branchReducer);
  const {crossMarketingBranchesParams} = useSelector(state => state.crossReducer);

  useEffect(() => {
    dispatch(crossActions.getCities());
    dispatch(crossActions.getCategories());
  }, []);

  useEffect(() => {
    if (info?.id) {
      dispatch(crossActions.getCrossMarketingGroupIdList(info.id));
    }
  }, [info]);

  useEffect(() => {
    if (info && info.id) {
      let params = {
        limit: crossMarketingBranchesParams.limit,
        skip: crossMarketingBranchesParams.skip,
      }

      if (crossMarketingBranchesParams.selectedCities.length) {
        params = {
          ...params,
          city_id: crossMarketingBranchesParams.selectedCities[0].id
        }
      }
      if (crossMarketingBranchesParams.selectedCategories.length) {
        params = {
          ...params,
          category_id: crossMarketingBranchesParams.selectedCategories[0].id
        }
      }

      dispatch(crossActions.getCrossMarketingList(info.id, params));
    }
  }, [info, crossMarketingBranchesParams])

  useEffect(() => {
    if (crossMarketingBranchesParams.selectedCities.length) {
      // dispatch(crossActions.getCategories({city_id: crossMarketingBranchesParams.selectedCities[0].id}));
    }
  }, [crossMarketingBranchesParams.selectedCities])

    return (
        <div className={styles.container}>
            <div className={styles.analytics}>
                <Switch>
                    <Route exact path={'/cross-marketing'} render={() => <Administration />}/>
                    <Route exact path={'/cross-marketing/analytics'} render={() => <Analytics />}/>
                    <Route exact path={'/cross-marketing/analytics/:group_id'} render={() => <AnalyticsInfo />}/>
                </Switch>
            </div>
        </div>
    )
}