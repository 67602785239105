import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { PENDING } from '../../../store/globalStatuses/globalStatuses';

import styles from './fillForm.module.scss';
import exclaimImg from 'assets/exclaim.png';

import { useDispatch, useSelector } from 'react-redux';
import ButtonFilled from "../../FormElements/ButtonFilled";
import transactionsActions from "../../../store/transactionsStore/transactionsActions";
import { store } from "react-notifications-component";
import TransactionsTypes from "../../../store/transactionsStore/transactionsTypes";
import { CustomSingleDatePicker } from "../../CustomDatePicker/CustomDatePicker";
import { convertDate } from "../../../tools/convertDateToString";
import clientsApi from "../../../store/clientsStore/clientsApi";
import clientInfoTypes from "../../../store/clientInfoStore/clientInfoTypes";

export default function FillForm(props) {
  const {
    isSendUseCashbackCodePending,
    isSendedUseCashbackCode,
    phone,
    customer,
    setStep,
    amount_sum,
    bonuses,
    changeAmountSum,
    changeBonuses,
    comment,
    payment,
    setPayment,
    changeComment,
    getClientCashback,
    add_stamp,
    give_award,
    changeAward,
    changeStamp,
    info,
    notRegistered,
    getClientAward,
    type,
    stamp_count,
    changeStampCount,
    cStatus,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState(null);

  const [awardId, setAwardId] = useState(customer.award_id);

  const { aStatus } = useSelector(state => state.clientInfoReducer);
  const { pending } = useSelector(state => state.branchReducer);

  const availableBonusesForWithdraw = info.configuration.private_cashback.access_withdraw_bonus_percentage ? (amount_sum / 100 * info.configuration.private_cashback.access_withdraw_bonus_percentage) >= customer.bonuses ?
    Math.floor(customer.bonuses) :
    Math.floor((amount_sum / 100 * info.configuration.private_cashback.access_withdraw_bonus_percentage)) : 0;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  useEffect(() => {
    if (customer) {
      setFirstName(customer.first_name || "");
      setLastName(customer.last_name || "");
      let d = null;
      if (customer.birthday) {
        const splitted = customer.birthday.split("-");
        d = `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
      }
      if (isValidDate(new Date(d))) {
        setBirthday(d);
      } else {
        setBirthday(null);
      }
    }
  }, [customer]);

  useEffect(() => {
    getClientCashback();
  }, [customer]);

  useEffect(() => {
    setAwardId(customer.award_id);

    if (info.configuration.award && info.configuration.award.enabled && customer.id) {
      if (!customer.received_stamp_count || !customer.expired_at || !customer.award_id) {
        if (!awardId || awardId !== customer.award_id) {
          getClientAward();
        }
      }
    }
  }, [customer, info]);

  const getHalfOfBonuses = () => {
    if (notRegistered || !customer.bonuses) return
    const bonuses = Math.floor(customer.bonuses);

    if (parseInt(amount_sum) < Math.ceil(bonuses / 2) && amount_sum.length !== 0) {
      return changeBonuses(amount_sum)
    }

    changeBonuses(Math.ceil( bonuses / 2))
  }

  const getAllBonuses = () => {
    if (notRegistered || !customer.bonuses) return
    const bonuses = Math.floor(customer.bonuses);
    if (parseInt(amount_sum) < bonuses && amount_sum.length !== 0) {
      changeBonuses(amount_sum)
    } else {
      changeBonuses(Math.floor(bonuses))
    }
  }

  const nextStep = () => {
    let newB = null;
    if (birthday) {
      newB = convertDate(birthday).split('.').join('-')
    }

    if (notRegistered) {
      if(isNaN(parseInt(amount_sum))) {
        setError(true);
        return;
      }

      setLoading(true);
      clientsApi.putClient('undefined', { phone_number: '+'+phone, first_name: firstName, last_name: lastName, birthday: newB })
        .then((customer) => {
          dispatch({ type: clientInfoTypes.UPDATE_CLIENT_INFO, payload: customer.data });
          setError(false);
          setStep(3);
        })
        .finally(() => {
          setLoading(false);
        })
      return
    }

    if (customer.send_code_for_use_cashback && bonuses > 0 && !isSendedUseCashbackCode) {
      store.addNotification({
        title: t("send_please_whatsapp_code"),
        message: "Whatsapp code",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
      return setError(true);
    }

    if (availableBonusesForWithdraw < bonuses) {
      setError(true);
      return;
    }

    if (parseInt(amount_sum) >= bonuses && amount_sum.length !== 0 && Math.floor(customer.bonuses) >= bonuses) {
      if (
        customer.first_name !== firstName ||
        customer.last_name !== lastName ||
        customer.birthday !== newB
      ) {
        setLoading(true);
        clientsApi.putClient(customer.id, { first_name: firstName, last_name: lastName, birthday: newB })
          .then((customer) => {
            dispatch({ type: clientInfoTypes.UPDATE_CLIENT_INFO, payload: customer.data });
            setError(false);
            setStep(3);
          })
          .finally(() => {
            setLoading(false);
          })
      } else {
        setError(false);
        setStep(3);
      }
    } else {
      setError(true);
    }
  }

  const sendUseCashback = (e) => {
    e.preventDefault();
    new Promise((resolve, reject) => {
      dispatch(transactionsActions.sendUseCashbackCode(customer.id, info.id, resolve, reject))
    })
  }

  // Изменять error на false если ввели amount_sum
  useEffect(() => {
    if (error && amount_sum) {
      setError(false)
    }
  }, [amount_sum]);

  const renderWhatsAppSendButton = () => {
    if (bonuses <= 0 || !customer.send_code_for_use_cashback) return null;

    return (
      <div style={{ maxWidth: 485, margin: "0 auto", width: "100%" }}>
        <ButtonFilled onClick={sendUseCashback} loading={isSendUseCashbackCodePending}>
          {t("send_whatsapp_code")}
        </ButtonFilled>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>{t('NSPayment.assessment')}</p>
        {
          ((!!customer.first_name || !!customer.last_name) && (
            <span>
                    {`${customer.first_name} ${customer.last_name}`} <br/>
                  </span>
          ))
        }
        {
          ((!!customer.gradation_status || customer.gradation_status === '') && (
            <p>{`${customer.gradation_status}`}</p>
          ))
        }
      </div>

      {
        notRegistered && (
          <div className={styles.notRegistered}>
            <p>{'+' + phone} {t('NSBonusModal.notReg')}</p>
            <div>
              <img src={exclaimImg}/>
              <span>{t('NSPayModal.regAuto')}</span>
            </div>
          </div>
        )
      }

      {/*<div className={styles.points}>*/}
      {/*  <p>Количество баллов:</p>*/}
      {/*  <span>{customer.bonuses > 0 ? Math.round(customer.bonuses) : 0}</span>*/}
      {/*</div>*/}

      <div className={styles.getPoints}>
        <p>{t('Form.firstName')}</p>
        <input
          label={t('Form.firstName')}
          placeholder={t('Form.enterFirstName')}
          disabled={pending || cStatus === PENDING}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className={styles.input}
        />
      </div>
      <div className={styles.getPoints}>
        <p>{t('Form.lastName')}</p>
        <input
          label={t('Form.lastName')}
          placeholder={t('Form.enterLastName')}
          disabled={pending || cStatus === PENDING}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className={styles.input}
          required
        />
      </div>
      <div className={styles.getPoints}>
        <p>{t("NSBonus.birthday")}</p>
        <div class={styles.inputContainer}>
          <CustomSingleDatePicker
            tSetDate={(value) => setBirthday(value)}
            tDate={birthday}
          />
        </div>
      </div>

      <div className={styles.getPoints}>
        <p>{t('NSPayment.account')}</p>
        <input
          className={styles.input}
          value={amount_sum}
          onChange={changeAmountSum}
          placeholder="0"
        />
        <div className={styles.bonusesAmount}>
          <p>
            {t('NSPayModal.hadBonus', {bonus: customer.bonuses > 0 ? Math.floor(customer.bonuses) : 0})}
          </p>
        </div>

      </div>

      {/*{*/}
      {/*  type === 'code' && (*/}
      {/*      */}
      {/*  )*/}
      {/*}*/}

      <div className={styles.getPoints}>
        <p>{t('NSPayModal.takeBonus')}</p>
        <input
          className={styles.input}
          placeholder="0"
          readOnly={notRegistered}
          value={bonuses}
          onChange={changeBonuses}
          style={error ? {border: '1px solid red'} : {}}
        />
        <div className={styles.bonusesAmount}>
          <p>
            {t('NSPayModal.availableBonus')} &nbsp;
            <span>
              {customer.bonuses ? availableBonusesForWithdraw : 0}
            </span>
          </p>
          {/*<div style={{ textAlign: 'right' }}>*/}
          {/*  <button  style={{ marginBottom: '10px' }} onClick={getHalfOfBonuses}>{t('NSPayModal.take', {percent: '50%'})}</button>*/}
          {/*  <button onClick={getAllBonuses}>{t('NSPayModal.takeAll')}</button>*/}
          {/*</div>*/}
          <div style={{textAlign: 'right'}}>
            <button className={styles.button} style={{marginBottom: '10px'}} onClick={getHalfOfBonuses}>50%</button>
            <button className={styles.button} onClick={getAllBonuses}>100%</button>
          </div>
        </div>
      </div>

      {renderWhatsAppSendButton()}

      {
        info.configuration.award && info.configuration.award.enabled && (
          <div className={styles.getPoints}>
            {
              ((notRegistered || customer.received_stamp_count != customer.stamp_count) ||
                (customer.received_stamp_count == 0 && customer.stamp_count == 0)) ? (
                <>
                  <p>{t('NSPayModal.countStamps')}</p>
                  <input
                    className={styles.input}
                    value={stamp_count}
                    onChange={changeStampCount}
                    placeholder="0"
                    disabled={cStatus === PENDING}
                  />
                  <div className={styles.bonusesAmount}>
                    <p>
                      {t('NSPayModal.hadStamps', {
                        hadStamps: customer.received_stamp_count ? customer.received_stamp_count : 0,
                        stampsCount: info.configuration.award.stamp_count
                      })}
                    </p>
                  </div>
                </>
              ) : !customer.award_id && !give_award ? (
                <>
                  <p>{t('NSPayModal.countStamps')}</p>
                  <input
                    className={styles.input}
                    value={stamp_count}
                    onChange={changeStampCount}
                    placeholder="0"
                    disabled={cStatus === PENDING}
                  />
                  <div className={styles.bonusesAmount}>
                    <p>
                      {t('NSPayModal.hadStamps', {hadStamps: 0, stampsCount: info.configuration.award.stamp_count})}
                    </p>
                  </div>
                </>
              ) : null
            }

            {
              (!!+customer.received_stamp_count && !!+customer.stamp_count) &&
              (customer.received_stamp_count == customer.stamp_count) && !give_award && (
                <div className={styles.getPoints}>
                  <p>{t('NSPayModal.countStamps')}</p>
                  <div className={styles.getPoints2}>
                    <p className={styles.getPoints2Counts}>{customer.received_stamp_count} из {customer.stamp_count}</p>
                    <button
                      className={styles.button}
                      onClick={() => setStep(4)}
                    >
                      {t('NSPayModal.givePresent')}
                    </button>
                  </div>
                </div>
              )
            }
          </div>
        )
      }

      <div className={styles.getPoints}>
        <p>{t('comment')}</p>
        <input
          className={styles.input}
          placeholder="N53213"
          value={comment}
          onChange={changeComment}
        />
        <div className={styles.bonusesAmount}>
          <p>
            {t('NSPayModal.exampleNumberCheck')}
          </p>
        </div>
      </div>

      <div className={styles.sendButton}>
        <ButtonFilled
          onClick={nextStep}
          loading={loading}
          disabled={pending}
        >
          {t("send")}
        </ButtonFilled>
      </div>

    </div>
  )
}
