import i18n from '../../../i18n';
import audienceTypes from "./audienceTypes";
import { PENDING, SUCCESS, ERROR } from "../../globalStatuses/globalStatuses";
import React from "react";

const initialState = {
    // analyticClientsDateFrom: new Date().setFullYear(new Date().getFullYear() - 1),
    analyticClientsDateFrom: null,
    analyticClientsDateTo: null,
    data: [],
    status: '',
    genders: [
        {x: i18n.t('male'), y: 0},
        {x: i18n.t('female'), y: 0},
        {x: i18n.t('unknown'), y: 0}
    ],
    genders_amount: 0,
    ageData: [
        ['', `${i18n.t('clientsTo')} %`],
        ['0-17', 0],
        ['18-24', 0],
        ['25-34', 0],
        ['35-44', 0],
        ['45-54', 0],
        ['55+', 0],
    ],
    audience_chart: [
        ['x', i18n.t('clientsTo')],
        [i18n.t('jan'), 0],
        [i18n.t('feb'), 0],
        [i18n.t('march'), 0],
        [i18n.t('apr'), 0],
        [i18n.t('may'), 0],
        [i18n.t('june'), 0],
        [i18n.t('july'), 0],
        [i18n.t('august'), 0],
        [i18n.t('sep'), 0],
        [i18n.t('oct'), 0],
        [i18n.t('nov'), 0],
        [i18n.t('dec'), 0],
    ],
    type: 4,
    genders_array: [
        {x: i18n.t('male'), y: 0},
        {x: i18n.t('female'), y: 0},
        {x: i18n.t('unknown'), y: 0}
    ],
}


const audienceReducer = (state = initialState, action) => {
    switch (action.type) {


        case audienceTypes.AA_GET_DATA_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case audienceTypes.AA_GET_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                status: SUCCESS,
                genders: action.payload.genders,
                ageData: action.payload.ageData,
                genders_amount: action.payload.genders_amount,
                audience_chart: action.payload.audience_chart,
                genders_array: action.payload.genders_array
            }


        case audienceTypes.AA_GET_DATA_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case audienceTypes.AA_CHANGE_DATA_PERIOD:
            return {
                ...state,
                type: action.payload
            }

        case audienceTypes.AA_CHANGE_DATE_FROM:
            return {
                ...state,
                analyticClientsDateFrom: action.payload
            }

        case audienceTypes.AA_CHANGE_DATE_TO:
            return {
                ...state,
                analyticClientsDateTo: action.payload
            }


        default:
            return {
                ...state
            }
    }
}


export default audienceReducer