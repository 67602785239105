import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SurveyCard from "../../../../components/SurveyCard";
import surveyActions from "../../../../store/surveyStore/surveyActions";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Pagination from "../../../../components/Pagination";
import { PENDING } from "../../../../store/globalStatuses/globalStatuses";
import Loader from "../../../../components/Loader";
import { computeSkipElements } from "../../../../tools/utils";
import Modal from "../../../../portals/Modal";
import styles from "../BonusBirthday/profileBonusBirthday.module.scss";

export default function Surveys() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { surveys, count, limit, status } = useSelector((s) => s.surveyReducer);
    const { info } = useSelector((s) => s.branchReducer);
    const [page, setPage] = useState(1);
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (info) {
            dispatch(surveyActions.clearSurveys());
            setError(false);

            if (!info.configuration.survey || !info.configuration.survey.enabled) {
                setError(true);
                return setErrorModal(true);
            }

            const queryParams = `limit=${limit}&branch_id=${info.id}`
            dispatch(surveyActions.getSurveys(queryParams));
        }
    }, [info, i18n.language]);

    const onSkip = (quantityOfSkip, cb) => {
        const queryParams = `limit=${limit}&branch_id=${info.id}&skip=${quantityOfSkip}`
        dispatch(surveyActions.getSurveys(queryParams));
        cb();
    }

    const onDeleteSurvey = (id) => (event) => {
        event.preventDefault();
        event.stopPropagation ? event.stopPropagation() : (event.cancelBubble=true);

        const queryParams = `limit=${limit}&branch_id=${info.id}&skip=${computeSkipElements({page_index: page, limit})}`
        dispatch(surveyActions.deleteSurvey(id, queryParams))
    }

    return (
        <div aria-disabled={status === PENDING}>
            {!error && <ButtonFilled to="surveys/create">{t('NSPoll.create')}</ButtonFilled>}

            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '31px 0 -20px', height: '478px'}}>
                {status !== PENDING && surveys.map((survey, index) => {
                    return <SurveyCard
                        pageIndex={page === 1 ? index + 1 : (page - 1) * 4 + index + 1}
                        survey={survey}
                        key={survey.id}
                        onDelete={onDeleteSurvey}
                    />
                })}
                {status !== PENDING && !surveys.length && (
                    <h2 style={{ textAlign: 'center', margin: 'auto' }}>{error ? t('optionForbidden') : t('noData')}</h2>
                )}
                {status === PENDING && (
                    <Loader color="#73D389" style={{ margin: 'auto' }}/>
                )}
            </div>
            <Pagination
                count={count}
                limit={limit}
                max={4}
                loading={status === PENDING}
                updated={info && info.id}
                page={page}
                setPage={setPage}
                onSkip={onSkip}
            />
            <Modal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
