import React from 'react';
import styles from './toggle.module.scss';

export default function Toggle({ label = null, value = false, setValue }) {
  const onClickToggle = (e) => {
    e.preventDefault();
    setValue();
  }

  return (
    <div
      className={`${styles.container}`}
      onClick={onClickToggle}
    >
      {label && <span>{label}</span>}
      <label className={styles.checkbox}>
        <input type="checkbox" checked={value} onChange={onClickToggle} />
        <span></span>
      </label>
    </div>
  )
}
