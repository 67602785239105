export const convertDateToShortString = (ms) => {
    const day = new Date(ms).getDate().toString().length === 1 ? `0${new Date(ms).getDate()}` : new Date(ms).getDate()
    const month = (new Date(ms).getMonth() + 1).toString().length === 1 ? `0${new Date(ms).getMonth() + 1}` : new Date(ms).getMonth() + 1
    const year = new Date(ms).getFullYear().toString().slice(2)
    return `${day}.${month}.${year}`
}

export const convertDateToLongString = (ms) => {
    const day = new Date(ms).getDate().toString().length === 1 ? `0${new Date(ms).getDate()}` : new Date(ms).getDate()
    const month = (new Date(ms).getMonth() + 1).toString().length === 1 ? `0${new Date(ms).getMonth() + 1}` : new Date(ms).getMonth() + 1
    const year = new Date(ms).getFullYear().toString()
    return `${day}.${month}.${year}`
}

export const convertDataToTime = (ms) => {
    const hours = new Date(ms).getHours().toString().length === 1 ? `0${new Date(ms).getHours()}` : new Date(ms).getHours()
    const minutes = new Date(ms).getMinutes().toString().length === 1 ? `0${new Date(ms).getMinutes()}` : new Date(ms).getMinutes()
    return `${hours}:${minutes}`
}

export const convertDataToTransactions = (date) => {
    const period = new Date(date)
    period.setDate(period.getDate());
    const day = period.getDate().toString().length === 1 ? `0${period.getDate()}` : period.getDate()
    const month = (period.getMonth() + 1).toString().length === 1 ? `0${period.getMonth() + 1}` : period.getMonth() + 1
    const year = period.getFullYear().toString()
    return `${day}-${month}-${year}`
}

export const convertDate = (date) => {
    const period = new Date(date)
    period.setDate(period.getDate());
    const day = period.getDate().toString().length === 1 ? `0${period.getDate()}` : period.getDate()
    const month = (period.getMonth() + 1).toString().length === 1 ? `0${period.getMonth() + 1}` : period.getMonth() + 1
    const year = period.getFullYear().toString()
    return `${day}-${month}-${year}`
}

export const convertDateYToString = (date) => {
    if (!date) return null

    const period = new Date(date)
    period.setDate(period.getDate());
    const day = period.getDate().toString().length === 1 ? `0${period.getDate()}` : period.getDate()
    const month = (period.getMonth() + 1).toString().length === 1 ? `0${period.getMonth() + 1}` : period.getMonth() + 1
    const year = period.getFullYear().toString()
    return `${year}-${month}-${day}`;
}