import React, { useEffect, useRef, useState } from 'react'
import Transactions from "./components/Transactions/Transactions";
import {useDispatch, useSelector} from "react-redux";
import transactionsActions from "../../store/transactionsStore/transactionsActions";
import debounce from "../../tools/debounce";
import TransactionsTypes from "../../store/transactionsStore/transactionsTypes";
import { useCurrentBranch } from "../../hooks/useCurrentBranch";


export default function TransactionsComponent() {
    const dispatch = useDispatch()
    const {info} = useSelector(state => state.branchReducer)
    const profile = useSelector(state => state.profileReducer)
    const {page, data, status} = useSelector(state => state.transactionsReducer)
    const {payment_status} = useSelector(state => state.bonusesModalReducer)
    const {customer} = useSelector(state => state.clientInfoReducer)
    const invisParams = useSelector(state => state.filtersReducer)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [phone, setPhone] = useState('')
    const searchRef = useRef(null);
    const { currentRole } = useCurrentBranch()

    const getTransactions = () => {
        dispatch(transactionsActions.getAllTransactions({
            branch_id: info && info.id, page, startDate, endDate, phone,
            roles: [currentRole],
            groupId: info.group_id,
            invisParams,
        }));
    }

    useEffect(() => {
        return () => {
            dispatch({ type: TransactionsTypes.CLEAR_TRANSACTIONS });
        }
    }, []);

    useEffect(() => {
        if (info) {
            debounce(()=>dispatch(transactionsActions.getAllTransactions({
                branch_id: info && info.id,
                page,
                startDate,
                endDate,
                phone,
                roles: [currentRole],
                groupId: info.group_id,
                invisParams,
            })), 100)
        }
    }, [page, info, endDate, startDate, payment_status, invisParams])

    useEffect(() => {
        if (info && page > 1) {
            dispatch(transactionsActions.getAllTransactions({
                branch_id: info && info.id,
                page: 1,
                startDate,
                endDate,
                phone,
                roles: [currentRole],
                groupId: info.group_id,
                invisParams,
            }))
        }
    }, [invisParams])

    useEffect(() => {
        if (info) {
            dispatch({ type: TransactionsTypes.CLEAR_TRANSACTIONS_PAGE })
            debounce(()=>dispatch(transactionsActions.getAllTransactions({
                branch_id: info && info.id, page: 1, startDate, endDate, phone, searchRef,
                roles: [currentRole],
                groupId: info.group_id,
                invisParams,
            })), 1500)
        }
    }, [phone])

    const changePageAction = (page) => {
        dispatch(transactionsActions.changePage(page))
    }

    return (
        <Transactions
            searchRef={searchRef}
            orders={data.orders && data.orders.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            })}
            getTransactions={getTransactions}
            count={data.count || 0}
            page={page}
            profileInfo={profile}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            changePageAction={changePageAction}
            customer={customer}
            info={info}
            status={status}
            phone={phone}
            setPhone={setPhone}
        />
    )
}
