import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import IconClose from './assets/icons/close.png';
import styles from './modal.module.scss';

const modalRoot = document.getElementById('root-modal');

const Modal = ({ children, className = 'modal', el = 'div', open, closable = true, title,
 onClickClose,
 size = 'medium'
}) => {
  const [container] = useState(document.createElement(el));

  container.classList.add(className);

  const setModalOpen = () => {
    container.classList.add('modal--open');
  }

  const setModalClose = () => {
    container.classList.remove('modal--open');
  }

  const onClickCloseModal = () => {
    onClickClose();
  }

  useEffect(() => {
    if (modalRoot) {
      modalRoot.appendChild(container);
    }
    return () => {
      if (modalRoot) {
        modalRoot.removeChild(container);
      }
    }
  }, [])

  useEffect(() => {
    open ? setModalOpen() : setModalClose();
  }, [open])

  const renderModal = () => {
    if (!open) return false;

    return (
      <div className={`${styles['modal__paper']} ${styles[`modal__paper--${size}`]}`}>
        <div className={`${styles['modal__header']} ${title ? styles['modal__header--border-bottom'] : ''}`}>
          {title && <h6 className="modal__title">{title}</h6>}

          {closable &&
          <button onClick={onClickCloseModal} className={styles['modal__button-close']}>
            <img src={IconClose} alt="" />
          </button>
          }
        </div>

        <div className={styles['modal__body']}>
          {children}
        </div>
      </div>
    )
  }

  return ReactDOM.createPortal(renderModal(), container)
}

export default Modal;
