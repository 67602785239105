import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import adsActions from "../../../../store/adsStore/adsActions";
import { formatDate } from "../../../../helpers";
import ComparisonLineChart from "../ComparisonLineChart/ComparisonLineChart";
import ColumnChart from "./ColumnChart";
import styles from './comparison.module.scss';

const options = {
  isStacked: true,
  colors: ['#5769EB', '#73D389'],
  legend: {position: 'none'},
  vAxis: {
    format: '0',
    textStyle: {
      fontSize: 14,
      color: '#576478'
    }
  },
  hAxis: {
    textStyle: {
      fontSize: 14,
      color: '#576478'
    }
  },

  tooltip: {
    textStyle: {
      // color: '#448352',
      fontSize: 15,
    }
  },
}

const Comparison = () => {
  const { t, i18n } = useTranslation();
  const [dates, setDates] = useState({
    from: new Date().setFullYear(new Date().getFullYear() - 1),
    to: null,
  });
  const dispatch = useDispatch();
  const {
    comparisons,
    pending,
    chosenAds,
    comparisonsChart,
    revenue_chart,
    transaction_chart,
    clients_chart,
  } = useSelector((state) => state.adsReducer);
  const { info, pending: infoPending } = useSelector((s) => s.branchReducer);
  const [type, changeType] = useState(4);

  useEffect(() => {
    if (chosenAds.length) {
      let dateParams = '';

      if (dates.from) {
        const today = new Date();

        switch (type) {
          case 2:
            const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
            setDates({
              from: lastWeek,
              to: null,
            })
            break;
          case 3:
            setDates({
              from: new Date().setMonth(new Date().getMonth() - 1),
              to: null,
            })
            break;
          case 4:
            setDates({
              from: new Date().setFullYear(new Date().getFullYear() - 1),
              to: null,
            })
            break;
          default:
            setDates({
              from: new Date().setFullYear(new Date().getFullYear() - 1),
              to: null,
            })
        }
      }
    }
  }, [type])

  useEffect(() => {
    if (chosenAds.length) {
      let dateParams = '';

      if (dates.from) {
        const fromDate = new Date(dates.from);
        let month = ((fromDate.getMonth() + 1) < 10) ? `0${fromDate.getMonth() + 1}` : fromDate.getMonth() + 1;
        let date = (fromDate.getDate() < 10) ? `0${fromDate.getDate()}` : fromDate.getDate();
        dateParams += `from=${fromDate.getFullYear()}-${month}-${date}`;
      }

      if (dates.to) {
        const toDate = new Date(dates.to);
        let month = ((toDate.getMonth() + 1) < 10) ? `0${toDate.getMonth() + 1}` : toDate.getMonth() + 1;
        let date = (toDate.getDate() < 10) ? `0${toDate.getDate()}` : toDate.getDate();
        dateParams += `&to=${toDate.getFullYear()}-${month}-${date}`;
      }

      dispatch(adsActions.compareAds(info.id, dateParams, {
        "sales": chosenAds.map((a) => ({ id: a.id, title: a.title }))
      }, type))
    }
  }, [dates, i18n.language])

  const changeDate = (type, date) => {
    switch (type) {
      case 1:
        setDates({...dates, from: date});
        break;
      case 2:
        setDates({...dates, to: date});
        break;
      default: return
    }
  }

  return (
    <div className={styles.container}>
      <h1>
        <span>{t('NSAds.comprAdsActs')}</span>
      </h1>
      <div style={{ margin: '10px 0', width: '100%' }}></div>
      <div className={styles.topData}>
        <div className={`data-view ${pending ? 'data-view--loading' : ''}`}>
          <div className="data-view__column">

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSAds.amountAdsActs')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{comparisons.length}</span>
              </div>
            </div>

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSGeneral.clients')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{comparisons.reduce((prev, next) => {
                  return prev += next?.report?.clients_count.NEW || 0 + next?.report?.clients_count.RETURNED || 0
                }, 0)}</span>
              </div>
            </div>

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSGeneral.leads')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{comparisons.reduce((prev, next) => {
                  let count = 0;
                  next?.report?.users.forEach((u) => count += u.count);
                  return prev += count
                }, 0)}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <table className={`table ${styles.table}`}>
          <thead>
          <tr>
            <th>{t('period')}</th>
            <th>{t('title')}</th>
            <th>{t('NSCumulative.amount')}</th>
            <th>{t('NSGeneral.clients')}</th>
            <th>{t('NSGeneral.leads')}</th>
            <th>{t('status')}</th>
          </tr>
          </thead>
          <tbody>
          {chosenAds.length ? chosenAds.map((c) => (
            <tr key={c.id}>
              <td>
                {c?.activated_at > 0 && <span>от {formatDate(c.activated_at, { year: 'numeric', month: 'numeric', day: 'numeric' })}</span>}
                {c?.expired_at > 0 && <span>до {formatDate(c.expired_at, { year: 'numeric', month: 'numeric', day: 'numeric' })}</span>}
              </td>
              <td>{c?.title}</td>
              <td>{c?.payload?.promo?.bonus}</td>
              <td>{c?.clients_count}</td>
              <td>{c?.users_count}</td>
              <td>
                <div className={`${styles.status} ${c.active ? styles.statusActive : ''}`}>
                  <span>{c.active ? t('active') : t('disabled')}</span>
                </div>
              </td>
            </tr>
          )) : (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      {comparisonsChart.length ? (
        <ComparisonLineChart
          title={t('NSGeneral.leads1')}
          tooltipMessage={t('NSGeneral.leads1')}
          showTypes
          type={type}
          data={comparisonsChart}
          changePeriod={changeType}
          dates={dates}
          changeDate={changeDate}
        />
      ) : null}

      {revenue_chart.length ? (
        <ColumnChart
          data={revenue_chart}
          options={options}
          title={t('NSSidebar.profit')}
        />
      ) : null}
      {transaction_chart.length ? (
        <ColumnChart
          data={transaction_chart}
          options={options}
          title={t('NSSidebar.transactions')}
        />
      ) : null}

      {clients_chart.length ? (
        <ColumnChart
          data={clients_chart}
          options={options}
          title={t('NSAds.amountNewLoyal')}
          titles={[t('NSAds.amountLoyal'), t('NSAds.amountNew')]}
          separated
        />
      ) : null}

    </div>
  )
}

export default Comparison;
