import React from 'react';
import Select from 'react-dropdown-select';
import './Filter.css';
import styled from "@emotion/styled";
import styles from './Filter.css'

const FilterCity = (props) => (
  <div className={styles.container}>
  <StyledSelect
      width='50px'
      multi
      placeholder='По городам'
      className='custom-select'
      color={"#62C68A"}
      keepSelectedInList={true}
      closeOnSelect={true}
      {...props}
   />
   </div>
);

const FilterBranch = (props) => (
  <div className={styles.container}>
  <StyledSelect 
      multi
      placeholder='По филиалам' 
      className='custom-select' 
      color={"#62C68A"}
      keepSelectedInList={true}
      closeOnSelect={true}
      {...props}
      />
  </div>
);

export { FilterCity, FilterBranch };


export const StyledSelect = styled(Select)`
  //background: #333;
    border-radius: 6px !important;
    border: 1px solid #62C68A !important;
    padding: 12px 18px 10px 24px !important;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    .react-dropdown-select-option {
        padding: 2px 4px;
    }
  .react-dropdown-select-dropdown-handle {
    color: white;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #62C68A;
    border-radius: 0 6px 6px 6px;
  }
`;

