import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import surveyActions from "../../store/surveyStore/surveyActions";
import ButtonOutlined from "../FormElements/ButtonOutlined";
import { computeSkipElements } from "../../tools/utils";
import { PENDING } from "../../store/globalStatuses/globalStatuses";
import Pagination from "../Pagination";
import styles from './surveyTesters.module.scss';

const backSvg = (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1L2 10L11 19" strokeWidth="2" strokeLinecap="round"/>
    </svg>
);

const checkedSvg =(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11.5" stroke="url(#paint01_linear)"/>
        <circle cx="12" cy="12" r="8" fill="url(#paint11_linear)"/>
        <defs>
            <linearGradient id="paint01_linear" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
            <linearGradient id="paint11_linear" x1="12" y1="4" x2="12" y2="20" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
        </defs>
    </svg>
);

const checkSvg = (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 4.5C0.5 2.567 2.067 1 4 1H19C20.933 1 22.5 2.567 22.5 4.5V19.5C22.5 21.433 20.933 23 19 23H4C2.067 23 0.5 21.433 0.5 19.5V4.5Z" fill="url(#linear01)" stroke="url(#linear11)"/>
        <path d="M5 12.4231L10.2 17.5L18 6.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
            <linearGradient id="linear01" x1="11.5" y1="0.5" x2="11.5" y2="23.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
            <linearGradient id="linear11" x1="11.5" y1="0.5" x2="11.5" y2="23.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
        </defs>
    </svg>
);

const textSvg = (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 9C0.5 8.17157 1.17157 7.5 2 7.5H15C15.8284 7.5 16.5 8.17157 16.5 9C16.5 9.82843 15.8284 10.5 15 10.5H2C1.17157 10.5 0.5 9.82843 0.5 9Z" fill="url(#paint0_linear)" stroke="url(#paint1_linear)"/>
        <path d="M0.5 16.375C0.5 15.5466 1.17157 14.875 2 14.875H10C10.8284 14.875 11.5 15.5466 11.5 16.375C11.5 17.2034 10.8284 17.875 10 17.875H2C1.17157 17.875 0.5 17.2034 0.5 16.375Z" fill="url(#paint2_linear)" stroke="url(#paint3_linear)"/>
        <path d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H22C22.8284 0.5 23.5 1.17157 23.5 2C23.5 2.82843 22.8284 3.5 22 3.5H2C1.17157 3.5 0.5 2.82843 0.5 2Z" fill="url(#paint4_linear)" stroke="url(#paint5_linear)"/>
        <defs>
            <linearGradient id="paint0_linear" x1="8.5" y1="7" x2="8.5" y2="11" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="8.5" y1="7" x2="8.5" y2="11" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="6" y1="14.375" x2="6" y2="18.375" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="6" y1="14.375" x2="6" y2="18.375" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
            <linearGradient id="paint4_linear" x1="12" y1="0" x2="12" y2="4" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
            <linearGradient id="paint5_linear" x1="12" y1="0" x2="12" y2="4" gradientUnits="userSpaceOnUse">
                <stop stopColor="#73D389"/>
                <stop offset="1" stopColor="#4EB78B"/>
            </linearGradient>
        </defs>
    </svg>
);

const emptySvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11.5" stroke="#AFAFAB"/>
        <circle cx="12" cy="12" r="8" fill="#AFAFAB"/>
    </svg>
);

const testIcon = {
    SINGLE_CHOICE: checkedSvg,
    MULTIPLE_CHOICE: checkSvg,
    TEXT_FIELD: textSvg,
}

export const surveyTester = (t, tester, i = 1, page = 1, onChooseTester= null) => {
    return (
        <div key={tester.id} className={styles.tester}>
            <p>{page === 1 ? i + 1 : (page - 1) * 3 + i + 1} Респондент</p>
            <div className={styles.testerInfo}>
                <p>
                    <span>{t('NSRespondents.name')}:</span>
                    <b>
                        {!tester.author.first_name && !tester.author.last_name ? t('client') : `${tester.author.first_name} ${tester.author.last_name}`}
                    </b>
                </p>
                <p><span>{t('NSRespondents.transactions')}:</span><b>{tester.author.transactions_count}</b></p>
                <p><span>{t('NSRespondents.answers')}: </span><b>{tester.answered_survey_count}/{tester.questions_count}</b></p>
            </div>
            {onChooseTester && (
                <ButtonOutlined
                    color="#1D1F23"
                    variant="green"
                    onClick={onChooseTester(tester, i)}
                >
                  {t('NSRespondents.watch')}
                </ButtonOutlined>
            )}
        </div>
    );
}

const SurveyTesters = ({ id }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [currentTester, setCurrentTester] = useState(null);
    const { tester } = useSelector((s) => s.surveyReducer);

    useEffect(() => {
        const queryParams = `limit=${tester.limit}&skip=${computeSkipElements({page_index: page, limit: tester.limit})}`;
        dispatch(surveyActions.getSurveyTesters(id, queryParams))

        return () => {
            dispatch(surveyActions.clearSurveyTesters());
        }
    }, [id]);

    const onSkip = (quantityOfSkip, cb) => {
        const queryParams = `limit=${tester.limit}&skip=${quantityOfSkip}`;
        dispatch(surveyActions.getSurveyTesters(id, queryParams))
        cb();
    }

    const onChooseTester = (tester, i) => () => {
        setCurrentTester(Object.assign({index: i}, tester));
    }

    const removeChosenTester = () => setCurrentTester(null);

    const renderTesters = () => {
        return (
            <div className={currentTester ? styles.hide : ''}>
                <div style={{ height: '663px' }}>
                    {tester.list.map((tester, i) => surveyTester(t, tester, i, page, onChooseTester))}
                </div>
                <Pagination
                    count={tester.count}
                    limit={tester.limit}
                    max={4}
                    loading={tester.status === PENDING}
                    page={page}
                    setPage={setPage}
                    onSkip={onSkip}
                />
            </div>
        )
    }

    const showAnswers = (result, t) => {
        if (!!result.answers && result.answers.length) {
            return result.answers.map((answer) => (
                <div>
                    {showIcon(result)} <span>{answer.content}</span>
                </div>
            ))
        }
        return(
            <div>
                <i>{emptySvg}</i> <span>{t('noAnswer')}</span>
            </div>
        )
    }
    const showIcon = (result) => {
        if (!!result.answers && result.answers.length) return <i>{testIcon[result.question.type]}</i>
    }

    const renderChosenTester = () => {
        return (
            <div className={styles.chosenTester}>
                <div className={styles.chosenTesterTop}>
                    <button onClick={removeChosenTester} className={styles.btn}>{backSvg}</button>
                    <div>
                        {surveyTester(t, currentTester, currentTester.index, page, null)}
                    </div>
                </div>
                <div style={{ marginTop: '24px' }}>
                    {currentTester.results.map((result, i) => (
                        <div key={i} className={styles.checked}>
                            <p>{i+1}. {result.question.title}</p>
                            {showAnswers(result, t)}
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            {renderTesters()}
            {currentTester && renderChosenTester()}
        </div>
    )
}

export default SurveyTesters;
