import React from 'react'
import i18n from '../../../i18n';
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import styles from './LineChart.module.css'

export default function PieChart({ type, data, changePeriod, title = i18n.t('NSAnalytics.clients'), tooltipMessage = '', showTypes = true, showDatePicker= true, color = '#4EB78B' }) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <h2>{title}</h2>
            {showTypes && (
              <div className={styles.periods}>
                <p style={type === 1 ? {color: '#4EB78B', margin: 12} : {margin: 12}} onClick={() => changePeriod(1)}>{t('day')}</p>
                <p style={type === 2 ? {
                  color
                } : {}} onClick={() => changePeriod(2)}>{t('week')}</p>
                <p style={type === 3 ? {
                  margin: '0 12px 0 12px',
                  color
                } : {margin: '0 12px 0 12px'}} onClick={() => changePeriod(3)}>{t('month')}</p>
                <p style={type === 4 ? { color } : {}} onClick={() => changePeriod(4)}>{t('year')}</p>
                {showDatePicker && (
                  <div style={{marginLeft: 49}}>
                    <CustomDatePicker type={type}/>
                  </div>
                )}
              </div>
            )}
            <Chart
                chartType="LineChart"
                loader={<div style={{width: '38vw', height: 350}}/>}
                data={data}
                options={{
                    width: 610,
                    height: 350,
                    smoothLine: true,
                    curveType: 'function',
                    colors: [color],
                    legend: {position: 'none'},
                    chartArea: {'width': '90%', 'height': '80%'},
                    lineWidth: 4,
                    tooltip: {isHtml: true, trigger: "focus"},
                    vAxis: {
                      viewWindowMode: 'explicit',
                      viewWindow: {
                        min: 0
                      },
                      format: '0'
                    }
                }}
                legendToggle
            />
        </div>
    )
}