import React, { useEffect } from 'react';
import styles from './analyticsInfo.module.scss'
import { useHistory } from "react-router-dom";
import { PENDING, SUCCESS } from "../../../../store/globalStatuses/globalStatuses";
import { convertDateToLongString, convertDateToShortString } from "../../../../tools/convertDateToString";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import crossActions from "../../../../store/crossStore/crossActions";
import Pagination from "../../../../components/Pagination";

const arrows = (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.4 13C4.4 13.3314 4.66863 13.6 5 13.6C5.33137 13.6 5.6 13.3314 5.6 13L4.4 13ZM5.42426 0.575736C5.18995 0.341421 4.81005 0.341421 4.57574 0.575736L0.757359 4.39411C0.523045 4.62843 0.523045 5.00833 0.757359 5.24264C0.991674 5.47696 1.37157 5.47696 1.60589 5.24264L5 1.84853L8.39411 5.24264C8.62843 5.47696 9.00833 5.47696 9.24264 5.24264C9.47696 5.00833 9.47696 4.62843 9.24264 4.39411L5.42426 0.575736ZM5.6 13L5.6 1L4.4 1L4.4 13L5.6 13Z" fill="black"/>
    <path d="M11.4 1C11.4 0.668629 11.6686 0.4 12 0.4C12.3314 0.4 12.6 0.668629 12.6 1L11.4 1ZM12.4243 13.4243C12.19 13.6586 11.8101 13.6586 11.5757 13.4243L7.75736 9.60589C7.52304 9.37157 7.52304 8.99167 7.75736 8.75736C7.99167 8.52304 8.37157 8.52304 8.60589 8.75736L12 12.1515L15.3941 8.75736C15.6284 8.52304 16.0083 8.52304 16.2426 8.75736C16.477 8.99167 16.477 9.37157 16.2426 9.60589L12.4243 13.4243ZM12.6 1L12.6 13L11.4 13L11.4 1L12.6 1Z" fill="black"/>
  </svg>
);

const AnalyticsInfo = () => {
  const {
    crossMarketingAnalyticClientsPending,
    crossMarketingAnalyticClients,
    crossMarketingAnalytic,
  } = useSelector((s) => s.crossReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { info, pending } = useSelector(s => s.branchReducer);
  const analytic = crossMarketingAnalytic.current;

  useEffect(() => {
    if (info && info.id) {
      dispatch(crossActions.getCrossMarketingAnalyticClientsList(info.id, crossMarketingAnalyticClients.params));
    }
  }, [info, crossMarketingAnalytic.current, crossMarketingAnalyticClients.params]);

  const changeFilter = (value) => () => {
    dispatch(crossActions.setCrossMarketingAnalyticClientsParams({name: 'customer_filter_type', value: value}));
  }

  const onSkip = (quantityOfSkip, cb) => {
    if (info && info.id) {
      dispatch(crossActions.setCrossMarketingAnalyticClientsParams({name: 'skip', value: quantityOfSkip}));
      cb();
    }
  }

  return (
    <div className={styles.container}>
      <i aria-hidden="true"
         style={{position: "absolute", left: 6, top: 20, cursor: 'pointer'}}
         onClick={() => history.goBack()}>
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 1L2 10L11 19" stroke="#1D1F23" strokeWidth="2" strokeLinecap="round"/>
        </svg>
      </i>
      <div className={styles.dataWrapper}>
        <div className={styles.dataWrapperColumn}>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.title')}</p>
            <p className={styles.span}>{analytic.branch_name}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.cat')}</p>
            <p className={styles.span}>{analytic.category_name || '-'}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.city')}</p>
            <p className={styles.span}>{analytic.city_name || '-'}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.clCount')}</p>
            <p className={styles.span}>{analytic.total_clients_count}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.newClCount')}</p>
            <p className={styles.span} style={{color: '#4EB78B'}}>{analytic.new_clients_count}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.loyalClCount')}</p>
            <p className={styles.span} style={{color: '#705AFB'}}>{analytic.loyalty_clients_count}</p>
          </div>
        </div>
        <div className={styles.dataWrapperColumn}>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.bonusToNew')}</p>
            <p className={styles.span}>{analytic.bonus_for_new_client}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.bonusToLoyal')}</p>
            <p className={styles.span}>{analytic.bonus_for_loyalty_client}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.profitOfNew')}</p>
            <p className={styles.span}>{analytic.new_clients_amount}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.profitOfLoyal')}</p>
            <p className={styles.span}>{analytic.loyalty_clients_amount}</p>
          </div>
          <div className={styles.dataItem}>
            <p className={styles.p}>{t('NSCrossMarketing.totalProfit')}</p>
            <p className={styles.span}>{analytic.total_clients_amount}</p>
          </div>
        </div>
      </div>

      <div className={styles.clients}>
        <h2 className={styles.h2}>{t('clients')}</h2>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>{t('client')}</th>
              <th>{t('NSAuth.phone')}</th>
              <th>{t('status')}</th>
              <th onClick={changeFilter(crossMarketingAnalyticClients.params['customer_filter_type'] > 0 ? -1 : 1)}>{t('NSGeneral.trans')} {arrows}</th>
              {/*<th>{t('bonuses')}</th>*/}
              <th onClick={changeFilter(crossMarketingAnalyticClients.params['customer_filter_type'] > 0 ? -2 : 2)}>{t('NSPurchase.total')} {arrows}</th>
              <th onClick={changeFilter(crossMarketingAnalyticClients.params['customer_filter_type'] > 0 ? -3 : 3)}>{t('NSPurchase.last')} {arrows}</th>
              <th>{t('NSClients.sourceBonuses')}</th>
            </tr>
          </thead>
          <tbody>
          {crossMarketingAnalyticClients.list.map((customer, index) => (
            <tr key={customer.id} onClick={(e) => history.push(`/clients/client/${customer.id}`)}>
              <td>
                {(crossMarketingAnalyticClientsPending || status === pending) &&
                  crossMarketingAnalyticClients.params.page === 1 ? index + 1 : (crossMarketingAnalyticClients.params.page - 1) * crossMarketingAnalyticClients.params.limit + index + 1}
              </td>
              <td>
                {customer.first_name.length && customer.last_name.length ? `${customer.first_name} ${customer.last_name}` : (
                  customer.first_name.length ? customer.first_name : (customer.last_name.length ? customer.last_name : '-')
                )}</td>
              <td>{customer.phone.length ? customer.phone : '-'}</td>
              <td>
                {customer.client_type === "RETURNED"
                  ?
                  <span style={{color: "#4EB78B"}}>{t('loyal')}</span>
                  :
                  (customer.client_type === "NEW" ?
                    <span style={{color: "#6979F8"}}>{t('new')}</span> :
                    <span>-</span>)

                }
              </td>
              <td>{customer.transactions_count}</td>
              {/*<td>{(customer.bonuses && Math.round(customer.bonuses)) || 0}</td>*/}
              <td>{customer.total_amount}</td>
              <td>{convertDateToShortString(customer.last_order)}</td>
              <td>
                {customer.come_from || t('NSComparison.notPoint')}
              </td>
            </tr>
          ))}
          {!crossMarketingAnalyticClients.list.length && (
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              {/*<td>-</td>*/}
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
          </tbody>
        </table>
        <Pagination
          count={crossMarketingAnalyticClients.count}
          limit={crossMarketingAnalyticClients.params.limit}
          max={5}
          loading={crossMarketingAnalyticClientsPending}
          updated={info && info.id}
          page={crossMarketingAnalyticClients.params.page}
          setPage={(page) => dispatch(crossActions.setCrossMarketingAnalyticClientsParams({name: 'page', value: page}))}
          onSkip={onSkip}
        />
      </div>
    </div>
  )
}

// <p style={{margin: '46px 0'}}>{t('NSCrossMarketing.title')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.cat')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.city')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.clCount')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.newClCount')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.loyalClCount')}:</p>
//
// <p style={{margin: '46px 0'}}>{t('NSCrossMarketing.bonusToNew')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.bonusToLoyal')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.profitOfNew')}:</p>
// <p style={{marginBottom: 46}}>{t('NSCrossMarketing.profitOfLoyal')}:</p>
// <p>{t('NSCrossMarketing.totalProfit')}:</p>

export default AnalyticsInfo;
