import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../FormElements/ButtonFilled";
import styles from './success.module.scss';

const Success = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M34.4497 0.953913C22.169 3.61752 10.4534 12.7662 4.88634 24.0413C-3.83873 41.7098 -0.724797 61.2821 12.9919 74.9935C30.3407 92.3355 57.7094 92.3355 75.0582 74.9935C86.0979 63.9588 90.4115 48.4807 86.6912 33.254C85.7182 29.2734 84.9623 28.13 83.3023 28.13C80.8733 28.13 80.3979 29.7707 81.5452 34.1963C82.133 36.4625 82.6133 40.919 82.6133 44.0992C82.6133 55.0588 79.1575 63.3114 71.2642 71.2009C63.3398 79.1222 55.1206 82.5455 44.0251 82.5455C32.9296 82.5455 24.7103 79.1222 16.786 71.2009C8.86161 63.2797 5.43691 55.0636 5.43691 43.9725C5.43691 32.9007 8.85334 24.6942 16.786 16.711C25.2099 8.23388 33.8475 4.97929 46.4293 5.54204C52.4917 5.81343 54.0559 6.16403 59.3391 8.43433C66.1685 11.3693 67.6996 11.4065 68.0187 8.64303C68.2226 6.87212 67.8512 6.5532 62.9767 4.3015C53.9691 0.141124 43.7357 -1.05946 34.4497 0.953913ZM75.6143 14.9518C73.9681 16.7957 66.265 25.9189 58.4956 35.2261C50.7263 44.5332 44.1532 52.1679 43.8893 52.1927C43.6247 52.2175 39.1285 48.2121 33.8978 43.2906C28.6663 38.3698 23.8821 34.3403 23.2646 34.3361C21.5613 34.3258 20.2892 36.2055 21.0155 37.6603C21.6439 38.9201 41.3198 57.6466 43.0624 58.644C43.5917 58.9471 44.4571 58.9471 44.985 58.644C45.8084 58.1715 54.5445 47.9538 77.6802 20.4051C81.5479 15.799 82.6181 14.0756 82.2763 13.0004C81.4894 10.5221 78.8874 11.2846 75.6143 14.9518Z" fill="#6BCD8A"/>
      </svg>
      <div className={styles.title}>{t('thank')}</div>
      <p>{t('NSBonusModal.success')}</p>
      <ButtonFilled className={styles.btn} onClick={closeModal}>{t('close')}</ButtonFilled>
    </div>
  )
}

export default Success