import axios from 'axios';
import cookies from 'react-cookies';
import {APP_MESSAGE, COOKIES} from './helpers';
import { store } from "react-notifications-component";
import i18n from "./i18n";

let JsonBigString = require('json-bigint')({"storeAsString": true});

let base_url = '';

// switch (process.env.NODE_ENV) {
//     case 'production':
//         base_url = 'https://gateway.sagi.kz';
//         break;
//     case 'development':
//         base_url = 'https://test.sagi.kz';
//         break;
//     default:
//         base_url = 'https://test.sagi.kz';
// }

export const BASE_URL = base_url;

export const API_URL = `${BASE_URL}/api/v1`;

const instance = (authorization = true, url = null, token = null) => {
    const axiosInstance = axios.create({
        baseURL: url || API_URL,
        transformResponse: [function (data) {
            // Do whatever you want to transform the data

            return JsonBigString.parse(data);
        }],
    });

    if (authorization && !token) token = cookies.load(COOKIES.TOKEN);

    axiosInstance.interceptors.request.use(
      (config) => {
          config.headers.Authorization = `Bearer ${token}`;
          return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        if (error?.message === APP_MESSAGE.API_CANCELLED) return Promise.reject(APP_MESSAGE.API_CANCELLED)

        store.addNotification({
            title: ``,
            message: `${error?.response?.data?.message ? error?.response?.data?.message : i18n.t('Notify.notRespond')}`,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });

        if (error?.response?.data?.message === 'signature is invalid' ||
            error?.response?.data?.message === 'token contains an invalid number of segments'
        ) {
            cookies.remove(COOKIES.TOKEN, {path: '/'});
            window.history.go();
        }

        if (error?.response?.data?.message === 'Token is expired' && !error.config._retry) {
            try {
                error.config._retry = true;

                const { data } = await refreshToken();

                cookies.save(COOKIES.TOKEN, data.access_token, {path: '/'});
                token = data.access_token;
                return axiosInstance(error.config);
            } catch (e) {
                cookies.remove(COOKIES.TOKEN, {path: '/'});
                window.history.go();
            }
        }

        return Promise.reject(error);
    });

    return axiosInstance;
};

export const login = (body) => {
    return instance(false).post(`/auth/business`, body).then(response => response.data);
}

export const refreshToken = () => {
    return instance().post('/auth/refresh_token').then(response => response.data);
}

export const askCodeForgetPassword = (body) => {
    return instance().post('/auth/business/password/forget', body).then(response => response.data);
}

export const confirmCodeForgetPassword = (body) => {
    return instance().post('/auth/business/password/forget-confirm', body).then(response => response.data);
}

export const  resetPasswordForgetPassword = (body) => {
    return instance().post('/auth/business/password/reset-forgotten-password', body).then(response => response.data);
}

export const  sendBonusForUser = (branchId, customerId, body) => {
    return instance().post(`/branches/${branchId}/customers/${customerId}/bonus`, body).then(response => response.data);
}

export const  getWhatsAppQRUrl = (branchId) => {
    return instance().get(`/branches/${branchId}/mailing/get-screenshot-url`).then(response => response.data);
}

export const  sendBonusesToNewUser = (branchId, body) => {
    return instance().post(`/branches/${branchId}/customer/bonus`, body).then(response => response.data);
}

export const  exportBranchCustomers = (branchId, startDate, endDate) => {
    let newparams = ``;
    if (startDate) {
        if (newparams.length) {
            newparams += `from=${startDate}&`
        } else {
            newparams = `?from=${startDate}&`
        }
    }
    if (endDate) {
        if (newparams.length) {
            newparams += `to=${endDate}`
        } else {
            newparams = `?to=${endDate}`
        }
    }
    return instance().get(`/branches/${branchId}/customer/generate/file${newparams}`).then(response => response.data);
}

export const  exportBranchesTransactionsListExcel = (branchId, params) => {
    let newparams = ``;
    if (params.query) {
        newparams = `?query=${params.query}&`
    }
    if (params.from) {
        if (newparams.length) {
            newparams += `from=${params.from}&`
        } else {
            newparams = `?from=${params.from}&`
        }
    }
    if (params.to) {
        if (newparams.length) {
            newparams += `to=${params.to}`
        } else {
            newparams = `?to=${params.to}`
        }
    }
    return fetch(`${API_URL}/branches/${branchId}/transactions/list/excel${newparams}`, {
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
            Authorization: `Bearer ${cookies.load(COOKIES.TOKEN)}`
        },
        method: 'GET'
    }).then((response) => {
        response.blob().then((blob) => {
            const filename = decodeURI(response.headers.get('Content-Disposition')?.split('filename=')[1] || '')
            if ('msSaveOrOpenBlob' in window.navigator) {
                navigator.msSaveBlob(blob, filename)
            } else {
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = window.URL.createObjectURL(blob)
                a.download = filename +'file.xlsx'
                a.target = '_blank'
                a.click()
                a.remove()
            }
        });

        return {};
    })
}

export default instance;
