import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';

export const useSocials = (t) => {
  const dispatch = useDispatch();

  const branch = useSelector(s => s.branchReducer);

  useEffect(() => {
    formik.resetForm();

    if (branch.info && !branch.pending) {
      formik.setFieldValue('website', branch.info.site);

      branch.info.social_networks.forEach(s => {
        if (s.type === '2gis') {
          formik.setFieldValue('twoGis', s.url);
          return
        }

        formik.setFieldValue(s.type, s.url);
      })
    }
  }, [branch]);

  const formik = useFormik({
    initialValues: {
      website: '',
      instagram: '',
      facebook: '',
      vk: '',
      youtube: '',
      twoGis: '',
      tiktok: '',
    },
    onSubmit: (async (values, { setSubmitting }) => {
      try {
        let data = {
          social_networks: Object.entries(values).map(v => {
            if (v[0] === 'website') return
            if (v[0] === 'twoGis') return {type: '2gis', url: v[1]}

            return {type: v[0], url: v[1]}
          }).filter(value => !!value)
        }

        data['website'] = values.website;

        dispatch(branchActions.changeSocials(branch.info.id, data));
      } catch (e) {
        const res = e.response ? e.response.data : null;

        store.addNotification({
          title: `Error code: ${res ? res.code : 'NULL'}`,
          message: `${res ? res.message : t('Notify.notRespond')}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      }
    })
  });

  return {
    formik,
    pending: branch.pending
  }
}