import React from 'react'
import { useTranslation } from "react-i18next";
import styles from './EmptyData.module.css'

export default function EmptyData({marginTop}) {
  const { t } = useTranslation();

  return (
      <div className={styles.emptyData} style={{marginTop}}>
          <h1>{t('noData')}</h1>
      </div>
  )
}