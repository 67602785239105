import instance from "../../../api";
import queryString from "query-string";

const loyalApi = {
    getLoyalData: async (branch_id, params) => {
        return await instance().get(`/branches/${branch_id}/reports?${params}`).then(res => res.data)
    },
    getAnalyticLoyalData: async (groupId, params, invisParams) => {
        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `&${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery}&${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }

        return await instance().get(`/branches/group/${groupId}/reports?${params}${invisQuery}`).then(res => res.data)
    },
}

export default loyalApi