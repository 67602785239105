import i18n from '../../i18n';
import directoryApi from "./directoryApi";
import directoryTypes from "./directoryTypes";
import { store } from "react-notifications-component";

const directoryActions = {
    getCities: () => dispatch => {
        dispatch({
            type: directoryTypes.GET_CITIES_PENDING
        });

        directoryApi.getCities()
            .then(res => {
                dispatch({
                    type: directoryTypes.GET_CITIES_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                dispatch({
                    type: directoryTypes.GET_CITIES_ERROR
                })
            });
    },
}

export default directoryActions
