import React from 'react';
import Auth from './components/Auth';
import cookies from 'react-cookies';
import { Redirect } from 'react-router-dom';
import IntroLayout from 'components/IntroLayout';
import styles from './authWrapper.module.scss';

import appViewImg from './assets/appView.png';

export default function AuthPage() {
  const logged = !!cookies.load('token');

  if (logged) {
    return <Redirect to="/" />
  }

  return (
    <IntroLayout info={<img src={appViewImg} className={styles.appView} />}>
      <Auth />
    </IntroLayout>
  )
}