import instance from "../../api";

const clientInfoApi = {
    getClientInfo: async (id, info) => {
        return await instance().get(`/branches/${info.id}/customers/${id}?group_id=${info.group_id}`).then(res => res.data)
    },
    getDatabaseClientInfo: async (id, groupId) => {
        return await instance().get(`/branches/group/${groupId}/customers/${id}`).then(res => res.data)
    },
    getClientAward: async (id, info) => {
        return await instance().get(`/branches/${info.id}/customers/${id}/award`).then(res => res.data)
    },
    getClientTransactions: async (id, page,info) => {
        const skip = (page - 1) * 5;
        return await instance().get(`/branches/${info.id}/customers/${id}/transactions?skip=${skip}&limit=5`).then(res => res.data)
    },
    getDatabaseClientTransactions: async (id, page,groupId) => {
        const skip = (page - 1) * 5;
        return await instance().get(`/branches/group/${groupId}/customers/${id}/transactions?skip=${skip}&limit=5`).then(res => res.data)
    }
}

export default clientInfoApi