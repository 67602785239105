import React from 'react';
import { useTranslation } from "react-i18next";
import TextField from "../../../../components/FormElements/TextField";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import ButtonOutlined from "../../../../components/FormElements/ButtonOutlined";
import { useAdsForm } from "../../hooks/useForm";
import styles from './form.module.scss';

const Form = () => {
  const { t, i18n } = useTranslation();
  const { formik, pending, infoPending } = useAdsForm(t, i18n);

  return (
    <div className={styles.container}>
      <h1>{t('NSSidebar.ads')}</h1>
      <form className={styles.form}>
        <fieldset className={styles.fieldset} disabled={infoPending}>
          <div className={styles.formContent}>
            <div>
              <div className={styles.block}>
                <b>{t('NSCumulative.amount')}<span>*</span></b>
                <TextField
                  placeholder="500"
                  width="180px"
                  value={formik.values.payload.bonus}
                  error={(formik.touched.payload && formik.touched.payload.bonus) && (formik.errors.payload && formik.errors.payload.bonus)}
                  onChange={(e) => formik.setFieldValue('payload.bonus', e.target.value)}
                  required
                  // disabled={!active}
                />
                <p className={styles['block__text']}>{t('NSAds.sumBonus')}</p>
              </div>
              <div className={styles.block}>
                <b>{t('title')}<span>*</span></b>
                <TextField
                  placeholder={t('NSAds.name')}
                  width="80%"
                  value={formik.values.title}
                  error={formik.touched.title && formik.errors.title}
                  onChange={(e) => formik.setFieldValue('title', e.target.value)}
                  required
                  // disabled={!active}
                />
                <p className={styles['block__text']}>{t('Form.fullTitle')}</p>
              </div>
              <div className={styles.block}>
                <b>{t('NSCorporative.link')}</b>
                <TextField
                  placeholder="https://app.sagi.kz/XLeVMFsfgvTycGYH9"
                  width="90%"
                  value={''}
                  // disabled={!active}
                  // error={error}
                  // onChange={(e) => isFinite(e.target.value) && +e.target.value <= 100 && setInput(e.target.value)}
                  required
                />
                <div style={{position: 'absolute', right: '12px', top: '40px'}}>
                  <QuestionTooltip text={t('NSAds.copy')} />
                </div>
                <p className={styles['block__text']}>{t('NSAds.spreadLink')}</p>
              </div>
            </div>
            <div>
              <div className={styles.block}>
                <b>{t('NSAds.budget')}<span>*</span></b>
                <TextField
                  placeholder={t('NSAds.budgetSum')}
                  value={formik.values.budget}
                  error={formik.touched.budget && formik.errors.budget}
                  onChange={(e) => formik.setFieldValue('budget', e.target.value)}
                  required
                  // disabled={!active}
                />
                <p className={styles['block__text']}>{t('Form.enterSum')}</p>
              </div>
              <div className={`${styles.block} ${styles.block_date}`}>
                <b>{t('NSAds.period')}<span>*</span></b>
                <CustomDatePicker
                  trans={true}
                  tStartDate={formik.values.activate_date}
                  tEndDate={formik.values.expire_date}
                  tSetStartDate={(v) => formik.setFieldValue('activate_date', v)}
                  tSetEndDate={(v) => formik.setFieldValue('expire_date', v)}
                />
              </div>
            </div>
          </div>
          <div className={styles.formFooter}>
            <ButtonFilled
              onClick={formik.handleSubmit}
              loading={pending}
            >{t('save')}</ButtonFilled>
            <ButtonOutlined variant="green" color="black" to="/ads">{t('cancel')}</ButtonOutlined>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export default Form;
