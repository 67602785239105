import i18n from '../../i18n';
import adsApi from "./adsApi";
import adsTypes from "../adsStore/adsTypes";
import { store } from "react-notifications-component";
import { prepareForLineChart } from "../../tools/utils";

const adsActions = {
    createSale: (data, resolve) => dispatch => {
        dispatch({
            type: adsTypes.CREATE_ADS_PENDING
        });

        adsApi.createSale(data)
            .then(res => {
                dispatch({
                    type: adsTypes.CREATE_ADS_SUCCESS
                });
                resolve();
            })
            .catch((e) => {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                dispatch({
                    type: adsTypes.CREATE_ADS_ERROR
                })
            });
    },
    getSalesForBranchGroup: (id, queryParams = '') => dispatch => {
        dispatch({
            type: adsTypes.GET_ADS_LIST_FOR_BRANCH_GROUP_PENDING
        });

        adsApi.getSalesForBranchGroup(id, queryParams)
            .then(res => {
                dispatch({
                    type: adsTypes.GET_ADS_LIST_FOR_BRANCH_GROUP_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                dispatch({
                    type: adsTypes.GET_ADS_LIST_FOR_BRANCH_GROUP_ERROR
                })
            });
    },
    getSaleById: (id) => dispatch => {
        dispatch({
            type: adsTypes.GET_ADS_INFO_PENDING
        });

        adsApi.getSaleById(id)
            .then(res => {
                dispatch({
                    type: adsTypes.GET_ADS_INFO_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                dispatch({
                    type: adsTypes.GET_ADS_INFO_ERROR
                })
            });
    },
    updateSaleStatus: (id, data) => dispatch => {
        dispatch({
            type: adsTypes.UPDATE_ADS_INFO_PENDING
        });

        adsApi.updateSaleStatus(id, data)
            .then(res => {
                dispatch({
                    type: adsTypes.UPDATE_ADS_INFO_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                dispatch({
                    type: adsTypes.UPDATE_ADS_INFO_ERROR
                })
            });
    },
    getSaleReport: (id, queryParams, type) => dispatch => {
        dispatch({
            type: adsTypes.GET_SALE_REPORT_PENDING
        });

        adsApi.getSaleReport(id, queryParams)
            .then(res => {
                const transformedUsersChart = prepareForLineChart(res.data.users, 'count', 'date', i18n.t('NSSidebar.leads1'), type);
                const transformedClientsChart = prepareForLineChart(res.data.clients, 'count', 'date', i18n.t('NSSidebar.clients1'), type);

                dispatch({
                    type: adsTypes.GET_SALE_REPORT_SUCCESS,
                    payload: {
                        usersChart: transformedUsersChart,
                        clientsChart: transformedClientsChart,
                        revenue_amount: res.data.revenue_amount,
                        transactions: res.data.transactions,
                        clients_count: res.data.clients_count,
                    },
                });
            })
            .catch((e) => {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                dispatch({
                    type: adsTypes.GET_SALE_REPORT_ERROR
                })
            });
    },
    compareAds: (id, queryParams, body, type) => dispatch => {
        dispatch({
            type: adsTypes.GET_COMPARISONS_PENDING
        });

        adsApi.compareAds(id, queryParams, body)
            .then(res => {
                let comparisons = [];
                let revenue_chart = [[i18n.t('profit'), i18n.t('new'), i18n.t('loyals')]];
                let transaction_chart = [[i18n.t('transactions'), i18n.t('new'), i18n.t('loyals')]];
                let clients_chart = [[i18n.t('clients'), i18n.t('new'), i18n.t('loyals')]];

                if (res.data instanceof Array) {

                    res.data.forEach((comparison_object) => {
                        comparisons.push(prepareForLineChart(comparison_object.report.users || [], 'count', 'date', i18n.t('NSSidebar.leads1'), type));
                        revenue_chart.push([comparison_object.title, comparison_object.report.revenue_amount.NEW || 0, comparison_object.report.revenue_amount.RETURNED || 0]);
                        transaction_chart.push([comparison_object.title, comparison_object.report.transactions.NEW || 0, comparison_object.report.transactions.RETURNED || 0]);
                        clients_chart.push([comparison_object.title, comparison_object.report.clients_count.NEW || 0, comparison_object.report.clients_count.RETURNED || 0]);
                    })

                    let transformedComparisons = [];

                    if (comparisons.length) transformedComparisons = comparisons[0];

                    res.data.forEach((ads, index) => {
                        const title = `${i18n.t('NSSidebar.leads2')} ${ads.title}`
                        comparisons[index].forEach((compr, comprIndex) => {
                            if (comprIndex < 1) {
                                comparisons[0][comprIndex][index + 1] = title;
                            } else {
                                comparisons[0][comprIndex][index + 1] = compr[1];
                            }
                        });
                    })
                }

                dispatch({
                    type: adsTypes.GET_COMPARISONS_SUCCESS,
                    payload: {
                        ads: res.data instanceof Array ? res.data : [],
                        adsChart: comparisons[0],
                        revenue_chart,
                        transaction_chart,
                        clients_chart,
                    },
                });
            })
            .catch((e) => {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                dispatch({
                    type: adsTypes.GET_COMPARISONS_ERROR
                })
            });
    },
    choseAds: (ads) => dispatch => {
        dispatch({
            type: adsTypes.CHOSE_ADS,
            payload: ads,
        });
    },
    removeAds: (ads) => dispatch => {
        dispatch({
            type: adsTypes.REMOVE_ADS,
            payload: ads.id,
        });
    },
    clearAds: () => dispatch => {
        dispatch({
            type: adsTypes.CLEAR_ADS
        });
    },
}

export default adsActions
