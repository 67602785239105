import i18n from '../../i18n';
import branchTypes from "./branchTypes";
import branchApi from "./branchApi";
import { store } from 'react-notifications-component';
import { hasResponseErrorMessage } from '../../helpers';
import transactionsType from "../transactionsStore/transactionsTypes";
import clientsTypes from "../clientsStore/clientsTypes";
import clientInfoTypes from "../clientInfoStore/clientInfoTypes";

const branchActions = {
    getBranch: (id, setBranchEnabledModal) => dispatch => {
        dispatch({
            type: branchTypes.GET_BRANCH_PENDING
        });

        branchApi.getBranch(id)
            .then(res => {
                if (!res.data.enabled) {
                    setBranchEnabledModal(true);
                    return null
                }
                dispatch({
                    type: branchTypes.GET_BRANCH_SUCCESS,
                    payload: res.data
                });
                dispatch({
                    type: transactionsType.CHANGE_TRANSACTIONS_PAGE,
                    payload: 1
                })
                dispatch({
                    type: clientsTypes.CHANGE_CLIENTS_PAGE,
                    payload: 1
                })
                dispatch({
                    type: clientInfoTypes.CHANGE_CLIENT_TRANSACTION_PAGE,
                    payload: 1
                })
            })
            .catch(() => {
                dispatch({
                    type: branchTypes.GET_BRANCH_ERROR
                })
            });
    },
    changeNotification: (id, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_BRANCH_WORKING_HOURS_PENDING
        });

        branchApi.changeNotification(id, data)
          .then(res => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_CONFIGURATION_SUCCESS,
                  payload: res.data
              });
              dispatch(branchActions.getBranch(id));
          })
          .catch((e) => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_CONFIGURATION_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },
    changeReportsContacts: (id, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_REPORTS_CONTACTS_PENDING
        });

        branchApi.changeReportsContacts(id, data)
          .then(res => {
              dispatch({
                  type: branchTypes.CHANGE_REPORTS_CONTACTS_SUCCESS,
                  payload: res.data
              });
              dispatch(branchActions.getBranch(id));
          })
          .catch((e) => {
              dispatch({
                  type: branchTypes.CHANGE_REPORTS_CONTACTS_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },
    changeLocales: (id, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_BRANCH_WORKING_HOURS_PENDING
        });

        branchApi.changeLocales(id, data)
          .then(res => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_CONFIGURATION_SUCCESS,
                  payload: res.data
              });
              dispatch(branchActions.getBranch(id));
          })
          .catch((e) => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_CONFIGURATION_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },
    changeWorkingHours: (id, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_BRANCH_WORKING_HOURS_PENDING
        });

        branchApi.changeWorkingHours(id, data)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_WORKING_HOURS_SUCCESS,
                    payload: res.data
                });
                dispatch(branchActions.getBranch(id));
            })
            .catch((e) => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_WORKING_HOURS_ERROR
                });
                if (hasResponseErrorMessage(e)) {
                    if (e.response.data.message === 'either day off or all day working') {
                        store.addNotification({
                            title: i18n.t('Notify.wrongFields'),
                            message: i18n.t('Notify.wrongWorkHours'),
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    } else if (e.response.data.message) {
                        store.addNotification({
                            title: `Ошибка`,
                            message: e.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            });
    },
    updateDateLimitForSecurity: (branchId, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_BRANCH_INFO_PENDING
        });

        branchApi.updateDateLimitForSecurity(branchId, data)
          .then(res => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_INFO_SUCCESS,
                  payload: res.data
              });
          })
          .catch((e) => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_INFO_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  store.addNotification({
                      title: i18n.t('error'),
                      message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                          duration: 5000,
                          onScreen: true
                      }
                  });
              }
          });
    },

    updateTxCashbackLimitForSecurity: (branchId, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_BRANCH_INFO_PENDING
        });

        branchApi.updateTxCashbackLimitForSecurity(branchId, data)
          .then(res => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_INFO_SUCCESS,
                  payload: res.data
              });
          })
          .catch((e) => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_INFO_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  store.addNotification({
                      title: i18n.t('error'),
                      message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                          duration: 5000,
                          onScreen: true
                      }
                  });
              }
          });
    },

    changeWhatsappProhibitions: (branchId, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_PENDING
        });

        branchApi.changeWhatsappProhibitions(branchId, data)
          .then(res => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_SUCCESS,
                  payload: res.data
              });
          })
          .catch((e) => {
              dispatch({
                  type: branchTypes.CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  store.addNotification({
                      title: i18n.t('error'),
                      message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                          duration: 5000,
                          onScreen: true
                      }
                  });
              }
          });
    },

    changeInfo: (id, data) => dispatch => {
        dispatch({
            type: branchTypes.CHANGE_BRANCH_INFO_PENDING
        });

        branchApi.changeInfo(id, data)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_INFO_SUCCESS,
                    payload: res.data
                });
            })
            .catch((e) => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_INFO_ERROR
                });
                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    addBanner: (id, formData) => dispatch => {
        dispatch({type: branchTypes.ADD_BRANCH_BANNER_PENDING});

        branchApi.addBanner(id, formData)
            .then(res => {
                dispatch({
                    type: branchTypes.ADD_BRANCH_BANNER_SUCCESS,
                    payload: res.data
                });
            })
            .catch((e) => {
                dispatch({type: branchTypes.ADD_BRANCH_BANNER_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    changeBrandedBonusCardBanner: (id, formData) => dispatch => {
        dispatch({type: branchTypes.CHANGE_BRANDED_BONUS_CARD_BANNER_PENDING});

        branchApi.changeBrandedBonusCardBanner(id, formData)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANDED_BONUS_CARD_BANNER_SUCCESS,
                    payload: res.data
                });

                console.log(res.data)
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANDED_BONUS_CARD_BANNER_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    deleteBanner: (branchId, bannerId) => dispatch => {
        dispatch({type: branchTypes.DELETE_BRANCH_BANNER_PENDING});

        branchApi.deleteBanner(branchId, bannerId)
            .then(res => {
                dispatch({
                    type: branchTypes.DELETE_BRANCH_BANNER_SUCCESS,
                    payload: bannerId
                });
            })
            .catch((e) => {
                dispatch({type: branchTypes.DELETE_BRANCH_BANNER_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    changeIcon: (branchId, formData) => dispatch => {
        dispatch({type: branchTypes.CHANGE_BRANCH_ICON_PENDING});

        branchApi.changeIcon(branchId, formData)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_ICON_SUCCESS,
                    payload: res.data.url
                });
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANCH_ICON_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    changeSocials: (branchId, data) => dispatch => {
        dispatch({type: branchTypes.CHANGE_BRANCH_SOCIALS_PENDING});

        branchApi.changeSocials(branchId, data)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_SOCIALS_SUCCESS,
                    payload: data
                });
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANCH_SOCIALS_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    changePrivateCashbackExpireDate: (branchId, data, resolve, reject) => dispatch => {
        dispatch({type: branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_PENDING});

        branchApi.changePrivateCashbackExpireDate(branchId, data)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_SUCCESS,
                    payload: res.data
                });
                resolve();
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
                reject();
            });
    },
    changePrivateCashbackSendCodeToCustomer: (branchId, data, resolve, reject) => dispatch => {
        dispatch({type: branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_PENDING});

        branchApi.changePrivateCashbackSendCodeToCustomer(branchId, data)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_SUCCESS,
                    payload: res.data
                });
                resolve();
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
                reject();
            });
    },
    changeAutoMailing: (branchId, data, resolve, reject) => dispatch => {
        dispatch({type: branchTypes.CHANGE_BRANCH_AUTOMAILING_PENDING});
        console.log(data, 'DATA')
        branchApi.changeAutoMailing(branchId, data)
            .then(res => {
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_AUTOMAILING_SUCCESS,
                    payload: res.data
                });
                resolve();
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANCH_AUTOMAILING_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
                reject();
            });
    },
    updateBonusForBirthday: (branchId, data, resolve, reject) => dispatch => {
        dispatch({type: branchTypes.UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_PENDING});

        branchApi.updateBonusForBirthday(branchId, data)
            .then(res => {
                dispatch({
                    type: branchTypes.UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_SUCCESS,
                    payload: res.data
                });
                resolve();
            })
            .catch((e) => {
                dispatch({type: branchTypes.UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
                reject();
            });
    },


    deleteBonusPromocode: (branchId, codeId, data, resolve, reject) => dispatch => {
        dispatch({type: branchTypes.DELETE_BRANCH_BONUS_PROMOCODE_PENDING});

        branchApi.deleteBonusPromocode(branchId, codeId, data)
          .then(res => {
              dispatch({
                  type: branchTypes.DELETE_BRANCH_BONUS_PROMOCODE_SUCCESS,
                  payload: codeId,
              });
              resolve();
          })
          .catch((e) => {
              dispatch({type: branchTypes.DELETE_BRANCH_BONUS_PROMOCODE_ERROR});

              if (hasResponseErrorMessage(e)) {
                  store.addNotification({
                      title: i18n.t('error'),
                      message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                          duration: 5000,
                          onScreen: true
                      }
                  });
              }
              reject();
          });
    },
    postBonusPromocode: (branchId, data, resolve, reject) => dispatch => {
        dispatch({type: branchTypes.POST_BRANCH_BONUS_PROMOCODE_PENDING});

        branchApi.postBonusPromocode(branchId, data)
          .then(res => {
              dispatch({
                  type: branchTypes.POST_BRANCH_BONUS_PROMOCODE_SUCCESS,
                  payload: res.data
              });
              resolve();
          })
          .catch((e) => {
              dispatch({type: branchTypes.POST_BRANCH_BONUS_PROMOCODE_ERROR});

              if (hasResponseErrorMessage(e)) {
                  store.addNotification({
                      title: i18n.t('error'),
                      message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                          duration: 5000,
                          onScreen: true
                      }
                  });
              }

              reject();
          });
    },

    changeWhatsAppHeader: (branchId, data, resolve, reject) => dispatch => {
        dispatch({type: branchTypes.CHANGE_BRANCH_WHATSAPP_HEADER_PENDING});

        branchApi.changeWhatsappHeader(branchId, data)
            .then(res => {
                console.log(data, res)
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_WHATSAPP_HEADER_SUCCESS,
                    payload: data
                });
                resolve();
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANCH_WHATSAPP_HEADER_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
                reject();
            });
    },
    activatedAwardConfig: (branchId, data) => dispatch => {
        dispatch({ type: branchTypes.ACTIVATED_BRANCH_AWARD_CONFIG_PENDING });

        branchApi.activatedAwardConfig(branchId, data)
          .then((response) => {
              dispatch({ type: branchTypes.ACTIVATED_BRANCH_AWARD_CONFIG_SUCCESS, payload: response.data });
          })
          .catch(() => {
              dispatch({ type: branchTypes.ACTIVATED_BRANCH_AWARD_CONFIG_ERROR })
          })
    },
    deActivatedAwardConfig: (branchId) => dispatch => {
        dispatch({ type: branchTypes.DEACTIVATED_BRANCH_AWARD_CONFIG_PENDING });

        branchApi.deactivatedAwardConfig(branchId)
          .then(() => {
              dispatch({ type: branchTypes.DEACTIVATED_BRANCH_AWARD_CONFIG_SUCCESS });
          })
          .catch(() => {
              dispatch({ type: branchTypes.DEACTIVATED_BRANCH_AWARD_CONFIG_ERROR })
          })
    },
    changeContractBasic: (branchId, data) => dispatch => {
        dispatch({ type: branchTypes.CHANGE_CONTRACT_BASIC_PENDING });

        branchApi.changeContractBasic(branchId, data)
            .then((response) => {
                dispatch({ type: branchTypes.CHANGE_CONTRACT_BASIC_SUCCESS, payload: response.data });
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_CONTRACT_BASIC_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    changeContractIncreased: (branchId, data) => dispatch => {
        dispatch({ type: branchTypes.CHANGE_CONTRACT_INCREASED_PENDING });

        branchApi.changeContractIncreased(branchId, data)
            .then((response) => {
                dispatch({ type: branchTypes.CHANGE_CONTRACT_INCREASED_SUCCESS, payload: response.data });
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_CONTRACT_INCREASED_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    },
    changeBonusDelay: (branchId, data) => dispatch => {
        dispatch({ type: branchTypes.CHANGE_BRANCH_BONUS_DELAY_PENDING });

        branchApi.changeBonusDelay(branchId, data)
            .then((response) => {
                dispatch({ type: branchTypes.CHANGE_BRANCH_BONUS_DELAY_SUCCESS, payload: response.data });
            })
            .catch((e) => {
                dispatch({type: branchTypes.CHANGE_BRANCH_BONUS_DELAY_ERROR});

                if (hasResponseErrorMessage(e)) {
                    store.addNotification({
                        title: i18n.t('error'),
                        message: `${e.response.data.message ? e.response.data.message : i18n.t('Notify.error')}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            });
    }
}

export default branchActions
