import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { kFormatter } from '../../../helpers';
import styles from './giveAward.module.scss';

import roungBgImg from '../assets/roundBg.png';
import giftImg from '../assets/gift.png';
import indicatorImg from '../assets/indicator.png';
import ButtonFilled from '../../FormElements/ButtonFilled';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import { PENDING } from '../../../store/globalStatuses/globalStatuses';

export default function GiveAward({ customer, giveAwardToUser, info }) {
  const { t } = useTranslation();
  const { give_award_status } = useSelector((s) => s.bonusesModalReducer);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>{t('NSPayModal.givePresent')}</p>
        {
          (!!customer.first_name || !!customer.last_name) && (
            <span>{`${customer.first_name} ${customer.last_name}`}</span>
          )
        }
      </div>
      {
        customer.expired_at != 0 ? (
          <CustomDatePicker
            icon={false}
            tStartDate={new Date(customer.activated_at)}
            tEndDate={new Date(customer.expired_at)}
          />
        ) : null
      }

      <div className={styles.award}>
        <div className={styles.awardOut} style={{ backgroundImage: `url(${roungBgImg})` }}>
          <div>
            <div className={styles.gift} style={{ backgroundImage: `url(${giftImg})` }}></div>
            <p className={styles.numbers}>
              {kFormatter(customer.received_stamp_count)}/{kFormatter(customer.stamp_count)}
            </p>
          </div>
          <div className={styles.indicator} style={{ backgroundImage: `url(${indicatorImg})` }}>
            <span>{kFormatter(customer.stamp_count)}</span>
          </div>
        </div>
      </div>

      <div className={styles.sendButton}>
        <ButtonFilled
          onClick={giveAwardToUser}
          loading={give_award_status === PENDING}
        >
          {t('NSPayModal.givePresent')}
        </ButtonFilled>
      </div>
    </div>
  )
}