const RFMTypes = {
    GET_RFM_PENDING: "GET_RFM_PENDING",
    GET_RFM_SUCCESS: "GET_RFM_SUCCESS",
    GET_RFM_ERROR: "GET_RFM_ERROR",

    SAVE_RFM_PENDING: "SAVE_RFM_PENDING",
    SAVE_RFM_SUCCESS: "SAVE_RFM_SUCCESS",
    SAVE_RFM_ERROR: "SAVE_RFM_ERROR",
}

export default RFMTypes