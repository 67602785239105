import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Logo from '../../../../assets/logo.svg'
import CarouselComponent from "./CarouselComponent/CarouselComponent";
import Balance from "./Balance/Balance";
import styles from './AccountInfo.module.css'

export default function AccountInfo() {
  const info = useSelector(s => s.profileReducer.info);
  const dispatch = useDispatch();

  return (
      <div className={styles.container}>
          <Link to="/">
            <img src={Logo}/>
          </Link>
          <CarouselComponent
            branches={info ? info.branches : []}
          />
          {/*<Balance/>*/}
      </div>
  )
}