import instance, { API_URL } from "../../api";
import cookies from "react-cookies";
import { COOKIES } from "../../helpers";

const surveyApi = {
    createSurvey: (params) => {
        return instance().post(`/surveys`, params).then(res => res.data)
    },
    getSurveys: (queryParams) => {
        return instance().get(`/surveys${queryParams ? '?' + queryParams : ''}`).then(res => res.data)
    },
    changeAnswerSurvey: (survey_id) => {
        return instance().post(`/surveys${survey_id}/answer`).then(res => res.data)
    },
    deleteSurvey: (survey_id) => {
        return instance().delete(`/surveys/${survey_id}`).then(res => res.data)
    },
    getSurveyById: (survey_id) => {
        return instance().get(`/surveys/${survey_id}`).then(res => res.data)
    },
    getSurveyTestersById: (survey_id, queryParams) => {
        return instance().get(`/surveys/${survey_id}/testers${queryParams ? '?' + queryParams : ''}`).then(res => res.data)
    },
    getSurveyResultsExcel: (surveyId) => {
        return fetch(`${API_URL}/surveys/${surveyId}/results/excel`, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
                Authorization: `Bearer ${cookies.load(COOKIES.TOKEN)}`
            },
            method: 'GET'
        }).then((response) => {
            response.blob().then((blob) => {
                const filename = decodeURI(response.headers.get('Content-Disposition')?.split('filename=')[1] || '')
                if ('msSaveOrOpenBlob' in window.navigator) {
                    navigator.msSaveBlob(blob, filename)
                } else {
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.href = window.URL.createObjectURL(blob)
                    a.download = filename +'file.xlsx'
                    a.target = '_blank'
                    a.click()
                    a.remove()
                }
            });

            return {};
        })
    }
}

export default surveyApi
