import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import TextField from "../../../../components/FormElements/TextField";
import PageHeader from "../../../../components/PageHeader";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import CustomSelect from "../../../../components/FormElements/Select";
import styles from './profileBonusCross.module.scss';
import Modal from "../../../../portals/Modal";
import { useBonusRef } from "../../hooks/useBonusRef";
import ButtonOutlined from "../../../../components/FormElements/ButtonOutlined";

export default function ProfileBonusRef() {
    const { t } = useTranslation();
    const {
        formik,
        pending,
        crossPending,
        errorModal,
        setErrorModal,
        error,
        options,
        info,
        handleActivation,
        handleDeactivation,
    } = useBonusRef(t);

    return (
        <>
            <PageHeader title={t('NSBonus.refBon')}>
                <div style={{ marginLeft: '50px' }}>
                    <QuestionTooltip text={t('NSBonus.accrAmb')} />
                </div>
            </PageHeader>
            <div className={styles.container}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.blocks}>
                        <div className={styles.leftBlock}>
                            <div className={styles.block}>
                                <TextField
                                  label={t('NSBonus.procentSalefromCl')}
                                  // type="number"
                                  value={formik.values.bonus_percentage + " %"}
                                  disabled={!formik.values.enabled}
                                  error={false}
                                  onChange={(e) => {
                                      let values = "";
                                      let value = "";

                                      if (e.nativeEvent.inputType === "deleteContentBackward") {
                                          values = e.target.value.split("%");
                                          value = values.join("").trim().split(" ").join("");
                                          value = value.slice(0, value.length - 1);
                                      } else {
                                          values = e.target.value.split("%");
                                          value = values.join("").trim().split(" ").join("");
                                      }

                                      formik.setFieldValue('bonus_percentage', +value || '')}

                                  }
                                />
                            </div>

                            <div className={styles.block}>
                                <TextField
                                  label={t('NSBonus.amountAccr')}
                                  // type="number"
                                  value={formik.values.limit}
                                  disabled={!formik.values.enabled}
                                  onChange={(e) => formik.setFieldValue('limit', +e.target.value || '')}
                                />
                            </div>
                        </div>
                        <div className={styles.rightBlock}>
                            <div>
                                {info?.configuration?.referal.enabled && <span className={styles.green}>{t("NSBonus.activated")}</span>}
                                {!info?.configuration?.referal.enabled && <span className={styles.red}>{t("NSComparison.disabled")}</span>}
                            </div>
                            {info?.configuration?.referal.enabled && <ButtonOutlined loading={pending} color="red" variant="red" onClick={handleDeactivation}>{t("disable")}</ButtonOutlined>}
                            {!info?.configuration?.referal.enabled && <ButtonOutlined loading={pending} color="green" variant="green" onClick={handleActivation}>{t("NSComparison.activate")}</ButtonOutlined>}
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <ButtonFilled disabled={error} type="submit" loading={pending || crossPending}>{t('save')}</ButtonFilled>
                    </div>
                </form>
            </div>
            <Modal
              open={errorModal} size="small"
              onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                          loading={pending}
                          onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
