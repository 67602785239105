import React from 'react';
import _ from "lodash";
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import TextField from "../../../../components/FormElements/TextField";
import Checkbox from "../../../../components/FormElements/Checkbox";
import { useSurveyCreate } from "../../hooks/useSurveyCreate";
import ButtonOutlined from "../../../../components/FormElements/ButtonOutlined";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import SurveyQuestion from "./SurveyQuestion";
import styles from './surveyCreate.module.scss';

const backSvg = (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1L2 10L11 19" stroke="#1D1F23" strokeWidth="2" strokeLinecap="round"/>
    </svg>
);

const SurveyCreate = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        formik,
        types, answersType,
        withdraw,
        questionAnswersType,
        options,
        question,
        singChoiceQuestion,
        loading, disabled
    } = useSurveyCreate();

    const onChangeCheckbox = (field) => () => {
        let v = '';
        switch (field) {
            case 'type':
                v = formik.values.type === types.current.FREE ? types.current.PAID : types.current.FREE;
                return formik.setFieldValue(field, v);
            case 'answers_type':
                v = formik.values.answers_type === answersType.current.STATIC ? answersType.current.DYNAMIC : answersType.current.STATIC;
                return formik.setFieldValue(field, v);
            default: return
        }
    }

    const onChangeNumber= (field) => (e) => {
        if (isFinite(e.target.value)) {
            formik.setFieldValue(field, e.target.value);
        }
    }

    const onAddQuestion = () => {
        let questionsArray = null;
        switch (formik.values.answers_type) {
            case answersType.current.STATIC:
                questionsArray = _.concat(formik.values.questions, singChoiceQuestion.current);
                return formik.setFieldValue(`questions`, questionsArray);
            case answersType.current.DYNAMIC:
                questionsArray = _.concat(formik.values.questions, question.current);
                return formik.setFieldValue(`questions`, questionsArray);
            default: return
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Link to="/profile/surveys" className={styles.backBtn}>{backSvg}</Link>
                <h2>{t('NSPoll.create')}</h2>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    label={t('NSPoll.name')}
                    placeholder={t('NSPoll.text')}
                    required

                    value={formik.values.title}
                    error={formik.touched.title && formik.errors.title}
                    onChange={(e) => formik.setFieldValue('title', e.target.value)}
                />
                <div className={styles.block}>
                    <div className={styles['block__title']}>{t('NSPoll.type')} <span>*</span></div>
                    <Checkbox
                        label={t('free')}
                        value={formik.values.type}
                        disabled={false}
                        checked={types.current.FREE === formik.values.type}
                        onChange={onChangeCheckbox('type')}
                    />
                    <div style={{ height: '10px' }} />
                    <Checkbox
                        label={t('NSPoll.bonus')}
                        value={formik.values.type}
                        disabled={false}
                        checked={types.current.PAID === formik.values.type}
                        onChange={onChangeCheckbox('type')}
                    />
                </div>
                {types.current.FREE !== formik.values.type && (
                    <div className={styles.block}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ marginRight: '59px' }}>
                                <TextField
                                    label={t('NSPoll.respondent')}
                                    placeholder="100"
                                    width="105px"
                                    required

                                    value={formik.values.bonus}
                                    error={formik.touched.bonus && formik.errors.bonus}
                                    onChange={onChangeNumber('bonus')}
                                />
                            </div>
                            <div>
                                <TextField
                                    label={t('NSPoll.max')}
                                    placeholder="100"
                                    width="105px"
                                    required

                                    value={formik.values.max_users_count}
                                    error={formik.touched.max_users_count && formik.errors.max_users_count}
                                    onChange={onChangeNumber('max_users_count')}
                                />
                            </div>
                        </div>
                        {!!withdraw && (
                            <p style={{ fontSize: '18px' }}>{t('NSPoll.sumWithdrawn')}: <b style={{ fontWeight: '500' }}>{withdraw.toFixed(2)}</b></p>
                        )}
                    </div>
                )}
                <div className={styles.block}>
                    {formik.values.questions.map((_, index) => (
                        <SurveyQuestion
                            formik={formik}
                            options={options}
                            questionAnswersType={questionAnswersType}
                            key={index}
                            index={index}
                            answersType={answersType}
                        />
                    ))}
                </div>
                <div className={styles.footer}>
                    <ButtonOutlined
                        variant="green"
                        color="#1D1F23"
                        onClick={onAddQuestion}>
                        <span style={{ fontWeight: '500' }}>+ &nbsp; {t('add')}</span>
                    </ButtonOutlined>

                    <div style={{ display: 'flex', marginTop: '34px', paddingTop: '24px', borderTop: '1px solid #E4E7EB' }}>
                        <div style={{ marginRight: '18px' }}><ButtonFilled type="submit" disabled={disabled} loading={loading}>{t('save')}</ButtonFilled></div>
                        <ButtonOutlined onClick={() => history.push('/profile/surveys')} variant="green" color="#1D1F23">&nbsp;{t('cancel')}&nbsp;</ButtonOutlined>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SurveyCreate
