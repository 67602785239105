import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import cookies from 'react-cookies';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { COOKIES, onlyDigits } from '../../../helpers';
import { login } from '../../../api';
import { setAuth } from 'store/authState';
import { Roles } from "../../../config";

export const useAuthForm = () => {
  const [pending, setPending] = useState(false);
  const { t } = useTranslation();

  const [remembered, setRemembered] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: ''
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required(t('Form.enterPhone'))
        .min(11, t('Form.correctPhone'))
        .max(40),
      password: Yup.string()
        .required(t('Form.enterPass'))
        .min(6, t('Form.minSymbol', { min: 6 }))
    }),
    onSubmit: (async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        setPending(true);
        const res  = await login({
          phone: '+' + values.phone,
          password: values.password,
        });

        if (res.data) {
          cookies.save(COOKIES.REMEMBER, remembered, {path: '/'});
          cookies.save(COOKIES.TOKEN, res.data.access_token, {path: '/'});

          dispatch(setAuth(res.data.access_token));
          setPending(false);
          history.replace('/');
          return
        }

        setPending(false);
      } catch (e) {
        const res = e.response ? e.response.data : null;

        store.addNotification({
          title: `Error code: ${res ? res.code : 'NULL'}`,
          message: `${res ? res.message : t('Notify.notRespond')}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

        setPending(false);
      }
    })
  });

  return { formik, pending, remembered, setRemembered };
};
