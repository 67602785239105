import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';

export const useBonusNotification = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const { info, pending } = useSelector(s => s.branchReducer);

    const formik = useFormik({
        initialValues: {
            phone: '',
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(t("Form.validEmail")).required(t("Form.requiredField")),
            phone: Yup.string().required(t("Form.enterPhone")),
        }),
        onSubmit: (async ({ enabled, ...values }) => {
            // report_phone_numbers
            try {
                if (info.id) {
                    let data = {
                        phones: [values.phone],
                        emails: [values.email],
                    };
                    // if (info.configuration.whatsapp_provider && info.configuration.whatsapp_provider && info.configuration.whatsapp_provider.enabled) {
                    //     data = {
                    //         ...data,
                    //         provider: info.configuration.whatsapp_provider.provider,
                    //         enabled: true,
                    //         login: info.configuration.whatsapp_provider.login,
                    //         commercial_text: info.configuration.whatsapp_provider.commercial_text,
                    //     };
                    // }
                    dispatch(branchActions.changeReportsContacts(info.id, data))
                }
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (info.configuration.whatsapp_provider && info.configuration.whatsapp_provider.enabled) {
                if (info.configuration.report_contacts.phones && info.configuration.report_contacts.phones?.length) {
                    formik.setFieldValue("phone", info.configuration.report_contacts.phones[0]);
                } else if (info.configuration.report_contacts.phones && typeof info.configuration.report_contacts.phones === "string") {
                    formik.setFieldValue("phone", info.configuration.report_contacts.phones);
                }

                if (info.configuration.report_contacts.emails && info.configuration.report_contacts.emails?.length) {
                    formik.setFieldValue("email", info.configuration.report_contacts.emails[0]);
                } else if (info.configuration.report_contacts.emails && typeof info.configuration.report_contacts.emails === "string") {
                    formik.setFieldValue("email", info.configuration.report_contacts.emails);
                }
            }
            if (!info.configuration.whatsapp_provider || !info.configuration.whatsapp_provider.enabled) {
                setError(true);
                setErrorModal(true);
            }
        }
    }, [info]);

    return {
        formik,
        pending,
        errorModal,
        setErrorModal,
        error,
        info,
    }
}
