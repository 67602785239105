import React from "react";
import {Link} from "react-router-dom";
import classNames from 'classnames';
import Loader from '../../Loader';
import styles from './buttonOutlined.module.scss';

const colors = {
    orange: "#FFCF5C",
    green: "#73D389",
    red: "#EB5757",
}

const ButtonOutlined = (props) => {
  const {
    children,
    to,
    className = '',
    onClick,
    onClose,
    prefix = null,
    variant = 'transparent',
    color = 'green',
    type = "button",
    loading = false,
    disabled = false,
    rounded = false
  } = props;

  const ButtonWrapper = (wrapperProps) => {
    const {children: wrapperChildren} = wrapperProps;
    const wrapperClassName = `${styles['button-outlined']} ${rounded ? styles['button-outlined--rounded'] : ''} ${styles[`button-outlined--${variant}`]} ${className}`.trim();

    if (!!to) {
      return (
        <Link
          onClick={onClose}
          to={to}
          className={wrapperClassName}>
          {wrapperChildren}
        </Link>
      );
    }

    return (
      <button
        onClick={(e) => {
          if (disabled || !onClick) {
            return;
          } else if (onClick) {
            onClick(e);
          }
        }}
        disabled={disabled || loading}
        type={type}
        className={classNames(wrapperClassName, {
          [styles['button-outlined--disabled']]: disabled || loading,
        })}>
        {wrapperChildren}
      </button>
    );
  };

  return (
    <ButtonWrapper>
      <React.Fragment>
        {prefix && (
          <i className={styles['button-outlined__prefix']}>{prefix}</i>
        )}
        {!loading && (
          <span
              className={classNames(
                styles['button-outlined__text'],
                styles[`button-outlined__text--${color}`], {
                  [styles['button-outlined__text--hidden']]: loading
                })}>
            {children}
          </span>
        )}
        {loading && <Loader color={colors[color]} />}
      </React.Fragment>
    </ButtonWrapper>
  );
};

export default ButtonOutlined;
