import React from 'react'
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import styles from './PieChart.module.scss'

export default function PieChart({ RETURNED, NEW, maxWidth = '250px', options = {}, title = '' }) {
    const { t } = useTranslation();
    let localRETURNED = 0;
    let localNEW = 0;

    if (!!RETURNED) localRETURNED = RETURNED
    if (!!NEW) localNEW = NEW;

    return (
      <div className={styles.container}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.block}>
          <div style={{ maxWidth }}>
            <Chart
              chartType="PieChart"
              data={[
                [t('NSChart.loyal'), t('NSChart.new')],
                [t('NSChart.loyal'), localRETURNED],
                [t('NSChart.new'), localNEW],
              ]}
              options={{...options}}
              width={"100%"}
              height={"auto"}
            />
          </div>
          <div className={styles.rightBlock}>
            <b>{title}</b>
            <p>{localRETURNED + localNEW}</p>
            <div className={styles.info}>
              <div><span></span><span>{t('NSChart.loyal1')}</span></div>
              <div><span></span><span>{t('NSChart.new1')}</span></div>
            </div>
          </div>
        </div>
      </div>
    )
}