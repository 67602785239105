import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextField from 'components/FormElements/TextField';
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import branchActions from "../../../../store/branchStore/branchActions";
import Modal from "../../../../portals/Modal";
import PageHeader from "../../../../components/PageHeader";
import styles from './profileBonusBirthday.module.scss';
import QuestionTooltip from "../../../../components/QuestionTooltip";
import CustomSelect from "../../../../components/FormElements/Select";
import Checkbox from "../../../../components/FormElements/Checkbox";

const initialState = {
    amount: 0,
    message: '',
    expire_interval: 0,
    errors: {
        amount: '',
        message: '',
        message_for_week: '',
    }
}

export default function ProfileBonusBirthday() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const { info, pending: branchPending } = useSelector((s) => s.branchReducer);
    const [pending, setPending] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [isBirthdayMessagesPushing, setIsBirthdayMessagesPushing] = useState(false);

    const options = useRef([
        {
            "label": 'NSActive.oneDay',
            "value": 8.64e+7,
        },
        {
            "label": 'NSActive.threeDay',
            "value": 2.592e+8,
        },
        {
            "label": 'week',
            "value": 6.048e+8,
        },
        {
            "label": "month",
            "value": 2.628e+9,
        },
        // {
        //     "label": 'NSBonus.two',
        //     "value": 5.256e+9,
        // },
        {
            "label": 'NSBonus.three',
            "value": 7.884e+9,
        },
        {
            "label": 'NSBonus.halfYear',
            "value": 1.577e+10,
        },
        {
            "label": 'year',
            "value": 3.154e+10,
        },
    ]);

    useEffect(() => {
        setState(initialState);
        setEnabled(false);

        if (info && !info.configuration.birthday_bonus) {
            return setErrorModal(true);
        }

        if (info && !info.configuration.birthday_bonus.enabled) {
            const option = options.current.find((o) => o.value == info.configuration.birthday_bonus.expire_interval) || {label: '', value: ''};
            setState({
                ...state,
                amount: info.configuration.birthday_bonus.amount,
                message: info.configuration.birthday_bonus.message,
                message_for_week: info.configuration.birthday_bonus.message_for_week,
                expire_interval: option,
            });
            return setErrorModal(true);
        }

        if (info && info.configuration.birthday_bonus) {
            const option = options.current.find((o) => o.value == info.configuration.birthday_bonus.expire_interval) || {label: '', value: ''};
            setState({
                ...state,
                amount: info.configuration.birthday_bonus.amount,
                message: info.configuration.birthday_bonus.message,
                message_for_week: info.configuration.birthday_bonus.message_for_week,
                expire_interval: option,
            });
            setEnabled(true);
        }

        if (info?.configuration?.whatsapp_provider?.prohibitions && !pending) {
            setIsBirthdayMessagesPushing(info?.configuration?.whatsapp_provider?.prohibitions?.birthday_messages || false);
        }
    }, [info]);

    const onChangeAmount = (e) => {
        if (isFinite(e.target.value)) {
            setState({ ...state, amount: +e.target.value })
        }
    }

    const onChangeMessage = (e) => {
        setState({ ...state, message: e.target.value })
    }

    const onChangeMessageForWeek = (e) => {
        setState({ ...state, message_for_week: e.target.value })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        // if (state.amount <= 0) return setState({...state, errors: {...state.errors, amount: 'Заполните поле!'}});
        // if (!state.message.length) return setState({...state, errors: {...state.errors, message: 'Заполните поле!'}});
        // if (!state.message_for_week.length) return setState({...state, errors: {...state.errors, message_for_week: 'Заполните поле!'}});

        if (info) {
            setPending(true);
            new Promise((resolve, reject) => {
                const {errors, expire_interval, ...data} = state;
                dispatch(branchActions.updateBonusForBirthday(info.id, {
                    ...data,
                    expire_interval: expire_interval.value,
                }, resolve, reject));
            }).finally(() => {
                setPending(false);
                setState({...state, errors: initialState.errors});
                changeWhatsappPush();
            });
        }

    }

    const onDeactivate = (e) => {
        e.preventDefault();

        if (info) {
            setPending(true);
            new Promise((resolve, reject) => {
                const {errors, ...data} = state;
                dispatch(branchActions.updateBonusForBirthday(info.id, {
                    amount: 0,
                    message: "",
                    message_for_week: "",
                }, resolve, reject));
            })
              .then(() => {
                  dispatch(branchActions.getBranch(info.id));
              })
              .finally(() => {
                setPending(false);
                setState({...state, errors: initialState.errors});
            });
        }

    }

    const changeWhatsappPush = () => {
        dispatch(branchActions.changeWhatsappProhibitions(info.id, { enabled: isBirthdayMessagesPushing, type: 'birthday_messages' }));
    }

    return (
        <div className={styles.container}>
            <PageHeader title={t('bonuses')}>
                <p>{t('NSBonuses.congratulate')}</p>
            </PageHeader>
            <form onSubmit={onSubmit}>
                 <TextField
                      label={t('NSBonuses.bonuses')}
                      placeholder="8"
                      width="105px"
                      value={state.amount}
                      onChange={onChangeAmount}
                      error={state.errors.amount}
                      disabled={!enabled}
                      // required
                 />

                <div className={styles.intervalSelect}>
                    <p>{t("NSBonus.expiration")}</p>
                    <CustomSelect
                      placeholder={t('week')}
                      disabled={!enabled}
                      options={options.current}
                      value={state.expire_interval}
                      onSelect={(o) => setState({ ...state, expire_interval: o })}
                    />
                </div>

                <div className={styles.p} style={{ position: 'relative', display: 'flex', gap: '16px', alignItems: 'center' }}>
                    <span>{t('NSBonuses.text')}</span>
                    <div>
                        <QuestionTooltip text={t('NSBonuses.push')} />
                    </div>
                </div>
                <textarea
                    placeholder={t('NSBonuses.type')}
                    value={state.message}
                    onChange={onChangeMessage}
                    error={state.errors.message}
                    disabled={!enabled}
                    // required
                />

                <div className={styles.p} style={{ position: 'relative', display: 'flex', gap: '16px', alignItems: 'center' }}>
                    <span>{t('NSBonuses.notification7Days')}</span>
                    <div>
                        <QuestionTooltip text={t('NSBonuses.pushNotification')} />
                    </div>
                </div>
                <textarea
                  placeholder={t('NSBonuses.type')}
                  value={state.message_for_week}
                  onChange={onChangeMessageForWeek}
                  error={state.errors.message}
                  disabled={!enabled}
                  // required
                />
                <Checkbox
                  label={t("notPushWhatsapp") || ""}
                  checked={isBirthdayMessagesPushing}
                  disabled={branchPending || !info}
                  onChange={() => setIsBirthdayMessagesPushing(!isBirthdayMessagesPushing)}
                />
                <div style={{ height: 20 }} />
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <ButtonFilled
                      type="submit"
                      loading={pending}
                      disabled={!enabled}
                    >{t('save')}</ButtonFilled>
                    <ButtonFilled
                      variant="red"
                      loading={pending}
                      disabled={!enabled}
                      onClick={onDeactivate}
                    >{t('NSComparison.deactivate')}</ButtonFilled>
                </div>
            </form>
            <Modal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
