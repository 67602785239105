const SET_AUTH = 'SET_AUTH';
const DELETE_AUTH = 'DELETE_AUTH';

const initialState = {
  token: null
};

export const setAuth = (payload) => ({
  type: SET_AUTH,
  payload
});

export const deleteAuth = () => ({
  type: DELETE_AUTH
});

const authState = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {...state, token: action.payload};
    case DELETE_AUTH:
      return {...initialState, token: null};
    default:
      return {...state};
  }
};

export default authState;
