import { useSelector } from "react-redux";
import { Roles } from "../config";


export const useCurrentBranch = () => {
  const { profileReducer, branchReducer } = useSelector(s => s);

  let currentBranch = { role: Roles.ADMINISTRATOR };
  let currentRole = null;

  if (profileReducer.info && profileReducer.info.branches.length && branchReducer.info && branchReducer.info.id) {
    currentBranch = profileReducer.info.branches.find(b => b.id === branchReducer.info.id);
    if (currentBranch) {
      currentRole = currentBranch.role;
    } else {
      currentBranch = { role: Roles.ADMINISTRATOR };
      currentRole = Roles.ADMINISTRATOR;
    }
  }

  return {currentBranch, currentRole, groupId: branchReducer?.info?.group_id};
}