import React from 'react';
import { useBonusActivate } from "../../hooks/useBonusActivate";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Modal from "../../../../portals/Modal";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import PageHeader from "../../../../components/PageHeader";
import Checkbox from "../../../../components/FormElements/Checkbox";
import CustomSelect from "../../../../components/FormElements/Select";

import styles from './profileBonusActivate.module.scss';
import { useTranslation } from "react-i18next";

export default function ProfileBonusActivate() {
    const { t } = useTranslation();
    const {
        info,
        formik,
        errorModal,
        setErrorModal,
        options,
        pending,
        // isBonusActivateMessagesPushing,
        // setIsBonusActivateMessagesPushing,
    } = useBonusActivate(t);

    return (
        <>
            <PageHeader title={t('activation')}>
                <div style={{ marginLeft: '50px' }}>
                    <QuestionTooltip text={t('NSActive.choose')} />
                </div>
            </PageHeader>
            <div className={styles.container}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.block}>
                        <Checkbox
                          label={t('NSActive.immediately')}
                          name="enabled"
                          checked={!formik.values.enabled}
                          onChange={() => formik.setFieldValue('enabled', false)}
                        />
                    </div>
                    <div className={styles.block} style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          name="enabled"
                          checked={formik.values.enabled}
                          onChange={() => formik.setFieldValue('enabled', true)}
                        />

                        <CustomSelect
                          placeholder={t('week')}
                          disabled={formik.values.enabled}
                          options={options}
                          value={formik.values.delay_interval}
                          onSelect={(o) => formik.setFieldValue('delay_interval', o)}
                        />
                    </div>
                    {/*<Checkbox*/}
                    {/*  label={t("pushWhatsapp") || ""}*/}
                    {/*  checked={isBonusActivateMessagesPushing}*/}
                    {/*  disabled={pending || !info}*/}
                    {/*  onChange={() => setIsBonusActivateMessagesPushing(!isBonusActivateMessagesPushing)}*/}
                    {/*/>*/}
                    <div style={{ height: 20 }} />
                    <div className={styles.footer}>
                        <ButtonFilled type="submit" loading={pending}>{t('save')}</ButtonFilled>
                    </div>
                </form>
            </div>
            <Modal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            loading={pending}
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
