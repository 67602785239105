import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { getWhatsAppQRUrl } from "../../api";
import { store } from "react-notifications-component";
import styles from "./whatsapp.module.scss";

const QrCode = ({ info }) => {
  const { t } = useTranslation();
  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [intervalTime, setIntervalTime] = useState(30);
  const interval = useRef(null);

  const showError = (errorTitle, errorMessage) => {
    store.addNotification({
      title: errorTitle,
      message: errorMessage,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }

  useEffect(() => {
    const id = info.id;

    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }

    let localeIntervalTime = intervalTime;
    let localeLoading = loading;
    interval.current = setInterval(() => {
      if (!localeLoading) {
        if (!localeIntervalTime) {
          localeLoading = true;
          setLoading(true);
          getWhatsAppQRUrl(id)
            .then((res) => {
              if (!res || !res.data) {
                showError(t('NSWhatsapp.errorQR1'), t('NSWhatsapp.errorQR2'))
              }
              if (res.data && res.data.message) {
                store.addNotification({
                  title: "",
                  message: res.data.message,
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true
                  }
                });
                localeLoading = false;
                setLoading(false);
                clearInterval(interval.current);
                setSuccess(true);
                return
              }
              setImgUrl(res.data);
              localeLoading = false;
              setLoading(false);
            })
            .catch((error) => {
              let errorMessage = t('NSWhatsapp.errorQR2');
              if (error && error?.response && error?.response?.data && error?.response?.data?.message) {
                errorMessage = error?.response?.data?.message
              }
              showError(t('NSWhatsapp.errorQR1'), errorMessage)
              setImgUrl('');
              localeLoading = false;
              setLoading(false);

            });
          return localeIntervalTime = 60
        } else {
          localeIntervalTime--;
        }
        setIntervalTime(localeIntervalTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval.current);
    }
  }, []);

  return (
    <>
      {
        loading ? (<span>{t('NSWhatsapp.loadQR')}</span>)
        : !success ? (<span>{t('NSWhatsapp.update', {seconds: intervalTime >= 10 ? `00:${intervalTime}` : `00:0${intervalTime}`})}</span>) : null
      }
      {!success && (
        <div className={styles.img}>
          {(loading || !imgUrl) && <Loader color="#73D389" style={{ marginTop: '100px' }} />}
          {/*{(imgUrl && !loading) && <img src={imgUrl} alt="QR code"/>}*/}
          {(imgUrl && !loading) && <iframe src={imgUrl} title="QR code"/>}
        </div>
      )}
    </>
  )
}

export default QrCode;