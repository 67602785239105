import React, { useState, useEffect, useRef } from 'react';

export default function LazyImage({src, ...props}) {
  const rootRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const loading = 'https://sagi.kz/customer/images/favicon.png';

  useEffect(() => {
    const defaultIntersectionOptions = {
      threshold: 0,
      rootMargin: '0px',
    };

    const checkIntersections = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    };

    if (!isVisible) {
      const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
      newIO.observe(rootRef.current);
      return () => newIO.disconnect();
    }
  }, [isVisible]);

  return (
    <img {...props} src={isVisible ? src : loading} ref={rootRef} />
  );
};