import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import rfmActions from '../../../store/analyticsStores/RFMStore/RFMActions';
import { PENDING } from '../../../store/globalStatuses/globalStatuses';
import { useEffect } from 'react';

export const useRFM = () => {
  const dispatch = useDispatch();
  const { info } = useSelector(s => s.branchReducer);
  const rfm = useSelector(s => s.RFMReducer);

  useEffect(() => {
    formik.resetForm();

    if (info && info.configuration && info.configuration.rfm_analysis && info.configuration.rfm_analysis.recency) {
      formik.setFieldValue('recencyOne', info.configuration.rfm_analysis.recency.one);
      formik.setFieldValue('frequencyOne', info.configuration.rfm_analysis.frequency.one);
      formik.setFieldValue('monetaryOne', info.configuration.rfm_analysis.monetary.one);

      formik.setFieldValue('recencyTwo', info.configuration.rfm_analysis.recency.two);
      formik.setFieldValue('frequencyTwo', info.configuration.rfm_analysis.frequency.two);
      formik.setFieldValue('monetaryTwo', info.configuration.rfm_analysis.monetary.two);
    }
  }, [info]);

  const formik = useFormik({
    initialValues: {
      recencyOne: '',
      recencyTwo: '',

      frequencyOne: '',
      frequencyTwo: '',

      monetaryOne: '',
      monetaryTwo: '',
    },
    validationSchema: Yup.object({
      recencyOne: Yup.number().typeError('Некорректное число').integer('Некорректное число').required('Пожалуйста введите число'),
      recencyTwo: Yup.number().typeError('Некорректное число').integer('Некорректное число').required('Пожалуйста введите число'),

      frequencyOne: Yup.number().typeError('Некорректное число').integer('Некорректное число').required('Пожалуйста введите число'),
      frequencyTwo: Yup.number().typeError('Некорректное число').integer('Некорректное число').required('Пожалуйста введите число'),

      monetaryOne: Yup.number().typeError('Некорректное число').integer('Некорректное число').required('Пожалуйста введите число'),
      monetaryTwo: Yup.number().typeError('Некорректное число').integer('Некорректное число').required('Пожалуйста введите число'),
    }),
    onSubmit: (async (values, { resetForm }) => {
      const data = {
        recency: {
          one: +values.recencyOne,
          two: +values.recencyTwo,
        },
        frequency: {
          one: +values.frequencyOne,
          two: +values.frequencyTwo,
        },
        monetary: {
          one: +values.monetaryOne,
          two: +values.monetaryTwo,
        },
      }

      try {
        dispatch(rfmActions.saveRFM(info.id, data));
      } catch (e) {
        const res = e.response ? e.response.data : null;

        store.addNotification({
          title: `Error code: ${res ? res.code : 'NULL'}`,
          message: `${res ? res.message : 'Сервер не отвечает! Попробуйте позже!'}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } finally {
        resetForm();
      }
    })
  });

  return {
    formik,
    pending: rfm.status === PENDING,
  }
}