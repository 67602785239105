import React from "react";
import styles from "./styles.module.scss";

const PageHeader = ({ title, children }) => (
  <div className={styles['head']}>
    <h2>{title}</h2>
    {children}
  </div>
);

export default PageHeader;