import React from 'react'
import styles from './NotificationItem.module.css'
import {convertDataToTime, convertDateToShortString} from "../../../../tools/convertDateToString";

export default function NotificationItem({item}) {
    return (
        <div className={styles.container}>
            <p>{`${convertDateToShortString(item.date)} ${convertDataToTime(item.date)}`}</p>
            <span>{item.content}</span>
            {item.media &&
            <img
                src={item.media.file.url}/>
            }
        </div>
    )
}