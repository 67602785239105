import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import TimePicker from 'components/FormElements/TimePicker';
import styles from './startEnd.module.scss';

export default function StartEndHours(props) {
  const { t } = useTranslation();
  const { lunch, start = '', end = '', onChangeHours, onAddLunch, onDeleteLunch } = props;
  const [lunchStatus, setLunchStatus] = useState(false);

  const onChangeTime = (type, time) => {
    onChangeHours(lunch, { [type]: time });
  }

  const onActiveLunchStatus = () => {
    setLunchStatus(true);
    onAddLunch();
  }

  const onDisableLunchStatus = () => {
    setLunchStatus(false);
    onDeleteLunch();
  }

  return (
    <>
      {
        lunch && (!start.length || !end.length) && !lunchStatus ? (
          <button className={styles.btn} onClick={onActiveLunchStatus}>{t('NSWorking.include')}</button>
        ) : (
          <>
            <TimePicker
              lunch={lunch}
              defaultValue={start}
              onChangeTime={(time) => onChangeTime('start', time)}
              onDisableLunchStatus={onDisableLunchStatus}
            />
            <TimePicker
              lunch={lunch}
              defaultValue={end}
              onChangeTime={(time) => onChangeTime('end', time)}
              onDisableLunchStatus={onDisableLunchStatus}
            />
          </>
        )
      }
    </>
  )
}