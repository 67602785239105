import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { getIn } from 'formik';
import TextField from "../../../../components/FormElements/TextField";
import CustomSelect from "../../../../components/FormElements/Select";
import Toggle from "../../../../components/FormElements/Toggle";
import Checkbox from "../../../../components/FormElements/Checkbox";
import styles from "./surveyCreate.module.scss";

const SurveyQuestion = ({
    index,
    formik,
    options,
    questionAnswersType,
    answersType,
}) => {
    const { t } = useTranslation();
    const [option, setOption] = useState({label: '', value: ''});

    useEffect(() => {
        const chosenOption = options.current.find(o => o.value === formik.values.questions[index].answers_type);
        if (chosenOption.value === questionAnswersType.current.TEXT_FIELD) {
            formik.setFieldValue(`questions[${index}].answers`, [{content: ''}]);
        }
        setOption(chosenOption);
    }, [formik.values.questions[index].answers_type]);

    const onSelectOption = (item) => {
        if (item.value === formik.values.questions[index].answers_type) return
        formik.setFieldValue(`questions[${index}].answers_type`, item.value);
    }

    const errorMessage = (name) => {
        const error = getIn(formik.errors.questions && formik.errors.questions.length && formik.errors.questions[index], name);
        const touch = getIn(formik.touched.questions && formik.touched.questions.length && formik.touched.questions[index], name);
        return touch && error ? error : null;
    };

    const typeOfAnswers = formik.values.questions[index].answers_type;

    const showAnswersType = () => {
        return typeOfAnswers == questionAnswersType.current.TEXT_FIELD ? null : (
            <Checkbox disabled shape={`${ typeOfAnswers == questionAnswersType.current.SINGLE_CHOICE ? 'round' : 'square' }`} />
        )
    }

    const onAddAnswer = () => {
        const answersArray = _.concat(formik.values.questions[index].answers, {content: ''});
        formik.setFieldValue(`questions[${index}].answers`, answersArray);
    }

    const onDeleteAnswer = (aindex) => () => {
        const answersArray = formik.values.questions[index].answers.filter((_, i) => i !== aindex);
        formik.setFieldValue(`questions[${index}].answers`, answersArray);
    }

    const onDeleteQuestion = () => {
        const questionsArray = formik.values.questions.filter((_, i) => i !== index);
        formik.setFieldValue(`questions`, questionsArray);
    }

    const changePosition = (pos) => {
        let questionsArray = _.cloneDeep(formik.values.questions);
        let lastElementIndex = questionsArray.length - 1
        let currentQuestion = questionsArray[index];

        if (pos === 'up') {
          if (index > 0) {
              let previousQuestion = questionsArray[index - 1];
              questionsArray[index - 1] = currentQuestion;
              questionsArray[index] = previousQuestion
          }
        } else {
            if (index < lastElementIndex) {
                let nextQuestion = questionsArray[index + 1];
                questionsArray[index + 1] = currentQuestion;
                questionsArray[index] = nextQuestion
            }
        }
        formik.setFieldValue(`questions`, questionsArray);
    }

    return (
      <div className={styles.question}>
          <TextField
            label={`${t('NSPoll.question')} №${index + 1}`}
            placeholder={t('Form.enterQuestion1')}
            required

            value={formik.values.questions[index].title}
            error={errorMessage('title')}
            onChange={(e) => formik.setFieldValue(`questions[${index}].title`, e.target.value)}
          />
          {formik.values.answers_type == answersType.current.DYNAMIC ? (
            <div style={{marginTop: '18px', width: '300px'}}>
                <CustomSelect
                  onSelect={onSelectOption}
                  value={option}
                  options={options.current}
                />
            </div>
          ) : null}
          <div className={styles.answers}>
              {formik.values.questions[index].answers.map((answer, aindex) => {
                  return (
                    <div className={styles.answer} key={aindex}>
                        {showAnswersType()}
                        <input
                          type="text"
                          value={formik.values.questions[index].answers[aindex].content}
                          onChange={formik.handleChange(`questions[${index}].answers[${aindex}].content`)}
                          className={`${!!errorMessage(`answers[${aindex}].content`) ? styles.answerError : ''}`}
                        />
                        {(aindex > 0 && formik.values.answers_type === answersType.current.DYNAMIC) ? (
                          <button onClick={onDeleteAnswer(aindex)} type="button">
                              <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <rect x="18.3848" y="5.65686" width="2" height="18" rx="1"
                                        transform="rotate(45 18.3848 5.65686)"/>
                                  <rect x="19.7988" y="18.3848" width="2" height="18" rx="1"
                                        transform="rotate(135 19.7988 18.3848)"/>
                              </svg>
                          </button>) : null}
                    </div>
                  )
              })}
              {/*{(formik.values.questions.length === (index + 1) && formik.values.answers_type === answersType.current.DYNAMIC && option.value !== questionAnswersType.current.TEXT_FIELD) ? (*/}
              {(formik.values.answers_type === answersType.current.DYNAMIC && option.value !== questionAnswersType.current.TEXT_FIELD) ? (
                <button type="button" onClick={onAddAnswer}>
                    {showAnswersType()}
                    <span>{t('NSPoll.add')}</span>
                </button>
              ) : null}
          </div>
          <Toggle
            setValue={() => formik.setFieldValue(`questions[${index}].is_required`, !formik.values.questions[index].is_required)}
            value={formik.values.questions[index].is_required}
            label={t('NSPoll.mandatory')}
          />


          {(formik.values.questions.length > 1) ? (
            <div className={styles.positionBtns}>
                <button type="button" onClick={() => changePosition('up')}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
                              d="m112 244 144-144 144 144M256 120v292"></path>
                    </svg>
                </button>
                <button type="button" onClick={() => changePosition('down')}>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"
                              d="m112 268 144 144 144-144M256 392V100"></path>
                    </svg>
                </button>
            </div>
          ) : null}

          {(formik.values.questions.length > 1) ? (
            <button onClick={onDeleteQuestion} className={styles.deleteQuestionBtn} type="button">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="18.3848" y="5.65686" width="2" height="18" rx="1" transform="rotate(45 18.3848 5.65686)"/>
                    <rect x="19.7988" y="18.3848" width="2" height="18" rx="1" transform="rotate(135 19.7988 18.3848)"/>
                </svg>
            </button>) : null}
      </div>
    )
}

export default SurveyQuestion;
