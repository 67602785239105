import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { store } from 'react-notifications-component';
import { convertDateToShortString } from "../../tools/convertDateToString";
import styles from './surveyCard.module.scss';

const copySvg = (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.8913 5.64258H3C1.89543 5.64258 1 6.53801 1 7.64258V23.9997C1 25.1043 1.89543 25.9997 3 25.9997H15.0714C16.176 25.9997 17.0714 25.1043 17.0714 23.9997V22.7855" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M8.17969 2.28565C8.17969 1.59529 8.73933 1.03564 9.42969 1.03564H21.5011C22.1915 1.03564 22.7511 1.59529 22.7511 2.28564V18.6428C22.7511 19.3331 22.1915 19.8928 21.5011 19.8928H9.42969C8.73933 19.8928 8.17969 19.3331 8.17969 18.6428V2.28565Z" strokeWidth="1.5"/>
    </svg>
);

const userSvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9996 11.3625C9.00898 11.3625 6.57617 8.92969 6.57617 5.93906C6.57617 2.94844 9.00898 0.515625 11.9996 0.515625C14.9902 0.515625 17.423 2.94844 17.423 5.93906C17.423 8.92969 14.9902 11.3625 11.9996 11.3625ZM11.9996 2.42812C10.0637 2.42812 8.48867 4.00312 8.48867 5.93906C8.48867 7.875 10.0637 9.45 11.9996 9.45C13.9355 9.45 15.5105 7.875 15.5105 5.93906C15.5105 4.00312 13.9355 2.42812 11.9996 2.42812Z" fill="#73D389"/>
        <path d="M22.5281 23.4843H1.47187C0.942187 23.4843 0.515625 23.0578 0.515625 22.5281C0.515625 19.4015 1.73438 16.4718 3.94219 14.2781C5.36719 12.8625 7.37812 12.0515 9.45469 12.0515H14.5453C16.6266 12.0515 18.6328 12.8625 20.0578 14.2781C22.2656 16.4718 23.4844 19.4015 23.4844 22.5281C23.4844 23.0578 23.0578 23.4843 22.5281 23.4843ZM2.475 21.5718H21.525C21.3094 19.3218 20.325 17.2406 18.7078 15.6375C17.6391 14.5781 16.1203 13.9687 14.5453 13.9687H9.45469C7.875 13.9687 6.36094 14.5781 5.29219 15.6375C3.675 17.2406 2.69062 19.3218 2.475 21.5718Z" fill="#73D389"/>
    </svg>
);

const questionMarkSvg = (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 0C5.15891 0 0 5.15891 0 11.5001C0 17.8412 5.15891 23 11.5 23C17.8411 23 23 17.8412 23 11.5C23 5.15884 17.8411 0 11.5 0ZM11.5 21.0652C6.22578 21.0652 1.93483 16.7743 1.93483 11.5001C1.93483 6.22584 6.22578 1.93483 11.5 1.93483C16.7742 1.93483 21.0652 6.22578 21.0652 11.5001C21.0652 16.7744 16.7742 21.0652 11.5 21.0652Z" fill="#73D389"/>
        <path d="M11.4995 4.31787C9.20481 4.31787 7.33789 6.18479 7.33789 8.4795C7.33789 9.01377 7.77104 9.44692 8.30531 9.44692C8.83958 9.44692 9.27272 9.01377 9.27272 8.4795C9.27272 7.25166 10.2717 6.2527 11.4995 6.2527C12.7274 6.2527 13.7263 7.25166 13.7263 8.4795C13.7263 9.70735 12.7274 10.7063 11.4995 10.7063C10.9653 10.7063 10.5321 11.1394 10.5321 11.6737V13.861C10.5321 14.3953 10.9653 14.8285 11.4995 14.8285C12.0338 14.8285 12.4669 14.3953 12.4669 13.861V12.5276C14.2967 12.0904 15.6612 10.4412 15.6612 8.47944C15.6612 6.18479 13.7942 4.31787 11.4995 4.31787Z" fill="#73D389"/>
        <path d="M11.5008 18.5033C12.2488 18.5033 12.8551 17.897 12.8551 17.149C12.8551 16.401 12.2488 15.7947 11.5008 15.7947C10.7528 15.7947 10.1465 16.401 10.1465 17.149C10.1465 17.897 10.7528 18.5033 11.5008 18.5033Z" fill="#73D389"/>
    </svg>
);

const dataSvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.75 0.75C11.751 0.75 7.5 2.064 7.5 4.5V7.5C7.5 7.914 7.836 8.25 8.25 8.25C8.664 8.25 9 7.914 9 7.5V6.7185C10.5915 7.722 13.2195 8.25 15.75 8.25C18.2805 8.25 20.9085 7.722 22.5 6.7185V7.5C22.5 8.2335 20.655 9.4035 17.187 9.693C16.776 9.726 16.4685 10.0905 16.503 10.503C16.5345 10.8945 16.863 11.19 17.2485 11.19C17.271 11.19 17.2905 11.1885 17.313 11.187C19.266 11.0235 21.2115 10.518 22.5 9.6975V10.5C22.5 11.1405 21.141 12.0615 18.6195 12.507C18.213 12.579 17.94 12.969 18.012 13.3755C18.0765 13.7385 18.393 13.995 18.7485 13.995C18.792 13.995 18.837 13.992 18.8805 13.983C20.406 13.7145 21.627 13.275 22.5 12.717V13.5C22.5 14.1405 21.141 15.0615 18.6195 15.507C18.213 15.579 17.94 15.969 18.012 16.3755C18.0765 16.7385 18.393 16.995 18.7485 16.995C18.792 16.995 18.837 16.992 18.8805 16.983C20.406 16.7145 21.627 16.2735 22.5 15.717V16.5C22.5 17.1405 21.141 18.0615 18.6195 18.507C18.213 18.579 17.94 18.969 18.012 19.3755C18.0765 19.7385 18.393 19.995 18.7485 19.995C18.792 19.995 18.837 19.992 18.8805 19.983C20.406 19.7145 21.627 19.2735 22.5 18.717V19.5C22.5 20.2335 20.655 21.4035 17.187 21.693C16.776 21.726 16.4685 22.0905 16.503 22.503C16.5345 22.8945 16.863 23.19 17.2485 23.19C17.271 23.19 17.2905 23.1885 17.313 23.187C20.6415 22.908 24 21.6615 24 19.5V4.5C24 2.064 19.749 0.75 15.75 0.75ZM15.75 6.75C11.6295 6.75 9 5.4165 9 4.5C9 3.5835 11.6295 2.25 15.75 2.25C19.8705 2.25 22.5 3.5835 22.5 4.5C22.5 5.4165 19.8705 6.75 15.75 6.75Z" fill="#73D389"/>
        <path d="M8.25 9.75C4.251 9.75 0 11.064 0 13.5V19.5C0 21.936 4.251 23.25 8.25 23.25C12.249 23.25 16.5 21.936 16.5 19.5V13.5C16.5 11.064 12.249 9.75 8.25 9.75ZM15 19.5C15 20.4165 12.3705 21.75 8.25 21.75C4.1295 21.75 1.5 20.4165 1.5 19.5V18.7185C3.0915 19.722 5.7195 20.25 8.25 20.25C10.7805 20.25 13.4085 19.722 15 18.7185V19.5ZM15 16.5C15 17.4165 12.3705 18.75 8.25 18.75C4.1295 18.75 1.5 17.4165 1.5 16.5V15.7185C3.0915 16.722 5.7195 17.25 8.25 17.25C10.7805 17.25 13.4085 16.722 15 15.7185V16.5ZM8.25 15.75C4.1295 15.75 1.5 14.4165 1.5 13.5C1.5 12.5835 4.1295 11.25 8.25 11.25C12.3705 11.25 15 12.5835 15 13.5C15 14.4165 12.3705 15.75 8.25 15.75Z" fill="#73D389"/>
    </svg>
);

const trashSvg = (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.1875 3.5H18.375V2.625C18.375 1.17759 17.1974 0 15.75 0H12.25C10.8026 0 9.625 1.17759 9.625 2.625V3.5H4.8125C3.60631 3.5 2.625 4.48131 2.625 5.6875V8.75C2.625 9.23322 3.01678 9.625 3.5 9.625H3.97819L4.73413 25.4999C4.80091 26.9018 5.95262 28 7.35612 28H20.6439C22.0474 28 23.1992 26.9018 23.2659 25.4999L24.0218 9.625H24.5C24.9832 9.625 25.375 9.23322 25.375 8.75V5.6875C25.375 4.48131 24.3937 3.5 23.1875 3.5ZM11.375 2.625C11.375 2.14255 11.7675 1.75 12.25 1.75H15.75C16.2325 1.75 16.625 2.14255 16.625 2.625V3.5H11.375V2.625ZM4.375 5.6875C4.375 5.44627 4.57127 5.25 4.8125 5.25H23.1875C23.4287 5.25 23.625 5.44627 23.625 5.6875V7.875C23.3553 7.875 5.49243 7.875 4.375 7.875V5.6875ZM21.5178 25.4166C21.4956 25.8839 21.1117 26.25 20.6439 26.25H7.35612C6.88827 26.25 6.50437 25.8839 6.48216 25.4166L5.73016 9.625H22.2698L21.5178 25.4166Z" />
        <path d="M14 24.5C14.4832 24.5 14.875 24.1082 14.875 23.625V12.25C14.875 11.7668 14.4832 11.375 14 11.375C13.5168 11.375 13.125 11.7668 13.125 12.25V23.625C13.125 24.1082 13.5167 24.5 14 24.5Z" />
        <path d="M18.375 24.5C18.8582 24.5 19.25 24.1082 19.25 23.625V12.25C19.25 11.7668 18.8582 11.375 18.375 11.375C17.8918 11.375 17.5 11.7668 17.5 12.25V23.625C17.5 24.1082 17.8917 24.5 18.375 24.5Z" />
        <path d="M9.625 24.5C10.1082 24.5 10.5 24.1082 10.5 23.625V12.25C10.5 11.7668 10.1082 11.375 9.625 11.375C9.14178 11.375 8.75 11.7668 8.75 12.25V23.625C8.75 24.1082 9.14173 24.5 9.625 24.5Z" />
    </svg>
);

const SurveyCard = (props) => {
    const { t } = useTranslation();
    const {onDelete, survey, pageIndex, linkable = true} = props;

    const onCopyShareLink = (text) => (event) => {
        event.preventDefault();
        event.stopPropagation ? event.stopPropagation() : (event.cancelBubble=true);
        navigator.clipboard.writeText(text);

        store.addNotification({
            title: t('Notify.linkCopied'),
            message: text,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    const renderCard = () => (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.headerLeft}>
                    <span>{pageIndex}</span>
                    <p>
                        {survey.title}
                    </p>
                </div>
                <div className={styles.headerRight}>
                    <button
                        onClick={onCopyShareLink(survey.share_url)}
                    >{copySvg}</button>
                    <button
                        onClick={onDelete(survey.id)}
                    >{trashSvg}</button>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.bodyColumn}>
                    <span>{t('respondents')}</span>
                    <div>
                        <i>{userSvg}</i>
                        <span>{survey.answered_users_count}</span>
                    </div>
                </div>
                <div className={styles.bodyColumn}>
                    <span>{t('questions')}</span>
                    <div>
                        <i>{questionMarkSvg}</i>
                        <span>{survey.questions.length}</span>
                    </div>
                </div>
                <div className={styles.bodyColumn}>
                    <span>{t('spent')}</span>
                    <div>
                        <i>{dataSvg}</i>
                        <span>{(+survey.answered_users_count ? +survey.answered_users_count : 0)  * (+survey.bonus ? + +survey.bonus : 0)}</span>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <span>{convertDateToShortString(survey.created_at)}</span>
            </div>
        </div>
    )

    if (linkable) return (
        <Link to={`/profile/surveys/${survey.id}/${pageIndex}/results`} className={styles.container}>
            {renderCard()}
        </Link>
    );

    return (
        <div className={styles.container}>
            {renderCard()}
        </div>
    );
};

export default SurveyCard;
