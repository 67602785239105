import React, { useCallback, useMemo }  from 'react';
import { useTranslation } from "react-i18next";
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import styles from './icon.module.scss';

const iconSvg = (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 0H42C45.3091 0 48 2.69092 48 6V42C48 45.3091 45.3091 48 42 48H6C2.69092 48 0 45.3091 0 42V6C0 2.69092 2.69092 0 6 0ZM42 4H6C4.89697 4 4 4.89697 4 6V31.1719L12.5859 22.5859C13.3672 21.8052 14.6328 21.8052 15.4141 22.5859L20.5 27.6719L34.5859 13.5859C35.3672 12.8052 36.6328 12.8052 37.4141 13.5859L44 20.1719V6C44 4.89697 43.103 4 42 4ZM42 44H6C4.89697 44 4 43.103 4 42V36.8281L14 26.8281L25.5859 38.4131C25.9771 38.8052 26.4878 39 27 39C27.5122 39 28.0229 38.8052 28.4141 38.4141C29.1948 37.6328 29.1948 36.3672 28.4141 35.5859L23.3281 30.5L36 17.8291L44 25.8291V42C44 43.103 43.103 44 42 44ZM12 16C14.209 16 16 14.209 16 12C16 9.79102 14.209 8 12 8C9.79102 8 8 9.79102 8 12C8 14.209 9.79102 16 12 16Z" />
  </svg>
)

export default function Icon({ onAddIcon }) {
  const { t } = useTranslation();
  const {info, pending} = useSelector(s => s.branchReducer);

  const onDrop = useCallback(acceptedFiles => {
    onAddIcon(acceptedFiles[0]);
  }, [onAddIcon]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    disabled: pending,
    onDrop
  });

  const style = useMemo(() => ({
    backgroundImage: `url(${info && info.icon})`
  }), [info]);

  return (
    <div {...getRootProps({className: styles.container, style})}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <>
            {iconSvg}
            <p>{t('NSPhoto.main')}</p>
          </>
      }
    </div>
  )
}