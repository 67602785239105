import React, {useState, useEffect} from 'react'
import styles from './styles.module.scss'
import branchApi from "../../store/branchStore/branchApi";
import {Roles} from "../../config";
import Checkbox from "../../components/FormElements/Checkbox";
import ButtonFilled from "../../components/FormElements/ButtonFilled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useCurrentBranch} from "../../hooks/useCurrentBranch";
import { store } from "react-notifications-component";
import {useHistory} from "react-router-dom";
import { format } from 'date-fns';

const ClientsDatabaseSendNotification = (props) => {
  const { info } = useSelector(s => s.branchReducer);
  const history = useHistory()
  const { t } = useTranslation();
  const [modalPending, setModalPending] = useState(false);
  const [pushMessage, setPushMessage] = useState('')
  const [disableAutonotificationStatus, setDisableAutonotificationStatus] = useState(false);
  const [historyOfNotifications, setHistoryOfNotifications] = useState([])
  const { currentRole, currentBranch, groupId } = useCurrentBranch();
  const [activeIndexBranch, setActiveIndexBranch] = useState(0)
  const [branchIds, setBranchIds] = useState([]);

  const onChangePushMessage = (e) => {
    if (e.target.value.length <= 625) {
      setPushMessage(e.target.value);
    }
  }

  const onDisableAutoNotification = () => {
    if (!info) return

    setDisableAutonotificationStatus(true);
    branchApi.branchesCustomersUploadedStop(info.group_id, {
      branch_ids: branchIds.length ? branchIds : historyOfNotifications.map(({ branch_id }) => String(branch_id))
    })
      .then((res) => {
        store.addNotification({
          title: t('success'),
          message: 'Рассылка успешно остановлена',
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      })
      .finally(() => {
        setDisableAutonotificationStatus(false);
      });
  }

  const onSendPushToCustomers = () => {
    if (!info || !pushMessage) return

    setModalPending(true);
    if (currentRole === Roles.ANALYTIC && groupId) {
      const queryParams = `?branch_ids=${branchIds.join(',')}`
      branchApi.branchesAnalyticCustomersUploadedSend(groupId, {
        branch_ids: branchIds.map((id) => id),
        message: pushMessage,
      })
        .then((res) => {
          store.addNotification({
            title: t('success'),
            message: t('successMessageSent'),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          setPushMessage('');
        })
        .finally(() => {
          setModalPending(false);
        });
    }
  }
  const calculateHistoryCount = () => {
    if (historyOfNotifications && historyOfNotifications.length) {
      let countsOfBranches = 0

      historyOfNotifications.forEach((item) => {
        if (item.history && item.history.length) {
          item.history.forEach((historyItem, index) => {
            if (historyItem.sent_count) {
              countsOfBranches += historyItem.sent_count
            }
          })
        }
      })

      return countsOfBranches
    }

    return 0
  }

  const calculateHistoryItemCount = (history = []) => {
    if (history && history.length) {
      let countsOfBranch = 0

      history.forEach((historyItem) => {
        if (historyItem.sent_count) {
          countsOfBranch += historyItem.sent_count
        }
      })

      return countsOfBranch
    }

    return 0
  }

  const renderDate = (item) => {
    if (item.history && item.history.length) {
      if (item.history[item.history.length - 1].last_date) {
        return new Intl.DateTimeFormat('ru-RU',
          { year: 'numeric', month: 'numeric', day: 'numeric' }
        ).format(history.last_date)
      }
    }


    return ''
  }

  useEffect(() => {
    let intervalId = null
    if (info?.group_id) {
      branchApi.branchesCustomersUploadedHistory(info.group_id)
        .then((response) => {
          setHistoryOfNotifications(response?.data || [])
        })

      intervalId = setInterval(() => {
        branchApi.branchesCustomersUploadedHistory(info.group_id)
          .then((response) => {
            setHistoryOfNotifications(response?.data || [])
          })
      }, 10000)
    }

    return () => {
      intervalId && clearInterval(intervalId)
    }
  }, [info?.group_id]);

  const showStatus = (item) => {
    if (!item.history) return null
    return item.history[item.history.length - 1].status === 'IN_PROGRESS' ? styles.active : styles.passive
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <div className={styles.modalContainer}>
            <i
              className="fa fa-chevron-left"
              aria-hidden="true"
              style={{cursor: 'pointer'}}
              onClick={() => history.push('/clients-database')}
            />
            <div className={styles.header}>
              <p>{t('NSClients.not')}</p>
            </div>
            <div>
              <p className={styles.p}>{t('NSClients.textNot')}</p>
              <textarea
                placeholder={t('Form.enterText')}
                className={styles.textArea}
                value={pushMessage}
                onChange={onChangePushMessage}
                required
              />
              <div className={styles.span}
                   style={{color: `${pushMessage.length >= 624 ? 'tomato' : '#000000'}`}}>
                {t('maxSym', {sym: 625})}
              </div>

              <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginTop: '16px'}}>
                <ButtonFilled
                  className={styles.stopBtn}
                  onClick={onDisableAutoNotification}
                  loading={modalPending || disableAutonotificationStatus}
                >
                  Прервать предыдущую авторассылку
                </ButtonFilled>
                <ButtonFilled
                  className={styles.btn}
                  onClick={onSendPushToCustomers}
                  disabled={!branchIds.length || !pushMessage}
                  loading={modalPending || disableAutonotificationStatus}
                >
                  {t('send')}
                </ButtonFilled>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rightContent}>
          <p className={styles.p}>История рассылок</p>

          <p>Общее кол-во отправок - {calculateHistoryCount()}</p>

          {historyOfNotifications.length ? (
            <ul className={styles.history}>
              <li>
                <p>
                  <Checkbox
                    checked={branchIds.length}
                    onChange={() => {
                      if (branchIds.length) {
                        setBranchIds([])
                      } else {
                        setBranchIds(historyOfNotifications.map(({branch_id}) => branch_id))
                      }
                    }}
                  />
                </p>
                <p>Филиал</p>
                <p>Дата</p>
                <p>Отправлено</p>
              </li>
              {historyOfNotifications.map((history, index) => (
                <li
                  key={history.branch_id}
                  className={`${showStatus(history)} ${activeIndexBranch === index ? styles.chosen : ''}`}
                  onClick={() => setActiveIndexBranch(index)}
                >
                  <p onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      checked={branchIds.includes(history.branch_id)}
                      onChange={() => {
                        if (branchIds.includes(history.branch_id)) {
                          setBranchIds(branchIds.filter((id) => id !== history.branch_id))
                        } else {
                          setBranchIds([...branchIds, history.branch_id])
                        }
                      }}
                    />
                  </p>
                  <p><span>{history.name}</span></p>
                  <p>
                    {renderDate(history)}
                  </p>
                  <p>
                  <span>
                    {calculateHistoryItemCount(history?.history || [])}
                  </span>
                  </p>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>

      <div className={styles.historyOfNotifications}>
        {historyOfNotifications[activeIndexBranch]?.history ? [...historyOfNotifications[activeIndexBranch]?.history].reverse().map((historyItem) => (
          <div className={styles.historyItem}>
            <div className={styles.historyTop}>
              <div>{format(new Date(historyItem.last_date), 'DD.MM.YYYY')}</div>
            </div>
            <div className={styles.historyCenter}>
              {historyItem.message}
            </div>
            <div className={styles.historyBottom}>
              <div>{historyItem.sent_count}</div>
              {historyItem.completed_date ? (
                <div>{format(new Date(historyItem.completed_date), 'DD.MM.YYYY')}</div>
              ) : null}
            </div>
          </div>
        )) : null}
      </div>
    </div>
  )
}

export default ClientsDatabaseSendNotification