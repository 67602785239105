import bonusesModalTypes from "./bonusesModalTypes";
import bonusesModalApi from "./bonusesModalApi";
import branchTypes from "../branchStore/branchTypes";
import { store } from 'react-notifications-component';
import { hasResponseErrorMessage } from "../../helpers";
import i18n from '../../i18n';
import transactionsActions from "../transactionsStore/transactionsActions";

const bonusesModalActions = {

    resetState: () => {
        return {
            type: bonusesModalTypes.BM_RESET_STATE
        }
    },

    getClientCashBack: (branch, id) => async dispatch => {
        dispatch({
            type: bonusesModalTypes.BM_GET_CLIENT_CASHBACK_PENDING
        })

        await bonusesModalApi.getClientCashBack(branch, id)
            .then(res => {
                dispatch({
                    type: bonusesModalTypes.BM_GET_CLIENT_CASHBACK_SUCCESS,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: bonusesModalTypes.BM_GET_CLIENT_CASHBACK_ERROR
                })
            })
    },

    giveAwardToUser: (branchId, awardId, data, resolve, reject) => async dispatch => {
        dispatch({
            type: bonusesModalTypes.BM_GIVE_AWARD_TO_USER_PENDING
        })

        await bonusesModalApi.giveAwardToUser(branchId, awardId, data)
            .then(() => {
                dispatch({
                    type: bonusesModalTypes.BM_GIVE_AWARD_TO_USER_SUCCESS,
                })
                resolve();
            })
            .catch((e) => {
                reject(e);
            });
    },

    changeAmountSum: (value) => {
        return {
            type: bonusesModalTypes.BM_AMOUNT_ON_CHANGE,
            payload: value
        }
    },
    changeStampCount: (value) => {
        return {
            type: bonusesModalTypes.BM_STAMP_COUNT_ON_CHANGE,
            payload: value
        }
    },
    changeBonuses: (value) => {
        return {
            type: bonusesModalTypes.BM_BONUSES_ON_CHANGE,
            payload: value
        }
    },
    changeComment: (value) => {
        return {
            type: bonusesModalTypes.BM_COMMENT_ON_CHANGE,
            payload: value
        }
    },
    changeStamp: (value) => {
        return {
            type: bonusesModalTypes.BM_STAMP_ON_CHANGE,
            payload: value
        }
    },
    changeAward: (value) => {
        return {
            type: bonusesModalTypes.BM_AWARD_ON_CHANGE,
            payload: value
        }
    },


    confirmPayment: (branch, params, bonuses, payment, resolve, reject) => dispatch => {
        dispatch({
            type: bonusesModalTypes.BM_CONFIRM_PAYMENT_PENDING
        })
        return bonusesModalApi.createOrder(branch, params)
            .then(res => {
                const id = res.data.id
                dispatch({
                    type: branchTypes.CHANGE_BRANCH_BALANCE,
                    payload: (res.data.order_cashback_amount + res.data.order_commission_amount)
                })
                bonusesModalApi.sendMoneyToUser(id, bonuses, payment)
                    .then(() => {
                        resolve(true)
                        dispatch({
                            type: bonusesModalTypes.BM_CONFIRM_PAYMENT_SUCCESS
                        })
                    })

                    .catch(() => {
                        dispatch({
                            type: bonusesModalTypes.BM_CONFIRM_PAYMENT_ERROR
                        })
                    })

            })
            .catch((e) => {
                reject(e);
                dispatch({ type: bonusesModalTypes.BM_CONFIRM_PAYMENT_ERROR });
                // dispatch({ type: bonusesModalTypes.BM_RESET_STATE });
            })
    },

    patchOrdersRefund: (order_id, params, resolve) => dispatch => {
        dispatch({
            type: bonusesModalTypes.PATCH_ORDERS_REFUND_PENDING,
            payload: true,
        })
        return bonusesModalApi.patchOrderRefund(order_id, params)
            .then(res => {
                store.addNotification({
                    title: i18n.t('success') || "",
                    message: i18n.t('NSBonusModal.patchOrdersRefundSuccess') || "",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                dispatch({
                    type: bonusesModalTypes.PATCH_ORDERS_REFUND_SUCCESS,
                });
                dispatch({
                    type: bonusesModalTypes.PATCH_ORDERS_REFUND_PENDING,
                    payload: false,
                })
                resolve();
            })
            .catch((e) => {
                if (hasResponseErrorMessage(e)) {
                    dispatch({ type: bonusesModalTypes.PATCH_ORDERS_REFUND_ERROR });
                    dispatch({
                        type: bonusesModalTypes.PATCH_ORDERS_REFUND_PENDING,
                        payload: false,
                    })
                    if (e.response.data.message) {
                        store.addNotification({
                            title: `Ошибка`,
                            message: e.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            })
    },

    revertTransaction: (order_id, params, resolve) => dispatch => {
        dispatch({
            type: bonusesModalTypes.PATCH_ORDERS_REFUND_PENDING,
            payload: true,
        })
        return bonusesModalApi.revertTransaction(order_id)
            .then(res => {
                store.addNotification({
                    title: i18n.t('success') || "",
                    message: i18n.t('NSBonusModal.patchOrdersRefundSuccess') || "",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                dispatch({
                    type: bonusesModalTypes.PATCH_ORDERS_REFUND_SUCCESS,
                });
                dispatch({
                    type: bonusesModalTypes.PATCH_ORDERS_REFUND_PENDING,
                    payload: false,
                })
                resolve();
            })
            .catch((e) => {
                if (hasResponseErrorMessage(e)) {
                    dispatch({ type: bonusesModalTypes.PATCH_ORDERS_REFUND_ERROR });
                    dispatch({
                        type: bonusesModalTypes.PATCH_ORDERS_REFUND_PENDING,
                        payload: false,
                    })
                    if (e.response.data.message) {
                        store.addNotification({
                            title: `Ошибка`,
                            message: e.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            })
    },

    completeTransaction: (order_id, params, resolve) => dispatch => {
        dispatch({
            type: bonusesModalTypes.COMPLETE_ORDERS_REFUND_PENDING,
            payload: true,
        })
        return bonusesModalApi.sendMoneyToUser(order_id, params.bonuses, params.method)
            .then(res => {
                store.addNotification({
                    title: i18n.t('success') || "",
                    message: i18n.t('NSBonusModal.patchOrdersRefundSuccess') || "",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                dispatch({
                    type: bonusesModalTypes.COMPLETE_ORDERS_REFUND_SUCCESS,
                });
                dispatch({
                    type: bonusesModalTypes.COMPLETE_ORDERS_REFUND_PENDING,
                    payload: false,
                })
                resolve();
            })
            .catch((e) => {
                if (hasResponseErrorMessage(e)) {
                    dispatch({ type: bonusesModalTypes.PATCH_ORDERS_REFUND_ERROR });
                    dispatch({
                        type: bonusesModalTypes.COMPLETE_ORDERS_REFUND_PENDING,
                        payload: false,
                    })
                    if (e.response.data.message) {
                        store.addNotification({
                            title: `Ошибка`,
                            message: e.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            })
    }
}

export default bonusesModalActions