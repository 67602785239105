import crossTypes from "./crossTypes";
import _ from "lodash";
import i18n from '../../i18n';

const initialState = {
    pending: false,
    pendingCities: false,
    pendingCategories: false,
    pendingCrossMarketingBranches: false,
    savingSelectedCrossMarketingBranches: false,
    pendingCrossMarketingGroupIdList: false,

    errorCities: '',
    errorCategories: '',

    errorCrossMarketingBranches: '',
    valueCities: [],
    valueCategories: [],
    valueCategoriesTimeStamp: null,
    valueCrossMarketingBranches: [],
    selectedCrossMarketingBranches: [],
    crossMarketingBranchesParams: {
        selectedCities: [],
        selectedCategories: [],
        skip: 0,
        limit: 8,
        page: 1,
    },

    crossMarketingAnalyticPending: false,
    crossMarketingAnalytic: {
        current: {},
        list: [],
        count: 0,
        params: {
            sort_filter_type: "",
            skip: 0,
            limit: 10,
            page: 1,
            category_id: "",
            from: null,
            to: null,
        },
        total: {
            loyalty_clients_count: 0,
            new_clients_count: 0,
            total_clients_count: 0,
        }
    },

    crossMarketingAnalyticClientsPending: false,
    crossMarketingAnalyticClients: {
        list: [],
        count: 0,
        params: {
            customer_filter_type: "",
            skip: 0,
            limit: 10,
            page: 1,
            group_id: "",
        },
    },

    valueCrossMarketingBranchesCount: 0,

    error: '',
}

const crossReducer = (state = initialState, action) => {

    switch (action.type) {
        case crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_SUCCESS:
            return {
                ...state,
                pending: false,
                error: '',
            }
        case crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_ERROR:
            return {
                ...state,
                pending: false,
                error: '',
            }

        case crossTypes.GET_CITIES_PENDING:
            return {
                ...state,
                pendingCities: true,
                valueCities: [],
                errorCities: '',
            }
        case crossTypes.GET_CITIES_SUCCESS:
            return {
                ...state,
                pendingCities: false,
                valueCities: action.payload,
                crossMarketingBranchesParams: {
                    ...state.crossMarketingBranchesParams,
                    selectedCategories: [],
                },
                errorCities: '',
            }
        case crossTypes.GET_CITIES_ERROR:
            return {
                ...state,
                pendingCities: false,
                valueCities: [],
                errorCities: '',
            }

        case crossTypes.GET_CATEGORIES_PENDING:
            return {
                ...state,
                pendingCategories: true,
                valueCategories: [],
                errorCategories: '',
            }
        case crossTypes.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                pendingCategories: false,
                valueCategories: action.payload.data.categories,
                valueCategoriesTimeStamp: action.payload.data.timestamp,
                errorCategories: '',
            }
        case crossTypes.GET_CATEGORIES_ERROR:
            return {
                ...state,
                pendingCategories: false,
                valueCategories: [],
                errorCategories: '',
            }

        case crossTypes.GET_CROSS_MARKETING_BRANCHES_PENDING:
            return {
                ...state,
                pendingCrossMarketingBranches: true,
                valueCrossMarketingBranches: [],
                errorCrossMarketingBranches: '',
            }
        case crossTypes.GET_CROSS_MARKETING_BRANCHES_SUCCESS:
            return {
                ...state,
                pendingCrossMarketingBranches: false,
                valueCrossMarketingBranches: action.payload?.data?.branches || [],
                valueCrossMarketingBranchesCount: action.payload.data.count,
                errorCrossMarketingBranches: '',
            }
        case crossTypes.GET_CROSS_MARKETING_BRANCHES_ERROR:
            return {
                ...state,
                pendingCrossMarketingBranches: false,
                valueCrossMarketingBranches: [],
                errorCrossMarketingBranches: '',
            }
        case crossTypes.SET_CROSS_MARKETING_BRANCHES_PARAMS:
            return {
                ...state,
                crossMarketingBranchesParams: {
                    ...state.crossMarketingBranchesParams,
                    ...action.payload
                }
            }
        case crossTypes.RESET_CROSS_MARKETING_BRANCHES_PARAMS:
            return {
                ...state,
                crossMarketingBranchesParams: {
                    category_id: null,
                    city_id: null,
                    selectedCategories: [],
                    selectedCities: [],
                    skip: 0,
                    limit: 8,
                }
            }

        case crossTypes.GET_CATEGORIES_GROUP_ID_LIST_PENDING:
            return {
                ...state,
                selectedCrossMarketingBranches: [],
                pendingCrossMarketingGroupIdList: true,
            }
        case crossTypes.GET_CATEGORIES_GROUP_ID_LIST_SUCCESS:
            return {
                ...state,
                selectedCrossMarketingBranches: action.payload,
                pendingCrossMarketingGroupIdList: false,
            }
        case crossTypes.GET_CATEGORIES_GROUP_ID_LIST_ERROR:
            return {
                ...state,
                selectedCrossMarketingBranches: [],
                pendingCrossMarketingGroupIdList: false,
            }
        case crossTypes.CHOSE_CMBRANCHES:
            return {
                ...state,
                comparisons: [],
                selectedCrossMarketingBranches: [...state.selectedCrossMarketingBranches, action.payload.group_id],
            }
        case crossTypes.REMOVE_CMBRANCHES:
            return {
                ...state,
                selectedCrossMarketingBranches: state.selectedCrossMarketingBranches.filter((group_id) => group_id !== action.payload.group_id),
            }
        case crossTypes.RESET_CMBRANCHES:
            return {
                ...state,
                selectedCrossMarketingBranches: []
            }

        case crossTypes.CLEAR_CMBRANCHES:
            return {
                ...state,
                selectedCrossMarketingBranches: []
            }

        case crossTypes.SAVE_CROSS_MARKETING_BRANCHES_PENDING:
            return {
                ...state,
                savingSelectedCrossMarketingBranches: true,
            }
        case crossTypes.SAVE_CROSS_MARKETING_BRANCHES_SUCCESS:
            return {
                ...state,
                savingSelectedCrossMarketingBranches: false,
            }
        case crossTypes.SAVE_CROSS_MARKETING_BRANCHES_ERROR:
            return {
                ...state,
                savingSelectedCrossMarketingBranches: false,
            }

        case crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_PENDING:
            return {
                ...state,
                crossMarketingAnalyticPending: true,
                crossMarketingAnalytic: {
                    ...state.crossMarketingAnalytic,
                    count: 0,
                    list: [],
                    total: {
                        loyalty_clients_count: 0,
                        new_clients_count: 0,
                        total_clients_count: 0,
                    }
                }
            }
        case crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_SUCCESS:
            return  {
                ...state,
                crossMarketingAnalyticPending: false,
                crossMarketingAnalytic: {
                    ...state.crossMarketingAnalytic,
                    count: action.payload.results.count || 0,
                    list: action.payload.results.branches || [],
                    // params: {
                    //     sort_filter_type: "",
                    //     skip: 0,
                    //     limit: 10,
                    //     page: 1,
                    // },
                    total: {
                        loyalty_clients_count: action.payload.total.loyalty_clients_count || 0,
                        new_clients_count: action.payload.total.new_clients_count || 0,
                        total_clients_count: action.payload.total.total_clients_count || 0,
                    }
                }
            }
        case crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_ERROR:
            return {
                ...state,
                crossMarketingAnalyticPending: false,
                crossMarketingAnalytic: {
                    ...state.crossMarketingAnalytic,
                    count: 0,
                    list: [],
                    total: {
                        loyalty_clients_count: 0,
                        new_clients_count: 0,
                        total_clients_count: 0,
                    }
                }
            }


        case crossTypes.SET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS:
            return {
                ...state,
                crossMarketingAnalytic: {
                    ...state.crossMarketingAnalytic,
                    params: {
                        ...state.crossMarketingAnalytic.params,
                        [action.payload.name]: action.payload.value,
                    },
                }
            }
        case crossTypes.RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS:
            return {
                ...state,
                crossMarketingAnalytic: {
                    ...state.crossMarketingAnalytic,
                    params: {
                        sort_filter_type: "",
                        skip: 0,
                        limit: 10,
                        page: 1,
                        category_id: "",
                        from: null,
                        to: null,
                    },
                }
            }

        case crossTypes.SET_CROSS_MARKETING_BRANCHES_ANALYTIC:
            return {
                ...state,
                crossMarketingAnalytic: {
                    ...state.crossMarketingAnalytic,
                    current: action.payload,
                },
                crossMarketingAnalyticClients: {
                    ...state.crossMarketingAnalyticClients,
                    params: {
                        ...state.crossMarketingAnalyticClients.params,
                        group_id: action.payload.group_id,
                    },
                }
            }

        case crossTypes.RESET_CROSS_MARKETING_BRANCHES_ANALYTIC:
            return {
                ...state,
                crossMarketingAnalytic: {
                    ...state.crossMarketingAnalytic,
                   current: {},
                }
            }


        case crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PENDING:
            return {
                ...state,
                crossMarketingAnalyticClientsPending: true,
                crossMarketingAnalyticClients: {
                    ...state.crossMarketingAnalyticClients,
                    list: [],
                    count: 0,
                },
            }
        case crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_SUCCESS:
            return {
                ...state,
                crossMarketingAnalyticClientsPending: false,
                crossMarketingAnalyticClients: {
                    ...state.crossMarketingAnalyticClients,
                    list: action.payload.customers || [],
                    count: action.payload.count || 0,
                },
            }
        case crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_ERROR:
            return {
                ...state,
                crossMarketingAnalyticClientsPending: false,
                crossMarketingAnalyticClients: {
                    ...state.crossMarketingAnalyticClients,
                    list: [],
                    count: 0,
                },
            }

        case crossTypes.RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS:
            return {
                ...state,
                crossMarketingAnalyticClientsPending: false,
                crossMarketingAnalyticClients: {
                    ...state.crossMarketingAnalyticClients,
                    params: {
                        customer_filter_type: "",
                        skip: 0,
                        limit: 3,
                        page: 1,
                        group_id: "",
                    },
                },
            }
        case crossTypes.SET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS:
            return {
                ...state,
                crossMarketingAnalyticClientsPending: false,
                crossMarketingAnalyticClients: {
                    ...state.crossMarketingAnalyticClients,
                    params: {
                        ...state.crossMarketingAnalyticClients.params,
                        [action.payload.name]: action.payload.value,
                    },
                },
            }

        default:
            return state
    }
}

export default crossReducer
