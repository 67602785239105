import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RFMActions from 'store/analyticsStores/RFMStore/RFMActions';
import { computePercent } from 'helpers';
import { ERROR, SUCCESS} from '../../../../store/globalStatuses/globalStatuses';
import Modal from 'portals/Modal';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import Loader from '../../../../components/Loader';
import styles from './rmfAnalysis.module.scss';
import { useCurrentBranch } from "../../../../hooks/useCurrentBranch";
import { Roles } from "../../../../config";
import Invis from "../../../../components/Filter/Invis";
import { convertDataToTransactions } from "../../../../tools/convertDateToString";

const cells = [
  ['emergingCustomer', 'topCustomer', 'topCustomer'],
  ['emergingCustomer', 'activeCustomer', 'topCustomer'],
  ['emergingCustomer', 'activeCustomer', 'activeCustomer'],
  ['emergingCustomer', 'emergingCustomer', 'emergingCustomer'],
  ['unsteadyCustomer', 'unsteadyCustomer', 'unsteadyCustomer'],
  ['potentialLostCustomer', 'potentialLostCustomer', 'unsteadyCustomer'],
  ['potentialLostCustomer', 'potentialLostCustomer', 'unsteadyCustomer'],
  ['lostCustomer', 'inactiveCustomer', 'inactiveCustomer'],
  ['lostCustomer', 'lostCustomer', 'inactiveCustomer'],
];

const initialState = {
  TOP_CUSTOMERS: false,
  ACTIVE_CUSTOMERS: false,
  EMERGING_CUSTOMERS: false,
  UNSTEADY_CUSTOMERS: false,
  POTENTIAL_LOST_CUSTOMERS: false,
  INACTIVE_CUSTOMERS: false,
  LOST_CUSTOMERS: false,
};

export default function RFMAnalysis() {
  const dispatch = useDispatch();
  const history = useHistory();
  const branch = useSelector(s => s.branchReducer.info);
  const rfm = useSelector(s => s.RFMReducer);
  const { t } = useTranslation();

  const invisParams = useSelector(state => state.filtersReducer)

  const [customerTypes, setCustomerTypes] = useState(initialState);
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [pending, setPending] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { currentRole } = useCurrentBranch()

  const exportExcel = () => {
    setDownloading(true);
    RFMActions.getRFMAnalyticExport(branch.group_id, invisParams)
  .finally(() => setDownloading(false));
  }

  useEffect(() => {
    if (branch) {
      new Promise((resolve, reject) => {
        setPending(true);
        dispatch(RFMActions.getRFM(branch.id, currentRole, invisParams, branch.group_id, resolve, reject))
      })
        .catch((e) => {
          if (e?.response?.data?.message === "you didn't fill in the indexes for rfm analysis") {
            setModal(true);
          } else {
            setErrorModal(true);
          }
        })
        .finally(() => setPending(false));
    }
  }, [branch, invisParams]);

  const onMouseIn = (type) => {
    setCustomerTypes({...initialState, [type]: true});
  }

  const onMouseOut = (type) => {
    setCustomerTypes({...customerTypes, [type]: false});
  }

  const onCloseModal = () => {
    setModal(false);
  }

  const onOpenModal = () => {
    setModal(true);
  }

  const goToClient = (clientType) => () => {
    history.push(`/analytics/rfm/${clientType.toLowerCase()}`)
  }

  const renderRFM = () => {
    return (
      <>
        <table className={styles.table}>
          <thead>
          <tr>
            <th>
              <div className={styles.tableHead}>
                <div>r</div>
              </div>
            </th>
            <th>
              <div className={styles.tableHead}>
                <div>m</div>
              </div>
            </th>
            <th>
              <div className={styles.tableHead}>
                <div>f-3</div>
                <div>f-2</div>
                <div>f-1</div>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <div>1</div>
              <div>2</div>
              <div>3</div>
            </td>
            <td>
              {new Array(3).fill(new Array(3).fill('')).map((_, index) => _.map((item, i) => (
                <div key={index + '' + i}>{i+1}</div>
              )))}
            </td>
            <td>
              <div className={styles.tableF}>
                <div
                  className={`
                        ${styles.customer}
                        ${styles.emergingCustomer}
                        ${customerTypes.EMERGING_CUSTOMERS ? styles.active : ''}`}
                  onMouseOver={() => onMouseIn('EMERGING_CUSTOMERS')}
                  onMouseLeave={() => onMouseOut('EMERGING_CUSTOMERS')}
                  onClick={goToClient('EMERGING_CUSTOMERS')}
                >
                  <p>{t('promising')}</p>
                </div>
                <div className={`
                            ${styles.customer}
                            ${styles.topCustomer}
                            ${customerTypes.TOP_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('TOP_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('TOP_CUSTOMERS')}
                     onClick={goToClient('TOP_CUSTOMERS')}
                />
                <div className={`
                           ${styles.customer}
                           ${styles.topCustomer}
                           ${customerTypes.TOP_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('TOP_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('TOP_CUSTOMERS')}
                     onClick={goToClient('TOP_CUSTOMERS')}
                >
                  <p>{t('NSAnalysis.important')}</p>
                </div>

                <div
                  className={`${styles.emergingCustomer} ${styles.customer} ${customerTypes.EMERGING_CUSTOMERS ? styles.active : ''}`}
                  onMouseOver={() => onMouseIn('EMERGING_CUSTOMERS')}
                  onMouseLeave={() => onMouseOut('EMERGING_CUSTOMERS')}
                  onClick={goToClient('EMERGING_CUSTOMERS')}
                >
                  <b>{rfm.EMERGING_CUSTOMERS.length}</b> &nbsp;
                  <span>({computePercent(rfm.EMERGING_CUSTOMERS.length, rfm.ALL_CUSTOMERS.length)}%)</span>
                </div>
                <div className={`${styles.activeCustomer} ${styles.customer} ${customerTypes.ACTIVE_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('ACTIVE_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('ACTIVE_CUSTOMERS')}
                     onClick={goToClient('ACTIVE_CUSTOMERS')}
                >
                  <p>{t('active')}</p>
                </div>
                <div className={`${styles.topCustomer} ${styles.customer} ${customerTypes.TOP_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('TOP_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('TOP_CUSTOMERS')}
                     onClick={goToClient('TOP_CUSTOMERS')}
                >
                  <b>{rfm.TOP_CUSTOMERS.length}</b> &nbsp;
                  <span>({computePercent(rfm.TOP_CUSTOMERS.length, rfm.ALL_CUSTOMERS.length)}%)</span>
                </div>

                <div
                  className={`${styles.emergingCustomer} ${styles.customer} ${customerTypes.EMERGING_CUSTOMERS ? styles.active : ''}`}
                  onMouseOver={() => onMouseIn('EMERGING_CUSTOMERS')}
                  onMouseLeave={() => onMouseOut('EMERGING_CUSTOMERS')}
                  onClick={goToClient('EMERGING_CUSTOMERS')}
                />
                <div className={`${styles.activeCustomer} ${styles.customer} ${customerTypes.ACTIVE_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('ACTIVE_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('ACTIVE_CUSTOMERS')}
                     onClick={goToClient('ACTIVE_CUSTOMERS')}
                >
                  <b>{rfm.ACTIVE_CUSTOMERS.length}</b> &nbsp;
                  <span>({computePercent(rfm.ACTIVE_CUSTOMERS.length, rfm.ALL_CUSTOMERS.length)}%)</span>
                </div>
                <div className={`${styles.activeCustomer} ${styles.customer} ${customerTypes.ACTIVE_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('ACTIVE_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('ACTIVE_CUSTOMERS')}
                     onClick={goToClient('ACTIVE_CUSTOMERS')}
                />

                <div className={`${styles.emergingCustomer} ${styles.customer} ${customerTypes.EMERGING_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('EMERGING_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('EMERGING_CUSTOMERS')}
                     onClick={goToClient('EMERGING_CUSTOMERS')}
                />
                <div className={`${styles.emergingCustomer} ${styles.customer} ${customerTypes.EMERGING_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('EMERGING_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('EMERGING_CUSTOMERS')}
                     onClick={goToClient('EMERGING_CUSTOMERS')}
                />
                <div className={`${styles.emergingCustomer} ${styles.customer} ${customerTypes.EMERGING_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('EMERGING_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('EMERGING_CUSTOMERS')}
                     onClick={goToClient('EMERGING_CUSTOMERS')}
                />

                <div className={`${styles.unsteadyCustomer} ${styles.customer} ${customerTypes.UNSTEADY_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('UNSTEADY_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('UNSTEADY_CUSTOMERS')}
                     onClick={goToClient('UNSTEADY_CUSTOMERS')}
                />
                <div className={`${styles.unsteadyCustomer} ${styles.customer} ${customerTypes.UNSTEADY_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('UNSTEADY_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('UNSTEADY_CUSTOMERS')}
                     onClick={goToClient('UNSTEADY_CUSTOMERS')}
                />
                <div className={`${styles.unsteadyCustomer} ${styles.customer} ${customerTypes.UNSTEADY_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('UNSTEADY_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('UNSTEADY_CUSTOMERS')}
                     onClick={goToClient('UNSTEADY_CUSTOMERS')}
                >
                  <p>{t('fickle')}</p>
                </div>

                <div className={`${styles.potentialLostCustomer} ${styles.customer} ${customerTypes.POTENTIAL_LOST_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('POTENTIAL_LOST_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('POTENTIAL_LOST_CUSTOMERS')}
                     onClick={goToClient('POTENTIAL_LOST_CUSTOMERS')}
                >
                  <p>{t('NSAnalysis.zone')}</p>
                </div>
                <div className={`${styles.potentialLostCustomer} ${styles.customer} ${customerTypes.POTENTIAL_LOST_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('POTENTIAL_LOST_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('POTENTIAL_LOST_CUSTOMERS')}
                     onClick={goToClient('POTENTIAL_LOST_CUSTOMERS')}
                />
                <div className={`${styles.unsteadyCustomer} ${styles.customer} ${customerTypes.UNSTEADY_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('UNSTEADY_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('UNSTEADY_CUSTOMERS')}
                     onClick={goToClient('UNSTEADY_CUSTOMERS')}
                >
                  <b>{rfm.UNSTEADY_CUSTOMERS.length}</b> &nbsp;
                  <span>({computePercent(rfm.UNSTEADY_CUSTOMERS.length, rfm.ALL_CUSTOMERS.length)}%)</span>
                </div>

                <div className={`${styles.potentialLostCustomer} ${styles.customer} ${customerTypes.POTENTIAL_LOST_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('POTENTIAL_LOST_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('POTENTIAL_LOST_CUSTOMERS')}
                     onClick={goToClient('POTENTIAL_LOST_CUSTOMERS')}
                >
                  <b>{rfm.POTENTIAL_LOST_CUSTOMERS.length}</b> &nbsp;
                  <span>({computePercent(rfm.POTENTIAL_LOST_CUSTOMERS.length, rfm.ALL_CUSTOMERS.length)}%)</span>
                </div>
                <div className={`${styles.potentialLostCustomer} ${styles.customer} ${customerTypes.POTENTIAL_LOST_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('POTENTIAL_LOST_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('POTENTIAL_LOST_CUSTOMERS')}
                     onClick={goToClient('POTENTIAL_LOST_CUSTOMERS')}
                />
                <div className={`${styles.unsteadyCustomer} ${styles.customer} ${customerTypes.UNSTEADY_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('UNSTEADY_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('UNSTEADY_CUSTOMERS')}
                     onClick={goToClient('UNSTEADY_CUSTOMERS')}
                />

                <div className={`${styles.lostCustomer} ${styles.customer} ${customerTypes.LOST_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('LOST_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('LOST_CUSTOMERS')}
                     onClick={goToClient('LOST_CUSTOMERS')}
                >
                  <p>{t('lost')}</p>
                </div>
                <div className={`${styles.inactiveCustomer} ${styles.customer} ${customerTypes.INACTIVE_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('INACTIVE_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('INACTIVE_CUSTOMERS')}
                     onClick={goToClient('INACTIVE_CUSTOMERS')}
                />
                <div className={`${styles.inactiveCustomer} ${styles.customer} ${customerTypes.INACTIVE_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('INACTIVE_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('INACTIVE_CUSTOMERS')}
                     onClick={goToClient('INACTIVE_CUSTOMERS')}
                >
                  <p>{t('inactive')}</p>
                </div>

                <div className={`${rfm.LOST_CUSTOMERS.length ? styles.lostCustomer : ''} ${styles.customer} ${customerTypes.LOST_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('LOST_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('LOST_CUSTOMERS')}
                     onClick={goToClient('LOST_CUSTOMERS')}
                >
                  <b>{rfm.LOST_CUSTOMERS.length}</b> &nbsp;
                  <span>({computePercent(rfm.LOST_CUSTOMERS.length, rfm.ALL_CUSTOMERS.length)}%)</span>
                </div>
                <div className={`${rfm.LOST_CUSTOMERS.length ? styles.lostCustomer : ''} ${styles.customer} ${customerTypes.LOST_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('LOST_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('LOST_CUSTOMERS')}
                     onClick={goToClient('LOST_CUSTOMERS')}
                />
                <div className={`${styles.inactiveCustomer} ${styles.customer} ${customerTypes.INACTIVE_CUSTOMERS ? styles.active : ''}`}
                     onMouseOver={() => onMouseIn('INACTIVE_CUSTOMERS')}
                     onMouseLeave={() => onMouseOut('INACTIVE_CUSTOMERS')}
                     onClick={goToClient('INACTIVE_CUSTOMERS')}
                >
                  <b>{rfm.INACTIVE_CUSTOMERS.length}</b> &nbsp;
                  <span>({computePercent(rfm.INACTIVE_CUSTOMERS.length, rfm.ALL_CUSTOMERS.length)}%)</span>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div className={styles.info}>
          {
            rfm.TOP_CUSTOMERS.length ? (
              <div className={`${styles.infoItem}`}
                   onMouseOver={() => onMouseIn('TOP_CUSTOMERS')}
                   onMouseLeave={() => onMouseOut('TOP_CUSTOMERS')}
              >
                <div className={`${styles.infoRound} ${styles.topCustomer}`}></div>
                <div  className={styles.infoText}>
                  {/*{rfm.TOP_CUSTOMERS[0].cluster.Title || ''}*/}
                  {t('NSAnalysis.TOP_CUSTOMERS')}
                </div>
              </div>
            ) : null
          }
          {
            rfm.EMERGING_CUSTOMERS.length ? (
              <div className={`${styles.infoItem}`}
                   onMouseOver={() => onMouseIn('EMERGING_CUSTOMERS')}
                   onMouseLeave={() => onMouseOut('EMERGING_CUSTOMERS')}
              >
                <div className={`${styles.infoRound} ${styles.emergingCustomer}`}></div>
                <div  className={styles.infoText}>
                  {/*{rfm.EMERGING_CUSTOMERS[0].cluster.Title || ''}*/}
                  {t('NSAnalysis.EMERGING_CUSTOMERS')}
                </div>
              </div>
            ) : null
          }
          {
            rfm.ACTIVE_CUSTOMERS.length ? (
              <div className={`${styles.infoItem}`}
                   onMouseOver={() => onMouseIn('ACTIVE_CUSTOMERS')}
                   onMouseLeave={() => onMouseOut('ACTIVE_CUSTOMERS')}
              >
                <div className={`${styles.infoRound} ${styles.activeCustomer}`}></div>
                <div  className={styles.infoText}>
                  {/*{rfm.ACTIVE_CUSTOMERS[0].cluster.Title || ''}*/}
                  {t('NSAnalysis.ACTIVE_CUSTOMERS')}
                </div>
              </div>
            ) : null
          }
          {
            rfm.UNSTEADY_CUSTOMERS.length ? (
              <div className={`${styles.infoItem}`}
                   onMouseOver={() => onMouseIn('UNSTEADY_CUSTOMERS')}
                   onMouseLeave={() => onMouseOut('UNSTEADY_CUSTOMERS')}
              >
                <div className={`${styles.infoRound} ${styles.unsteadyCustomer}`}></div>
                <div  className={styles.infoText}>
                  {/*{rfm.UNSTEADY_CUSTOMERS[0].cluster.Title || ''}*/}
                  {t('NSAnalysis.UNSTEADY_CUSTOMERS')}
                </div>
              </div>
            ) : null
          }
          {
            rfm.POTENTIAL_LOST_CUSTOMERS.length ? (
              <div className={`${styles.infoItem}`}
                   onMouseOver={() => onMouseIn('POTENTIAL_LOST_CUSTOMERS')}
                   onMouseLeave={() => onMouseOut('POTENTIAL_LOST_CUSTOMERS')}
              >
                <div className={`${styles.infoRound} ${styles.potentialLostCustomer}`}></div>
                <div  className={styles.infoText}>
                  {/*{rfm.POTENTIAL_LOST_CUSTOMERS[0].cluster.Title || ''}*/}
                  {t('NSAnalysis.POTENTIAL_LOST_CUSTOMERS')}
                </div>
              </div>
            ) : null
          }
          {
            rfm.LOST_CUSTOMERS.length ? (
              <div className={`${styles.infoItem}`}
                   onMouseOver={() => onMouseIn('LOST_CUSTOMERS')}
                   onMouseLeave={() => onMouseOut('LOST_CUSTOMERS')}
              >
                <div className={`${styles.infoRound} ${styles.lostCustomer}`}></div>
                <div  className={styles.infoText}>
                  {/*{rfm.LOST_CUSTOMERS[0].cluster.Title || ''}*/}
                  {t('NSAnalysis.LOST_CUSTOMERS')}
                </div>
              </div>
            ) : null
          }
          {
            rfm.INACTIVE_CUSTOMERS.length ? (
              <div className={`${styles.infoItem}`}
                   onMouseOver={() => onMouseIn('INACTIVE_CUSTOMERS')}
                   onMouseLeave={() => onMouseOut('INACTIVE_CUSTOMERS')}
              >
                <div className={`${styles.infoRound} ${styles.inactiveCustomer}`}></div>
                <div  className={styles.infoText}>
                  {/*{rfm.INACTIVE_CUSTOMERS[0].cluster.Title || ''}*/}
                  {t('NSAnalysis.INACTIVE_CUSTOMERS')}
                </div>
              </div>
            ) : null
          }
        </div>
      </>
    )
  }

  return (
        <div className={styles.container}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {currentRole === Roles.ANALYTIC ? (
              <div style={{marginLeft: 'auto', width: '100%', padding: '0px 10px 24px 0px'}}>
                <Invis />
              </div>
            ) : null}
            {branch?.group_id ? (
              <div style={{marginLeft: 'auto'}}>
                <ButtonFilled
                  onClick={exportExcel}
                  loading={downloading || pending}
                >{t("export")}</ButtonFilled>
              </div>
            ) : null}
          </div>

          {pending ? (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px'}}>
              <Loader color="#73D389"/>
            </div>
          ) : (
            <>
            {
                rfm.status === ERROR ? (
                  <div className={styles.error}>
                    <h2>{t("pageIsNotAvailable")}</h2>
                  </div>
                ) : renderRFM()
              }
            </>
          )}

          <Modal
            open={modal} size="small"
            onClickClose={onCloseModal}>
            <div className={styles.modal}>
              <p>
                {t("optionForbidden")} <br/>
                {t("touchModerator")}
              </p>
              <div>
                <ButtonFilled
                  onClick={() => history.push('/profile/rfm')}
                >{t("settings")}</ButtonFilled>
              </div>
            </div>
          </Modal>

          <Modal
            open={errorModal} size="small"
            onClickClose={() => setErrorModal(false)}>
            <div className={styles.modal}>
              <p>
                {t("optionForbidden")} <br/>
                {t("touchModerator")}
              </p>
              <div>
                <ButtonFilled
                  onClick={() => setErrorModal(false)}
                >{t("close")}</ButtonFilled>
              </div>
            </div>
          </Modal>
        </div>
    )
}