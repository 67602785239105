import React from 'react';
import { Trans, useTranslation } from "react-i18next";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Modal from "../../../../portals/Modal";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import TextField from "../../../../components/FormElements/TextField";
import { useBonusStandard } from "../../hooks/useBonusStandard";
import PageHeader from "../../../../components/PageHeader";

import styles from './profileBonusStandard.module.scss';
import { useBonusIncreased } from "../../hooks/useBonusIncreased";
import CustomDatePickerWithTime from "../../../../components/CustomDatePickerWithTime/CustomDatePicker";
import CustomTimePicker from "../../../../components/CustomTimePicker/CustomDatePicker";
import Checkbox from "../../../../components/FormElements/Checkbox";

export default function ProfileBonusStandard() {
    const { t } = useTranslation();
    const {
        formik,
        errorModal,
        setErrorModal,
        firstCashback,
        pending,
    } = useBonusStandard(t);
    const {
        isCalendar,
        setIsCalendar,
        isHours,
        setIsHours,
        enabledIncreasedCashback,
        formik: increasedFormik,
        errorModal: increasedErrorModal,
        setErrorModal: increasedSetErrorModal,
        firstCashback: increasedFirstCashback,
        pending: increasedPending,
        changeDate,
        changeHour,
        onSwitchOff,
    } = useBonusIncreased(t);

    return (
      <>
          <PageHeader title={t('bonuses')}>
              <div style={{marginLeft: '50px'}}>
                  <QuestionTooltip text={t('NSStandard.welcome')}/>
              </div>
          </PageHeader>
          <div className={styles.container}>
              <form onSubmit={formik.handleSubmit}>
                  <div className={styles.block}>
                      <TextField
                        label={t('NSStandard.standard')}
                        placeholder="8%"
                        width="160px"
                        disabled={pending}
                        required

                        value={formik.values.basic_bonus}
                        error={formik.touched.basic_bonus && formik.errors.basic_bonus}
                        onChange={(e) => isFinite(e.target.value) && formik.setFieldValue('basic_bonus', e.target.value)}
                      />
                      <span className={styles['block__right-text']}>{t('NSStandard.total')}</span>
                      <p className={styles['block__text']}>{t('NSStandard.type')}</p>
                  </div>
                  {formik.values.hasFirstBonus && (
                    <div className={styles.block}>
                        <TextField
                          label={t('NSStandard.firstBonus')}
                          placeholder="8%"
                          width="160px"
                          disabled={pending}
                          required

                          value={formik.values.first_bonus}
                          error={formik.touched.first_bonus && formik.errors.first_bonus}
                          onChange={(e) => isFinite(e.target.value) && formik.setFieldValue('first_bonus', e.target.value)}
                        />
                        <span className={styles['block__right-text']}>{t('NSStandard.total')}</span>
                        <p className={styles['block__text']}>{t('NSStandard.choose1')}</p>
                    </div>
                  )}
                  {/*<div className={styles.block}>*/}
                  {/*    <TextField*/}
                  {/*        label="При оплате сертификатом"*/}
                  {/*        placeholder="8%"*/}
                  {/*        width="160px"*/}
                  {/*        disabled={pending}*/}
                  {/*        required*/}

                  {/*        value={formik.values.certificate_bonus}*/}
                  {/*        error={formik.touched.certificate_bonus && formik.errors.certificate_bonus}*/}
                  {/*        onChange={(e) => isFinite(e.target.value) && formik.setFieldValue('certificate_bonus', e.target.value)}*/}
                  {/*    />*/}
                  {/*    <span className={styles['block__right-text']}>% от суммы чека</span>*/}
                  {/*    <p className={styles['block__text']}>Укажите размер бонуса при оплате подарочным сертификатом</p>*/}
                  {/*</div>*/}
                  <div className={styles.footer}>
                      <ButtonFilled type="submit" loading={pending}>{t('save')}</ButtonFilled>
                  </div>
              </form>
          </div>


          {enabledIncreasedCashback ? <div className={styles.container} style={{marginTop: 30}}>
              <form onSubmit={increasedFormik.handleSubmit}>
                  <div className={styles.block}>
                      <TextField
                        label={t('NSStandard.increasedBonus')}
                        placeholder="7%"
                        width="160px"
                        disabled={increasedPending}
                        required

                        value={increasedFormik.values.percentage}
                        error={increasedFormik.touched.percentage && increasedFormik.errors.percentage}
                        onChange={(e) => isFinite(e.target.value) && increasedFormik.setFieldValue('percentage', e.target.value)}
                      />
                      <span className={styles['block__right-text']}>{t('NSStandard.total')}</span>
                      <p className={styles['block__text']}>{t('NSStandard.chooseIncrease')}</p>
                  </div>
                  <div className={styles.block}
                       style={{margin: "32px 0", gap: "16px", display: "flex", flexDirection: "column"}}>
                      <div style={{display: "flex", alignItems: "center" }}>
                          <Checkbox
                            label=""
                            name="enabled"
                            checked={isCalendar}
                            onChange={() => setIsCalendar(!isCalendar)}
                          />
                          <CustomDatePickerWithTime
                            trans={true}
                            tStartDate={increasedFormik.values.dates[0].from}
                            tEndDate={increasedFormik.values.dates[0].to}
                            tSetStartDate={(v) => isCalendar && changeDate(0, "from", v)}
                            tSetEndDate={(v) => isCalendar && changeDate(0, "to", v)}
                          />
                      </div>
                      <div style={{display: "flex", alignItems: "center", marginTop: "6px" }}>
                          <Checkbox
                            label={t("always")}
                            name="enabled"
                            checked={increasedFormik.values.always_on}
                            onChange={() => increasedFormik.setFieldValue('always_on', !increasedFormik.values.always_on)}
                          />
                      </div>
                      <div style={{display: "flex", alignItems: "center" }}>
                          <Checkbox
                            label=""
                            name="always_on"
                            checked={isHours}
                            onChange={() => setIsHours(!isHours)}
                          />
                          <div style={{ display: "flex", gap: "14px", alignItems: "center", flexWrap: "wrap" }}>
                              {t("always_from")}
                              <div style={{ maxWidth: "160px" }}>
                                  <TextField
                                    type="number"
                                    min={1}
                                    max={24}
                                    onChange={(e) => {
                                        if (Number(e.target.value) > 0 && Number(e.target.value) <= 24 && isHours) {
                                            return changeHour(0, "from", e.target.value);
                                        }
                                        if (e.target.value === "") {
                                            changeHour(0, "from", "");
                                        }
                                    }}
                                    value = {increasedFormik.values.hours[0].from}
                                    disabled={!isHours}
                                  />
                              </div>
                              {t("to")}
                              <div style={{ maxWidth: "160px" }}>
                                  <TextField
                                    type="number"
                                    min={1}
                                    max={24}
                                    onChange={(e) => {
                                        if (Number(e.target.value) > 0 && Number(e.target.value) <= 24 && isHours) {
                                            return changeHour(0, "to", e.target.value);
                                        }
                                        if (e.target.value === "") {
                                            changeHour(0, "to", "");
                                        }
                                    }}
                                    value = {increasedFormik.values.hours[0].to}
                                    disabled={!isHours}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.footer}>
                      <ButtonFilled type="submit" loading={increasedPending}>{t('save')}</ButtonFilled>
                      <ButtonFilled
                        variant="red"
                        type="button"
                        loading={increasedPending}
                        onClick={onSwitchOff}
                      >{t('switch_off')}
                      </ButtonFilled>
                  </div>
              </form>
          </div> : null}

          <Modal
            open={errorModal} size="small"
            onClickClose={() => setErrorModal(false)}>
              <div className={styles.modal}>
                  <p>
                      {t('optionForbidden')} <br/>
                      {t('touchModerator')}
                  </p>
                  <div>
                      <ButtonFilled
                        onClick={() => setErrorModal(false)}
                      >{t('close')}</ButtonFilled>
                  </div>
              </div>
          </Modal>
      </>
    )
}
