import React, { useState } from 'react'
import Sticky from 'react-sticky-el';
import AccountInfo from "./components/AccountInfo/AccountInfo";
import Navigation from "./components/Navigation/Navigation";
import logout from './assets/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cookies from 'react-cookies';
import { COOKIES, plural } from '../../helpers';
import { deleteAuth } from '../../store/authState';
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from "react-i18next";
import styles from './Sidebar.module.css'
import { convertDateToShortString } from "../../tools/convertDateToString";
import Countdown from 'react-countdown';

export default function Sidebar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFinishedSubscription, setIsFinishedSubscription] = useState(false);
  const info = useSelector(state => state.branchReducer.info)
  const { t, i18n } = useTranslation()

  // const info = {
  //   subscription: {
  //     started_at: 1701927042921,
  //     expired_at: Date.now() + 1000 * 50,
  //     manager_phone: "+99321551233411",
  //     manager_name: "Жумалиев Айберген Тастович",
  //   }
  // }

  const onExit = () => {
    cookies.remove(COOKIES.TOKEN, {path: '/'});
    cookies.remove(COOKIES.REMEMBER, {path: '/'});
    dispatch(deleteAuth());
    history.push('/auth');
  }

  const renderCountDown = ({ days, hours, minutes, seconds }) => {
    const years = Math.floor(days / 365);

    if (years) {
      return (
        <div className={styles.countDown}>
          {t(`subscriptionExpire`, { remainInfo: years + ' ' + t(`NSPlural.${plural(years, "year")}`) })}
        </div>
      )
    }

    if (days) {
      return (
        <div className={styles.countDown}>
          {t(`subscriptionExpire`, { remainInfo: days + ' ' + t(`NSPlural.${plural(days, "day")}`) })}
        </div>
      )
    }

    if (hours) {
      return (
        <div className={styles.countDown}>
          {t(`subscriptionExpire`, { remainInfo: hours + ' ' + t(`NSPlural.${plural(hours, "hour")}`) })}
        </div>
      )
    }

    if (minutes) {
      return (
        <div className={styles.countDown}>
          {t(`subscriptionExpire`, { remainInfo: minutes + ' ' + t(`NSPlural.${plural(minutes, "minute")}`) })}
        </div>
      )
    }

    return (
      <div className={styles.countDown}>
        {t(`subscriptionExpire`, { remainInfo: `00:${seconds > 10 ? seconds : `0${seconds}`}` })}
      </div>
    )
  }

  return (
      <div className={styles.container}>
          <AccountInfo />
          <div style={{position:'relative',display:'flex',justifyContent:'center'}}>
              <Navigation />
          </div>
          <Sticky
            boundaryElement={`.${styles.container}`}
          >
            <div className={styles.lang}>
              <LanguageSelector withLabel color="#fff" />
            </div>
            <div className={styles.logout} onClick={onExit}>
              <img src={logout}/>
              <span>{t('logout')}</span>
            </div>
            {info?.subscription && (info?.subscription.started_at !== 0 && info?.subscription.expired_at !== 0) ? (
              <ul className={styles.subscription}>
                {info?.subscription?.started_at ? (
                  <li>
                    {t("startSubs")} {convertDateToShortString(info?.subscription?.started_at)}
                  </li>
                ) : null}
                <li>
                  {!isFinishedSubscription && info?.subscription?.expired_at && Date.now() < info?.subscription?.expired_at ?
                    <Countdown
                      date={info.subscription.expired_at}
                      renderer={renderCountDown}
                      onComplete={() => setIsFinishedSubscription(true)}
                    />
                    : <span className={styles.danger}>{t("subsExpired")}</span>}
                </li>
                {info.subscription.delay_days ? (
                  <li>
                    <div className={styles.countDown}>
                      {t("defermentDays")} {info.subscription.delay_days}
                    </div>
                  </li>
                ) : null}
                {info?.subscription?.manager_name ? (
                  <li>
                    {t("yourManager")} - {info?.subscription?.manager_name}
                  </li>
                ) : null}
                <li>
                  <a href={`tel:${info?.subscription?.manager_phone}`}>{info?.subscription?.manager_phone}</a>
                </li>
              </ul>
            ) : null}
          </Sticky>
      </div>
  )
}
