import instance from "../../api";

const adsApi = {
    getSales: () => {
        return instance().get(`/sales`).then(res => res.data)
    },
    getSalesForBranchGroup: (id, queryParams) => {
        return instance().get(`/branches/${id}/sales${queryParams ? `?${queryParams}` : ''}`).then(res => res.data)
    },
    getSaleById: (id) => {
        return instance().get(`/sales/${id}`).then(res => res.data)
    },

    getCustomersBySale: (id) => {
        return instance().get(`/sales/${id}/customers`).then(res => res.data)
    },
    getBusinessCustomersBySale: (id) => {
        return instance().get(`/branches/${id}/sale/users`).then(res => res.data)
    },
    getSaleReport: (id, queryParams) => {
        return instance().get(`/sales/${id}/report${queryParams ? `?${queryParams}` : ''}`).then(res => res.data)
    },

    createSale: (data) => {
        return instance().post(`/sales`, data).then(res => res.data)
    },
    activateSale: (id) => {
        return instance().post(`/sales/${id}/activate`).then(res => res.data)
    },

    updateSale: (id, data) => {
        return instance().put(`/sales/${id}`, data).then(res => res.data)
    },
    updateSaleStatus: (id, data) => {
        return instance().put(`/sales/${id}/status`, data).then(res => res.data)
    },

    deleteSale: (id, data) => {
        return instance().delete(`/sales/${id}`, data).then(res => res.data)
    },

    compareAds: (id, queryParams, body) => {
        return instance().post(`/branches/${id}/sales/compare${queryParams ? `?${queryParams}` : ''}`, body).then(res => res.data)
    },
}

export default adsApi;
