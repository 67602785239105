import i18n from '../../../i18n';
import generalTypes from "./generalTypes";
import {PENDING, SUCCESS, ERROR} from "../../globalStatuses/globalStatuses";

const initialState = {
    data: [],
    status: '',
    payment_total: 0,
    cashback_amount: 0,
    average_check: 0,
    transactions: 0,
    type: 4,
    dinamicSalesDateFrom: null,
    // dinamicSalesDateFrom: new Date().setFullYear(new Date().getFullYear() - 1),
    dinamicSalesDateTo: null,
    payment_from_private_balance: 0,
    chart_data: [
        ['', ''],
        [i18n.t('jan'), 0],
        [i18n.t('feb'), 0],
        [i18n.t('march'), 0],
        [i18n.t('apr'), 0],
        [i18n.t('may'), 0],
        [i18n.t('june'), 0],
        [i18n.t('july'), 0],
        [i18n.t('august'), 0],
        [i18n.t('sep'), 0],
        [i18n.t('oct'), 0],
        [i18n.t('nov'), 0],
        [i18n.t('dec'), 0],
    ],
}


const generalReducer = (state = initialState, action) => {
    switch (action.type) {

        case generalTypes.GA_GET_DATA_PENDING:
            return {
                ...state,
                status: PENDING
            }


        case generalTypes.GA_GET_DATA_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
                data: action.payload.data,
                payment_total: action.payload.payment_total,
                cashback_amount: action.payload.cashback_amount,
                average_check: action.payload.average_check,
                transactions: action.payload.transactions,
                chart_data: action.payload.chart_data,
                payment_from_private_balance: action.payload.payment_from_private_balance,
            }


        case generalTypes.GA_GET_DATA_ERROR:
            return {
                ...state,
                status: ERROR
            }


        case generalTypes.GA_CHANGE_DATA_PERIOD:
            return {
                ...state,
                type: action.payload
            }

        case generalTypes.GA_CHANGE_DATE_FROM:
            return {
                ...state,
                dinamicSalesDateFrom: action.payload
            }

        case generalTypes.GA_CHANGE_DATE_TO:
            return {
                ...state,
                dinamicSalesDateTo: action.payload
            }

        default:
            return {
                ...state
            }
    }
}

export default generalReducer
