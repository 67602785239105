import React, { useState, useRef, useEffect } from 'react';
import styles from './timePicker.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { mask } from '../../../helpers';


const timeSvg = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99999 0C3.134 0 0 3.134 0 6.99999C0 10.866 3.134 14 6.99999 14C10.866 14 14 10.866 14 6.99999C13.9959 3.13573 10.8643 0.00413086 6.99999 0ZM6.99999 13C3.68628 13 0.99999 10.3137 0.99999 6.99999C0.99999 3.68628 3.68628 0.99999 6.99999 0.99999C10.3137 0.99999 13 3.68628 13 6.99999C12.9964 10.3122 10.3122 12.9964 6.99999 13Z" fill="url(#paint0_linear)"/>
    <path d="M7 2.5C6.72384 2.5 6.49999 2.72386 6.49999 3.00001V6.50002H3.00001C2.72386 6.50002 2.5 6.72387 2.5 7.00003C2.5 7.27618 2.72386 7.50001 3.00001 7.50001H7C7.27615 7.50001 7.50001 7.27615 7.50001 7V3.00001C7.50001 2.72386 7.27615 2.5 7 2.5Z" fill="url(#paint1_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="6.99999" y1="0" x2="6.99999" y2="14" gradientUnits="userSpaceOnUse">
        <stop stopColor="#73D389"/>
        <stop offset="1" stopColor="#4EB78B"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="5" y1="2.5" x2="5" y2="7.50001" gradientUnits="userSpaceOnUse">
        <stop stopColor="#73D389"/>
        <stop offset="1" stopColor="#4EB78B"/>
      </linearGradient>
    </defs>
  </svg>
);

export default function CustomTimePicker({
  defaultValue = '00:00',
  onChangeTime, lunch = false,
  onDisableLunchStatus
}) {
  const [inTimePicker, setInTimePicker] = useState(false);
  const [date, setDate] = useState(defaultValue);

  useEffect(() => {
    setDate(defaultValue);
  }, [defaultValue])

  const inputRef = useRef(null);

  const hhList = useRef(new Array(24).fill(null));
  const mmList = useRef(new Array(60).fill(null));

  const onFocus = (e) => {
    if (!inTimePicker) {
      setInTimePicker(true);
      inputRef.current.focus();
    }
  }

  const onBlur = (e) => {
    if (inTimePicker) {
      setInTimePicker(false);
      inputRef.current.blur();
    }
  }

  const onTypeTime = (e) => {
    let { value } = e.target;

    if (value.length === 5) {
      let hh = value.substr(0, 2);
      let mm = value.substr(3, 2);
      if (Number(hh) >= 24) hh = '00';
      if (Number(mm) > 59) mm = '00';
      value = hh + mm;
      setDate(mask(value, '##:##'))
      onChangeTime ? onChangeTime(mask(value, '##:##')) : null
      return
    }

    setDate(mask(value, '##:##'));
  }

  const onSelectTime = (type, time) => {
    let hh = date.substr(0, 2);
    let mm = date.substr(3, 2);

    switch (type) {
      case 'hh':
        hh = time;
        break;
      case 'mm':
        mm = time;
        break;
    }

    let value = hh.toString().length ? hh  + mm : '00' + mm;

    setDate(mask(value, '##:##'));
    if (value.length === 4) {
      onChangeTime ? onChangeTime(mask(value, '##:##')) : null
    }
  }

  const onClickWithoutLunch = () => {
    setDate('');
    onChangeTime ? onChangeTime('') : null
    onDisableLunchStatus();
  }

  return (
    <div
      className={`${styles.container} ${inTimePicker ? styles.active : ''}`}
    >
      <div className={styles.input}>
        <input
          type="text"
          onChange={onTypeTime}
          value={date}
          ref={inputRef}
          onFocus={onFocus}
        />
      </div>
      {timeSvg}
      <div
        className={styles.times}
        onMouseLeave={onBlur}
      >
        <div className={styles.top}>
          {
            lunch ? <button onClick={onClickWithoutLunch}>Без перерыва</button> : null
          }
        </div>
        <div className={styles.timesWrapper}>
          <div className={styles.timesInner}>
            <Scrollbars
              style={{ width: 68, height: 160 }}
              renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} />}
            >
              <div className={styles.time}>
                {
                  hhList.current.map((_, i) => (
                    <span key={i} onClick={() => onSelectTime('hh',i < 10 ? '0' + i : i)}>{i < 10 ? '0' + i : i}</span>)
                  )
                }
              </div>
            </Scrollbars>
          </div>
          <div className={styles.time}>
            <Scrollbars
              style={{ width: 68, height: 160 }}
              renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} />}
            >
              <div className={styles.time}>
                {
                  mmList.current.map((_, i) => (
                    <span key={i} onClick={() => onSelectTime('mm', i < 10 ? '0' + i : i)}>{i < 10 ? '0' + i : i}</span>)
                  )
                }
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  )
}