import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { PageLayout } from '../Layout';
import styles from './qr.module.scss';
import Modal from '../../../../portals/Modal';

import {
  FacebookShareButton,
  InstapaperShareButton,
  OKShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";

import facebookImg from '../../assets/icons/Facebook.svg';
import instagramImg from '../../assets/icons/Instagram.svg';
import okImg from '../../assets/icons/ok.svg';
import telegramImg from '../../assets/icons/telegram.svg';
import twitterImg from '../../assets/icons/twitter.svg';
import vkImg from '../../assets/icons/VK.svg';
import whatsappImg from '../../assets/icons/whatsapp.svg';

const shareSvg = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.1905 10C11.4504 10 10.7701 10.2443 10.2331 10.653L6.87789 8.65128C6.97725 8.22226 6.97725 7.77771 6.87789 7.34869L10.2331 5.347C10.7701 5.75566 11.4504 6 12.1905 6C13.9262 6 15.3334 4.65684 15.3334 3C15.3334 1.34316 13.9262 0 12.1905 0C10.4548 0 9.04764 1.34316 9.04764 3C9.04764 3.22369 9.0735 3.44156 9.12215 3.65128L5.76695 5.65297C5.22995 5.24434 4.54969 5 3.80954 5C2.0738 5 0.666687 6.34316 0.666687 8C0.666687 9.65684 2.0738 11 3.80954 11C4.54969 11 5.22995 10.7557 5.76695 10.347L9.12215 12.3487C9.07256 12.5626 9.04757 12.781 9.04764 13C9.04764 14.6568 10.4548 16 12.1905 16C13.9262 16 15.3334 14.6568 15.3334 13C15.3334 11.3432 13.9262 10 12.1905 10Z" />
  </svg>
);

const downloadSvg = (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.75 0H7.25C6.83438 0 6.5 0.334375 6.5 0.75V6H3.75625C3.2 6 2.92188 6.67188 3.31563 7.06563L8.075 11.8219C8.30937 12.0562 8.69375 12.0562 8.92813 11.8219L13.6812 7.06563C14.075 6.67188 13.7969 6 13.2406 6H10.5V0.75C10.5 0.334375 10.1656 0 9.75 0ZM16.5 15.25V11.75C16.5 11.3344 16.1656 11 15.75 11H11.1656L9.63437 12.5312C9.00625 13.1594 7.99375 13.1594 7.36562 12.5312L5.83437 11H1.25C0.834375 11 0.5 11.3344 0.5 11.75V15.25C0.5 15.6656 0.834375 16 1.25 16H15.75C16.1656 16 16.5 15.6656 16.5 15.25ZM12.625 14.5C12.625 14.1562 12.3438 13.875 12 13.875C11.6562 13.875 11.375 14.1562 11.375 14.5C11.375 14.8438 11.6562 15.125 12 15.125C12.3438 15.125 12.625 14.8438 12.625 14.5ZM14 13.875C14.3438 13.875 14.625 14.1562 14.625 14.5C14.625 14.8438 14.3438 15.125 14 15.125C13.6562 15.125 13.375 14.8438 13.375 14.5C13.375 14.1562 13.6562 13.875 14 13.875Z" />
  </svg>
);

export default function Qr() {
  const { t } = useTranslation();
  const [data, setData] = useState({
    url: '',
    shareUrl: '',
  });

  const [modal, setModal] = useState(false)

  const info = useSelector(s => s.branchReducer.info);

  useEffect(() => {
    if (info && info.qr) {
      setData({
        url: info.qr.url,
        shareUrl: info.share_url
      })
    }
  }, [info]);

  const onDownloadQrCode = () => {
    const {url} = data;
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const hrefTag = document.createElement('a'); // create html element anchor
      hrefTag.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
      hrefTag.download = "myQR.png"; // Set the file name.
      hrefTag.style.display = 'none'; // set anchor as hidden
      document.body.appendChild(hrefTag);
      hrefTag.click();
      hrefTag.remove()
    };
    xhr.open('GET', url);
    xhr.send();
  }

  return (
    <PageLayout
      title={t('code')}
      text={t('NSBonus.information')}
    >
      <div className={styles.container}>
        <img src={data.url} className={styles.qr} />
      </div>
      <div className={styles.buttons}>
        <button onClick={() => setModal(true)} className={styles.button}>
          {shareSvg}
          <span>{t('NSQR.qr')}</span>
        </button>
        <button onClick={onDownloadQrCode} className={styles.button}>
          {downloadSvg}
          <span>{t('NSQR.code')}</span>
        </button>
      </div>
      <Modal
        open={modal} size="small"
        onClickClose={() => setModal(false)}>
        <div className={styles.shareLinks}>
          <FacebookShareButton url={data.url}>
            <img src={facebookImg} />
          </FacebookShareButton>
          <InstapaperShareButton url={data.url}>
            <img src={instagramImg} />
          </InstapaperShareButton>
          <OKShareButton url={data.url}>
            <img src={okImg} />
          </OKShareButton>
          <TelegramShareButton url={data.url}>
            <img src={telegramImg} />
          </TelegramShareButton>
          <TwitterShareButton url={data.url}>
            <img src={twitterImg} />
          </TwitterShareButton>
          <VKShareButton  url={data.url}>
            <img src={vkImg} />
          </VKShareButton>
          <WhatsappShareButton url={data.url}>
            <img src={whatsappImg} />
          </WhatsappShareButton>
        </div>
      </Modal>
    </PageLayout>
  )
}
