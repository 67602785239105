import * as Yup from 'yup';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from "../../../store/branchStore/branchActions";

export const useBonusStandard = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const { info, pending } = useSelector(s => s.branchReducer);

    const formik = useFormik({
        initialValues: {
            hasFirstBonus: false,
            basic_bonus: '',
            first_bonus: '',
            // certificate_bonus: '',
        },
        validationSchema: Yup.object({
            basic_bonus: Yup.string().required(t('Form.fillField')),
            first_bonus: Yup.string().when('hasFirstBonus', {
                is: true,
                then: Yup.string().required(t('Form.fillField')),
                otherwise: Yup.string(),
            }),
            // certificate_bonus: Yup.string().required('Пожалуйста заполните поле!!'),
        }),
        onSubmit: (async (values) => {
            const {hasFirstBonus, ...otherValues} = values;
            let data = {};

            _.forIn(otherValues, function(value, key) {
                data[key] = Number(value);
            });

            try {
                if (info) dispatch(branchActions.changeContractBasic(info.id, data))
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setFieldValue('hasFirstBonus', false);

        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            formik.setFieldValue('basic_bonus', info.rule.cashback_percentage);
            // formik.setFieldValue('certificate_bonus', info.rule.certificate_cashback_percentage);

            if (info.configuration.first_cashback && info.configuration.first_cashback.enabled) {
                formik.setFieldValue('first_bonus', info.rule.first_cashback_percentage);
                formik.setFieldValue('hasFirstBonus', true);
            }

            if (!info.configuration.first_cashback || !info.configuration.first_cashback.enabled) {
                formik.setFieldValue('first_bonus', undefined);
                formik.setFieldValue('hasFirstBonus', false);
            }

        }
    }, [info]);

    return {
        formik,
        pending,
        errorModal,
        setErrorModal,
    }
}
