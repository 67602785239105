import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../FormElements/ButtonFilled";
import styles from './foundInfo.module.scss';

const FoundInfo = ({ user, bonus, bonuses, isSending, sendBonuses }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {
          (!!user.first_name || !!user.last_name) && (
            <span>{`${user.first_name} ${user.last_name}`}</span>
          )
        }
      </div>
      <div className={styles.block}>
        <p>{t('NSAssessment.bonuses')}:</p>
        <span>{bonuses}</span>
      </div>
      <div className={styles.block}>
        <p>{t('NSClients.bonusCount')}:</p>
        <span>{bonus || 0}</span>
      </div>
      <ButtonFilled loading={isSending} onClick={sendBonuses} className={styles.btn}>
        {t('confirm')}
      </ButtonFilled>
    </div>
  )
}

export default FoundInfo
