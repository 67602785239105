import React, {useEffect, useState} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from './assets/calendar.svg'
import styles from './CustomDatePicker.module.css'
import { useTranslation } from "react-i18next";

const ExampleCustomTimeInput = ({date, value, onChange}) => (
    <input
        value={value}
        onChange={e => onChange(e.target.value)}
    />
)


export default function CustomDatePicker({type, trans, tEndDate = new Date(), tSetEndDate, tSetStartDate, tStartDate = new Date(),changePageAction, icon = true}) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(tStartDate);
    const [endDate, setEndDate] = useState(tEndDate);

    useEffect(() => {
        if (type === 4) {
            setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
        } else if (type === 3) {
            setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)))
        } else if (type === 2) {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - 7)))
        } else if (type === 1) {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)))
        }
    }, [type])

    if (!trans) {
        return (
            <div className={styles.container}>
                {icon && <img src={calendarIcon}/>}
                <DatePicker
                    disabled
                    dateFormat="dd.MM.yyyy"
                    selected={startDate}
                    // customTimeInput={<ExampleCustomTimeInput/>}
                    calendarClassName={styles.calendar}
                    is24Hour={true}
                    timeInputLabel={t("time") + ": "}
                    showTimeInput
                />
                <span>-</span>
                <DatePicker
                    disabled
                    dateFormat="dd.MM.yyyy"
                    selected={endDate}
                    // customTimeInput={<ExampleCustomTimeInput/>}
                    calendarClassName={styles.calendar}
                    is24Hour={true}
                    timeInputLabel={t("time") + ": "}
                    showTimeInput
                />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <img src={calendarIcon}/>
            <DatePicker
                selected={tStartDate}
                onChange={date => {
                    if (changePageAction) {
                      changePageAction(1)
                    }
                    tSetStartDate(date)}
                }
                dateFormat="dd.MM.yyyy HH:mm"
                placeholderText="        -- -- --"
                // customTimeInput={<ExampleCustomTimeInput/>}
                calendarClassName={styles.calendar}
                is24Hour={true}
                timeInputLabel={t("time") + ": "}
                showTimeInput
            />
            <span>—</span>
            <DatePicker
                selected={tEndDate}
                onChange={date => {
                    if (changePageAction) {
                      changePageAction(1)
                    }
                    tSetEndDate(date)
                }}
                placeholderText="-- -- --           "
                dateFormat="dd.MM.yyyy HH:mm"
                // customTimeInput={<ExampleCustomTimeInput/>}
                className={styles.calendar}
                is24Hour={true}
                timeInputLabel={t("time") + ": "}
                showTimeInput
            />
        </div>
    )
}

