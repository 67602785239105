import React, {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from "react-redux";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Modal from "../../../../portals/Modal";
import CustomPhotoDropzone from "../../../../components/CustomPhotoDropzone";
import { convertDateToLongString } from "../../../../tools/convertDateToString";
import branchActions from "../../../../store/branchStore/branchActions";
import PageHeader from "../../../../components/PageHeader";
import styles from './profileBonusPhotos.module.scss';
import { useTranslation } from "react-i18next";
import logoImg from '../../../../assets/lg.png';

export default function ProfileBonusPhotos() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { info, pending } = useSelector((s) => s.branchReducer);
    const [errorModal, setErrorModal] = useState(false);
    const [file, setFile] = useState(null);
    const [active, setActive] = useState(false);

    const onAddBanner = useCallback((img) => {
        const image = new FormData();
        image.append('banner', img);
        if (info && active) dispatch(branchActions.changeBrandedBonusCardBanner(info.id, image));
    }, [info, active, i18n.language]);

    useEffect(() => {
        setActive(false);

        if (info &&
            (
                !info.configuration.private_cashback ||
                !info.configuration.private_cashback.banner ||
                !info.configuration.private_cashback.banner.enabled
            )
        ) {
            return setErrorModal(true);
        }

        if (
            info &&
            (
                info.configuration.private_cashback &&
                info.configuration.private_cashback.banner &&
                info.configuration.private_cashback.banner.enabled
            )
        ) {
            setFile(info.configuration.private_cashback.banner.file);
            setActive(true);
        }
    }, [info]);

    return (
        <>
            <PageHeader title={t('bonuses')}>
                <p>{t('NSBonus.information')}</p>
            </PageHeader>
            <div className={styles.container}>
                {(!!info) && (
                    <div
                        className={styles.card}
                        style={{ backgroundImage: `url(${file ? file.url : ''})` }}
                    >
                        <div className={styles.cardHeader}>
                            {<img src={(info && info.icon) || logoImg} />}
                            <div className={styles.cardTitle}>
                                <span>{info && info.name}</span>
                            </div>
                        </div>
                        <div className={styles.cardBody}>
                            <b>820</b>
                            <span>{convertDateToLongString(new Date(new Date(). getTime() + (24 * 60 * 60 * 1000)))}</span>
                        </div>
                        <div className={styles.cardFooter}>
                            <span>{t('bonuses')}: {info && info.rule.cashback_percentage || '0'}%</span>
                        </div>
                    </div>
                )}
                {active && (
                    <div style={{ width: '80%', marginTop: '20px' }}>
                        <CustomPhotoDropzone
                            onAddBanner={onAddBanner}
                            pending={pending}
                            classnames={styles.dropzone}
                        />
                    </div>
                )}
            </div>
            <Modal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
