import React from 'react';
import styles from './loader.module.scss';

const Loader = ({className = '', style = {}, color = '#fff'}) => {
  return (
    <div className={className.trim()} style={style}>
      <div className={styles['lds-ring']}>
        <div style={{ borderColor: `${color} transparent transparent transparent` }}></div>
        <div style={{ borderColor: `${color} transparent transparent transparent` }}></div>
        <div style={{ borderColor: `${color} transparent transparent transparent` }}></div>
        <div style={{ borderColor: `${color} transparent transparent transparent` }}></div>
      </div>
    </div>
  );
};

export default Loader;
