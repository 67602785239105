import React, { useEffect, useState } from 'react';
import { FilterBranch, FilterCity } from './Filter';
import {useDispatch, useSelector} from "react-redux";
import styles from './Invis.css';
import ButtonFilled from "../FormElements/ButtonFilled";
import filtersActions from "../../store/filtersStore/filtersActions";

const Invis = (props) => {
  const {
    loading = false,
    isVisibleProp = false,
    disabled = false,
    changeCb,
  } = props
  const [isVisible, setIsVisible] = useState(isVisibleProp);
  const { cities, citiesPending } = useSelector((state) => state.directoryReducer)
  const { info, pending } = useSelector((state) => state.profileReducer)
  const invisParams = useSelector((state) => state.filtersReducer)
  const dispatch = useDispatch()

  const setInvisParams = (payload) => {
    dispatch(filtersActions.changeFilters(payload))
    changeCb && changeCb(payload)
  }

  useEffect(() => {
    if (invisParams.city_ids.length || invisParams.branch_ids.length) {
      setIsVisible(true)
    }
  }, [invisParams]);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <div className='bonusButton'>

      {isVisibleProp ? null : (
        <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          <button onClick={toggleVisibility}>Фильтр</button>
          {invisParams.branch_ids?.length || invisParams?.city_ids?.length ? (
              <ButtonFilled
                  onClick={() => setInvisParams({ branch_ids: [], city_ids: [] })}
              >
                Сбросить
              </ButtonFilled>
          ) : null}
        </div>
      )}

      {isVisible && (
        <div className="content">
          <div style={{padding: '10px 0px 10px 0px'}}>
            <FilterCity
              options={cities.map((city) => ({ value: city.id , label: city.name }))}
              loading={citiesPending}
              values={invisParams.city_ids}
              onChange={(ids) => setInvisParams({...invisParams, city_ids: ids})}
              disabled={disabled || pending || loading}
            />
          </div>

          <div>
          <FilterBranch
            options={info.branches.map((branch) => ({ value: branch.id , label: branch.name }))}
            loading={pending}
            values={invisParams.branch_ids}
            onChange={(ids) => setInvisParams({...invisParams, branch_ids: ids})}
            disabled={disabled || pending || loading}
          />
          </div>

        </div>
      )}
    </div>
  );
};

export default Invis;

