import React from 'react'
import styles from './ChartBarComponent.module.css'
import {Chart} from "react-google-charts";
import Loader from "../../Loader";


export default function ChartBarComponent({width, data, percents}) {
    return (
        percents ? <Chart
            chartType="ColumnChart"
            loader={<div style={{width: `${width}vw`, height: 350}}/>}
            data={data}
            options={{
                width: 400,
                height: 350,
                colors: ['#73D389'],
                legend: {position: 'none'},
                vAxis: {
                    maxValue: 100,
                    format: '0',
                    textStyle: {
                        fontSize: 15,
                        bold: true
                    }
                },
                hAxis: {
                    textStyle: {
                        fontSize: 13,
                        color: '#576478'
                    }
                },

                tooltip: {
                    textStyle: {
                        color: '#448352',
                        fontSize: 15,
                    }
                },

                chartArea: {'width': '85%', 'height': '90%'},
            }}
        /> : <Chart
            chartType="Bar"
            loader={<div style={{width: `${width}vw`, height: 350}}/>}
            data={data}
            options={{
                width: `${width}vw`,
                height: 350,
                colors: ['#73D389'],
                legend: {position: 'none'},
                hAxis: {
                    title: 'Total Population',
                    minValue: 0,
                },
            }}
        />
    )
}