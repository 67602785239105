import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Modal from "../../../../portals/Modal";
import TextField from "../../../../components/FormElements/TextField";
import ButtonOutlined from "../../../../components/FormElements/ButtonOutlined";
import {useFormik} from "formik";
import * as Yup from "yup";
import { store } from "react-notifications-component";
import instance from "../../../../api";
import PageHeader from "../../../../components/PageHeader";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import styles from './profileBonusTransactionGradiation.module.scss';


export default function ProfileBonusTransactionGradation() {
    const { t } = useTranslation();
    const { info }  = useSelector(s => s.branchReducer);
    const [errorModal, setErrorModal] = useState(false);
    const [cashbacks, setCashbacks] = useState([]);
    const [deleting, setDeleting] = useState(false);

    const formik = useFormik({
        initialValues: {
            new_cashback: '',
            amount: '',
            title: ''
        },
        validationSchema: Yup.object({
            new_cashback: Yup.string().required(t('Form.fillField')),
            amount: Yup.string().required(t('Form.fillField')),
        }),
        onSubmit: (async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true);

            try {
                const res = await instance().post(`/branches/${info.id}/gradation/transaction/cashback`, {
                    amount: Number(values.amount),
                    new_cashback: Number(values.new_cashback),
                    title: values.title
                }).then(res => res.data);

                setCashbacks(res.data);
                resetForm();
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            } finally {
                setSubmitting(false);
            }
        })
    });

    useEffect(() => {
        if (info && info.rule.transaction_gradation_cashbacks) {
            setCashbacks(info.rule.transaction_gradation_cashbacks);
        }
        if (info && (!info.rule.transaction_gradation_cashbacks || !info.rule.transaction_gradation_cashbacks.length)) {
            setCashbacks([]);
        }
    }, [info]);

    const onDeleteCashback = (c) => async (e) => {
        try {
            setDeleting(true);
            await instance().delete(`/branches/${info.id}/gradation/transaction/${c.id}`);
            setCashbacks((prev) => prev.filter(p => p.id !== c.id));
        } catch (e) {
            const res = e.response ? e.response.data : null;

            store.addNotification({
                title: `Error code: ${res ? res.code : 'NULL'}`,
                message: `${res ? res.message : t('Notify.notRespond')}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        } finally {
            setDeleting(false);
        }
    }

    return (
        <>
            <PageHeader title={t('bonuses')}>
                <div style={{ marginLeft: '50px' }}>
                    <QuestionTooltip text={t('NSTransactionGradation.info')} />
                </div>
            </PageHeader>
            <div className={styles.container}>
                <form className={styles.form} onSubmit={formik.handleSubmit}>
                    <div className={styles.block}>
                        <TextField
                            label={t('NSTransactionGradation.new')}
                            placeholder="8%"
                            disabled={formik.isSubmitting}
                            width="160px"

                            value={formik.values.new_cashback}
                            error={formik.touched.new_cashback && formik.errors.new_cashback}
                            onChange={(e) => isFinite(e.target.value) && +e.target.value <= 100 && formik.setFieldValue('new_cashback', e.target.value)}
                        />
                        <span className={styles['block__right-text']}>{t('NSTransactionGradation.total')}</span>
                        <p className={styles['block__text']}>{t('NSTransactionGradation.amount')}</p>
                    </div>
                    <div className={styles.block}>
                        <TextField
                            label={t('sum')}
                            placeholder="10 000"
                            disabled={formik.isSubmitting}
                            width="160px"

                            value={formik.values.amount}
                            error={formik.touched.amount && formik.errors.amount}
                            onChange={(e) => isFinite(e.target.value) && formik.setFieldValue('amount', e.target.value)}
                        />
                        <p className={styles['block__text']}>{t('NSTransactionGradation.accumulation')}</p>
                    </div>
                    <div className={styles.block}>
                        <TextField
                            label={t('title')}
                            placeholder="Gold"
                            disabled={formik.isSubmitting}
                            width="250px"

                            value={formik.values.title}
                            onChange={(e) => formik.setFieldValue('title', e.target.value)}
                        />
                        <p className={styles['block__text']}>{t('NSTransactionGradation.accumulation')}</p>
                    </div>
                    <ButtonOutlined variant="green" color="black" type="submit" disabled={formik.isSubmitting}>{t('add')}</ButtonOutlined>
                </form>
                <table className="table">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>{t('NSTransactionGradation.title')}</th>
                            <th>{t('NSTransactionGradation.bonus')}</th>
                            <th>{t('NSTransactionGradation.purchases')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {!!cashbacks.length ? cashbacks.map((c, i) => (
                        <tr key={c.id}>
                            <td>{i + 1}</td>
                            <td>{c.title}</td>
                            <td>{c.extra_cashback}</td>
                            <td>{c.amount}</td>
                            <td>
                                <button style={{ marginLeft: 'auto', marginRight: '16px' }} className="btn-trash" type="button" onClick={onDeleteCashback(c, i)} disabled={deleting}>
                                    <i>
                                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.4219 1.875H8.84375V1.40625C8.84375 0.63085 8.2129 0 7.4375 0H5.5625C4.7871 0 4.15625 0.63085 4.15625 1.40625V1.875H1.57812C0.931953 1.875 0.40625 2.4007 0.40625 3.04688V4.6875C0.40625 4.94637 0.616133 5.15625 0.875 5.15625H1.13117L1.53614 13.6606C1.57191 14.4117 2.18891 15 2.94078 15H10.0592C10.8111 15 11.4281 14.4117 11.4639 13.6606L11.8688 5.15625H12.125C12.3839 5.15625 12.5938 4.94637 12.5938 4.6875V3.04688C12.5938 2.4007 12.068 1.875 11.4219 1.875ZM5.09375 1.40625C5.09375 1.14779 5.30404 0.9375 5.5625 0.9375H7.4375C7.69596 0.9375 7.90625 1.14779 7.90625 1.40625V1.875H5.09375V1.40625ZM1.34375 3.04688C1.34375 2.91765 1.4489 2.8125 1.57812 2.8125H11.4219C11.5511 2.8125 11.6562 2.91765 11.6562 3.04688V4.21875C11.5118 4.21875 1.94237 4.21875 1.34375 4.21875V3.04688ZM10.5274 13.616C10.5155 13.8664 10.3098 14.0625 10.0592 14.0625H2.94078C2.69015 14.0625 2.48448 13.8664 2.47259 13.616L2.06973 5.15625H10.9303L10.5274 13.616Z" fill="white"/>
                                            <path d="M6.5 13.125C6.75887 13.125 6.96875 12.9151 6.96875 12.6562V6.5625C6.96875 6.30363 6.75887 6.09375 6.5 6.09375C6.24113 6.09375 6.03125 6.30363 6.03125 6.5625V12.6562C6.03125 12.9151 6.2411 13.125 6.5 13.125Z" fill="white"/>
                                            <path d="M8.84375 13.125C9.10262 13.125 9.3125 12.9151 9.3125 12.6562V6.5625C9.3125 6.30363 9.10262 6.09375 8.84375 6.09375C8.58488 6.09375 8.375 6.30363 8.375 6.5625V12.6562C8.375 12.9151 8.58485 13.125 8.84375 13.125Z" fill="white"/>
                                            <path d="M4.15625 13.125C4.41512 13.125 4.625 12.9151 4.625 12.6562V6.5625C4.625 6.30363 4.41512 6.09375 4.15625 6.09375C3.89738 6.09375 3.6875 6.30363 3.6875 6.5625V12.6562C3.6875 12.9151 3.89735 13.125 4.15625 13.125Z" fill="white"/>
                                        </svg>
                                    </i>
                                    <span>{t('delete')}</span>
                                </button>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td></td>
                        </tr>
                    )}

                    </tbody>
                </table>
            </div>

            <Modal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
