import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';
import { convertDate } from '../../../tools/convertDateToString';

export const usePrizes = (t) => {
  const dispatch = useDispatch();
  const branch = useSelector(s => s.branchReducer);
  const [isAwardMessagesPushing, setIsAwardMessagesPushing] = useState(false);

  useEffect(() => {
    if (branch.info) {
      if (branch.info?.configuration?.whatsapp_provider?.prohibitions) {
        setIsAwardMessagesPushing(branch.info?.configuration?.whatsapp_provider?.prohibitions?.award_messages || false);
      }
    }
  }, [branch.info]);

  useEffect(() => {
    if (branch.info && !branch.pending) {
      const { info: { configuration: { award } } } = branch;
      formik.setFieldValue('deadline', false);

      if (award) {
        formik.setFieldValue('enabled', award.enabled);

        formik.setFieldValue('title', award.title);
        formik.setFieldValue('description', award.description);
        formik.setFieldValue('stamp_count', award.stamp_count);
        formik.setFieldValue('from', award.activated_at ? new Date(award.activated_at) : new Date());
        formik.setFieldValue('to', award.expired_at ? new Date(award.expired_at) : new Date());

        if (award.expired_at == 0) {
          formik.setFieldValue('deadline', true);
        }
      }
    }
  }, [branch]);

  const formik = useFormik({
    initialValues: {
      enabled: false,

      deadline: false,
      title: '',
      description: '',
      stamp_count: 0,
      from: new Date(),
      to: new Date(),
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(t('Form.enterBuyCount')),
      description: Yup.string(),
      stamp_count: Yup.number()
        .required(t('Form.enterBuyCount'))
        .min(1, t('Form.minCount', {min: 1})),
      deadline: Yup.boolean()
    }),
    onSubmit: (async (values, { setSubmitting }) => {
      let { from, to, enabled, stamp_count, deadline, ...otherProps } = values;

      const data = {
        ...otherProps,
        enabled: true,
        stamp_count: Number(stamp_count),
      }

      from = convertDate(from).split('.').join('-');
      to = convertDate(to).split('.').join('-');

      if (!deadline) {
        data.from = from;
        data.to = to;
      }

      try {
        dispatch(branchActions.activatedAwardConfig(branch.info.id, data));
      } catch (e) {
        const res = e.response ? e.response.data : null;

        store.addNotification({
          title: `Error code: ${res ? res.code : 'NULL'}`,
          message: `${res ? res.message : t('Notify.notRespond')}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      }
    })
  });

  const onDeactivateAward = () => {
    dispatch(branchActions.deActivatedAwardConfig(branch.info.id));
  }

  const changeWhatsappPush = (value) => {
    dispatch(branchActions.changeWhatsappProhibitions(branch.info.id, { enabled: value, type: 'award_messages' }));
  }

  return {
    formik,
    onDeactivateAward,
    isAwardMessagesPushing,
    setIsAwardMessagesPushing,
    changeWhatsappPush,
    info: branch.info,
    pending: branch.pending,
  }
}