import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DG from '2gis-maps';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import styles from './map.module.scss';

export default function Map({ lat, lng, onChooseLocation }) {
  const mapRef = useRef(null);
  const [location, setLocation] = useState({lat: 0, lng: 0});
  const { t } = useTranslation();

  const onClickChoosenLocation = () => {
    onChooseLocation(location)
  }

  useEffect(() => {
    if (!mapRef.current) return

    setLocation({ lat, lng });

    DG.then(function () {
      const mapLat =  lat ? lat : 51.16;
      const mapLng = lng ? lng : 71.44;

      const map = DG.map(mapRef.current, {
        center: [mapLat, mapLng],
        zoom: 15
      });
      const marker = DG.marker({ lat: mapLat, lng: mapLng }, { draggable: true }).addTo(map);

      map.on('locationfound', (e) => {
        setLocation({
          lat: e.target._latlng.lat.toFixed(3),
          lng: e.target._latlng.lng.toFixed(3),
        });
      })

      marker.on('dragend', function(e) {
        setLocation({
          lat: e.target._latlng.lat.toFixed(3),
          lng: e.target._latlng.lng.toFixed(3),
        });
      });
    });

    return () => {
      mapRef.current = null;
    }
  }, [lat, lng])

  return (
    <div className={styles.container}>
      <div ref={mapRef} style={{ width: '100%', height: '450px' }}></div>
      <ButtonFilled
        className={styles.mapBtn}
        onClick={onClickChoosenLocation}
      >{t('chooseLoc')}</ButtonFilled>
    </div>
  )
}