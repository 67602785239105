import React from 'react';
import { useTranslation } from "react-i18next";
import { PageLayout } from '../Layout';
import TextField from '../../../../components/FormElements/TextField';
import styles from './socials.module.scss';

import websiteImg from '../../assets/images/web.png';
import instagramImg from '../../assets/images/Instagram.png';
import facebookImg from '../../assets/images/Facebook.png';
import vkImg from '../../assets/images/VK.png';
import tiktokImg from '../../assets/images/Tiktok.png';
import youtubeImg from '../../assets/images/Youtube.png';
import twoGisImg from '../../assets/images/2gis.png';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import { useSocials } from '../../hooks/useSocials';

export default function Socials() {
  const { t } = useTranslation();
  const { formik, pending } = useSocials(t);

  return (
    <PageLayout
      title={t('networks')}
      text={t('NSBonus.information')}
    >
      <div className={styles.container}>
        <label className={styles.label}>{t('NSWebsite.website')}</label>
        <div className={styles.inputWrapper}>
          <img src={websiteImg} />
          <div className={styles.input}>
            <TextField
              placeholder="https://"

              value={formik.values.website}
              onChange={(e) => formik.setFieldValue('website', e.target.value)}
            />
          </div>
        </div>
        <div style={{ height: '22px' }} />
        <label className={styles.label}>{t('NSWebsite.social')}</label>
        <div className={styles.inputWrapper}>
          <img src={instagramImg} />
          <div className={styles.input}>
            <TextField
              placeholder="https://"

              value={formik.values.instagram}
              onChange={(e) => formik.setFieldValue('instagram', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <img src={facebookImg} />
          <div className={styles.input}>
            <TextField
              placeholder="https://"

              value={formik.values.facebook}
              onChange={(e) => formik.setFieldValue('facebook', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <img src={vkImg} />
          <div className={styles.input}>
            <TextField
              placeholder="https://"

              value={formik.values.vk}
              onChange={(e) => formik.setFieldValue('vk', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <img src={tiktokImg} />
          <div className={styles.input}>
            <TextField
              placeholder="https://"

              value={formik.values.tiktok}
              onChange={(e) => formik.setFieldValue('tiktok', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <img src={youtubeImg} />
          <div className={styles.input}>
            <TextField
              placeholder="https://"

              value={formik.values.youtube}
              onChange={(e) => formik.setFieldValue('youtube', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <img src={twoGisImg} />
          <div className={styles.input}>
            <TextField
              placeholder="https://"

              value={formik.values.twoGis}
              onChange={(e) => formik.setFieldValue('twoGis', e.target.value)}
            />
          </div>
        </div>
        <div className={styles.divider}></div>
      </div>
      <ButtonFilled
        onClick={formik.handleSubmit}
        loading={pending}
      >{t('save')}</ButtonFilled>
    </PageLayout>
  )
}