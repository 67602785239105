import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { PENDING } from '../../../store/globalStatuses/globalStatuses';
import Loader from '../../Loader';
import MaskService from 'react-masked-text/src/mask-service';
import transactionsActions from '../../../store/transactionsStore/transactionsActions';
import { mask } from 'helpers';

import styles from './findCustomer.module.scss';
import { PhoneInputField } from "../../PhoneInput";
import { ref } from "yup";

export default function FindCustomer({ setStep, setNotRegistered, setPhone, type, setType }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {cStatus} = useSelector(state => state.clientInfoReducer);
  const {info} = useSelector(state => state.branchReducer);
  const refPhone = useRef()

  const [error, setError] = useState(false);
  const [value, setValue] = useState('');

  const findCustomer = (e) => {
    e.preventDefault();

    let v = value;
    if (type === 'phone') {
      v = v.replace(/\D/g, '');
    }

    new Promise((resolve, reject) => {
      dispatch(transactionsActions.getUserInfo(info.id, info.group_id, type, v, resolve, reject))
    })
      .then(() => {
        setStep(2);
      })
      .catch((e) => {
        if (type === 'code') return setError(true);
        if (e.code === 100) {
          setNotRegistered(true);
          setPhone(v);
        }

        setStep(2);
      })
  }

  const onPhoneChange = (value) => {
    setError(false);
    setValue(value);
  }

  const onTypeChange = () => {
    setValue('');

    switch (type) {
      case 'phone':
        setType('code');
        break;
      case 'code':
        setType('phone');
        break;
    }
  }

  const onCodeChange = (e) => {
    if (error) setError(false);
    setValue(mask(e.target.value, '### ####', 7));
  }

  useEffect(() => {
    if (refPhone.current) {
      const clickEvent = new MouseEvent("focus", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });
      const input = refPhone.current.querySelector('input');
      input.focus()
      input.dispatchEvent(clickEvent)
    }
  }, [refPhone]);

  let disabled = false;

  if (type === 'code' && value.length < 7) disabled = true
  if (type === 'phone' && value.length < 7) disabled = true

  return (
    <form onSubmit={cStatus !== PENDING ? findCustomer : null} className={styles.container}>
      <p>{t('NSPayModal.enter')}</p>
      <div className={styles.secondInner}>
        {type === 'phone' && <PhoneInputField
          value={value}
          disabled={cStatus === PENDING}
          ref={refPhone}
          onChange={onPhoneChange}
          label={t('NSAuth.phone')}
        />}
      </div>
      <div className={styles.inner}>
        {type === 'code' && <input
          autoFocus
          maxLength={7}
          onChange={onCodeChange}
          value={value}
          type="text"
          disabled={cStatus === PENDING}
          placeholder={t('NSPayModal.enter6')}
        />}
        <button type="button" onClick={onTypeChange} className={styles.switcher}>
          {type === 'phone' && t('NSPayModal.enter6')}
          {type === 'code' && t('NSPayModal.enterPhone')}
        </button>
        {error && <span className={styles.span}>{t('NSPayModal.notValid6')}</span>}
      </div>
      <button
        type="submit"
        className={styles.btn}
        disabled={cStatus === PENDING || disabled}
        style={cStatus === PENDING ? {background: 'gray'} : {}}>
        {cStatus === PENDING ? <Loader/> : t('proceed')}
      </button>
    </form>
  )
}