import {PENDING, SUCCESS, ERROR} from "../globalStatuses/globalStatuses";
import clientsTypes from "./clientsTypes";
import queryString from "querystring";

const queryParams = queryString.parse(location.search.slice(1))

const initialState = {
    clients: [],
    chosenClients: [],
    count: 0,
    status: '',
    params: {
        ['customer_filter_type']: queryParams['customer_filter_type'] || null,
        page: queryParams.page || 1,
        skip: queryParams.skip || 0,
        limit: 10,
        from: queryParams.from || null,
        to: queryParams.to || null,
        branch_ids: [],
        city_ids: [],
        interval: null,
    }
}

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {

        case clientsTypes.CLEAR_CLIENTS:
            return {
                ...state,
                clients: [],
                chosenClients: [],
                count: 0,
            }

        case clientsTypes.GET_CLIENTS_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case clientsTypes.GET_CLIENTS_SUCCESS:
            const transformClients = action.payload.customers.map(c => {
                c.checked = false;
                return c;
            });
            const detectedCheckedClients = transformClients.map(t => {
                const client = t;
                state.chosenClients.forEach((c) => t.id == c ? t.checked = true : null);
                return client;
            });

            return {
                ...state,
                clients: detectedCheckedClients,
                count: action.payload.count,
                status: SUCCESS
            }

        case clientsTypes.GET_CLIENTS_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case clientsTypes.CHANGE_PARAMS:
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                }
            }

        case clientsTypes.CHANGE_CHOSEN_CLIENTS:
            const tempClients = state.clients.map(c => {
                if (c.id == action.payload.id) c.checked = action.payload.checked
                return c;
            });

            switch (action.payload.checked) {
                case true:
                    return {
                        ...state,
                        clients: tempClients,
                        chosenClients: [...state.chosenClients, action.payload.id]
                    }
                case false:
                    return {
                        ...state,
                        clients: tempClients,
                        chosenClients: state.chosenClients.filter(id => id !== action.payload.id)
                    }
            }
            return state;

        case clientsTypes.CLEAR_CHOSEN_CLIENTS:
            return {
                ...state,
                chosenClients: [],
                clients: state.clients.map(c => {
                    c.checked = false
                    return c;
                }),
            }

        default:
            return {
                ...state
            }
    }
}

export default clientsReducer
