import React, { useEffect, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import bonusesModalActions from 'store/bonusesModalStore/bonusesModalActions';
import { store } from 'react-notifications-component';
import FindCustomer from './FindCustomer';
import FillForm from './FillForm';
import ConfirmForm from './ConfirmForm';
import { hasResponseErrorMessage, paymentType } from '../../helpers';

import styles from './pay.modal.module.scss';

import closeModalImage from './assets/CloseModalIcon.svg';
import clientInfoActions from '../../store/clientInfoStore/clientInfoActions';
import GiveAward from './GiveAward';
import { useTranslation } from "react-i18next";
import TransactionsTypes from "../../store/transactionsStore/transactionsTypes";

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    borderRadius: 24,
  }
};

export default function PayModal(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { info } = useSelector(state => state.branchReducer);
  const { isSendUseCashbackCodePending, isSendedUseCashbackCode } = useSelector(state => state.transactionsReducer);
  const { customer, cStatus } = useSelector(state => state.clientInfoReducer);
  const {
    amount_sum,
    stamp_count,
    bonuses,
    client_cashback,
    payment_status,
    give_award_status,
    comment,
    add_stamp,
    give_award
  } = useSelector(state => state.bonusesModalReducer);

  const {
    isOpen,
    hasClientInfo = false,
    setModal
  } = props;

  const [step, setStep] = useState(hasClientInfo ? 2 : 1);
  const [phone, setPhone] = useState('');
  const [payment, setPayment] = useState(paymentType.Cash);
  const [notRegistered, setNotRegistered] = useState(false);
  const [isStandardBonus, setIsStandardBonus] = useState(true);
  const [whatsAppCode, setWhatsAppCode] = useState("");
  const [type, setType] = useState('phone');

  useEffect(() => {
    if (bonuses <= 0) {
      dispatch({ type: TransactionsTypes.CLEAR_USE_CASHBACK_CODE_PENDING });
    }
    return () => {
      dispatch({ type: TransactionsTypes.CLEAR_USE_CASHBACK_CODE_PENDING });
    }
  }, [bonuses]);

  const onModalClose = () => {
    if (step === 4) {
      return setStep(2);
    }
    if (!hasClientInfo) {
      dispatch(clientInfoActions.resetClientInfo());
    }

    dispatch(bonusesModalActions.resetState());
    setStep(hasClientInfo ? 2 : 1);
    setWhatsAppCode("");
    setIsStandardBonus(true);
    setNotRegistered(false);
    setPhone('');
    setModal(false);
  }

  const changeAmountSum = (e) => {
    if (isNaN(Number(e.target.value))) return
    dispatch(bonusesModalActions.changeAmountSum(e.target.value))
  }

  const changeStampCount = (e) => {
    let rsc = customer.received_stamp_count;
    if (!rsc) {
      rsc = 0;
    }
    if (isNaN(Number(e.target.value))) return
    if (Number(e.target.value) <= (info.configuration.award.stamp_count - rsc)) {
      dispatch(bonusesModalActions.changeStampCount(e.target.value))
    }
  }

  const changeBonuses = (e) => {
    if (e.target) {
      if (isNaN(Number(e.target.value))) return
      return dispatch(bonusesModalActions.changeBonuses(e.target.value))
    }

    dispatch(bonusesModalActions.changeBonuses(e));
  }

  const changeComment = (e) => {
    dispatch(bonusesModalActions.changeComment(e.target.value));
  }

  const changeStamp = (value) => {
    dispatch(bonusesModalActions.changeStamp(value));
  }

  const changeAward = (value) => {
    dispatch(bonusesModalActions.changeAward(value));
  }

  const getClientCashback = () => {
    notRegistered ?
      dispatch(bonusesModalActions.getClientCashBack(info.id, 0)) :
        customer?.id ? dispatch(bonusesModalActions.getClientCashBack(info.id, customer.id)) : null
  }

  const getClientAward = () => {
    dispatch(clientInfoActions.getClientAward(customer.id, info));
  }

  const giveAwardToUser = () => {
    if ((stamp_count + customer.received_stamp_count) > info.configuration.award.stamp_count) {
      return store.addNotification({
        title: t('error'),
        message: t('NSPayModal.limit', {stamps: stamp_count + customer.received_stamp_count, limit: info.configuration.award.stamp_count}),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
    const data = {
      user_id: customer.id,
      stamp_count: customer.stamp_count
    }

    new Promise((resolve, reject) => {
      dispatch(bonusesModalActions.giveAwardToUser(info.id, customer.award_id, data, resolve, reject));
    })
      .then(() => {
        onModalClose();
      })
      .catch(e => {
        if (hasResponseErrorMessage(e)) {
          store.addNotification({
            title: t('error'),
            message: e.response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
        onModalClose();
      })

  }

  const confirmPayment = () => {
    let params = {}

    if (notRegistered) {
      params = {
        user_phone: '+' + phone,
        amount: parseInt(amount_sum),
        stamp_count: parseInt(stamp_count),
        comment,
        add_stamp: !!(stamp_count),
        give_award,
        use_increased_cashback: isStandardBonus ? false : true,
      }
    } else {
      params = {
        user_id: customer.id,
        amount: parseInt(amount_sum),
        bonus_amount: parseInt(bonuses),
        stamp_count: parseInt(stamp_count),
        comment,
        add_stamp: !!(stamp_count),
        give_award,
        use_increased_cashback: isStandardBonus ? false : true,
      }
    }

    if (whatsAppCode && isSendedUseCashbackCode) {
      params.use_balance_code = whatsAppCode;
    }

    new Promise((resolve, reject) => {
      dispatch(bonusesModalActions.confirmPayment(info.id, params, bonuses, payment, resolve, reject))
    })
      .then(() => {
        setWhatsAppCode("");
        onModalClose();
      })
      .catch(e => {
        if (e.response.data.message === 'not supported number') {
          store.addNotification({
            title: t('error'),
            message: t('Form.correctNumber'),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });

          setNotRegistered(false);
          return setStep(1);
        }
        if (hasResponseErrorMessage(e)) {
          store.addNotification({
            title: t('error'),
            message: e.response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        }
      })
      .finally(() => {
      })
  }

  const cashback = useMemo(() => {
    return client_cashback ? client_cashback : 0;
  }, [client_cashback]);

  const increasedCashback = useMemo(() => {
    if (info && info.rule) {
      if (info.rule.increased_cashback.enabled && info.rule.increased_cashback.always_on) {
        return info.rule.increased_cashback.percentage;
      }
    }
    return null;
  }, [info]);

  const renderForm = () => {
    switch (step) {
      case 1:
        return <FindCustomer
          setStep={setStep}
          setNotRegistered={setNotRegistered}
          setPhone={setPhone}
          type={type}
          setType={setType}
        />
      case 2:
        return (
          <FillForm
            cStatus={cStatus}
            setStep={setStep}
            phone={phone}
            customer={customer}
            isSendUseCashbackCodePending={isSendUseCashbackCodePending}
            isSendedUseCashbackCode={isSendedUseCashbackCode}
            amount_sum={amount_sum}
            bonuses={bonuses}
            notRegistered={notRegistered}
            comment={comment}
            payment={payment}
            setPayment={setPayment}
            add_stamp={add_stamp}
            give_award={give_award}
            info={info}
            type={type}
            getClientAward={getClientAward}
            changeStamp={changeStamp}
            changeAward={changeAward}
            changeComment={changeComment}
            changeAmountSum={changeAmountSum}
            changeBonuses={changeBonuses}
            getClientCashback={getClientCashback}
            changeStampCount={changeStampCount}
            stamp_count={stamp_count}
          />
        )
      case 3:
        return (
          <ConfirmForm
            client_cashback={cashback}
            increasedCashback={increasedCashback}
            setStep={setStep}
            bonuses={bonuses}
            payment_status={payment_status}
            amount_sum={amount_sum}
            notRegistered={notRegistered}
            isStandardBonus={isStandardBonus}
            setIsStandardBonus={setIsStandardBonus}
            type={type}
            comment={comment}
            payment={payment}
            stamp_count={stamp_count}
            add_stamp={add_stamp}
            give_award={give_award}
            isSendedUseCashbackCode={isSendedUseCashbackCode}
            confirmPayment={confirmPayment}
            whatsAppCode={whatsAppCode}
            setWhatsAppCode={setWhatsAppCode}
            customer={customer}
          />
        )
      case 4:
        return <GiveAward customer={customer} giveAwardToUser={giveAwardToUser} info={info} />
      default:
        return null
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={onModalClose}
    >
      <div className={styles.modal}>
        <div className={styles.closeModal} onClick={onModalClose}>
          <img src={closeModalImage}/>
        </div>
        <div className={styles.container}>
          <Scrollbars style={{ width: '100%', height: '100%' }}>
            {renderForm()}
          </Scrollbars>
        </div>
      </div>
    </Modal>
  )
}
