import React from 'react'
import { useTranslation } from "react-i18next";
import { Chart } from "react-google-charts";
import styles from './columnChart.module.scss';

export default function ColumnChart({ data, maxWidth = '610px', options = {}, title = '', separated = false, titles = [] }) {
  // if (!(RETURNED + NEW)) return null
  const { t } = useTranslation();
  const sum = data.reduce((previous, current, index) => {
    if (index > 0) {
      previous += current[1] + current[2];
    }

    return previous;
  }, 0)

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.block}>
        <div style={{ maxWidth, width: '100%' }}>
          <Chart
            chartType="ColumnChart"
            width={"100%"}
            height={"auto"}
            data={data}
            options={options}
          />
          {/*<Chart*/}
          {/*  chartType="PieChart"*/}
          {/*  data={[*/}
          {/*    ['Лояльные', 'Новые'],*/}
          {/*    ['Лояльные', RETURNED],*/}
          {/*    ['Новые', NEW],*/}
          {/*  ]}*/}
          {/*  options={{...options}}*/}
          {/*  width={"100%"}*/}
          {/*  height={"auto"}*/}
          {/*/>*/}
        </div>
        <div className={styles.rightBlock}>
          {separated ? (
            <>
              <b>{titles[0]}</b>
              <p>{data.reduce((previous, current, index) => {
                if (index > 0) {
                  previous += current[2];
                }

                return previous;
              }, 0)}</p>
              <b>{titles[1]}</b>
              <p>{data.reduce((previous, current, index) => {
                if (index > 0) {
                  previous += current[1];
                }

                return previous;
              }, 0)}</p>
            </>
          ) : (
            <>
              <b>{title}</b>
              <p>{sum}</p>
            </>
          )}
          <div className={styles.info}>
            <div><span></span><span>{t('loyal2')}</span></div>
            <div><span></span><span>{t('new2')}</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}