import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { useHistory, useLocation } from "react-router-dom";
import crossActions from "../../../../store/crossStore/crossActions";
import DropdownSelector, { StyledSelect } from "../../../../components/Analytics/DropdownSelector/DropdownSelector";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import Pagination from "../../../../components/Pagination";
import queryString from "querystring";
import { PENDING } from "../../../../store/globalStatuses/globalStatuses";
import { convertDateYToString } from "../../../../tools/convertDateToString";
import styles from './crossAnalytics.module.scss';
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import crossApi from "../../../../store/crossStore/crossApi";

const arrowsSvg = (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none">
    <path d="M4.4 13C4.4 13.3314 4.66863 13.6 5 13.6C5.33137 13.6 5.6 13.3314 5.6 13L4.4 13ZM5.42426 0.575736C5.18995 0.341421 4.81005 0.341421 4.57574 0.575736L0.757359 4.39411C0.523045 4.62843 0.523045 5.00833 0.757359 5.24264C0.991674 5.47696 1.37157 5.47696 1.60589 5.24264L5 1.84853L8.39411 5.24264C8.62843 5.47696 9.00833 5.47696 9.24264 5.24264C9.47696 5.00833 9.47696 4.62843 9.24264 4.39411L5.42426 0.575736ZM5.6 13L5.6 1L4.4 1L4.4 13L5.6 13Z" fill="black"/>
    <path d="M11.4 1C11.4 0.668629 11.6686 0.4 12 0.4C12.3314 0.4 12.6 0.668629 12.6 1L11.4 1ZM12.4243 13.4243C12.19 13.6586 11.8101 13.6586 11.5757 13.4243L7.75736 9.60589C7.52304 9.37157 7.52304 8.99167 7.75736 8.75736C7.99167 8.52304 8.37157 8.52304 8.60589 8.75736L12 12.1515L15.3941 8.75736C15.6284 8.52304 16.0083 8.52304 16.2426 8.75736C16.477 8.99167 16.477 9.37157 16.2426 9.60589L12.4243 13.4243ZM12.6 1L12.6 13L11.4 13L11.4 1L12.6 1Z" fill="black"/>
  </svg>
);

const Analytics = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search.slice(1));
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { info, pending } = useSelector((s) => s.branchReducer);
  const {
    valueCategories,
    pendingCategories,
    crossMarketingAnalyticPending,
    crossMarketingAnalytic,
  } = useSelector((s) => s.crossReducer);
  const [category, setCategory] = useState([]);
  const [date, setDate] = useState({from: queryParams.from ? new Date(queryParams.from) : null, to: queryParams.to ? new Date(queryParams.to) : null});
  const [isExcelLoading, setIsExcelLoading] = useState(false);

  useEffect(() => {
    if (category.length) {
      dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'category_id', value: category[0].id}));
    }
  }, [category]);

  useEffect(() => {
    if (valueCategories.length) {
      const foundItem = valueCategories.find((item) => item.id == queryParams.category_id);
      if (foundItem) {
        setCategory([foundItem])
      }
    }
  }, [valueCategories])

  useEffect(() => {
    let queryParams = {};
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] || queryParams[key] === 0) {
        queryParams = {...queryParams, [key]: queryParams[key]}
      }
    });

    dispatch(crossActions.setCrossMarketingAnalyticParams(queryParams));
  }, []);

  useEffect(() => {
    if (info && info.id) {
      history.push({
        pathname: '/cross-marketing/analytics',
        search: `?${queryString.stringify(crossMarketingAnalytic.params)}`
      });
      dispatch(crossActions.getCrossMarketingAnalyticList(info.id, crossMarketingAnalytic.params))
    }
  }, [info, crossMarketingAnalytic.params]);

  useEffect(() => {
    dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'from', value: convertDateYToString(date.from)}));
    dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'to', value: convertDateYToString(date.to)}));
  }, [date]);

  const onExport = () => {
    if (!info || !info?.id) return
    const params = {};
    if (date.from) {
      params.from = convertDateYToString(date.from);
    }
    if (date.to) {
      params.to = convertDateYToString(date.to);
    }
    setIsExcelLoading(true);
    crossApi.exportCrossMarketingClients(info.id, params)
      .finally(() => {
        setIsExcelLoading(false);
      })
  }

  const onSkip = (quantityOfSkip, cb) => {
    if (info && info.id) {
      dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'skip', value: quantityOfSkip}));
      cb();
    }
  }

  const sort = (value) => {
    if (Math.abs(value) == Math.abs(crossMarketingAnalytic.params.sort_filter_type)) {
      if (Number(crossMarketingAnalytic.params.sort_filter_type) < 0) {
        dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'sort_filter_type', value: Math.abs(crossMarketingAnalytic.params.sort_filter_type)}));
      } else {
        dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'sort_filter_type', value: -Number(crossMarketingAnalytic.params.sort_filter_type)}));
      }

      return
    }

    dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'sort_filter_type', value: value}));
  }

  const onChangePeriod = (type) => {
    switch (type) {
      case 5:
        setDate({
          from: null,
          to: null,
        });
        break
      case 4:
        setDate({
          ...date,
          from: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        });
        break
      case 3:
        setDate({
          ...date,
          from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        });
        break
      case 2:
        setDate({
          ...date,
          from: new Date(new Date().setDate(new Date().getDate() - 7)),
        });
        break
      case 1:
        setDate({
          ...date,
          from: new Date(new Date().setDate(new Date().getDate() - 1)),
        });
        break
    }
  }

  const onChooseAnalytic = (analytic) => {
    dispatch(crossActions.setCrossMarketingBranchesAnalytic(analytic));
    history.push(`/cross-marketing/analytics/${analytic.group_id}`);
  }

  if (info && !info?.configuration?.cross_marketing?.enabled) return (
    <div className={styles.modal}>
      <p>
        {t('optionForbidden')} <br/>
        {t('touchModerator')}
      </p>
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>
          <span>{t('NSCrossMarketing.analyticsCross')}</span>
        </h1>
        <p>{t('NSCrossMarketing.whereCouldNewClGo')}</p>
      </div>

      <b
        style={{
          marginTop: 20,
          display: 'block',
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "28px",
          color: "#1D1F23",
        }}
      >
        {t('NSCrossMarketing.clientStats')}
      </b>
      <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', maxWidth: '900px', width: '100%', padding: '1rem 0 1rem'}}>
        <div style={{marginRight: 20}}>
          <StyledSelect
            style={{minHeight: 50}}
            options={valueCategories}
            loading={pendingCategories | crossMarketingAnalyticPending || false}
            placeholder={t('NSCrossMarketing.chooseCat')}
            values={category}
            onChange={(option) => {
              setCategory(option);
            }}
            noDataRenderer={() => <div style={{ padding: '20px', textAlign: 'center', color: '#333333' }}>
              <span>{t('NSCrossMarketing.chooseCity')}</span>
            </div>}
            color={"#62C68A"}
            keepSelectedInList={true}
            closeOnSelect={true}
            labelField={'name'}
            valueField={'id'}
            // loading={status === PENDING}
            // disabled={status === PENDING}
            searchable={false} />
        </div>
        <DropdownSelector
          status={crossMarketingAnalyticPending ? PENDING : null}
          changePeriod={onChangePeriod}
          trans={true} />
        <div style={{marginLeft: 34, marginRight: 18}}>
          <CustomDatePicker
            trans={true}
            tStartDate={date.from}
            tEndDate={date.to}
            tSetStartDate={(v) => setDate({...date, from: v})}
            tSetEndDate={(v) => setDate({...date, to: v})}
          />
        </div>
      </div>

      <div className={styles.headerInfo}>
        <p className={styles.sum}>
          <span>{t('NSCrossMarketing.totalCrossCl')}: </span> <b>{crossMarketingAnalytic.total.total_clients_count}</b>
        </p>
        <p className={styles.new}>
          <span>{t('NSCrossMarketing.newClCount')}:</span> <span>{crossMarketingAnalytic.total.new_clients_count}</span>
        </p>
        <p className={styles.loyal}>
          <span>{t('NSCrossMarketing.loyalClCount')}: </span> <span>{crossMarketingAnalytic.total.loyalty_clients_count}</span>
        </p>
      </div>

      <div style={{ width: '100%', padding: '40px 0 0 0' }}>
        <table className={`table ${styles.table}`}>
          <thead>
            <tr>
              <th>№</th>
              <th>{t('NSCrossMarketing.name')}</th>
              <th>{t('NSCrossMarketing.cat')}</th>

              <th>
                <div>
                  <span>{t('NSCrossMarketing.bonusNewCl')}</span>
                  {/*<i>{arrowsSvg}</i>*/}
                </div>
              </th>
              <th className={`${styles.tableColumn}`} style={{ overflow: crossMarketingAnalytic.list.length ? 'visible' : 'hidden' }}>
                <span className={styles.textColumn}>
                  {t('NSCrossMarketing.bonus')}
                </span>
                <div>
                  <span>{t('NSCrossMarketing.bonusLoyalCl')}</span>
                  {/*<i>{arrowsSvg}</i>*/}
                </div>
              </th>

              <th onClick={() => sort(1)}>
                <div>
                  <span>{t('NSCrossMarketing.bonusNewCl')}</span>
                  <i>{arrowsSvg}</i>
                </div>
              </th>
              <th onClick={() => sort(2)}>
                <div>
                  <span>{t('NSCrossMarketing.bonusLoyalCl')}</span>
                  <i>{arrowsSvg}</i>
                </div>
              </th>
              <th className={`${styles.tableColumn}`} style={{ overflow: crossMarketingAnalytic.list.length ? 'visible' : 'hidden' }} onClick={() => sort(3)}>
                <span className={styles.textColumn}>
                  {t('NSCrossMarketing.numCl')}
                </span>
                <div>
                  <span>{t('NSCrossMarketing.sumClCount')}</span>
                  <i>{arrowsSvg}</i>
                </div>
              </th>

              <th  onClick={() => sort(4)}>
                <div>
                  <span>{t('NSCrossMarketing.bonusNewCl')}</span>
                  <i>{arrowsSvg}</i>
                </div>
              </th>
              <th style={{position: 'relative', overflow: crossMarketingAnalytic.list.length ? 'visible' : 'hidden'}} onClick={() => sort(5)}>
                <span className={styles.textColumn}>{t('NSCrossMarketing.rev')}</span>
                <div>
                  <span>{t('NSCrossMarketing.bonusLoyalCl')}</span>
                  <i>{arrowsSvg}</i>
                </div>
              </th>
              <th className={`${styles.tableColumn}`} style={{ overflow: crossMarketingAnalytic.list.length ? 'visible' : 'hidden' }} onClick={() => sort(6)}>
                <div>
                  <span>{t('NSCrossMarketing.sumClCount')}</span>
                  <i>{arrowsSvg}</i>
                </div>
              </th>

              <th>{t('NSCrossMarketing.city')}</th>
              {/*<th>{t('NSCrossMarketing.info')}</th>*/}

            </tr>
          </thead>
          <tbody>
            {crossMarketingAnalytic.list ? crossMarketingAnalytic.list.map((item, index) => (
              <tr key={item.group_id} onClick={() => onChooseAnalytic(item)}>
                <td>{index+1}</td>
                <td>{item.branch_name}</td>
                <td>{item.category_name}</td>
                <td>{item.bonus_for_new_client}</td>
                <td className={`${styles.tableColumn}`}>{item.bonus_for_loyalty_client}</td>
                <td>{item.new_clients_count}</td>
                <td>{item.loyalty_clients_count}</td>
                <td className={`${styles.tableColumn}`}>{item.total_clients_count}</td>
                <td>{item.new_clients_amount}</td>
                <td>{item.loyalty_clients_amount}</td>
                <td className={`${styles.tableColumn}`}>{item.total_clients_amount}</td>
                <td>{item.city_name}</td>
                {/*<td>12</td>*/}
              </tr>
            )) : (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        {crossMarketingAnalyticPending && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '580px' }}>
            <Loader color="#73D389" style={{ margin: 'auto' }}/>
          </div>
        )}

        <Pagination
          count={crossMarketingAnalytic.count}
          limit={crossMarketingAnalytic.params.limit}
          max={5}
          loading={crossMarketingAnalyticPending}
          updated={info && info.id}
          page={crossMarketingAnalytic.params.page}
          setPage={(page) => dispatch(crossActions.setCrossMarketingAnalyticParams({name: 'page', value: page}))}
          onSkip={onSkip}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "2rem" }}>
        <ButtonFilled onClick={onExport} loading={isExcelLoading}>
          Экспорт
        </ButtonFilled>
      </div>
    </div>
  )
}

export default Analytics;
