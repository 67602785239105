const profileTypes = {
    GET_PROFILE_PENDING: "GET_PROFILE_PENDING",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    GET_PROFILE_ERROR: "GET_PROFILE_ERROR",

    CHANGE_PROFILE_PENDING: "CHANGE_PROFILE_PENDING",
    CHANGE_PROFILE_SUCCESS: "CHANGE_PROFILE_SUCCESS",
    CHANGE_PROFILE_ERROR: "CHANGE_PROFILE_ERROR",

    DELETE_PROFILE_PENDING: "DELETE_PROFILE_PENDING",
    DELETE_PROFILE_SUCCESS: "DELETE_PROFILE_SUCCESS",
    DELETE_PROFILE_ERROR: "DELETE_PROFILE_ERROR",
}

export default profileTypes;