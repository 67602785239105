const bonusesModalTypes = {
    BM_AMOUNT_ON_CHANGE: "BM_AMOUNT_ON_CHANGE",
    BM_BONUSES_ON_CHANGE: "BM_BONUSES_ON_CHANGE",
    BM_STAMP_COUNT_ON_CHANGE: "BM_STAMP_COUNT_ON_CHANGE",
    BM_COMMENT_ON_CHANGE: "BM_COMMENT_ON_CHANGE",

    BM_STAMP_ON_CHANGE: "BM_STAMP_ON_CHANGE",
    BM_AWARD_ON_CHANGE: "BM_AWARD_ON_CHANGE",


    BM_GET_CLIENT_CASHBACK_PENDING: "BM_GET_CLIENT_CASHBACK_PENDING",
    BM_GET_CLIENT_CASHBACK_SUCCESS: "BM_GET_CLIENT_CASHBACK_SUCCESS",
    BM_GET_CLIENT_CASHBACK_ERROR: 'BM_GET_CLIENT_CASHBACK_ERROR',

    PATCH_ORDERS_REFUND_PENDING: "PATCH_ORDERS_REFUND_PENDING",
    PATCH_ORDERS_REFUND_SUCCESS: "PATCH_ORDERS_REFUND_SUCCESS",
    PATCH_ORDERS_REFUND_ERROR: 'PATCH_ORDERS_REFUND_ERROR',

    COMPLETE_ORDERS_REFUND_PENDING: "COMPLETE_ORDERS_REFUND_PENDING",
    COMPLETE_ORDERS_REFUND_SUCCESS: "COMPLETE_ORDERS_REFUND_SUCCESS",
    COMPLETE_ORDERS_REFUND_ERROR: 'COMPLETE_ORDERS_REFUND_ERROR',

    BM_CONFIRM_PAYMENT_PENDING:"BM_CONFIRM_PAYMENT_PENDING",
    BM_CONFIRM_PAYMENT_SUCCESS:"BM_CONFIRM_PAYMENT_SUCCESS",
    BM_CONFIRM_PAYMENT_ERROR:"BM_CONFIRM_PAYMENT_ERROR",

    BM_GIVE_AWARD_TO_USER_PENDING: "BM_GIVE_AWARD_TO_USER_PENDING",
    BM_GIVE_AWARD_TO_USER_SUCCESS: "BM_GIVE_AWARD_TO_USER_SUCCESS",
    BM_GIVE_AWARD_TO_USER_ERROR: "BM_GIVE_AWARD_TO_USER_ERROR",

    BM_RESET_STATE: "BM_RESET_STATE"
}

export default bonusesModalTypes