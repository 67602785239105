import instance from "../../api";
import * as queryString from "querystring";

const branchApi = {
    getBranch: (id) => {
        return instance().get(`/branches/${id}/full`).then(res => res.data)
    },
    changeWorkingHours: (id, data) => {
        return instance().post(`/branches/${id}/workhours`, data).then(res => res.data)
    },
    changeInfo: (id, data) => {
        return instance().post(`/branches/${id}/info`, data).then(res => res.data)
    },
    addBanner: (id, formData) => {
        return instance().post(`/branches/${id}/banners`, formData).then(res => res.data)
    },
    changeBrandedBonusCardBanner: (id, formData) => {
        return instance().post(`/branches/${id}/configuration/branded-bonus-card`, formData).then(res => res.data)
    },
    changeNotification: (id, data) => {
        return instance().post(`/branches/${id}/configuration/mailing`, data).then(res => res.data)
    },
    changeReportsContacts: (id, data) => {
        return instance().put(`/branches/${id}/reports/contacts`, data).then(res => res.data)
    },
    changeLocales: (id, data) => {
        return instance().patch(`/branches/${id}/locales`, data).then(res => res.data)
    },
    deleteBanner: (branchId, bannerId) => {
        return instance().delete(`/branches/${branchId}/banners/${bannerId}`).then(res => res.data)
    },
    changeIcon: (branchId, formData) => {
        return instance().post(`/branches/${branchId}/icon`, formData).then(res => res.data)
    },
    changePrivateCashbackExpireDate: (branchId, data) => {
        return instance().post(`/branches/${branchId}/configuration/private-cashback/expire-date`, data).then(res => res.data)
    },
    changePrivateCashbackSendCodeToCustomer: (branchId, data) => {
        return instance().put(`/branches/${branchId}/configuration/private-cashback/send-code-to-customer`, data).then(res => res.data)
    },
    changeAutoMailing: (branchId, data) => {
        return instance().post(`/branches/${branchId}/configuration/auto-notification`, data).then(res => res.data)
    },
    updateBonusForBirthday: (branchId, data) => {
        return instance().post(`/branches/${branchId}/configuration/birthday-bonus`, data).then(res => res.data)
    },
    changeWhatsappHeader: (branchId, data) => {
        return instance().post(`/branches/${branchId}/configuration/whatsapp-header`, data).then(res => res.data)
    },
    changeBonusDelay: (branchId, data) => {
        return instance().post(`/branches/${branchId}/configuration/delay-bonuses`, data).then(res => res.data)
    },
    changeSocials: (branchId, data) => {
        return instance().post(`/branches/${branchId}/social`, data).then(res => res.data)
    },
    activatedAwardConfig: (branchId, data) => {
        return instance().post(`/branches/${branchId}/configuration/award/activated`, data).then(res => res.data)
    },
    deactivatedAwardConfig: (branchId) => {
        return instance().post(`/branches/${branchId}/configuration/award/deactivated`, {enabled: false}).then(res => res.data)
    },

    sendPushToCustomers: (branchId, data) => {
        return instance().post(`/branches/${branchId}/send/push/customers`, data).then(res => res.data)
    },
    branchesCustomersUploadedSend: (branchId, data) => {
        return instance().post(`/branches/${branchId}/customers/uploaded/send`, data).then(res => res.data)
    },
    branchesCustomersUploadedStop: (branchId, data) => {
        return instance().post(`/branches/group/${branchId}/customers/uploaded/stop`, data).then(res => res.data)
    },
    branchesCustomersUploadedHistory: (groupId) => {
        return instance().get(`/branches/group/${groupId}/customers/uploaded/history`).then(res => res.data)
    },
    branchesAnalyticCustomersUploadedSend: (groupId, data) => {
        return instance().post(`/branches/group/${groupId}/customers/uploaded/send`, data).then(res => res.data)
    },
    sendBonusesToUsers: (branchId, data) => {
        return instance().post(`/branches/${branchId}/bonuses/users`, data).then(res => res.data)
    },
    changeContractBasic: (brand_id, data) => {
        return instance().put(`/branches/${brand_id}/contract/basic`, data).then(res => res.data)
    },
    changeContractIncreased: (brand_id, data) => {
        return instance().put(`/branches/${brand_id}/contract/increased`, data).then(res => res.data)
    },
    getBranchesSecurityUsers: (id) => {
        return instance().get(`/branches/${id}/security/users`).then(res => res.data)
    },
    addUserToWhiteList: (branchId, phoneNumber, data) => {
        return instance().put(`/branches/${branchId}/configuration/security/blacklist/add-one`, { phone_number: phoneNumber }).then(res => res.data)
    },
    addUserToExcludeList: (branchId, phoneNumber, data) => {
        return instance().put(`/branches/${branchId}/configuration/security/whitelist/add-one`, { phone_number: phoneNumber }).then(res => res.data)
    },
    removeUserFromWhiteList: (branchId, data) => {
        return instance().put(`/branches/${branchId}/configuration/security/blacklist/remove`, { phone_numbers: data }).then(res => res.data)
    },
    excludeUserFromWhiteList: (branchId, data) => {
        return instance().put(`/branches/${branchId}/configuration/security/whitelist/remove`, { phone_numbers: data }).then(res => res.data)
    },
    updateDateLimitForSecurity: (branchId, data) => {
        return instance().put(`/branches/${branchId}/configuration/security/daily-tx-limit`, data).then(res => res.data)
    },
    updateTxCashbackLimitForSecurity: (branchId, data) => {
        return instance().put(`/branches/${branchId}/configuration/security/tx-cashback-limit`, data).then(res => res.data)
    },
    postBonusPromocode: (branchId, data) => {
        return instance().post(`/branches/${branchId}/promocode`, data).then(res => res.data)
    },
    deleteBonusPromocode: (branchId, codeId, data) => {
        return instance().delete(`/branches/${branchId}/promocode/${codeId}`, data).then(res => res.data)
    },
    changeWhatsappProhibitions: (branchId, data) => {
        return instance().post(`/branches/${branchId}/configuration/whatsapp-prohibitions`, data).then(res => res.data)
    },
}

export default branchApi;
