import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import { useDispatch, useSelector } from "react-redux";
import adsActions from "../../../../store/adsStore/adsActions";
import { formatDate } from "../../../../helpers";
import Pagination from "../../../../components/Pagination";
import Loader from "../../../../components/Loader";
import { useHistory } from "react-router-dom";
import styles from './common.module.scss';
import Checkbox from "../../../../components/FormElements/Checkbox";
import { store } from "react-notifications-component";

const Common = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { info, pending } = useSelector((s) => s.branchReducer);
  const { ads: { list, count, limit }, pending: adsPending, chosenAds } = useSelector((s) => s.adsReducer);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (info && info.id) {
      dispatch(adsActions.clearAds());
      const queryParams = `limit=${limit}`;
      dispatch(adsActions.getSalesForBranchGroup(info.id, queryParams));
    }
  }, [info, i18n.language]);

  const onSkip = (quantityOfSkip, cb) => {
    if (info && info.id) {
      const queryParams = `limit=${limit}&skip=${quantityOfSkip}`;
      dispatch(adsActions.getSalesForBranchGroup(info.id, queryParams));
      cb();
    }
  }

  const onChangeChosenAds = (e, item) => {
    if (chosenAds.length === 5 && e.target.checked) {
      return store.addNotification({
        title: ``,
        message: t('NSComparison.notAllow', {count: 5}),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
    if (e.target.checked) {
      dispatch(adsActions.choseAds(item))
    } else {
      dispatch(adsActions.removeAds(item))
    }
  }

  return (
    <div className={styles.container}>
      <h1>
        <span>{t('NSSidebar.ads')}</span>
        <ButtonFilled to="/ads/form">{t('NSComparison.createAds')}</ButtonFilled>
      </h1>
      <div
        style={{marginLeft: 'auto', display: 'inline-block', margin: '10px 0',}}
      >
        {chosenAds.length > 1 ? (
          <ButtonFilled to="/ads/comparison">{t('NSComparison.compr')}</ButtonFilled>
        ) : null}
      </div>
      <div style={{ width: '100%' }}>
        <table className={`table ${styles.table}`}>
          <thead>
          <tr>
            <th>{t('period')}</th>
            <th>{t('title')}</th>
            <th>{t('NSCumulative.amount')}</th>
            <th>{t('NSGeneral.clients')}</th>
            <th>{t('NSGeneral.leads')}</th>
            <th>{t('status')}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            {list ? list.map((item) => (
              <tr key={item.id} onClick={() => history.push(`/ads/sale/${item.id}`)}>
                <td className={styles.period}>
                  {item.activated_at > 0 && <span>{t("from")} {formatDate(item.activated_at, { year: 'numeric', month: 'numeric', day: 'numeric' })}</span>}
                  {item.expired_at > 0 && <span>{t("till")} {formatDate(item.expired_at, { year: 'numeric', month: 'numeric', day: 'numeric' })}</span>}
                  <span></span>
                </td>
                <td>{item.title}</td>
                <td>{item.payload.promo.bonus}</td>
                <td>{item.clients_count}</td>
                <td>{item.users_count}</td>
                <td>
                  <div className={`${styles.status} ${item.active ? styles.statusActive : ''}`}>
                    <span>{item.active ? t('active') : t('disabled')}</span>
                  </div>
                </td>
                <td onClick={(e) => e.stopPropagation()}>
                  <Checkbox onChange={(e) => onChangeChosenAds(e, item)} checked={chosenAds.some((a) => a.id == item.id)} />
                </td>
              </tr>
            )) : (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        {adsPending && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
            <Loader color="#73D389" style={{ margin: 'auto' }}/>
          </div>
        )}
        <Pagination
          count={count}
          limit={limit}
          max={limit}
          loading={adsPending}
          updated={info && info.id}
          page={page}
          setPage={setPage}
          onSkip={onSkip}
        />
      </div>
    </div>
  )
}

export default Common;
