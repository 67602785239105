const audienceTypes = {
    AA_GET_DATA_PENDING: "AA_GET_DATA_PENDING",
    AA_GET_DATA_SUCCESS: "AA_GET_DATA_SUCCESS",
    AA_GET_DATA_ERROR: "AA_GET_DATA_ERROR",


    AA_CHANGE_DATA_PERIOD: "AA_CHANGE_DATA_PERIOD",
    AA_CHANGE_DATE_FROM: "AA_CHANGE_DATE_FROM",
    AA_CHANGE_DATE_TO: "AA_CHANGE_DATE_TO",
}

export default audienceTypes