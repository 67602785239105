import filtersTypes from "./filtersTypes";

const initialState = {
    city_ids: [],
    branch_ids: [],
}

const filtersReducer = (state = initialState, action) => {

    switch (action.type) {
        case filtersTypes.CHANGE_FILTERS:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}

export default filtersReducer
