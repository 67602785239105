import instance, { API_URL } from "../../api";
import * as queryString from "querystring";
import cookies from "react-cookies";
import { COOKIES } from "../../helpers";

const crossApi = {
    updateBranchConfigForCrossMarketing: (id, data) => {
        return instance().post(`/branches/${id}/configuration/cross-marketing`, data).then(res => res.data)
    },
    exportCrossMarketingClients: (branchID, params) => {
        return fetch(`${API_URL}/branches/${branchID}/cross-marketing/clients/export`, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
                Authorization: `Bearer ${cookies.load(COOKIES.TOKEN)}`
            },
            method: 'GET'
        }).then((response) => {
            response.blob().then((blob) => {
                const filename = decodeURI(response.headers.get('Content-Disposition')?.split('filename=')[1] || '')
                if ('msSaveOrOpenBlob' in window.navigator) {
                    navigator.msSaveBlob(blob, filename)
                } else {
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.href = window.URL.createObjectURL(blob)
                    a.download = filename
                    a.target = '_blank'
                    a.click()
                    a.remove()
                }
            });

            return {};
        })
    },
    updateBranchConfigReferral: (id, data) => {
        return instance().post(`/branches/${id}/configuration/referal-system`, data).then(res => res.data)
    },
    getCities: () => {
        return instance().get(`/cities`).then(res => res.data)
    },
    getCategories: (params) => {
        return instance().get(`/categories`, {params}).then(res => res.data)
    },
    getCrossMarketingList: (id, params) => {
        let pureParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] || params[key] === 0) {
                pureParams = {...pureParams, [key]: params[key]}
            }
        })
        const prms = queryString.stringify(pureParams);
        return instance().get(`/branches/${id}/cross-marketing/list?${prms}`, {}).then(res => res.data)
    },
    getCrossMarketingGroupIdList: (id) => {
        return instance().get(`/branches/${id}/cross-marketing/group-id-list`).then(res => res.data)
    },
    saveSelectedCrossMarketingList: (id, body) => {
        return instance().post(`/branches/${id}/cross-marketing`, body).then(res => res.data)
    },
    getCrossMarketingAnalyticList: (id, params) => {
        let pureParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] || params[key] === 0) {
                pureParams = {...pureParams, [key]: params[key]}
            }
        })
        const prms = queryString.stringify(pureParams);
        return instance().get(`/branches/${id}/cross-marketing/analytic?${prms}`, {}).then(res => res.data)
    },
    getCrossMarketingAnalyticClientsList: (id, params) => {
        let pureParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] || params[key] === 0) {
                pureParams = {...pureParams, [key]: params[key]}
            }
        })
        const prms = queryString.stringify(pureParams);
        return instance().get(`/branches/${id}/cross-marketing/customers?${prms}`, {}).then(res => res.data)
    },
}

export default crossApi;
