import * as Yup from 'yup';
import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from "../../../store/branchStore/branchActions";

export const useBonusIncreased = (t) => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const { info, pending } = useSelector(s => s.branchReducer);
    const [isCalendar, setIsCalendar] = useState();
    const [isHours, setIsHours] = useState();

    const formik = useFormik({
        initialValues: {
            percentage: '',
            enabled: false,
            always_on: false,
            hours: [{ from: "", to: "" }],
            dates: [{ from: null, to: null }],
        },
        validationSchema: Yup.object({
            percentage: Yup.string().required(t('Form.fillField')),
        }),
        onSubmit: (async (values) => {
            const data = Object.assign({}, values);
            _.forIn(values, function(value, key) {
                if (key === "percentage" && values[key]) {
                    data[key] = Number(value);
                }
                if (key === "dates") {
                    const dates = [];
                    values[key].forEach((date, index) => {
                        if (date.from && date.to) {
                            dates[index] = {
                                from: new Date(date.from).getTime(),
                                to: new Date(date.to).getTime(),
                            }
                        }
                    })
                    if (dates.length) {
                        data[key] = dates;
                    } else {
                        data[key] = null;
                    }
                }

                if (key === "hours") {
                    const hours = [];
                    values[key].forEach((date, index) => {
                        if (date.from && date.to) {
                            hours[index] = {
                                from: Number(date.from),
                                to: Number(date.to),
                            }
                        }
                    })
                    if (hours.length) {
                        data[key] = hours;
                    } else {
                        data[key] = null;
                    }
                }
            });

            try {
                if (info) dispatch(branchActions.changeContractIncreased(info.id, { ...data, enabled: true }))
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (info.rule.increased_cashback && info.rule.increased_cashback) {
                if (info.rule.increased_cashback.percentage) {
                    formik.setFieldValue('percentage', info.rule.increased_cashback.percentage);
                }
                formik.setFieldValue('always_on', info.rule.increased_cashback.always_on || false);
                formik.setFieldValue('enabled', info.rule.increased_cashback.enabled || false);
                if (info.rule.increased_cashback.dates && info.rule.increased_cashback.dates.length) {
                    formik.setFieldValue('dates', info.rule.increased_cashback.dates);
                    setIsCalendar(true);
                }
                if (info.rule.increased_cashback.hours && info.rule.increased_cashback.hours.length) {
                    formik.setFieldValue('hours', info.rule.increased_cashback.hours);
                    setIsHours(true);
                }
            }
        }
    }, [info]);

    useEffect(() => {
        if (isCalendar) {
            formik.setFieldValue("always_on", false);
            setIsHours(false);
        }
    }, [isCalendar]);

    useEffect(() => {
        if (isHours) {
            formik.setFieldValue("always_on", false);
            setIsCalendar(false);
        }
    }, [isHours]);

    useEffect(() => {
        if (formik.values.always_on) {
            setIsHours(false);
            setIsCalendar(false);
            formik.setFieldValue("dates", [{ from: null, to: null }]);
            formik.setFieldValue("hours", [{ from: "", to: "" }]);
        }
    }, [formik.values.always_on]);

    const changeDate = (index, name, value) => {
        const deepCopiedDateValues = _.cloneDeep(formik.values.dates);
        deepCopiedDateValues[index][name] = value;
        formik.setFieldValue("dates", deepCopiedDateValues);
    }

    const changeHour = (index, name, value) => {
        const deepCopiedHoursValues = _.cloneDeep(formik.values.hours);
        deepCopiedHoursValues[index][name] = value;
        formik.setFieldValue("hours", deepCopiedHoursValues);
    }

    const onSwitchOff = async () => {
        try {
            if (info) dispatch(branchActions.changeContractIncreased(info.id, {
                percentage: 0,
                enabled: false,
                always_on: false,
                hours: [],
                dates: [],
            }));
            formik.resetForm();
            setIsHours(false);
            setIsCalendar(false);
        } catch (e) {
            const res = e.response ? e.response.data : null;
            store.addNotification({
                title: `Error code: ${res ? res.code : 'NULL'}`,
                message: `${res ? res.message : t('Notify.notRespond')}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });

        }
    }

    return {
        changeDate,
        changeHour,
        onSwitchOff,
        isCalendar,
        setIsCalendar,
        isHours,
        setIsHours,
        formik,
        pending,
        error,
        setError,
        // enabledIncreasedCashback: info?.rule?.increased_cashback?.enabled,
        enabledIncreasedCashback: true,
    }
}
