import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import General from "./components/General/General";
import { useDispatch, useSelector } from "react-redux";
import generalActions from "../../../../store/analyticsStores/generalStore/generalActions";
import { useCurrentBranch } from "../../../../hooks/useCurrentBranch";

export default function GeneralContainer() {
    const {
        data,
        type,
        payment_total,
        cashback_amount,
        transactions,
        average_check,
        status,
        chart_data,
        payment_from_private_balance,
        dinamicSalesDateFrom,
        dinamicSalesDateTo,
    } = useSelector(state => state.generalReducer)

    const invisParams = useSelector(state => state.filtersReducer)
    const { i18n } = useTranslation();
    const { info } = useSelector(state => state.branchReducer)
    const { currentRole, groupId } = useCurrentBranch()
    const dispatch = useDispatch()

    const changePeriod = (period) => {
        dispatch(generalActions.changeDateFrom(null))
        dispatch(generalActions.changeDateTo(null))
        dispatch(generalActions.changeDataPeriod(period))
    }

    const changeDate = (type, date) => {
        switch (type) {
            case 1:
                dispatch(generalActions.changeDataPeriod(null))
                dispatch(generalActions.changeDateFrom(date))
                break;
            case 2:
                dispatch(generalActions.changeDataPeriod(null))
                dispatch(generalActions.changeDateTo(date))
                break;
            default: return;
        }
    }

    useEffect(() => {
        if (info) {
            dispatch(generalActions.getGeneralData(info && info.id, currentRole, groupId, type, dinamicSalesDateFrom, dinamicSalesDateTo, invisParams))
        }
    }, [info, type, dinamicSalesDateFrom, dinamicSalesDateTo,i18n.language, invisParams])


    return (
        <General
            data={data}
            type={type}
            payment_total={payment_total}
            cashback_amount={cashback_amount}
            transactions={transactions}
            average_check={average_check}
            payment_from_private_balance={payment_from_private_balance}
            changePeriod={changePeriod}
            changeDate={changeDate}
            status={status}
            chart_data={chart_data}
            dinamicSalesDateFrom={dinamicSalesDateFrom}
            dinamicSalesDateTo={dinamicSalesDateTo}
        />
    )
}
