import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import TextField from "../../../../components/FormElements/TextField";
import PageHeader from "../../../../components/PageHeader";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import CustomSelect from "../../../../components/FormElements/Select";
import styles from './profileBonusNotification.module.scss';
import Modal from "../../../../portals/Modal";
import { StyledSelect } from "../../../../components/Analytics/DropdownSelector/DropdownSelector";
import Checkbox from "../../../../components/FormElements/Checkbox";
import { useBonusNotification } from "../../hooks/useBonusNotification";
import { PhoneInputField } from "../../../../components/PhoneInput";

export default function ProfileBonusCross() {
    const { t } = useTranslation();
    const {
        formik,
        pending,
        crossPending,
        errorModal,
        setErrorModal,
        error,
        info,
    } = useBonusNotification(t);

    return (
        <>
            <PageHeader title={t('monthlyReport')}>
                <div style={{ marginLeft: '30px' }}>
                    <QuestionTooltip text={t('NSBonus.addWhatsapp')} />
                </div>
            </PageHeader>
            <div className={styles.container}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.block}>
                        <PhoneInputField
                          value={formik.values.phone}
                          error={formik.touched.phone && formik.errors.phone}
                          pending={pending}
                          onChange={(v) =>  !error && formik.setFieldValue('phone', v)}
                          label={t('Form.enterPhone')}
                        />
                    </div>
                    <div className={styles.block}>
                        <TextField
                          label="Email"
                          required

                          value={formik.values.email}
                          error={formik.touched.email && formik.errors.email}
                          onChange={(e) => formik.setFieldValue('email', e.target.value)}
                        />
                    </div>
                    <div className={styles.footer}>
                        <ButtonFilled disabled={error} type="submit" loading={pending || crossPending}>{t('save')}</ButtonFilled>
                    </div>
                </form>
            </div>
            <Modal
              open={errorModal} size="small"
              onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                          loading={pending}
                          onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
