import React from 'react';
import { useTranslation } from "react-i18next";
import { PageLayout } from '../Layout';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import Checkbox from "../../../../components/FormElements/Checkbox";
import { useBonusNotificationWhatsapp } from "../../hooks/useBonusNotificationWhatsapp";

export default function WhatsAppCode() {
  const { t } = useTranslation();
  const {
    formik,
    pending,
  } = useBonusNotificationWhatsapp(t);

  const langs = [
    { label: t("kk"), value: "kk" },
    { label: t("ky"), value: "ky" },
    { label: t("ru"), value: "ru" },
    { label: t("en"), value: "en" },
  ]

  return (
    <PageLayout
      title={t('notification') + " Whatsapp"}
    >
      <div style={{ maxWidth: 400, padding: "40px 0", margin: "0 auto", textAlign: "center" }}>
        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: 'center' }}>
          {langs.map(({ label, value }) => (
            <Checkbox
              label={label}
              name={value}
              checked={formik.values.locales.includes(value)}
              onChange={() => {
                if (formik.values?.locales?.includes(value)) {
                  formik.setFieldValue("locales", formik.values.locales.filter((v) => v !== value))
                } else {
                  formik.setFieldValue("locales", [...formik.values.locales, value])
                }
              }}
            />
          ))}
        </div>

        <div style={{ height: '30px' }}></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonFilled
            onClick={formik.handleSubmit}
            loading={pending}
          >
            {t('save')}
          </ButtonFilled>
        </div>
      </div>
    </PageLayout>
  )
}