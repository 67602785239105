import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import adsActions from '../../../store/adsStore/adsActions';
import { useHistory } from "react-router-dom";

export const useAdsForm = (t) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorModal, setErrorModal] = useState(false);
  const { ads, currentAds, pending } = useSelector(s => s.adsReducer);
  const { info, pending: infoPending } = useSelector(s => s.branchReducer);

  const formik = useFormik({
    initialValues: {
      title: null,
      budget: null,
      description: null,
      active: false,
      type: "PROMO_BONUS",
      expire_date: null,
      activate_date: null,
      user_count: 0,
      payload: {
        id: null,
        bonus: null,
        expired_at: null,
        group_id: null,
        branch_name: null,
      }
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('Form.enterTitle')).nullable(),
      budget : Yup.number().required(t('Form.enterBudget')).typeError(t('Form.enterCorrectBudgetAds')).nullable(),
      payload: Yup.object().shape({
        bonus : Yup.number().required(t('Form.enterSumBonus')).typeError(t('Form.enterCorrectSumBonus')).nullable(),
      }).required(t('Form.fillQuestion'))
    }),
    onSubmit: (async (values) => {
      const data = {
        ...values,
        expire_date: values.expire_date ? values.expire_date.getTime() : 0,
        activate_date: values.activate_date ? values.activate_date.getTime() : 0,
        budget: Number(values.budget),
        payload: {
          ...values.payload,
          bonus: Number(values.payload.bonus),
        }
      }

      new Promise((resolve, reject) => {
        dispatch(adsActions.createSale(data, resolve, reject));
      })
        .then(() => history.push('/ads'));
    })
  });

  useEffect(() => {
    if (info) {
      if (info.id) {
        formik.setFieldValue('payload.id', info.id);
        formik.setFieldValue('payload.group_id', info.group_id);
        formik.setFieldValue('payload.branch_name', info.name);
        formik.setFieldValue('payload.expired_at', info?.configuration?.private_cashback?.expire_interval ?? 0);
      }
    }
  }, [info]);

  return {
    formik,
    pending,
    infoPending,
    errorModal,
    setErrorModal,
  }
}
