const notificationsTypes = {
    GET_NOTIFICATIONS_PENDING: "GET_NOTIFICATIONS_PENDING",
    GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
    GET_NOTIFICATIONS_ERROR: "GET_NOTIFICATIONS_ERROR",

    CHANGE_NOTIFICATIONS_PAGE: "CHANGE_NOTIFICATIONS_PAGE",


    SEND_MESSAGE_IN_NOTIFICATION_PENDING: "SEND_MESSAGE_IN_NOTIFICATION_PENDING",
    SEND_MESSAGE_IN_NOTIFICATION_SUCCESS: "SEND_MESSAGE_IN_NOTIFICATION_SUCCESS",
    SEND_MESSAGE_IN_NOTIFICATION_ERROR: "SEND_MESSAGE_IN_NOTIFICATION_ERROR"
}

export default notificationsTypes