import React from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import Loader from '../../Loader';
import styles from './buttonFilled.module.scss';

const ButtonFilled= (props) => {
  const {
    children,
    to,
    className = '',
    onClick,
    variant = 'green',
    type = "button",
    loading = false,
    disabled = false,
    rounded = false,
    ...otherProps
  } = props;

  const ButtonWrapper = (wrapperProps) => {
    const {children: wrapperChildren} = wrapperProps;
    const wrapperClassName = `${styles['button-filled']} ${rounded ? styles['button-filled--rounded'] : ''} ${styles[`button-filled--${variant}`]} ${className}`.trim();

    if (!!to) {
      return (
        <Link
          to={to}
          disabled={disabled || loading}
          className={classNames(wrapperClassName, {
            [styles['button-filled--disabled']]: disabled || loading
          })}
          {...otherProps}
        >
          {wrapperChildren}
        </Link>
      );
    }

    return (
      <button onClick={(e) => {
        if (disabled || !onClick) {
          return
        } else if (onClick) {
          onClick(e);
        }
      }}
      disabled={disabled || loading}
      type={type}
      className={classNames(wrapperClassName, {
        [styles['button-filled--disabled']]: disabled || loading
      })}
        {...otherProps}
      >
        {wrapperChildren}
      </button>
    )
  };

  return (
    <ButtonWrapper>
      <React.Fragment>
        <span
          className={classNames(styles['button-filled__text'], {
            [styles['button-filled__text--hidden']]: loading
          })}>
          {children}
        </span>
        {loading ? <Loader className={styles['button-filled__spinner']} /> : null}
      </React.Fragment>
    </ButtonWrapper>
  );
};

export default ButtonFilled;
