import React, { useEffect, useState } from "react";
import { Trans, t, useTranslation } from "react-i18next";
import PageHeader from "../../components/PageHeader";
import { useSelector } from "react-redux";
import styles from "./whatsapp.module.scss";
import Loader from "../../components/Loader";
import ButtonFilled from "../../components/FormElements/ButtonFilled";
import Modal from "../../portals/Modal";
import QrCode from "./QrCode";
import { store } from 'react-notifications-component';

const WhatsApp = () => {
  const { pending, info } = useSelector(s => s.branchReducer);
  const [enabled, setEnabled] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const { t } = useTranslation();


  useEffect(() => {
    if (info && info.configuration) {
      if (info.configuration && info.configuration.whatsapp_provider) {
        const { enabled, state } = info.configuration.whatsapp_provider;
        if (enabled) {
          setEnabled(enabled);
        } else {
          store.addNotification({
            title: 'Whatsapp',
            message: t('disconnectWhatsapp'),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          setEnabled(false);
          setErrorModal(true);
        }
      }
    }
  }, [info]);

  if (pending) {
    return (
      <div className={styles.container} style={{ justifyContent: 'center' }}>
        <Loader color="#73D389" style={{ marginTop: '100px', textAlign: 'center' }} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <PageHeader title={t('NSWhatsapp.title')} />

      <div className={styles.header}>
        <div className={styles['header__left']}>
          <h2>{t('note')}</h2>
          <ul>
            <li>
              <Trans i18nKey="NSWhatsapp.instruction">
                <span>1. Зайдите в WhatsApp > Настройки > Связанные </span><span>устройства > Привязка устройства и отсканируйте</span> <span>QR код</span>
              </Trans>
            </li>
            <li>
              {t('NSWhatsapp.refresh')}
            </li>
            <li>
              <Trans i18nKey="NSWhatsapp.refresh2" components={[<span />]} />
            </li>
          </ul>
        </div>
        <div className={styles['header__right']}>
          <h2>{t('instruction')}</h2>
          <p>
            <span>{t('NSWhatsapp.note1')}</span>
            <span>{t('NSWhatsapp.note2')}</span>
            <span>{t('NSWhatsapp.note3')}</span>
          </p>
        </div>
      </div>

      <div className={styles.body}>
        {enabled && (
          <QrCode
            info={info}
          />
        )}
      </div>

      <Modal
        open={errorModal} size="small"
        onClickClose={() => setErrorModal(false)}>
        <div className={styles.modal}>
          <p>
            {t('optionForbidden')} <br/>
            {t('touchModerator')}
          </p>
          <div>
            <ButtonFilled
              onClick={() => setErrorModal(false)}
            >{t('close')}</ButtonFilled>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default WhatsApp;