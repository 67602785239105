import directoryTypes from "./directoryTypes";

const initialState = {
    citiesPending: false,
    cities: [],
}

const directoryReducer = (state = initialState, action) => {

    switch (action.type) {
        case directoryTypes.GET_CITIES_PENDING:
            return {
                ...state,
                cities: [],
                citiesPending: true,
            }
        case directoryTypes.GET_CITIES_ERROR:
            return {
                ...state,
                cities: [],

                citiesPending: false,
            }
        case directoryTypes.GET_CITIES_SUCCESS:
            return {
                ...state,
                cities: action.payload,
                citiesPending: false,
            }

        default:
            return state
    }
}

export default directoryReducer
