import instance from "../../../api";
import queryString from 'query-string';

const generalApi = {
    getGeneralData: async (branch_id, type, dates) => {
        return await instance().get(`/branches/${branch_id}/reports?${type ? `type=${type}&` : ''}show_payment_hours=true${dates}`).then(res => res.data)
        // return await instance().get(`/branches/${branch_id}/reports?show_payment_hours=true${dates}`).then(res => res.data)
    },
    getAnalyticGeneralData: async (groupId, type, dates, invisParams) => {

        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `&${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery}&${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }

        return await instance().get(`/branches/group/${groupId}/reports?${type ? `type=${type}&` : ''}show_payment_hours=true${dates}${invisQuery}`).then(res => res.data)
        // return await instance().get(`/branches/${branch_id}/reports?show_payment_hours=true${dates}`).then(res => res.data)
    },
}

export default generalApi