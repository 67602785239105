import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import StartEndHours from '../StartEndHours';
import styles from './workingHour.module.scss';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export default function WorkingHour({ info, onChangeWorkingHour }) {
  const { t } = useTranslation();
  const { start = '00:00', end = '00:00', day, dayoff = false, allday = false, lunch } = info;
  const defaultLunchValues = useRef({lunch: {start: '', end: ''}});

  useEffect(() => {
    if (info) {
      onChangeWorkingHour(info);
    }
  }, [info]);

  let lunchStart = '';
  let lunchEnd = '';

  if (lunch) {
    lunchStart = lunch.start;
    lunchEnd = lunch.end;
  }

  const onChangeSelect = (type, value) => {
    onChangeWorkingHour({ ...info, [type]: value });
  }

  const onChangeHours = (lunchStatus, value) => {
    if (lunchStatus) {
      onChangeWorkingHour({ ...info, lunch: { ...lunch, ...value } });
    } else {
      onChangeWorkingHour({ ...info, ...value });
    }
  }

  const onAddLunch = () => {
    onChangeWorkingHour({ ...info, ...defaultLunchValues.current });
  }

  const onDeleteLunch = () => {
    const {lunch, ...restInfo} = _.cloneDeep(info);
    onChangeWorkingHour(restInfo);
  }

  return (
    <div className={styles.container}>
      <h6>{t(days[day - 1])}</h6>
      <div className={styles.times}>
        <div className={styles.time}>
          <div className={styles.hours}>
            <StartEndHours
              onDeleteLunch={onDeleteLunch}
              onAddLunch={onAddLunch}
              lunch={false}
              start={start} end={end}
              onChangeHours={onChangeHours}
            />
          </div>
          <span>{t('NSWorking.hours')}</span>
          <div className={styles.checkbox}>
            <input type="checkbox" checked={allday} onChange={() => onChangeSelect('allday', !allday)} /> {t('NSWorking.clock')}
          </div>
        </div>
        <div className={styles.time}>
          <div className={styles.hours}>
            <StartEndHours
              onDeleteLunch={onDeleteLunch}
              onAddLunch={onAddLunch}
              lunch={true}
              start={lunchStart}
              end={lunchEnd}
              onChangeHours={onChangeHours}
            />
          </div>
          <span>{t('break')}</span>
          <div className={styles.checkbox}>
            <input type="checkbox" checked={dayoff} onChange={() => onChangeSelect('dayoff', !dayoff)} /> {t('weekend')}
          </div>
        </div>
      </div>
    </div>
  )
}