import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Modal from "../../../../portals/Modal";
import branchActions from "../../../../store/branchStore/branchActions";
import PageHeader from "../../../../components/PageHeader";
import CustomSelect from "../../../../components/FormElements/Select";
import styles from './profileBonusAutomailing.module.scss';
import QuestionTooltip from "../../../../components/QuestionTooltip";
import Checkbox from "../../../../components/FormElements/Checkbox";
import TextField from "../../../../components/FormElements/TextField";
import InfiniteScroll from "react-infinite-scroller";
import instance from "../../../../api";
import { clearMask } from "../../../../helpers";
import { store } from "react-notifications-component";
import MaskService from "react-masked-text/src/mask-service";

export default function ProfileBonusAutoMailing() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const info = useSelector((s) => s.branchReducer.info);
    const [state, setState] = useState(null);
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const [pending, setPending] = useState(false);
    const [interval, setInterval] = useState({label: '', value: 0});
    const [message, setMessage] = useState('');
    const [bonus_amount, setBonusAmount] = useState(0);
    const [send_bonus_amount_in_message, setSendBonusAmountInMessage] = useState(false);
    const [bonus_to_all, setBonusToAll] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [phone, setPhone] = useState('');
    const [blackListPhones, setBlackListPhones] = useState([]);
    const [lookingClient, setSetLookingClient] = useState(false);
    const [limitOff, setLimitOff] = useState(false);
    const [autoNotification, setAutoNotification] = useState(null);

    const options = [
        {
            "label": 'week',
            "value": 6.048e+8,
        },
        {
            "label": 'NSBonus.weeks',
            "value": 1.21e+9,
        },
        {
            "label": "month",
            "value": 2.628e+9,
        },
        {
            "label":'NSBonus.two',
            "value": 5.256e+9,
        },
        {
            "label": 'NSBonus.three',
            "value": 7.884e+9,
        },
        {
            "label": 'NSBonus.halfYear',
            "value": 1.577e+10,
        },
        {
            "label": 'year',
            "value": 3.154e+10,
        },
    ];

    useEffect(() => {
        setState(null);
        setError(false);

        if (info && !info.configuration.auto_notification) {
            setError(true);
            return setErrorModal(true);
        }

        if (info && info.configuration.auto_notification) {
            let currentOption = options.find(o => o.value === info.configuration.auto_notification.interval);
            if (currentOption) {
                setInterval(currentOption);
            }

            setMessage(info.configuration.auto_notification.message)
            setEnabled(info.configuration.auto_notification.enabled)
            setLimitOff(info.configuration.auto_notification.limit_off)
            setAutoNotification(info.configuration.auto_notification)
            if (info.configuration.auto_notification.blacklist_phones) {
                setBlackListPhones(info.configuration.auto_notification.blacklist_phones);
            }
            if (info.configuration.auto_notification.bonus_amount) {
                setBonusAmount(info.configuration.auto_notification.bonus_amount)
            }
            if (info.configuration.auto_notification.bonus_to_all) {
                setBonusToAll(info.configuration.auto_notification.bonus_to_all)
            }
            if (info.configuration.auto_notification.send_bonus_amount_in_message) {
                setSendBonusAmountInMessage(info.configuration.auto_notification.send_bonus_amount_in_message)
            }

            setState({
                interval: info.configuration.auto_notification.interval,
                enabled: info.configuration.auto_notification.enabled,
                message: info.configuration.auto_notification.message,
            });
        }
    }, [info])

    const onChangeBonusAmount = (e) => {
        if (isFinite(e.target.value)) {
            setBonusAmount(+e.target.value)
        }
    }

    useEffect(() => {
        setState({
            interval: interval.value
        });
    }, [interval])

    const onSubmit = () => {
        if (state && info) {

            if (!info.configuration?.auto_notification.limit_off && limitOff) {
                store.addNotification({
                    title: t('warning'),
                    message: t('NSNotifications.attention'),
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
            }

            setPending(true);
            new Promise((resolve, reject) => {
                dispatch(branchActions.changeAutoMailing(info.id, {
                    interval: state.interval,
                    message: message,
                    bonus_to_all: bonus_to_all,
                    bonus_amount: bonus_amount,
                    send_bonus_amount_in_message,
                    blacklist_phones: blackListPhones,
                    limit_off: limitOff,
                    enabled,
                }, resolve, reject));
            }).finally(() => {
                setPending(false);
            })
        }
    }

    const onFindClient = async () => {
        if (!phone || lookingClient) return

        try {
            setSetLookingClient(true);
            const client = await instance().get(`/promoters/find?phone=${clearMask(phone)}`).then(res => res.data);
            if (client && autoNotification) {
                setPending(true);
                new Promise((resolve, reject) => {
                    dispatch(branchActions.changeAutoMailing(info.id, {
                        ...autoNotification,
                        blacklist_phones: [...blackListPhones, "+" + clearMask(phone)],
                    }, resolve, reject));
                    setBlackListPhones([...blackListPhones, "+" + clearMask(phone)]);
                    setAutoNotification({
                        ...autoNotification,
                        blacklist_phones: [...blackListPhones, "+" + clearMask(phone)]
                    });
                }).finally(() => {
                    setPending(false);
                })
            }
        } catch (e) {
            store.addNotification({
                title: t('error'),
                message: t('Notify.notReg'),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        } finally {
            setSetLookingClient(false);
        }
    }

    const onChangePhone = (e) => {
        setPhone(MaskService.toMask('custom', e.target.value, {
            mask: '7 (999) 999-99-99'
        }));
    }

    const onDeleteUser = (ph) => {
        const newBlackListPhones = blackListPhones.filter((p) => p !== ph);
        setPending(true);
        new Promise((resolve, reject) => {
            dispatch(branchActions.changeAutoMailing(info.id, {
                ...autoNotification,
                blacklist_phones: newBlackListPhones,
            }, resolve, reject));
            setBlackListPhones(newBlackListPhones);
            setAutoNotification({
                ...autoNotification,
                blacklist_phones: newBlackListPhones
            });
        }).finally(() => {
            setPending(false);
        })
    }

    return (
        <>
            <PageHeader title={t('autocast')}>
                <p>{t('NSBonuses.sending')}</p>
            </PageHeader>
            <div className={styles.container}>
                <div className={styles.block}>
                    <div className={styles['block__title']}>{t('NSMailing.number')} <span>*</span></div>
                    <CustomSelect
                      options={options}
                      value={interval}
                      onSelect={(o) => setInterval(o)}
                    />
                </div>
                <div className={styles.p} style={{position: 'relative'}}>
                    {t('NSMailing.automail')} <i style={{color: 'tomato'}}>*</i>
                    <div style={{position: 'absolute', left: '168px', top: '0'}}>
                        <QuestionTooltip text={t('NSMailing.info')}/>
                    </div>
                </div>
                <textarea
                  placeholder={t('NSMailing.text')}
                  value={message}
                  onChange={(e) => e.target.value.length <= 625 && setMessage(e.target.value)}
                  required
                />
                <div style={{color: `${message.length >= 624 ? 'tomato' : '#000000'}`, marginBottom: 24}}>
                    {t('maxSym', {sym: 625})}
                </div>
                <TextField
                  label={t('NSMailing.bonus_amount')}
                  placeholder="8"
                  width="105px"
                  value={bonus_amount}
                  onChange={onChangeBonusAmount}
                />
                <div style={{margin: '24px'}}></div>
                <div className={styles.block}>
                    <Checkbox
                      label={t('NSMailing.bonus_to_all')}
                      name="bonus_to_all"
                      checked={bonus_to_all}
                      onChange={() => setBonusToAll(!bonus_to_all)}
                    />
                    <div style={{margin: '24px'}}></div>
                    <Checkbox
                      label={t('NSMailing.send_bonus_amount_in_message')}
                      name="send_bonus_amount_in_message"
                      checked={send_bonus_amount_in_message}
                      onChange={() => setSendBonusAmountInMessage(!send_bonus_amount_in_message)}
                    />
                    <div style={{margin: '24px'}}></div>
                    <Checkbox
                      label={t('activation')}
                      name="enabled"
                      checked={enabled}
                      onChange={() => setEnabled(!enabled)}
                    />
                    <div style={{margin: '24px'}}></div>
                    <Checkbox
                      label={t('NSNotifications.notificationLimit')}
                      name="enabled"
                      checked={limitOff}
                      onChange={() => setLimitOff(!limitOff)}
                    />
                </div>
                <div style={{display: 'flex'}}>
                    <div style={{marginRight: '18px'}}>
                        <ButtonFilled disabled={!state || error} loading={pending} onClick={onSubmit}>
                            {t('save')}
                        </ButtonFilled>
                    </div>
                </div>
            </div>

            <div className={styles.clients}>
                <div className={styles.secondBlock}>
                    <TextField
                      label={t('NSCorporative.phone')}
                      placeholder="7 (707) 777-77-77"
                      width="224px"
                      value={phone}
                      onKeyUp={(e) => e.code === 'Enter' && onFindClient()}
                      onChange={onChangePhone}
                    />
                    <ButtonFilled disabled={pending} loading={lookingClient} className={styles['secondBlock__btn']} onClick={onFindClient}>{t('add')}</ButtonFilled>
                    <p className={styles['block__text']}>{t('NSMailing.assessed')}</p>
                </div>
                {blackListPhones.length ? (
                  <table className="table">
                      <thead>
                      <tr>
                          <th>№</th>
                          <th>{t('NSCorporative.phone')}</th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                          !!blackListPhones.length ? blackListPhones.map((c, index) => (
                              <tr key={c}>
                                  <td>{index + 1}</td>
                                  <td>{c}</td>
                                  <td style={{  paddingRight: '28px' }}>
                                      <button style={{ marginLeft: 'auto' }} className="btn-trash" type="button" onClick={() => onDeleteUser(c)} disabled={lookingClient || pending}>
                                          <i>
                                              <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M11.4219 1.875H8.84375V1.40625C8.84375 0.63085 8.2129 0 7.4375 0H5.5625C4.7871 0 4.15625 0.63085 4.15625 1.40625V1.875H1.57812C0.931953 1.875 0.40625 2.4007 0.40625 3.04688V4.6875C0.40625 4.94637 0.616133 5.15625 0.875 5.15625H1.13117L1.53614 13.6606C1.57191 14.4117 2.18891 15 2.94078 15H10.0592C10.8111 15 11.4281 14.4117 11.4639 13.6606L11.8688 5.15625H12.125C12.3839 5.15625 12.5938 4.94637 12.5938 4.6875V3.04688C12.5938 2.4007 12.068 1.875 11.4219 1.875ZM5.09375 1.40625C5.09375 1.14779 5.30404 0.9375 5.5625 0.9375H7.4375C7.69596 0.9375 7.90625 1.14779 7.90625 1.40625V1.875H5.09375V1.40625ZM1.34375 3.04688C1.34375 2.91765 1.4489 2.8125 1.57812 2.8125H11.4219C11.5511 2.8125 11.6562 2.91765 11.6562 3.04688V4.21875C11.5118 4.21875 1.94237 4.21875 1.34375 4.21875V3.04688ZM10.5274 13.616C10.5155 13.8664 10.3098 14.0625 10.0592 14.0625H2.94078C2.69015 14.0625 2.48448 13.8664 2.47259 13.616L2.06973 5.15625H10.9303L10.5274 13.616Z" fill="white"/>
                                                  <path d="M6.5 13.125C6.75887 13.125 6.96875 12.9151 6.96875 12.6562V6.5625C6.96875 6.30363 6.75887 6.09375 6.5 6.09375C6.24113 6.09375 6.03125 6.30363 6.03125 6.5625V12.6562C6.03125 12.9151 6.2411 13.125 6.5 13.125Z" fill="white"/>
                                                  <path d="M8.84375 13.125C9.10262 13.125 9.3125 12.9151 9.3125 12.6562V6.5625C9.3125 6.30363 9.10262 6.09375 8.84375 6.09375C8.58488 6.09375 8.375 6.30363 8.375 6.5625V12.6562C8.375 12.9151 8.58485 13.125 8.84375 13.125Z" fill="white"/>
                                                  <path d="M4.15625 13.125C4.41512 13.125 4.625 12.9151 4.625 12.6562V6.5625C4.625 6.30363 4.41512 6.09375 4.15625 6.09375C3.89738 6.09375 3.6875 6.30363 3.6875 6.5625V12.6562C3.6875 12.9151 3.89735 13.125 4.15625 13.125Z" fill="white"/>
                                              </svg>
                                          </i>
                                          <span>{t('delete')}</span>
                                      </button>
                                  </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                          )
                      }
                      </tbody>
                  </table>
                ) : null}
                {/*<InfiniteScroll*/}
                {/*  loadMore={hasMoreReviews ? onLoadMoreReviews : () => {}}*/}
                {/*  hasMore={hasMoreReviews}*/}
                {/*>*/}
                {/*    */}
                {/*</InfiniteScroll>*/}
            </div>

            <Modal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
