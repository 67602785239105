import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';
import Checkbox from '../../components/FormElements/Checkbox'
import ButtonOutlined from '../../components/FormElements/ButtonOutlined'
import ButtonFilled from "../../components/FormElements/ButtonFilled";
import clientsActions from "../../store/clientsStore/clientsActions";
import { PENDING } from "../../store/globalStatuses/globalStatuses";
import CustomPagination from "../../components/CustomPagination";
import Loader from "../../components/Loader";
import { useCurrentBranch } from "../../hooks/useCurrentBranch";
import * as queryString from "querystring";
import styles from './Clients.module.scss';
import { Roles } from "../../config";
import Invis from "../../components/Filter/Invis";
import TextField from "../../components/FormElements/TextField";
import findImg from "../../assets/icons/find.svg";
import QuestionTooltip from "../../components/QuestionTooltip";
import SendNotificationModal from "./components/SendNotificationModal";
import {convertDateToShortString} from "../../tools/convertDateToString";

const cancelSvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="17.1758" y="5.32983" width="1.67514" height="16.7514" rx="0.837569" transform="rotate(45 17.1758 5.32983)" fill="#EB5757"/>
        <rect x="18.3613" y="17.175" width="1.67514" height="16.7514" rx="0.837569" transform="rotate(135 18.3613 17.175)" fill="#EB5757"/>
    </svg>
);

const arrows = (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.4 13C4.4 13.3314 4.66863 13.6 5 13.6C5.33137 13.6 5.6 13.3314 5.6 13L4.4 13ZM5.42426 0.575736C5.18995 0.341421 4.81005 0.341421 4.57574 0.575736L0.757359 4.39411C0.523045 4.62843 0.523045 5.00833 0.757359 5.24264C0.991674 5.47696 1.37157 5.47696 1.60589 5.24264L5 1.84853L8.39411 5.24264C8.62843 5.47696 9.00833 5.47696 9.24264 5.24264C9.47696 5.00833 9.47696 4.62843 9.24264 4.39411L5.42426 0.575736ZM5.6 13L5.6 1L4.4 1L4.4 13L5.6 13Z" fill="black"/>
        <path d="M11.4 1C11.4 0.668629 11.6686 0.4 12 0.4C12.3314 0.4 12.6 0.668629 12.6 1L11.4 1ZM12.4243 13.4243C12.19 13.6586 11.8101 13.6586 11.5757 13.4243L7.75736 9.60589C7.52304 9.37157 7.52304 8.99167 7.75736 8.75736C7.99167 8.52304 8.37157 8.52304 8.60589 8.75736L12 12.1515L15.3941 8.75736C15.6284 8.52304 16.0083 8.52304 16.2426 8.75736C16.477 8.99167 16.477 9.37157 16.2426 9.60589L12.4243 13.4243ZM12.6 1L12.6 13L11.4 13L11.4 1L12.6 1Z" fill="black"/>
    </svg>
);

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        padding: 0,
        borderRadius: 24,
    }
};

const oneDayInMS = 60*60*60*24

const Clients = () => {
    const { t } = useTranslation();
    const { currentRole } = useCurrentBranch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { info, pending } = useSelector(s => s.branchReducer);
    const {
        clients,
        chosenClients,
        count,
        params,
        status,
    } = useSelector(s => s.clientsReducer);
    const location = useLocation();
    const queryParams = queryString.parse(location.search.slice(1));

    const [activeChoice, setActiveChoice] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const invisParams = useSelector(state => state.filtersReducer)
    const searchRef = useRef(null);


    const debounced = _.debounce(() => {
        dispatch(clientsActions.getClientsDatabase(info.id, {
            ...params,
            branch_ids: params.branch_ids.length ? params.branch_ids.map((b) => b.value) : null,
            city_ids: params.city_ids.length ? params.city_ids.map((c) => c.value) : null,
            currentRole,
            groupId: info.group_id,
            interval: params.interval || null,
        }, searchRef));
    }, 1500);

    useEffect(() => {
        if (info && info.id) {
            if (params.query) {
                debounced();
            } else {
                dispatch(clientsActions.getClientsDatabase(info.id, {
                    ...params,
                    branch_ids: params.branch_ids.length ? params.branch_ids.map((b) => b.value) : null,
                    city_ids: params.city_ids.length ? params.city_ids.map((c) => c.value) : null,
                    currentRole,
                    groupId: info.group_id,
                }))
            }

            let paramsForQuery = {}
            for (let key in params) {
                if (params[key] || params[key]?.length || key === 'skip' || !Array.isArray(params[key])) {
                    paramsForQuery[key] = params[key]
                }
                if (params.branch_ids.length) {
                    paramsForQuery['branch_ids'] = params.branch_ids.map((b) => b.value || b);
                }
                if (params.city_ids.length) {
                    paramsForQuery['city_ids'] = params.city_ids.map((c) => c.value || c);
                }
            }
            history.push({
                search: `?${queryString.stringify(paramsForQuery)}`
            })
        }
        return () => debounced.cancel();
    }, [params, info]);

    useEffect(() => {
        const allParams = { ...params, ...queryParams }

        let paramsForQuery = {}
        if (allParams) {
            for (let key in allParams) {
                if (allParams[key] || allParams[key]?.length || key === 'skip' || !Array.isArray(allParams[key])) {
                    paramsForQuery[key] = allParams[key]
                }

                if (typeof allParams.branch_ids !== 'string' && allParams.branch_ids.length) {
                    paramsForQuery['branch_ids'] = allParams.branch_ids.map((b) => b.value || b);
                } else if (typeof allParams.branch_ids === 'string' && allParams.branch_ids.length) {
                    paramsForQuery['branch_ids'] = [allParams.branch_ids]
                }

                if (typeof allParams.city_ids !== 'string' && allParams.city_ids.length) {
                    paramsForQuery['city_ids'] = allParams.city_ids.map((c) => c.value || c);
                } else if (typeof allParams.city_ids === 'string' && allParams.city_ids.length) {
                    paramsForQuery['city_ids'] = [allParams.city_ids]
                }
            }
        }
        history.push({
            pathname: '/clients-database',
            search: `?${queryString.stringify(paramsForQuery)}`
        });

        return () => {
            dispatch(clientsActions.clearClients())
        }
    }, []);

    const onSkip = (quantityOfSkip, cb) => {
        dispatch(clientsActions.changeParams({
            skip: quantityOfSkip,
        }))
        cb();
    }

    const onChooseClient  = (customer) => (e) => {
        dispatch(clientsActions.changeChosenClients(customer));
    }

    const onDisableActiveChoice = () => {
        setActiveChoice(prev => !prev);
        dispatch(clientsActions.clearChosenClients());
    }

    const renderTable = () => {
        return (
            <table className="table">
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>{t('client')}</th>
                    <th>Рассылка</th>
                    <th>{t('NSAuth.phone')}</th>
                    <th>{t('bonuses')}</th>
                </tr>
                </thead>
                <tbody>
                {clients.map((customer, index) => (
                    <tr
                      onClick={(e) => history.push(`/clients/client/${customer.id}?groupId=${info.group_id}`)}
                      key={customer.id}
                    >
                        <td
                          onClick={(e) => e.stopPropagation()}
                        >
                            {activeChoice && (
                                <Checkbox
                                  checkedType="square"
                                  checked={customer.checked}
                                  onChange={onChooseClient(customer)}
                                />
                            )}
                        </td>
                        <td >{status !== PENDING && params.page === 1 ? index + 1 : (params.page - 1) * 10 + index + 1}</td>
                        <td >
                            {customer.first_name.length && customer.last_name.length ? `${customer.first_name} ${customer.last_name}` : (
                                customer.first_name.length ? customer.first_name : (customer.last_name.length ? customer.last_name : '-')
                            )}</td>
                        <td >
                            {customer.last_uploaded_notification ? convertDateToShortString(customer.last_uploaded_notification) : '-'}
                        </td>
                        <td >{customer.phone.length ? customer.phone : '-'}</td>
                        <td >{(customer.bonuses && Math.round(customer.bonuses)) || 0}</td>
                    </tr>
                ))}
                {!clients.length && (
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                )}
                </tbody>
            </table>
        )
    }

    return (
      <div className={styles.container}>
          <div className={styles.header}>
              <div className={styles.headerTop}>
                  <div>
                      <TextField
                        ref={searchRef}
                        icon={findImg}
                        placeholder={t('NSClients.search', {phone: 7077087778})}
                        value={params.query}
                        disabled={status === PENDING}
                        onChange={(e) => {
                            dispatch(clientsActions.changeParams({
                                query: e.target.value,
                                page: 1,
                                skip: 0,
                            }))
                        }}
                        width="500px"
                      />
                  </div>
                  {chosenClients.length ? (
                    <ButtonFilled style={{flexShrink: 0}} onClick={() => setSendModal(true)}>
                        {t('NSStatistics.notification')}
                    </ButtonFilled>
                  ) : (
                    <ButtonFilled style={{flexShrink: 0}} to="/clients-database/send-notification">
                        {t('NSStatistics.notification')}
                    </ButtonFilled>
                  )}
              </div>

              {currentRole === Roles.ANALYTIC ? (
                <div style={{marginLeft: 'auto', width: '100%', padding: '24px 10px 0 0px'}}>
                    <Invis
                      loading={status === PENDING}
                      changeCb={(payload) => {
                          dispatch(clientsActions.changeParams({
                              ...invisParams,
                              ...payload,
                              page: 1,
                              skip: 0,
                          }))
                      }}
                    />
                </div>
              ) : null}

              {currentRole === Roles.ANALYTIC ? (
                <div className={styles.headerTop}>
                    <div>
                        {activeChoice ? (
                          <div style={{marginBottom: '-22px'}}>
                              <ButtonOutlined
                                prefix={cancelSvg}
                                color="red"
                                disabled={status === PENDING}
                                onClick={onDisableActiveChoice}
                              >
                            <span style={{
                                fontSize: '18px',
                                transform: 'translate(-9px, -2px)',
                                display: 'block'
                            }}>{t('cancel')}</span>
                              </ButtonOutlined>
                          </div>
                        ) : (
                          <Checkbox label={t('NSPurchase.select')} color="#73D389"
                                    onChange={() => setActiveChoice(prev => !prev)}
                          />
                        )}
                    </div>

                    <div>
                        <div style={{maxWidth: 300, marginLeft: 'auto', marginTop: 25}}>
                            <TextField
                              value={params.interval ? params.interval / oneDayInMS : ''}
                              onChange={(e) => {
                                  if (isFinite(e.target.value)) {
                                      if (+e.target.value === 0) {
                                          return dispatch(clientsActions.changeParams({
                                              interval: null,
                                          }))
                                      }
                                      if (+e.target.value > 365) return
                                      dispatch(clientsActions.changeParams({
                                          interval: +e.target.value * oneDayInMS,
                                      }))
                                  }
                              }}
                              min="1"
                              max="365"
                              label={<div style={{display: 'flex', gap: '4px'}}>Кол-во дней <QuestionTooltip
                                text="Кол-во дней в течении которого вернулись клиенты после рассылки"/></div>}
                            />
                        </div>
                    </div>
                </div>
              ) : null}
          </div>
          {!!count && <p style={{textAlign: 'right'}}>Общее количество: {count}</p>}
          <div style={{position: 'relative'}}>
              {(status === PENDING || pending) && (
                <div className={styles.loader}>
                    <Loader color="#000" style={{zIndex: 100, opacity: 1}}/>
                </div>
              )}
              {renderTable()}
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                  <CustomPagination
                    count={count}
                    limit={params.limit}
                    max={4}
                    loading={status === PENDING}
                    updated={false}
                    page={params.page}
                    setPage={(v) => {
                        dispatch(clientsActions.changeParams({
                            page: v,
                        }))
                    }}
                    onSkip={onSkip}
                  />
              </div>
          </div>

          <SendNotificationModal
            ariaHideApp={false}
            isOpen={sendModal}
            style={modalStyles}
            onRequestClose={() => setSendModal(false)}
          />
      </div>
    )
}

export default Clients;
