import profileTypes from "./profileTypes";
import i18n from '../../i18n';

const initialState = {
    pending: false,
    error: '',
    info: null
}

const profileReducer = (state = initialState, action) => {

    switch (action.type) {
        case profileTypes.GET_PROFILE_PENDING:
            return {
                ...state,
                pending: true
            }
        case profileTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case profileTypes.GET_PROFILE_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorProfile')
            }


        default: return state
    }
}

export default profileReducer