import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import StarRatings from 'react-star-ratings';
import { getBranchReviewStats } from '../../api';
import styles from './rate.module.scss';

const defaultState = {
  "1": 0,
  "2": 0,
  "3": 0,
  "4": 0,
  "5": 0,
  "count": 0,
  "rating": 0
};

export default function Rate({ info }) {
  const { t } = useTranslation();
  const [stats, setStats] = useState(defaultState);
  const mountedRef = useRef(true);

  useEffect(() => {
    setStats(defaultState);

    if (info) {
      getBranchReviewStats(info.id)
        .then(res => {
          mountedRef.current && setStats(res.data);
        })
        .catch(e => {})
    }

    return () => {
      mountedRef.current = false;
    }
  }, [info])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.rateNumber}>{stats.rating}</div>
        <div style={{ margin: '0 9px 0 13px' }}>
          <StarRatings
            rating={stats.rating}
            numberOfStars={5}
            starRatedColor="#F5B35C"
            starEmptyColor="#E7E7E6"
            name="rating"
            starSpacing="2px"
            starDimension="36px"
          />
        </div>
        <div className={styles.reviewsCount}>({stats.count} {t('reviews')})</div>
      </div>
      <div className={styles.body}>
      <div className={styles.review}>
        <div className={styles.mark}>
          <span>5,0</span>
          &nbsp;
          <span>({stats['5']})</span>
        </div>
        <div className={styles.line}>
          <div style={{ width: stats.count ? `${Math.round(stats['5'] / stats.count * 100)}%` : '0' }}></div>
        </div>
      </div>
      <div className={styles.review}>
        <div className={styles.mark}>
          <span>4,0</span>
          &nbsp;
          <span>({stats['4']})</span>
        </div>
        <div className={styles.line}>
          <div style={{ width: stats.count ? `${Math.round(stats['4'] / stats.count * 100)}%` : '0' }}></div>
        </div>
      </div>
      <div className={styles.review}>
        <div className={styles.mark}>
          <span>3,0</span>
          &nbsp;
          <span>({stats['3']})</span>
        </div>
        <div className={styles.line}>
          <div style={{ width: stats.count ? `${Math.round(stats['3'] / stats.count * 100)}%` : '0' }}></div>
        </div>
      </div>
        <div className={styles.review}>
          <div className={styles.mark}>
            <span>2,0</span>
            &nbsp;
            <span>({stats['2']})</span>
          </div>
          <div className={styles.line}>
            <div style={{ width: stats.count ? `${Math.round(stats['2'] / stats.count * 100)}%` : 0 }}></div>
          </div>
        </div>
        <div className={styles.review}>
          <div className={styles.mark}>
            <span>1,0</span>
            &nbsp;
            <span>({stats['1']})</span>
          </div>
          <div className={styles.line}>
            <div style={{ width: stats.count ? `${Math.round(stats['1'] / stats.count * 100)}%` : 0 }}></div>
          </div>
        </div>
    </div>
    </div>
  )
}