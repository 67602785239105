import { useState, useEffect } from 'react';
import { answerToReview, editAnswerCommentToReview, getBranchReviews } from '../api';
import { useSelector } from 'react-redux';

export const useReviews = (t, i18n) => {
  const info = useSelector(s => s.branchReducer.info);

  const [pending, setPending] = useState();
  const [pendingComment, setPendingComment] = useState();
  const [hasMoreReviews, setHasMoreReviews] = useState(false);
  const [reviews, setReviews] = useState([]);

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    let load = true;
    let id = reviews.length >= 20 ? reviews[reviews.length - 1].id : '';

    if (!!info) {
      getBranchReviews(info.id, id, date.startDate, date.endDate)
        .then((res) => {
          if (res.data.length) {
            if (load) {
              setReviews([...reviews, ...res.data]);
            }
          } else {
            setReviews([]);
            setHasMoreReviews(false);
          }
        })
        .catch(e => {
          setHasMoreReviews(false);
          console.log(e)
        })
    }

    if (!hasMoreReviews) setHasMoreReviews(true);

    return () => {
      load = false;
    }
  }, [date, info])

  const onChangePeriod = (type) => {
    switch (type) {
      case 5:
        setDate({
          startDate: new Date(),
          endDate: new Date(),
        });
        break
      case 4:
        setDate({
          ...date,
          startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
        });
        break
      case 3:
        setDate({
          ...date,
          startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        });
        break
      case 2:
        setDate({
          ...date,
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        });
        break
      case 1:
        setDate({
          ...date,
          startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        });
        break
    }
  }

  const onLoadMoreReviews = () => {
    setPending(true);

    if (reviews.length >= 20) {
      getBranchReviews(info.id, reviews[reviews.length - 1].id, date.startDate, date.endDate)
        .then((res) => {
          if (res.data.length) {
            setReviews([...reviews, ...res.data]);
          } else {
            setHasMoreReviews(false);
          }
        })
        .catch(e => console.log(e))
    } else {
      getBranchReviews(info.id, '', date.startDate, date.endDate)
        .then((res) => {
          setReviews(res.data);
          setHasMoreReviews(false);
        })
        .catch(e => {
          setHasMoreReviews(false);
          console.log(e)
        })
    }
  }

  const onSetDate = (start) => (v) => {
    if (start) {
      setDate({ ...date, startDate: v });
    } else {
      setDate({ ...date, endDate: v });
    }
  }

  const onSuccessComment = (index) => (res) => {
    const newReviews = [...reviews];
    newReviews[index] = res.data;

    setReviews(newReviews);
    setPendingComment(false);
  }

  const onFailComment = (e) => {
    console.log(e);
    console.log(e.response);
    setPendingComment(false);
  }

  const onCommentToReview = (answer, reviewId, index, comment) => {
    setPendingComment(true);

    if (answer) {
      answerToReview(info.id, reviewId, {comment})
        .then(onSuccessComment(index))
        .catch(onFailComment)
    } else {
      editAnswerCommentToReview(info.id, reviewId, {comment})
        .then(onSuccessComment(index))
        .catch(onFailComment)
    }
  }

  return {
    info,
    reviews,
    hasMoreReviews,
    startDate: date.startDate,
    endDate: date.endDate,
    pending,
    onLoadMoreReviews,
    onChangePeriod,
    onSetDate: onSetDate,
    onCommentToReview,
    pendingComment,
  }
}