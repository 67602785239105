import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import branchActions from "../../../../store/branchStore/branchActions";
import Modal from "../../../../portals/Modal";
import PageHeader from "../../../../components/PageHeader";
import styles from './profileBonusHeaderWhatsapp.module.scss';

const initialState = {
    text: "",
    text_for_bonus: "",
}

export default function ProfileHeaderWhatsapp() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const info = useSelector((s) => s.branchReducer.info);
    const [pending, setPending] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setState(initialState);
        setEnabled(false);

        if (info && !info.configuration.whatsapp_header) {
            return setErrorModal(true);
        }

        if (info && !info.configuration.whatsapp_header.enabled) {
            setState({
                ...state,
                custom_message: info.configuration.whatsapp_header.custom_message || '',
                text: info.configuration.whatsapp_header.text,
            });
            return setErrorModal(true);
        }

        if (info && info.configuration.whatsapp_header) {
            setState({
                ...state,
                custom_message: info.configuration.whatsapp_header.custom_message || '',
                text: info.configuration.whatsapp_header.text,
            });
            setEnabled(true);
        }
    }, [info]);

    const onChangeText = (e) => {
        if (e.target.value.length > 350) return
        setState({ ...state, text: e.target.value })
    }

    const onChangeCustomerMessage = (e) => {
        if (e.target.value.length > 350) return
        setState({ ...state, custom_message: e.target.value })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        // if (state.amount <= 0) return setState({...state, errors: {...state.errors, amount: 'Заполните поле!'}});
        // if (!state.message.length) return setState({...state, errors: {...state.errors, message: 'Заполните поле!'}});
        // if (!state.message_for_week.length) return setState({...state, errors: {...state.errors, message_for_week: 'Заполните поле!'}});

        if (info) {
            setPending(true);
            new Promise((resolve, reject) => {
                const {errors, ...data} = state;
                dispatch(branchActions.changeWhatsAppHeader(info.id, { ...data, enabled }, resolve, reject));
            }).finally(() => {
                setPending(false);
                setState({ ...state });
            });
        }

    }

    const onDeactivate = (e) => {
        e.preventDefault();

        if (info) {
            setPending(true);
            new Promise((resolve, reject) => {
                const {errors, ...data} = state;
                dispatch(branchActions.updateBonusForBirthday(info.id, {
                    amount: 0,
                    message: "",
                    message_for_week: "",
                }, resolve, reject));
            })
              .then(() => {
                  dispatch(branchActions.getBranch(info.id));
              })
              .finally(() => {
                setPending(false);
                setState({...state, errors: initialState.errors});
            });
        }

    }

    return (
        <div className={styles.container}>
            <PageHeader title={t('whatsappHeader')}>
                {/*<p>{t('NSBonuses.congratulate')}</p>*/}
            </PageHeader>
            <form onSubmit={onSubmit}>
                <div className={styles.p} style={{position: 'relative'}}>
                    {t('symbols350Restriction')}
                    {/*<i style={{ color: 'tomato' }}>*</i>*/}
                    {/*<div style={{position: 'absolute', left: '126px', top: '0'}}>*/}
                    {/*    <QuestionTooltip text={t('NSBonuses.pushNotification')} />*/}
                    {/*</div>*/}
                </div>
                <textarea
                  placeholder={t('Form.requiredField')}
                  value={state.text}
                  onChange={onChangeText}
                  disabled={!enabled}
                  // required
                />

                <div style={{position: 'relative', marginBottom: '1rem'}}>
                    {t('textForBonusInfo')}
                </div>
                <textarea
                  placeholder={t('text')}
                  value={state.custom_message}
                  onChange={onChangeCustomerMessage}
                  disabled={!enabled}
                  // required
                />

                <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                    <ButtonFilled
                      type="submit"
                      loading={pending}
                      disabled={!enabled}
                    >{t('save')}</ButtonFilled>
                    {/*<ButtonFilled*/}
                    {/*  variant="red"*/}
                    {/*  loading={pending}*/}
                    {/*  disabled={!enabled}*/}
                    {/*  onClick={onDeactivate}*/}
                    {/*>{t('NSComparison.deactivate')}</ButtonFilled>*/}
                </div>
            </form>
            <Modal
              open={errorModal} size="small"
              onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                          onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
