import React, { useState, useEffect, useRef } from 'react';
import { computeSkipElements } from "../../tools/utils";
import ReactPaginate from 'react-paginate';
import styles from './pagination.module.css';

const leftArrow = (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 1L1 8L7.5 14.5" stroke="#1D1F23" strokeLinecap="round"/>
    </svg>
);

const rightArrow = (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 1L7 8L0.5 14.5" stroke="#1D1F23" strokeLinecap="round"/>
    </svg>
);

export default function Pagination({
    count,
    limit,
    max,
    loading,
    updated,
    onSkip,
    page,
    setPage
}) {
    const onPageChanged = (page_index) => () => {
        setPage(page_index);
    }

    const onChangePage = (page_index, index) => () => {
        if (page_index == page || loading) return

        if (isFinite(page_index)) {
            if (!computeSkipElements({page_index, limit})) return onSkip(0, onPageChanged(1));
            return onSkip(computeSkipElements({page_index: page_index, limit}), onPageChanged(page_index));
        }
        if (index < Math.ceil(currentPages.length / 2)) {
            return onSkip((page - 1 ? page - 1 : 1) * limit, onPageChanged((page - 1 ? page - 1 : 1)));
        }
        if (index > Math.floor(currentPages.length / 2)) {
            return onSkip((page + 1 >= pages.current.length ? pages.current.length : page) * limit, onPageChanged((page + 1 >= pages.current.length ? pages.current.length : page + 1)));
        }
    }

    return (
      <div className={loading ? styles.loadingContainer : ""}>
          <ReactPaginate
            breakLabel="..."
            nextLabel={rightArrow}
            onPageChange={({ selected }) => !loading && onChangePage(selected  + 1)()}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={Math.ceil(count / limit) <= 1 ? 0 : Math.ceil(count / limit) }
            previousLabel={leftArrow}
            renderOnZeroPageCount={null}
            activeClassName={styles.selected}
            className={styles.pagination}
            pageClassName={loading ? styles.loading : ''}
          />
      </div>
    )
}
