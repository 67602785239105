import './index.scss';
import 'react-notifications-component/dist/theme.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactNotification from 'react-notifications-component';
import store from "./store";
import App from './App';
import AuthPage from './pages/Auth';
import NotFound from './pages/NotFound';
import cookies from 'react-cookies';
import { COOKIES } from './helpers';

import './i18n';


if (cookies.load(COOKIES.REMEMBER) === 'false') {
  cookies.remove(COOKIES.TOKEN);
}

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback="">
            <Provider store={store}>
                <BrowserRouter>
                    <ReactNotification/>
                    <Switch>
                        <Route exact path={'/auth'} render={() => <AuthPage/>}/>
                        <Route exact path="/404" render={() => <NotFound />}/>
                        <Route path="/" render={() => <App/>}/>
                    </Switch>
                </BrowserRouter>
            </Provider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);


