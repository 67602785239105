import React from 'react'
import styles from './ComparisonLineChart.module.css'
import { Chart } from "react-google-charts";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import { useTranslation } from "react-i18next";

export default function ComparisonLineChart({
  type,
  data,
  changePeriod,
  title = 'Кол-во клиентов',
  tooltipMessage = '',
  showTypes = true,
  showDatePicker= true,
  colors = ['#4EB78B', '#5769EB', '#7FE0D8', '#FFDB57', '#6CAFFD'],
  dates,
  changeDate,
}) {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h2>{title}</h2>
              <div className={styles.periods}>
                {/*<p style={type === 1 ? {color: '#4EB78B', margin: 12} : {margin: 12}} onClick={() => changePeriod(1)}>День</p>*/}
                {showTypes && (
                  <>
                    <p style={type === 2 ? {color: '#4EB78B', margin: 12} : {margin: 12}} onClick={() => changePeriod(2)}>{t('week')}</p>
                    <p style={type === 3 ? {
                      margin: '0 12px 0 12px',
                      color: '#4EB78B'
                    } : {margin: '0 12px 0 12px'}} onClick={() => changePeriod(3)}>{t('month')}</p>
                    <p style={type === 4 ? { color: '#4EB78B', margin: '0 49px 0 12px' } : {margin: '0 12px 0 12px'}} onClick={() => changePeriod(4)}>{t('year')}</p>
                  </>
                )}
                {showDatePicker && (
                  <div>
                    <CustomDatePicker
                      trans
                      type={type}
                      tStartDate={dates.from}
                      tEndDate={dates.to}
                      tSetStartDate={(v) => changeDate(1, v)}
                      tSetEndDate={(v) => changeDate(2, v)}
                    />
                  </div>
                )}
              </div>

          {data && data.length > 1 && (
            <Chart
              chartType="LineChart"
              loader={<div style={{width: '38vw', height: 350}}/>}
              data={data}
              options={{
                width: 610,
                height: 350,
                smoothLine: true,
                curveType: 'function',
                colors,
                legend: {position: 'none'},
                chartArea: {'width': '90%', 'height': '80%'},
                lineWidth: 4,
                tooltip: {isHtml: true, trigger: "focus"},
                vAxis: {
                  viewWindowMode: 'explicit',
                  viewWindow: {
                    min: 0
                  },
                  format: '0'
                }
              }}
              legendToggle
            />
          )}
        </div>
    )
}