import {PENDING, ERROR, SUCCESS} from "../../globalStatuses/globalStatuses";
import RFMTypes from "./RFMTypes";

const initialState = {
    ALL_CUSTOMERS: [],
    status: '',

    TOP_CUSTOMERS: [],
    ACTIVE_CUSTOMERS: [],
    EMERGING_CUSTOMERS: [],
    UNSTEADY_CUSTOMERS: [],
    POTENTIAL_LOST_CUSTOMERS: [],
    INACTIVE_CUSTOMERS: [],
    LOST_CUSTOMERS: []
}


const RFMReducer = (state = initialState, action) => {
    switch (action.type) {

        case RFMTypes.GET_RFM_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case RFMTypes.GET_RFM_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
                ALL_CUSTOMERS: action.payload.ALL_CUSTOMERS,

                TOP_CUSTOMERS: action.payload.TOP_CUSTOMERS,
                ACTIVE_CUSTOMERS: action.payload.ACTIVE_CUSTOMERS,
                EMERGING_CUSTOMERS: action.payload.EMERGING_CUSTOMERS,
                UNSTEADY_CUSTOMERS: action.payload.UNSTEADY_CUSTOMERS,
                POTENTIAL_LOST_CUSTOMERS: action.payload.POTENTIAL_LOST_CUSTOMERS,
                INACTIVE_CUSTOMERS: action.payload.INACTIVE_CUSTOMERS,
                LOST_CUSTOMERS: action.payload.LOST_CUSTOMERS,
            }

        case RFMTypes.GET_RFM_ERROR:
            return {
                ...state,
                ALL_CUSTOMERS: [],
                TOP_CUSTOMERS: [],
                ACTIVE_CUSTOMERS: [],
                EMERGING_CUSTOMERS: [],
                UNSTEADY_CUSTOMERS: [],
                POTENTIAL_LOST_CUSTOMERS: [],
                INACTIVE_CUSTOMERS: [],
                LOST_CUSTOMERS: [],
                status: ERROR
            }

        case RFMTypes.SAVE_RFM_PENDING:
            return {
                ...state,
                status: PENDING
            }
        case RFMTypes.SAVE_RFM_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
            }
        case RFMTypes.SAVE_RFM_ERROR:
            return {
                ...state,
                status: ERROR
            }

        default:
            return {
                ...state
            }
    }
}

export default RFMReducer