import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import {convertDataToTime, convertDateToShortString} from "../../../tools/convertDateToString";
import TransactionInfoModal from "../../TransactionInfoModal";
import styles from './DataTableTransactions.module.css'
import { useLocation } from "react-router-dom";
import transactionsApi from "../../../store/transactionsStore/transactionsApi";
import { useSelector } from "react-redux";

export default function DataTableTransactions(props) {
    const [transactionModal, setTransactionModal] = useState(false);
    const [transaction, setTransaction] = useState(null);
    const [pendingTransaction, setPendingTransaction] = useState(false);
    const { t } = useTranslation();
    const {info} = useSelector(state => state.branchReducer)
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get("transactionId") && info?.id) {
            setPendingTransaction(true);
            transactionsApi.getTransactionById(info.id, searchParams.get("transactionId"))
              .then((order) => {
                  setTransaction(order.data);
                  setTransactionModal(true);
              })
              .catch(() => {
                  history.push("/");
              })
              .finally(() => setPendingTransaction(false))
        }
    }, [info]);

    const {orders, page, changePageAction, count, getTransactions} = props

    const pages = []

    for (let i = 1; i <= Math.ceil(count / 10); i++) {
        pages.push(i)
    }

    const onChangePageByNumbers = (page) => {
        changePageAction(page)
    }

    const onChangePageForward = () => {
        if (page === pages.length) {
            changePageAction(1)
        } else {
            changePageAction(page + 1)
        }
    }

    const onChangePageToBack = () => {
        if (page === 1) {
            changePageAction(pages.length)
        } else {
            changePageAction(page - 1)
        }
    }

    const showTransaction = (t) => {
        setTransaction(t);
        setTransactionModal(true);
    }

    const renderStatus = (order) => {
        if (
          (order.initial_order_total_amount !== 0) && order.status === 'COMPLETED'
        ) {
            return <span className={styles.status} style={{background: '#24CC64', padding: "8px 22px"}}>{t("returned")}</span>
        }
        if ((order.initial_order_total_amount == undefined || order.initial_order_total_amount == 0) && order.status === 'COMPLETED') {
            return <span className={styles.status} style={{background: '#24CC64', padding: "8px 22px"}}>{t("received")}</span>
        }
        if (order.status === 'DECLINED') {
            return <span className={styles.status} style={{background: '#FA6262', padding: "8px 19px"}}>{t('refused')}</span>
        }
        return <span className={styles.status} style={{background: '#6979F8', padding: "8px 12px"}}>{t('NSTransactions.processing')}</span>
    }

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                <tr>
                    <th>{t('date')}</th>
                    {/*<th>{t('NSTransactions.transaction')}</th>*/}
                    <th>{t('client')}</th>
                    <th>{t('NSTransactions.number')}</th>
                    {/*<th>{t('paymentMethod')}</th>*/}
                    {/*<th>{t('sum')}</th>*/}
                    {/*<th>{t('used')}</th>*/}
                    <th>{t('bonus')}</th>
                    {/*<th>{t('status')}</th>*/}
                </tr>
                </thead>
                <tbody>
                {orders && orders.length ? orders.map((order, i) => (
                    <tr key={i} onClick={() => pendingTransaction ? null : showTransaction(order)}>
                        <td>{convertDateToShortString(order.created_at)}<p>{convertDataToTime(order.created_at)}</p>
                        </td>
                        {/*<td>{order.order_number}</td>*/}
                        <td>
                            {order.sender.first_name.length ? order.sender.first_name : ''} &nbsp;
                            {order.sender.last_name.length ? order.sender.last_name : ''} &nbsp;
                            {!order.sender.first_name.length && !order.sender.last_name.length && '-'}
                        </td>
                        <td>{order.sender.phone.length ? order.sender.phone : '-'}</td>
                        {/*<td>{order.payment_method && order.payment_method !== 'UNKNOWN'  ? order.payment_method : (order.type === 'BONUS' || order.type === 'BIRTHDAY_BONUS' ? order.type : t('NSComparison.notPoint'))}</td>*/}
                        {/*<td>{order.order_total_amount}</td>*/}
                        {/*<td>{order.paid_from_private_balance}</td>*/}
                        <td>{order.order_cashback_amount}</td>
                        {/*<td>*/}
                        {/*    /!*{*!/*/}
                        {/*    /!*    order.status === "COMPLETED" ?*!/*/}
                        {/*    /!*        <span className={styles.status}*!/*/}
                        {/*    /!*              style={{background: '#24CC64', padding: "8px 22px"}}>{t('received')}</span>*!/*/}
                        {/*    /!*        :*!/*/}
                        {/*    /!*        (*!/*/}
                        {/*    /!*            order.status === "DECLINED" ?*!/*/}
                        {/*    /!*                <span className={styles.status}*!/*/}
                        {/*    /!*                      style={{background: '#FA6262', padding: "8px 19px"}}>{t('refused')}</span>*!/*/}
                        {/*    /!*                : <span className={styles.status}*!/*/}
                        {/*    /!*                        style={{background: '#6979F8', padding: "8px 12px"}}>{t('NSTransactions.processing')}</span>*!/*/}
                        {/*    /!*        )*!/*/}

                        {/*    /!*}*!/*/}
                        {/*    {renderStatus(order)}*/}
                        {/*</td>*/}
                    </tr>
                )) : (
                    <tr style={{width: '100%'}}>
                        <td>
                            <span style={{padding: "8px 0"}}>-</span>
                        </td>
                        {/*<td>-</td>*/}
                        {/*<td>-</td>*/}
                        {/*<td>-</td>*/}
                        {/*<td>-</td>*/}
                        {/*<td>-</td>*/}
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                )}

                </tbody>


            </table>
            {pages.length > 1 &&
            <div className={styles.pagination}>
                <i className="fa fa-chevron-left" aria-hidden="true" onClick={onChangePageToBack}/>
                <div className={styles.paginationNumbers}>
                    {
                        page > 5 &&
                        <>
                            <p onClick={() => onChangePageByNumbers(1)}>1</p>
                            <p>...</p>
                        </>
                    }
                    {pages.map((number, index) => (page + 6 > number && page - 5 < number && (number < pages[pages.length - 1] || page == pages[pages.length - 1] || page == pages[pages.length - 2])) && (
                        <p
                          key={index}
                          style={page === number ? {background: "#E7E7E6"} : {}}
                          onClick={() => onChangePageByNumbers(number)}
                        >
                            {number}
                        </p>
                      )
                    )}
                    {page !== pages.length && pages.length - 1 && pages.length > 6 && page !== pages[pages.length - 2] &&
                    <>
                        <p>...</p>
                        <p onClick={() => onChangePageByNumbers(pages.length)}>{pages.length}</p>
                    </>

                    }
                </div>
                <i className="fa fa-chevron-right" aria-hidden="true" onClick={onChangePageForward}/>
            </div>
            }
            <TransactionInfoModal
                isOpen={transactionModal}
                setModal={setTransactionModal}
                transaction={transaction}
                getTransactions={getTransactions}
                setTransaction={setTransaction}
            />
        </div>
    )
}
