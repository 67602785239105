import bonusesModalTypes from "./bonusesModalTypes";
import {PENDING, SUCCESS, ERROR} from "../globalStatuses/globalStatuses";

const initialState = {
    amount_sum: '',
    stamp_count: '',
    bonuses: '',
    client_cashback: '',
    comment: '',

    add_stamp: false,
    give_award: false,

    isLoadingPatchOrdersRefund: false,
    give_award_status: '',
    client_cashback_status: '',
    payment_status: '',
}

const bonusesModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case bonusesModalTypes.BM_RESET_STATE:
            return initialState

        case bonusesModalTypes.BM_AMOUNT_ON_CHANGE:
            return {
                ...state,
                amount_sum: action.payload
            }

        case bonusesModalTypes.BM_STAMP_COUNT_ON_CHANGE:
            return {
                ...state,
                stamp_count: action.payload
            }

        case bonusesModalTypes.BM_BONUSES_ON_CHANGE:
            return {
                ...state,
                bonuses: action.payload
            }

        case bonusesModalTypes.BM_COMMENT_ON_CHANGE:
            return {
                ...state,
                comment: action.payload
            }

        case bonusesModalTypes.BM_STAMP_ON_CHANGE:
            return {
                ...state,
                add_stamp: action.payload
            }

        case bonusesModalTypes.BM_AWARD_ON_CHANGE:
            return {
                ...state,
                give_award: action.payload
            }


        case bonusesModalTypes.BM_GET_CLIENT_CASHBACK_PENDING:
            return {
                ...state,
                client_cashback_status: PENDING
            }

        case bonusesModalTypes.BM_GET_CLIENT_CASHBACK_SUCCESS:
            return {
                ...state,
                client_cashback: action.payload
            }

        case bonusesModalTypes.BM_GET_CLIENT_CASHBACK_ERROR:
            return {
                ...state,
                client_cashback_status: ERROR
            }


        case bonusesModalTypes.BM_CONFIRM_PAYMENT_PENDING:
            return {
                ...state,
                payment_status: PENDING
            }

        case bonusesModalTypes.BM_CONFIRM_PAYMENT_SUCCESS:
            return {
                ...state,
                payment_status: SUCCESS
            }

        case bonusesModalTypes.BM_CONFIRM_PAYMENT_ERROR:
            return {
                ...state,
                payment_status: ERROR
            }

        case bonusesModalTypes.BM_GIVE_AWARD_TO_USER_PENDING:
            return {
                ...state,
                give_award_status: PENDING
            }

        case bonusesModalTypes.BM_GIVE_AWARD_TO_USER_SUCCESS:
            return {
                ...state,
                give_award: true,
                add_stamp: false,
                give_award_status: SUCCESS
            }

        case bonusesModalTypes.BM_GIVE_AWARD_TO_USER_ERROR:
            return {
                ...state,
                give_award_status: ERROR
            }


        case bonusesModalTypes.PATCH_ORDERS_REFUND_PENDING:
            return {
                ...state,
                isLoadingPatchOrdersRefund: action.payload,
            }

        case bonusesModalTypes.PATCH_ORDERS_REFUND_SUCCESS:
            return {
                ...state,
                isLoadingPatchOrdersRefund: false,
            }

        case bonusesModalTypes.PATCH_ORDERS_REFUND_ERROR:
            return {
                ...state,
                isLoadingPatchOrdersRefund: false,
            }

        default:
            return {
                ...state
            }
    }
}

export default bonusesModalReducer