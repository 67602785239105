import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import { phoneMask, onlyDigits, COOKIES } from 'helpers.js';
import branchActions from '../../../store/branchStore/branchActions';
import { usePasswordTypeBtn } from "../../hooks/usePasswordTypeBtn";
import * as yup from "yup";
import profileApi from "../../../store/profileStore/profileApi";
import cookies from "react-cookies";
import { deleteAuth } from "../../../store/authState";

export const useChangePassword = (t) => {
  const dispatch = useDispatch();
  const { type, passwordTypeBtn } = usePasswordTypeBtn({show: t('show'), hide: t('hide')});
  const { type: type1, passwordTypeBtn: passwordTypeBtn1 } = usePasswordTypeBtn({show: t('show'), hide: t('hide')});
  const { type: type2, passwordTypeBtn: passwordTypeBtn2 } = usePasswordTypeBtn({show: t('show'), hide: t('hide')});
  const branch = useSelector(s => s.branchReducer);
  const [pending, setPending] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      repeat_password: ""
    },
    validationSchema: Yup.object({
      old_password: yup
        .string()
        .required(t("Form.fillField") || "")
        .min(6, t("Form.minSymbol", { min: 6 })),
      new_password: yup
        .string()
        .required(t("Form.fillField") || "")
        .min(6, t("Form.minSymbol", { min: 6 }))
        // .matches(
        //   REGULAR_EXPRESSIONS.PASSWORD,
        //   VALIDATION_ERRORS.NOT_VALID_PASSWORD
        // )
        .trim(),
      repeat_password: yup
        .string()
        .required(t("Form.fillField") || "")
        .oneOf(
          [yup.ref("new_password"), null],
          t("Form.passwordNotMatches") || ""
        ),
    }),
    onSubmit: ({ repeat_password, ...values }, { setSubmitting }) => {
      setPending(true);
      profileApi.changePassword(values)
        .then(() => {
          cookies.remove(COOKIES.TOKEN, {path: '/'});
          cookies.remove(COOKIES.REMEMBER, {path: '/'});
          dispatch(deleteAuth());
          history.push('/auth');
        })
        .catch((e) => {
          const res = e.response ? e.response.data : null;

          store.addNotification({
            title: `Error code: ${res ? res.code : 'NULL'}`,
            message: `${res ? res.message : t('Notify.notRespond')}`,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        })
        .finally(() => {
          setPending(false);
        })
    }
  });

  return {
    type,
    passwordTypeBtn,
    type1,
    passwordTypeBtn1,
    type2,
    passwordTypeBtn2,
    formik,
    pending,
  }
}