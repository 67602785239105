import audienceTypes from "./audienceTypes";
import audienceApi from "./audienceApi";
import React from "react";
import { getAgeData, getGenderData, getVisitorsData } from "../../../tools/analyticsTools";
import { Roles } from "../../../config";

const audienceActions = {
    changeDataPeriod: (period) => {
        return {
            type: audienceTypes.AA_CHANGE_DATA_PERIOD,
            payload: period
        }
    },

    getAudienceData: (branch_id, type, from, to, groupId, invisParams, currentRole) => async dispatch => {
        dispatch({
            type: audienceTypes.AA_GET_DATA_PENDING
        })

        let dates = '';

        if (from) {
            const fromDate = new Date(from);
            let month = (fromDate.getMonth() + 1) < 10 ? `0${fromDate.getMonth() + 1}` : fromDate.getMonth() + 1;
            dates += `&from=${new Date(from).getFullYear()}-${month}-${new Date(from).getDate()}`;
        }

        if (to) {
            const toDate = new Date(to);
            let month = (toDate.getMonth() + 1) < 10 ? `0${toDate.getMonth() + 1}` : toDate.getMonth() + 1;
            dates += `&to=${new Date(to).getFullYear()}-${month}-${new Date(to).getDate()}`;
        }

        if (currentRole === Roles.ANALYTIC && groupId) {
            await audienceApi.getAnalyticAudienceData(branch_id, type, dates, invisParams)
              .then((res) => {
                  let data = res.data
                  let ageData = getAgeData(data)
                  let {genders,genders_amount,genders_array} = getGenderData(data)
                  let audience_chart = getVisitorsData(data,type)
                  dispatch({
                      type: audienceTypes.AA_GET_DATA_SUCCESS,
                      payload: {
                          ageData,
                          genders,
                          genders_amount,
                          audience_chart,
                          genders_array
                      }
                  })
              })
              .catch(() => {
                  dispatch({
                      type: audienceTypes.AA_GET_DATA_ERROR
                  })
              })
        } else {
            await audienceApi.getAudienceData(branch_id, type, dates)
              .then((res) => {
                  let data = res.data
                  let ageData = getAgeData(data)
                  let {genders,genders_amount,genders_array} = getGenderData(data)
                  let audience_chart = getVisitorsData(data,type)
                  dispatch({
                      type: audienceTypes.AA_GET_DATA_SUCCESS,
                      payload: {
                          ageData,
                          genders,
                          genders_amount,
                          audience_chart,
                          genders_array
                      }
                  })
              })
              .catch(() => {
                  dispatch({
                      type: audienceTypes.AA_GET_DATA_ERROR
                  })
              })
        }
    },

    changeDateTo: (date) => {
        return {
            type: audienceTypes.AA_CHANGE_DATE_TO,
            payload: date
        }
    },

    changeDateFrom: (date) => {
        return {
            type: audienceTypes.AA_CHANGE_DATE_FROM,
            payload: date
        }
    },
}

export default audienceActions