const transactionsType = {
    CLEAR_TRANSACTIONS: "CLEAR_TRANSACTIONS",
    GET_TRANSACTIONS_PENDING: "GET_TRANSACTIONS_PENDING",
    GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
    GET_TRANSACTIONS_ERROR: "GET_TRANSACTIONS_ERROR",

    CHANGE_TRANSACTIONS_PAGE: "CHANGE_TRANSACTIONS_PAGE",
    CLEAR_TRANSACTIONS_PAGE: "CLEAR_TRANSACTIONS_PAGE",

    CLEAR_USE_CASHBACK_CODE_PENDING: "CLEAR_USE_CASHBACK_CODE_PENDING",
    SEND_USE_CASHBACK_CODE_PENDING: "SEND_USE_CASHBACK_CODE_PENDING",
    SEND_USE_CASHBACK_CODE_SUCCESS: "SEND_USE_CASHBACK_CODE_SUCCESS",
    SEND_USE_CASHBACK_CODE_ERROR: "SEND_USE_CASHBACK_CODE_ERROR",
}

export default transactionsType