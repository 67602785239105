import React, { useEffect, useState } from 'react'
import Audience from "./components/Audience/Audience";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import audienceActions from "store/analyticsStores/audienceStore/audienceActions";
import { useCurrentBranch } from "../../../../hooks/useCurrentBranch";


export default function AudienceContainer() {
    const dispatch = useDispatch()
    const {info} = useSelector(state => state.branchReducer)
    const {
        data,
        type,
        ageData,
        status,
        genders,
        genders_amount,
        audience_chart,
        genders_array,
        analyticClientsDateFrom,
        analyticClientsDateTo,
    } = useSelector(state => state.audienceReducer)
    const { i18n } = useTranslation();

    const invisParams = useSelector(state => state.filtersReducer)
    const { currentRole } = useCurrentBranch()

    useEffect(() => {
        if (info) {
            dispatch(audienceActions.getAudienceData(
              info && info.id, type, analyticClientsDateFrom, analyticClientsDateTo,
                info?.group_id, invisParams, currentRole
              ))
        }
    }, [info, type, analyticClientsDateFrom, analyticClientsDateTo, i18n.language, invisParams])

    const changeDate = (type, date) => {
        switch (type) {
            case 1:
                dispatch(audienceActions.changeDateFrom(date))
                break;
            case 2:
                dispatch(audienceActions.changeDateTo(date))
                break;
            default: return;
        }
    }

    const changePeriod = (period) => {
        dispatch(audienceActions.changeDataPeriod(period))
    }


    return (
        <Audience
            currentRole={currentRole}
            ageData={ageData}
            changePeriod={changePeriod}
            changeDate={changeDate}
            status={status}
            genders={genders}
            genders_amount={genders_amount}
            type={type}
            audience_chart={audience_chart}
            genders_array={genders_array}
            analyticClientsDateFrom={analyticClientsDateFrom}
            analyticClientsDateTo={analyticClientsDateTo}
        />
    )
}