import notificationsTypes from "./notificationsTypes";
import notificationsApi from "./notificationsApi";

const notificationsActions = {
    getNotificationsData: (id, page) => async dispatch => {
        dispatch({
            type: notificationsTypes.GET_NOTIFICATIONS_PENDING
        })

        await notificationsApi.getNotificationsData(id, page)
            .then((res) => {
                dispatch({
                    type: notificationsTypes.GET_NOTIFICATIONS_SUCCESS,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: notificationsTypes.GET_NOTIFICATIONS_ERROR
                })
            })
    },
    changePage: (page) => {
        return {
            type: notificationsTypes.CHANGE_NOTIFICATIONS_PAGE,
            payload: page
        }
    },
    sendMessageWithImage: (id, formData, content, resolve) => async dispatch => {
        dispatch({
            type: notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_PENDING
        })

        await notificationsApi.sendOnlyImage(id, formData)
            .then(res => {
                const media = {...res.data}
                const data = {
                    content,
                    media: {
                        type: "photo",
                        file: {
                            ...media
                        }
                    }
                }
                notificationsApi.sendMessage(id, data)
                    .then((res) => {
                        resolve(true)
                        dispatch({
                            type: notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_SUCCESS,
                            payload: res.data
                        })
                    })
                    .catch(() => {
                        dispatch({
                            type: notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_ERROR,
                        })
                    })
            })
            .catch(() => {
                dispatch({
                    type: notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_ERROR
                })
            })
    },
    sendMessage: (id, content, resolve) => dispatch => {
        dispatch({
            type: notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_PENDING
        })
        notificationsApi.sendMessage(id, {content})
            .then((res) => {
                resolve(true)
                dispatch({
                    type: notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_SUCCESS,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_ERROR,
                })
            })
    }
}

export default notificationsActions