import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { PageLayout } from '../Layout';
import CustomPhotoDropzone from '../../../../components/CustomPhotoDropzone';
import branchActions from '../../../../store/branchStore/branchActions';
import Icon from './Icon';
import styles from './photos.module.scss';

export default function Photos() {
  const { t, i18n } = useTranslation();
  const branch = useSelector(s => s.branchReducer);
  const [branchId, setBranchId] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (branch.info) {
      setBranchId(branch.info.id);
    }
  }, [branch, i18n.language]);

  const onAddBanner = useCallback((img) => {
    const image = new FormData();
    image.append('banner', img);
    dispatch(branchActions.addBanner(branchId, image));
  }, [branchId, i18n.language])

  const onDeleteBanner = (bannerId) => {
    dispatch(branchActions.deleteBanner(branchId, bannerId));
  }

  const onAddIcon = useCallback((img) => {
    const image = new FormData();
    image.append('icon', img);
    dispatch(branchActions.changeIcon(branchId, image));
  }, [branchId, i18n.language]);

  return (
    <PageLayout
      title={t('photos')}
      text={t('NSImage.info')}
    >
      <div className={styles.uploaders}>
        <Icon onAddIcon={onAddIcon} />
        <div style={{ width: '72.2%', height: '218px' }}>
          <CustomPhotoDropzone
              onAddBanner={onAddBanner}
              pending={branch.pending}
              classnames={styles.dropzone}
          />
        </div>
      </div>
      {
        !!branch.info && (
          <div className={styles.banners}>
            {
              branch.info.banners.map((b) => (
                <div className={styles.img} key={b.id}>
                  <button
                    onClick={() => onDeleteBanner(b.id)}
                    className={styles.deletePhotoBtn}
                  >{t('delete')}</button>
                  <img src={b.url} />
                </div>
              ))
            }
          </div>
        )
      }
    </PageLayout>
  )
}
