import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";;
import CustomPagination from "../../../../components/CustomPagination";
import Loader from "../../../../components/Loader";
import { useHistory } from "react-router-dom";
import Checkbox from "../../../../components/FormElements/Checkbox";
import { StyledSelect } from "../../../../components/Analytics/DropdownSelector/DropdownSelector";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import ButtonOutlined from "../../../../components/FormElements/ButtonOutlined";
import crossActions from "../../../../store/crossStore/crossActions";
import styles from './common.module.scss';

const Administration = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { info, pending } = useSelector((s) => s.branchReducer);
  const {
    valueCities,
    valueCategories,
    pendingCities,
    pendingCategories,
    valueCrossMarketingBranches,
    crossMarketingBranchesParams,
    pendingCrossMarketingBranches,
    valueCrossMarketingBranchesCount,
    selectedCrossMarketingBranches,
    savingSelectedCrossMarketingBranches,
    pendingCrossMarketingGroupIdList,
  } = useSelector((s) => s.crossReducer);

  const onSkip = (quantityOfSkip, cb) => {
    if (info && info.id) {
      dispatch(crossActions.setCrossMarketingListParams({skip: quantityOfSkip}));
      cb();
    }
  }

  const onChangeChosenCMBranches = (e, item) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      dispatch(crossActions.choseCMBranches(item))
    } else {
      dispatch(crossActions.removeCMBranches(item))
    }
  }

  useEffect(() => {
    return () => {
      dispatch(crossActions.resetCrossMarketingListParams());
    }
  }, [])

  if (info && !info?.configuration?.cross_marketing?.enabled) return (
    <div className={styles.modal}>
      <p>
        {t('optionForbidden')} <br/>
        {t('touchModerator')}
      </p>
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>
          <span>{t('NSCrossMarketing.crossMark')}</span>
        </h1>
        <p>{t('NSCrossMarketing.sendClCrossPart')}</p>
      </div>

      <b
        style={{
          marginTop: 20,
          display: 'block',
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "28px",
          color: "#1D1F23",
        }}
      >
        {t('NSCrossMarketing.chooseCrossPartner')}
      </b>
      <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '500px', width: '100%', padding: '.5rem 0 1rem'}}>
        <div style={{ minWidth: '48%', marginRight: 16 }}>
          <StyledSelect
            style={{minHeight: 50}}
            options={valueCities}
            loading={pendingCities}
            placeholder={t('NSCrossMarketing.chooseCity')}
            values={crossMarketingBranchesParams.selectedCities.map(({id, name}) => ({id, name}))}
            onChange={(option) => {
              dispatch(crossActions.setCrossMarketingListParams({selectedCities: option}));
              dispatch(crossActions.setCrossMarketingListParams({selectedCategories: []}))
            }}
            color={"#62C68A"}
            keepSelectedInList={true}
            closeOnSelect={true}
            labelField={'name'}
            valueField={'id'}
            noDataRenderer={() => <div></div>}
            disabled={!valueCities.length}
            // loading={status === PENDING}
            // disabled={status === PENDING}
            searchable={false}
          />
        </div>
        <div style={{ minWidth: '48%' }}>
          <StyledSelect
            style={{minHeight: 50}}
            options={valueCategories}
            loading={pendingCategories}
            placeholder={t('NSCrossMarketing.chooseCat')}
            values={crossMarketingBranchesParams.selectedCategories}
            onChange={(option) => {
              dispatch(crossActions.setCrossMarketingListParams({selectedCategories: option}))
            }}
            noDataRenderer={() => <div style={{ padding: '20px', textAlign: 'center', color: '#333333' }}>
              <span>{t('NSCrossMarketing.chooseCity')}</span>
            </div>}
            color={"#62C68A"}
            keepSelectedInList={true}
            closeOnSelect={true}
            labelField={'name'}
            valueField={'id'}
            // loading={status === PENDING}
            // disabled={status === PENDING}
            searchable={false}
          />
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <table className={`table ${styles.table} ${pendingCrossMarketingGroupIdList ? 'loading' : ''}`}>
          <thead>
          <tr>
            <th></th>
            <th>{t('NSCrossMarketing.name')}</th>
            <th>{t('NSCrossMarketing.profitNewCl')}</th>
            <th>{t('NSCrossMarketing.bonusLoyalCl')}</th>
            <th>{t('NSCrossMarketing.cat')}</th>
            <th>{t('NSCrossMarketing.city')}</th>
          </tr>
          </thead>
          <tbody>
            {valueCrossMarketingBranches ? valueCrossMarketingBranches.map((item) => (
              <tr key={item.id} >
                <td onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    onChange={(e) => onChangeChosenCMBranches(e, item)}
                    checked={selectedCrossMarketingBranches.includes(item.group_id)}
                  />
                </td>
                <td className={styles.period}>
                  {item.branch_name}
                  <span></span>
                </td>
                <td>{item.bonus_for_new_client}</td>
                <td>{item.bonus_for_loyalty_client}</td>
                <td>{item.category_name}</td>
                <td>{item.city_name}</td>
              </tr>
            )) : (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        {pendingCrossMarketingBranches && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '580px' }}>
            <Loader color="#73D389" style={{ margin: 'auto' }}/>
          </div>
        )}

        <CustomPagination
          count={valueCrossMarketingBranchesCount}
          limit={crossMarketingBranchesParams.limit}
          max={5}
          loading={pendingCrossMarketingBranches}
          updated={info && info.id}
          page={crossMarketingBranchesParams.page}
          setPage={(page) => dispatch(crossActions.setCrossMarketingListParams({page}))}
          onSkip={onSkip}
        />
      </div>

      <hr style={{background: '#E4E7EB', height: '1px', display: 'block', width: '100%', margin: '15px 0 20px', border: 'none'}} />

      <div style={{ display: 'flex' }}>
        <ButtonFilled
          disabled={pending || pendingCrossMarketingGroupIdList}
          loading={savingSelectedCrossMarketingBranches}
          onClick={() => dispatch(crossActions.saveCrossMarketingList(info.id, { group_id_list: selectedCrossMarketingBranches }))}
        >
          {t('save')}
        </ButtonFilled>
        <div style={{ width: '10px' }}></div>
        <ButtonOutlined
          disabled={savingSelectedCrossMarketingBranches || pending}
          loading={pendingCrossMarketingGroupIdList}
          variant="green"
          onClick={() => {
            // dispatch(crossActions.resetCMBranches()
            dispatch(crossActions.getCrossMarketingGroupIdList(info.id));
          }}
        >
          {t('cancel')}
        </ButtonOutlined>
      </div>
    </div>
  )
}

export default Administration;
