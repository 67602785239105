const adsTypes = {
    GET_ADS_LIST_FOR_BRANCH_GROUP_PENDING: "GET_ADS_LIST_FOR_BRANCH_GROUP_PENDING",
    GET_ADS_LIST_FOR_BRANCH_GROUP_SUCCESS: "GET_ADS_LIST_FOR_BRANCH_GROUP_SUCCESS",
    GET_ADS_LIST_FOR_BRANCH_GROUP_ERROR: "GET_ADS_LIST_FOR_BRANCH_GROUP_ERROR",

    GET_ADS_LIST_PENDING: "GET_ADS_LIST_PENDING",
    GET_ADS_LIST_SUCCESS: "GET_ADS_LIST_SUCCESS",
    GET_ADS_LIST_ERROR: "GET_ADS_LIST_ERROR",

    GET_COMPARISONS_PENDING: "GET_COMPARISONS_PENDING",
    GET_COMPARISONS_SUCCESS: "GET_COMPARISONS_SUCCESS",
    GET_COMPARISONS_ERROR: "GET_COMPARISONS_ERROR",

    GET_ADS_INFO_PENDING: "GET_ADS_INFO_PENDING",
    GET_ADS_INFO_SUCCESS: "GET_ADS_INFO_SUCCESS",
    GET_ADS_INFO_ERROR: "GET_ADS_INFO_ERROR",

    GET_SALE_REPORT_PENDING: "GET_SALE_REPORT_PENDING",
    GET_SALE_REPORT_SUCCESS: "GET_SALE_REPORT_SUCCESS",
    GET_SALE_REPORT_ERROR: "GET_SALE_REPORT_ERROR",

    UPDATE_ADS_INFO_PENDING: "UPDATE_ADS_INFO_PENDING",
    UPDATE_ADS_INFO_SUCCESS: "UPDATE_ADS_INFO_SUCCESS",
    UPDATE_ADS_INFO_ERROR: "UPDATE_ADS_INFO_ERROR",

    CREATE_ADS_PENDING: "CREATE_ADS_PENDING",
    CREATE_ADS_SUCCESS: "CREATE_ADS_SUCCESS",
    CREATE_ADS_ERROR: "CREATE_ADS_ERROR",

    REMOVE_ADS_PENDING: "REMOVE_ADS_PENDING",
    REMOVE_ADS_SUCCESS: "REMOVE_ADS_SUCCESS",
    REMOVE_ADS_ERROR: "REMOVE_ADS_ERROR",

    CHOSE_ADS: "CHOSE_ADS",
    REMOVE_ADS: "REMOVE_ADS",
    CLEAR_ADS: "CLEAR_ADS",
}

export default adsTypes;
