import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import adsActions from "../../../../store/adsStore/adsActions";
import { formatDate } from "../../../../helpers";
import { store } from "react-notifications-component";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import LineChart from "../LineChart/LineChart";
import PieChart from "../PieChart/PieChart";
import styles from './sale.module.scss';

const backSvg = (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1L2 10L11 19" stroke="#1D1F23" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

const copySvg = (
  <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.67357 5.11865H3C1.89543 5.11865 1 6.01408 1 7.11865V22.5696C1 23.6742 1.89543 24.5696 3 24.5696H14.356C15.4606 24.5696 16.356 23.6742 16.356 22.5696V21.4985" stroke="#AFAFAB" strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M7.89453 2C7.89453 1.30964 8.45418 0.75 9.14453 0.75H20.5005C21.1909 0.75 21.7505 1.30965 21.7505 2V17.4509C21.7505 18.1413 21.1909 18.7009 20.5005 18.7009H9.14453C8.45418 18.7009 7.89453 18.1413 7.89453 17.4509V2Z" stroke="#AFAFAB" strokeWidth="1.5"/>
  </svg>
);

const options = {
  chartArea: {
    height: '100%',
    width: '100%',
  },
  legend: 'none',
  colors: ['#73D389', '#705AFB'],
  slices: {0: {offset: 0}, 1: {offset: 0}},
  pieSliceBorderColor: "none",
  pieSliceTextStyle: {fontName: 'graphic', fontSize: 22, bold: true}
};

const Sale = () => {
  const { t, i18n } = useTranslation();
  const [dates, setDates] = useState({
    from: new Date().setFullYear(new Date().getFullYear() - 1),
    to: null,
  })
  const { id } = useParams();
  const history = useHistory();
  const { currentAds, pending, saleReport } = useSelector((s) => s.adsReducer);
  const { info, pending: infoPending } = useSelector((s) => s.branchReducer);
  const dispatch = useDispatch();
  const [type, changeType] = useState(4);

  useEffect(() => {
    if (id) {
      dispatch(adsActions.getSaleById(id));
    } else {
      history.goBack();
    }
  },[i18n.language]);

  useEffect(() => {
    let dateParams = '';

    if (dates.from) {
      const today = new Date();

      switch (type) {
        case 2:
          const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
          setDates({
            from: lastWeek,
            to: null,
          })
          break;
        case 3:
          setDates({
            from: new Date().setMonth(new Date().getMonth() - 1),
            to: null,
          })
          break;
        case 4:
          setDates({
            from: new Date().setFullYear(new Date().getFullYear() - 1),
            to: null,
          })
          break;
        default:
          setDates({
            from: new Date().setFullYear(new Date().getFullYear() - 1),
            to: null,
          })
      }
    }
  }, [type])

  useEffect(() => {
    if (id) {
      let dateParams = '';

      if (dates.from) {
        const fromDate = new Date(dates.from);
        let month = ((fromDate.getMonth() + 1) < 10) ? `0${fromDate.getMonth() + 1}` : fromDate.getMonth() + 1;
        let date = (fromDate.getDate() < 10) ? `0${fromDate.getDate()}` : fromDate.getDate();
        dateParams += `from=${fromDate.getFullYear()}-${month}-${date}`;
      }

      if (dates.to) {
        const toDate = new Date(dates.to);
        let month = ((toDate.getMonth() + 1) < 10) ? `0${toDate.getMonth() + 1}` : toDate.getMonth() + 1;
        let date = (toDate.getDate() < 10) ? `0${toDate.getDate()}` : toDate.getDate();
        dateParams += `&to=${toDate.getFullYear()}-${month}-${date}`;
      }

      dispatch(adsActions.getSaleReport(id, dateParams, type))
    }
  }, [id, dates, i18n.language])

  const getBackToClients = () => {
    history.goBack();
  }

  const showData = (field, data) => {
    if (data) {
      return data;
    }
    if (currentAds) {
      return currentAds[field]
    }

    return null
  }

  const onCopyShareLink = (text) => (event) => {
    event.preventDefault();
    event.stopPropagation ? event.stopPropagation() : (event.cancelBubble=true);
    navigator.clipboard.writeText(text);

    store.addNotification({
      title: t('Notify.linkCopied'),
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }

  const onChangeStatus = (status) => {
    currentAds && currentAds.id && dispatch(adsActions.updateSaleStatus(currentAds.id, { active: status }))
  }

  const changeDate = (type, date) => {
    switch (type) {
      case 1:
        setDates({...dates, from: date});
        break;
      case 2:
        setDates({...dates, to: date});
        break;
      default: return
    }
  }

  return (
    <div className={styles.container}>
      <i
        aria-hidden="true"
        style={{position: "absolute", left: 9, top: 27, cursor: 'pointer'}}
        onClick={getBackToClients}
      >{backSvg}</i>
      <div className={styles.topData}>
        <div className={`data-view ${pending ? 'data-view--loading' : ''}`}>
          <div className="data-view__column">

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('title')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('title')}</span>
              </div>
            </div>

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSCumulative.amount')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData(null, currentAds?.payload?.promo?.bonus)}</span>
              </div>
            </div>

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSComparison.dateStart')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{(currentAds && currentAds?.activated_at) ?
                  showData(null, formatDate(currentAds?.activated_at, { year: 'numeric', month: 'numeric', day: 'numeric' })) :
                    t('NSComparison.notPoint')}</span>
              </div>
            </div>

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSComparison.dateEnd')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{currentAds && currentAds?.expired_at ?
                  showData(null, formatDate(currentAds?.expired_at, { year: 'numeric', month: 'numeric', day: 'numeric' })) :
                    t('NSComparison.notPoint')}</span>
              </div>
            </div>

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSCorporative.link')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>
                  {showData('link')}
                </span>
                <button
                  className="data-view__copyBtn"
                  onClick={onCopyShareLink(currentAds?.link)}
                >{copySvg}
                </button>
              </div>
            </div>

            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('status')}:</b>
              </div>
              <div className="data-view__item-data">
                {currentAds?.active ? (
                  <span style={{ color: '#4EB78B' }}>{t('NSComparison.activated')}</span>
                ) : (
                  <span style={{ color: '#FA6262' }}>{t('NSComparison.disabled')}</span>
                )}
              </div>
            </div>

            {currentAds && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                {!currentAds?.active ? (
                  <ButtonFilled onClick={() => onChangeStatus(true)} loading={pending} disabled={infoPending}>{t('NSComparison.activate')}</ButtonFilled>
                ) : (
                  <ButtonFilled onClick={() => onChangeStatus(false)} loading={pending} disabled={infoPending} variant="red">{t('NSComparison.deactivate')}</ButtonFilled>
                )}
              </div>
            )}

          </div>
          <div className="data-view__column">
            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSGeneral.leads')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('users_count')}</span>
              </div>
            </div>
            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSGeneral.clients')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('clients_count')}</span>
              </div>
            </div>
            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSGeneral.bonusSpent')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('spent_bonuses_amount')}</span>
              </div>
            </div>
            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSSidebar.transactions')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('transactions_count')}</span>
              </div>
            </div>
            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSSidebar.profit')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('revenue_amount')}</span>
              </div>
            </div>
            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSGeneral.cons')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('cost_amount')}</span>
              </div>
            </div>
            <div className="data-view__item">
              <div className="data-view__item-title">
                <b>{t('NSGeneral.rev')}:</b>
              </div>
              <div className="data-view__item-data">
                <span>{showData('income_amount')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>

        <LineChart
          title={t('NSGeneral.leads')}
          tooltipMessage={t('NSGeneral.leads1')}
          type={type}
          data={saleReport.usersChart}
          changePeriod={changeType}
          dates={dates}
          changeDate={changeDate}
        />

        <LineChart
          color="#705AFB"
          showTypes={false}
          title={t('NSGeneral.clients')}
          tooltipMessage={t('clients')}
          type={type}
          data={saleReport.clientsChart}
          changePeriod={changeType}
        />

      </div>

      <div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PieChart
            title={t('NSSidebar.profit')}
            RETURNED={saleReport?.revenue_amount?.RETURNED}
            NEW={saleReport?.revenue_amount?.NEW}
            options={options}
          />

          <PieChart
            title={t('NSSidebar.transactions')}
            RETURNED={saleReport?.transactions?.RETURNED}
            NEW={saleReport?.transactions?.NEW}
            options={options}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart
            title={t('NSGeneral.clients')}
            RETURNED={saleReport?.clients_count?.RETURNED}
            NEW={saleReport?.clients_count?.NEW}
            options={options}
          />
        </div>
      </div>
    </div>
  )
}

export default Sale;