import { useState } from 'react';

const passwordTypeBtnStyles = {
  fontSize: '12px',
  color: '#000',
  opacity: '.6',
  fontWeight: 500,
  cursor: 'pointer'
}

export const usePasswordTypeBtn = ({show, hide}) => {
  const [type, setType] = useState('password');

  const passwordTypeBtn = () => {
    switch (type) {
      case 'password':
        return <div style={passwordTypeBtnStyles} onClick={() => setType('text')}>{show}</div>
      case 'text':
        return <div style={passwordTypeBtnStyles} onClick={() => setType('password')}>{hide}</div>
    }
  };

  return {
    type,
    passwordTypeBtn,
  }
}