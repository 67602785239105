import instance from "../../api";
import {convertDataToTransactions, convertDateToShortString} from "../../tools/convertDateToString";
import queryString from 'query-string';

const transactionsApi = {
    getTransactionsData: async (branch_id, page, from, to, phone) => {
        let skip = (Number(page) - 1) * 10;

        // if (convertDateToShortString(new Date()) === convertDateToShortString(new Date(from)) && convertDateToShortString(new Date()) === convertDateToShortString(new Date(to))) {
        //     return await instance().get(`/branches/${branch_id}/transactions/list?skip=${skip}&limit=10${phone ? `&query=${phone}` : ''}`).then(res => res.data)
        // }

        let fromDate, toDate;

        if (from) {
            fromDate = convertDataToTransactions(from)
        }

        if (to) {
            toDate = convertDataToTransactions(to)
        }

        return await instance().get(`/branches/${branch_id}/transactions/list?skip=${skip}${fromDate ? `&from=${fromDate}` : ''}${toDate ? `&to=${toDate}` : ''}&limit=10${phone ? `&query=${phone}` : ''}`).then(res => res.data)
    },
    getAnalyticTransactionsData: async (groupId, page, from, to, phone, invisParams) => {
        let skip = (Number(page) - 1) * 10;

        let fromDate, toDate;

        if (from) {
            fromDate = convertDataToTransactions(from)
        }

        if (to) {
            toDate = convertDataToTransactions(to)
        }

        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `&${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery}&${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }

        return await instance().get(`/branches/group/${groupId}/transactions?skip=${skip}${fromDate ? `&from=${fromDate}` : ''}${toDate ? `&to=${toDate}` : ''}&limit=10${phone ? `&query=${phone}` : ''}${invisQuery}`).then(res => res.data)
    },
    getTransactionById: (branch_id, id) => {
        return instance().get(`/orders/${id}`).then(res => res.data)
    },
    getBonusTransactionsData: async (branch_id, page, from, to, phone) => {
        let skip = (Number(page) - 1) * 10;

        // if (convertDateToShortString(new Date()) === convertDateToShortString(new Date(from)) && convertDateToShortString(new Date()) === convertDateToShortString(new Date(to))) {
        //     return await instance().get(`/branches/${branch_id}/transactions/list?skip=${skip}&limit=10${phone ? `&query=${phone}` : ''}`).then(res => res.data)
        // }

        let fromDate, toDate;

        if (from) {
            fromDate = convertDataToTransactions(from)
        }

        if (to) {
            toDate = convertDataToTransactions(to)
        }

        return await instance().get(`/branches/${branch_id}/transactions/list/bonus?skip=${skip}${fromDate ? `&from=${fromDate}` : ''}${toDate ? `&to=${toDate}` : ''}&limit=10${phone ? `&query=${phone}` : ''}`).then(res => res.data)
    },
    getAnalyticBonusTransactionsData: async (groupId, page, from, to, phone, invisParams) => {
        let skip = (Number(page) - 1) * 10;

        // if (convertDateToShortString(new Date()) === convertDateToShortString(new Date(from)) && convertDateToShortString(new Date()) === convertDateToShortString(new Date(to))) {
        //     return await instance().get(`/branches/${branch_id}/transactions/list?skip=${skip}&limit=10${phone ? `&query=${phone}` : ''}`).then(res => res.data)
        // }

        let fromDate, toDate;

        if (from) {
            fromDate = convertDataToTransactions(from)
        }

        if (to) {
            toDate = convertDataToTransactions(to)
        }

        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `&${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery}&${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }

        return await instance().get(`/branches/group/${groupId}/transactions/bonus?skip=${skip}${fromDate ? `&from=${fromDate}` : ''}${toDate ? `&to=${toDate}` : ''}&limit=10${phone ? `&query=${phone}` : ''}${invisQuery}`).then(res => res.data)
    },
    getUserInfo: (type, value, branch_id) => {
        return instance().get(`/promoters/find?${type}=${value}&branch_id=${branch_id}`).then(res => res.data)
    },
    sendUseCashbackCode: async (userId, branch_id) => {
        return await instance().post(`/customers/${userId}/send-use-cashback-code`, { branch_id }).then(res => res.data)
    },
    getUserBonuses: async (branch_id, group_id, user_id) => {
        return await instance().get(`/branches/${branch_id}/private/balance?user_id=${user_id}&group_id=${group_id}`).then(res => res.data)
    }
}
export default transactionsApi