import surveyTypes from "./surveyTypes";
import { PENDING, SUCCESS, ERROR } from "../globalStatuses/globalStatuses";

const initialState = {
    surveys: [],
    currentSurvey: null,
    count: 0,
    limit: 4,
    status: '',
    tester: {
        list: [],
        status: '',
        count: 0,
        limit: 3,
    }
}

const surveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case surveyTypes.CREATE_SURVEY_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case surveyTypes.CREATE_SURVEY_SUCCESS:
            return {
                ...state,
                status: SUCCESS
            }

        case surveyTypes.CREATE_SURVEY_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case surveyTypes.GET_SURVEYS_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case surveyTypes.GET_SURVEYS_SUCCESS:
            return {
                ...state,
                surveys: action.payload.surveys,
                count: action.payload.count,
                status: SUCCESS
            }

        case surveyTypes.GET_SURVEYS_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case surveyTypes.CLEAR_SURVEYS:
            return {
                ...state,
                surveys: [],
                count: 0,
                status: '',
            }

        case surveyTypes.DELETE_SURVEY_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case surveyTypes.DELETE_SURVEY_SUCCESS:
            return {
                ...state,
                status: SUCCESS
            }

        case surveyTypes.DELETE_SURVEY_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case surveyTypes.GET_SURVEY_PENDING:
            return {
                ...state,
                currentSurvey: null,
                status: PENDING
            }

        case surveyTypes.GET_SURVEY_SUCCESS:
            return {
                ...state,
                currentSurvey: action.payload,
                status: SUCCESS
            }

        case surveyTypes.GET_SURVEY_ERROR:
            return {
                ...state,
                currentSurvey: null,
                status: ERROR
            }

        case surveyTypes.CHANGE_ANSWER_SURVEY_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case surveyTypes.CHANGE_ANSWER_SURVEY_SUCCESS:
            return {
                ...state,
                status: SUCCESS
            }

        case surveyTypes.CHANGE_ANSWER_SURVEY_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case surveyTypes.GET_SURVEY_TESTERS_PENDING:
            return {
                ...state,
                tester: {
                    ...state.tester,
                    list: [],
                    status: PENDING
                },
            }

        case surveyTypes.GET_SURVEY_TESTERS_SUCCESS:
            return {
                ...state,
                tester: {
                    ...state.tester,
                    list: action.payload.testers,
                    count: action.payload.count,
                    status: SUCCESS
                },
            }

        case surveyTypes.GET_SURVEY_TESTERS_ERROR:
            return {
                ...state,
                tester: {
                    ...state.tester,
                    list: [],
                    count: 0,
                    status: ERROR
                },
            }

        case surveyTypes.CLEAR_SURVEY_TESTERS:
            return {
                ...state,
                tester: initialState.tester,
            }

        default:
            return {
                ...state
            }
    }
}

export default surveyReducer
