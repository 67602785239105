import i18n from '../../i18n';
import crossTypes from "./crossTypes";
import crossApi from "./crossApi";
import { store } from 'react-notifications-component';
import { hasResponseErrorMessage } from '../../helpers';
import branchActions from "../branchStore/branchActions";

const crossActions = {
    updateBranchConfigForCrossMarketing: (id, data) => dispatch => {
        dispatch({
            type: crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_PENDING
        });

        crossApi.updateBranchConfigForCrossMarketing(id, data)
            .then(() => {
                dispatch({
                    type: crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_SUCCESS,
                });
                dispatch(branchActions.getBranch(id));
            })
            .catch((e) => {
                dispatch({
                    type: crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_ERROR
                });
                if (hasResponseErrorMessage(e)) {
                    if (e.response.data.message === 'either day off or all day working') {
                        store.addNotification({
                            title: i18n.t('Notify.wrongFields'),
                            message: i18n.t('Notify.wrongWorkHours'),
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    } else if (e.response.data.message) {
                        store.addNotification({
                            title: `Ошибка`,
                            message: e.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            });
    },
    updateBranchConfigReferral: (id, data) => dispatch => {
        dispatch({
            type: crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_PENDING
        });

        crossApi.updateBranchConfigReferral(id, data)
          .then(() => {
              dispatch({
                  type: crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_SUCCESS,
              });
              dispatch(branchActions.getBranch(id));
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },

    getCities: () => dispatch => {
        dispatch({
            type: crossTypes.GET_CITIES_PENDING
        });

        crossApi.getCities()
            .then((res) => {
                dispatch({
                    type: crossTypes.GET_CITIES_SUCCESS,
                    payload: res.data
                });
            })
            .catch((e) => {
                dispatch({
                    type: crossTypes.GET_CITIES_ERROR
                });
                if (hasResponseErrorMessage(e)) {
                    if (e.response.data.message === 'either day off or all day working') {
                        store.addNotification({
                            title: i18n.t('Notify.wrongFields'),
                            message: i18n.t('Notify.wrongWorkHours'),
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    } else if (e.response.data.message) {
                        store.addNotification({
                            title: `Ошибка`,
                            message: e.response.data.message,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            });
    },

    getCategories: (id, params) => dispatch => {
        dispatch({
            type: crossTypes.GET_CATEGORIES_PENDING
        });

        crossApi.getCategories(params)
          .then((res) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_SUCCESS,
                  payload: res
              });
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },

    getCrossMarketingList: (id, params) => dispatch => {
        dispatch({
            type: crossTypes.GET_CROSS_MARKETING_BRANCHES_PENDING
        });

        crossApi.getCrossMarketingList(id, params)
          .then((res) => {
              dispatch({
                  type: crossTypes.GET_CROSS_MARKETING_BRANCHES_SUCCESS,
                  payload: res
              });
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.GET_CROSS_MARKETING_BRANCHES_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },
    getCrossMarketingGroupIdList: (id) => dispatch => {
        dispatch({
            type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_PENDING
        });

        crossApi.getCrossMarketingGroupIdList(id)
          .then((res) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_SUCCESS,
                  payload: res.data
              });
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },
    getCrossMarketingGroupIdList: (id) => dispatch => {
        dispatch({
            type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_PENDING
        });

        crossApi.getCrossMarketingGroupIdList(id)
          .then((res) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_SUCCESS,
                  payload: res.data
              });
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошбика`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },
    getCrossMarketingGroupIdList: (id) => dispatch => {
        dispatch({
            type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_PENDING
        });

        crossApi.getCrossMarketingGroupIdList(id)
          .then((res) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_SUCCESS,
                  payload: res.data
              });
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.GET_CATEGORIES_GROUP_ID_LIST_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошбика`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },

    saveCrossMarketingList: (id, body) => (dispatch, getState) => {
        dispatch({
            type: crossTypes.SAVE_CROSS_MARKETING_BRANCHES_PENDING
        });

        crossApi.saveSelectedCrossMarketingList(id, body)
          .then((res) => {
              dispatch({
                  type: crossTypes.SAVE_CROSS_MARKETING_BRANCHES_SUCCESS,
              });
              dispatch(crossActions.getCrossMarketingList(id, getState().crossReducer.crossMarketingBranchesParams));
              dispatch(crossActions.getCrossMarketingGroupIdList(id));
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.SAVE_CROSS_MARKETING_BRANCHES_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },

    getCrossMarketingAnalyticList: (id, params) => (dispatch, getState) => {
        dispatch({
            type: crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_PENDING
        });

        crossApi.getCrossMarketingAnalyticList(id, params)
          .then((res) => {
              dispatch({
                  type: crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_SUCCESS,
                  payload: res.data,
              });
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },

    setCrossMarketingListParams: (payload) => dispatch => {
        dispatch({
            type: crossTypes.SET_CROSS_MARKETING_BRANCHES_PARAMS,
            payload
        });
    },
    resetCrossMarketingListParams: () => dispatch => {
        dispatch({
            type: crossTypes.RESET_CROSS_MARKETING_BRANCHES_PARAMS
        });
    },

    getCrossMarketingAnalyticClientsList: (id, params) => (dispatch, getState) => {
        dispatch({
            type: crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PENDING
        });

        crossApi.getCrossMarketingAnalyticClientsList(id, params)
          .then((res) => {

              dispatch({
                  type: crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_SUCCESS,
                  payload: res.data,
              });
          })
          .catch((e) => {
              dispatch({
                  type: crossTypes.GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_ERROR
              });
              if (hasResponseErrorMessage(e)) {
                  if (e.response.data.message === 'either day off or all day working') {
                      store.addNotification({
                          title: i18n.t('Notify.wrongFields'),
                          message: i18n.t('Notify.wrongWorkHours'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  } else if (e.response.data.message) {
                      store.addNotification({
                          title: `Ошибка`,
                          message: e.response.data.message,
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    },

    setCrossMarketingAnalyticParams: (payload) => dispatch => {
        dispatch({
            type: crossTypes.SET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS,
            payload
        });
    },
    resetCrossMarketingAnalyticParams: () => dispatch => {
        dispatch({
            type: crossTypes.RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS
        });
    },

    setCrossMarketingAnalyticClientsParams: (payload) => dispatch => {
        dispatch({
            type: crossTypes.SET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS,
            payload
        });
    },
    resetCrossMarketingAnalyticClientsParams: () => dispatch => {
        dispatch({
            type: crossTypes.RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS
        });
    },


    resetCMBranches: () => dispatch => {
        dispatch({
            type: crossTypes.RESET_CMBRANCHES,
        });
    },
    choseCMBranches: (values) => dispatch => {
        dispatch({
            type: crossTypes.CHOSE_CMBRANCHES,
            payload: values,
        });
    },
    removeCMBranches: (value) => dispatch => {
        dispatch({
            type: crossTypes.REMOVE_CMBRANCHES,
            payload: value,
        });
    },

    setCrossMarketingBranchesAnalytic: (payload) => dispatch => {
        dispatch({
            type: crossTypes.SET_CROSS_MARKETING_BRANCHES_ANALYTIC,
            payload,
        });
    },
    resetCrossMarketingBranchesAnalytic: () => dispatch => {
        dispatch({
            type: crossTypes.RESET_CROSS_MARKETING_BRANCHES_ANALYTIC,
        });
    },
}

export default crossActions
