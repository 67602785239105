import instance from "../../api";

let JsonBigString = require('json-bigint')({"storeAsString": true});

const bonusesModalApi = {
    getClientCashBack: async (branch, id) => {
        return await instance().get(`/branches/${branch}/cashback?user_id=${id}`).then(res => res.data)
    },

    createOrder: async (branch, params) => {
        return await instance().post(`branches/${branch}/cash/order`,
            params
        ).then(res => res.data)
    },

    sendMoneyToUser: async (order_id, bonuses, payment_method) => {
        const payload = bonuses > 0 ? {use_balance: true, amount: parseInt(bonuses), payment_method} : {use_balance: false, amount: 0, payment_method}

        return await instance().post(`/orders/${order_id}/cash/complete`, payload).then(res => res.data)
    },

    patchOrderRefund: (order_id, params) => {
        // const payload = { amount: parseInt() };
        return instance().patch(`/orders/${order_id}/refund`, params).then(res => res.data)
    },

    revertTransaction: (order_id) => {
        return instance().post(`/orders/${order_id}/revert-transaction`).then(res => res.data)
    },

    giveAwardToUser: (branch_id, award_id, data) => {
        return instance().post(`/branches/${branch_id}/awards/${award_id}/give`, data).then(res => res.data)
    },
}

export default bonusesModalApi