import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import useClickOutside from "../../hooks/useClickOutside";
import styles from './languageSelector.module.scss';

import ruImg from '../../assets/languages/ru.svg';
import enImg from '../../assets/languages/en.svg';

const languages = ['ru', 'en'];

const languageInfo = {
  ru: {
    title: 'Русский',
    code: 'ru',
    icon: ruImg,
  },
  en: {
    title: 'English',
    code: 'en',
    icon: enImg
  },
}

const LanguageSelector = ({ classes, withLabel = false, color = '#1A1A1A' }) => {
  const [isActive, setIsActive] = useState(false);
  const { i18n } = useTranslation();
  const select = useRef();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  const handlerOpenSelect = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }

  const closeSelectOutside = (e) => {
    setIsActive(e && e.target === select.current);
  }

  // useClickOutside(select, closeSelectOutside);

  return (
    <div className={`${styles.container} ${classes}`} ref={select}>
      <div className={`${styles['lang-menu']} ${isActive ? styles['lang-menu--active'] : ''}`} onClick={handlerOpenSelect}>
        <div className={styles['selected-lang']}>
          <i style={{ backgroundImage: `url(${languageInfo[i18n.language].icon})` }}  />
          {withLabel ? <span style={{ color, textTransform: 'uppercase' }}>{languageInfo[i18n.language].code}</span> : null}
        </div>
        <ul>
          {languages.map(lang => (
            <li key={languageInfo[lang].code}>
              <a href="#"  onClick={(e) => {
                e.preventDefault();
                changeLanguage(languageInfo[lang].code);
              }}>
                <i style={{ backgroundImage: `url(${languageInfo[lang].icon})` }}  />
                <span>{languageInfo[lang].title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LanguageSelector;