import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';

export const useWhatsappCode = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const { info, pending } = useSelector(s => s.branchReducer);

    const formik = useFormik({
        initialValues: {
            send_code_to_customer: false,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (async ({ send_code_to_customer }, { setSubmitting }) => {
            // report_phone_numbers
            try {
                if (info.id) {
                    new Promise((resolve, reject) => {
                        dispatch(branchActions.changePrivateCashbackSendCodeToCustomer(info.id, {
                            send_code_to_customer
                        }, resolve, reject));
                    }).finally(() => {
                        setSubmitting(false);
                    })
                }
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (
              info.configuration.private_cashback
            ) {
                if (info.locales) {
                    formik.setFieldValue("send_code_to_customer", info.configuration.private_cashback.send_code_to_customer);
                }
            }
            console.log(info.configuration.private_cashback);
            if (!info.configuration.private_cashback || !info.configuration.private_cashback.enable_send_code_to_customer
            ) {
                setError(true);
                setErrorModal(true);
            }
        }
    }, [info]);

    return {
        formik,
        pending,
        errorModal,
        setErrorModal,
        error,
        info,
    }
}
