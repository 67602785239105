import i18n from '../../i18n';
import surveyTypes from "./surveyTypes";
import surveyApi from "./surveyApi";
import { store } from "react-notifications-component";

const surveyActions = {
    createSurvey: (params, resolve, reject) => dispatch => {
        dispatch({ type: surveyTypes.CREATE_SURVEY_PENDING });

        surveyApi.createSurvey(params)
            .then(res => {
                dispatch({
                    type: surveyTypes.CREATE_SURVEY_SUCCESS,
                    payload: res.data
                });
                store.addNotification({
                    title: i18n.t('Notify.survCreated'),
                    message: i18n.t('Notify.survCreated1'),
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                resolve(res.data.id);
            })
            .catch((e) => {
                dispatch({
                    type: surveyTypes.CREATE_SURVEY_ERROR
                });
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                reject(e);
            });
    },
    getSurveys: (queryParams) => dispatch => {
        dispatch({ type: surveyTypes.GET_SURVEYS_PENDING });

        surveyApi.getSurveys(queryParams)
            .then(res => {
                dispatch({
                    type: surveyTypes.GET_SURVEYS_SUCCESS,
                    payload: res.data
                });
            })
            .catch(() => {
                dispatch({
                    type: surveyTypes.GET_SURVEYS_ERROR
                });
            });
    },
    deleteSurvey: (survey_id, queryParams) => dispatch => {
        dispatch({ type: surveyTypes.DELETE_SURVEY_PENDING });

        surveyApi.deleteSurvey(survey_id)
            .then(res => {
                dispatch(surveyActions.getSurveys(queryParams));
                store.addNotification({
                    title: i18n.t('Notify.deleted'),
                    message: i18n.t('Notify.deleted1'),
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            })
            .catch(() => {
                dispatch({
                    type: surveyTypes.DELETE_SURVEY_ERROR
                });
                store.addNotification({
                    title: i18n.t('error'),
                    message: i18n.t('Notify.errorSurvDel'),
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            });
    },
    getSurvey: (survey_id) => dispatch => {
        dispatch({ type: surveyTypes.GET_SURVEY_PENDING });

        surveyApi.getSurveyById(survey_id)
            .then(res => {
                dispatch({
                    type: surveyTypes.GET_SURVEY_SUCCESS,
                    payload: res.data
                });
            })
            .catch(() => {
                dispatch({
                    type: surveyTypes.GET_SURVEY_ERROR
                });
            });
    },
    getSurveyTesters: (survey_id, queryParams) => dispatch => {
        dispatch({ type: surveyTypes.GET_SURVEY_TESTERS_PENDING });

        surveyApi.getSurveyTestersById(survey_id, queryParams)
            .then(res => {

                dispatch({
                    type: surveyTypes.GET_SURVEY_TESTERS_SUCCESS,
                    payload: res.data
                });
            })
            .catch(() => {
                dispatch({
                    type: surveyTypes.GET_SURVEY_TESTERS_ERROR
                });
            });
    },
    clearSurveyTesters: () => dispatch => {
        dispatch({ type: surveyTypes.CLEAR_SURVEY_TESTERS });
    },
    clearSurveys: () => dispatch => {
        dispatch({ type: surveyTypes.CLEAR_SURVEYS });
    },
    changeAnswerSurvey: (survey_id) => dispatch => {
        dispatch({ type: surveyTypes.CHANGE_ANSWER_SURVEY_PENDING });

        surveyApi.changeAnswerSurvey(survey_id)
            .then(res => {
                dispatch({
                    type: surveyTypes.CHANGE_ANSWER_SURVEY_SUCCESS,
                    payload: res.data
                });
            })
            .catch(() => {
                dispatch({
                    type: surveyTypes.CHANGE_ANSWER_SURVEY_ERROR
                });
            });
    },
}

export default surveyActions
