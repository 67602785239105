import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './input.module.scss';

const TextField = React.forwardRef((props, ref) => {
  const {
    icon,
    error = '',
    className = '',
    label = '',
    prefix = '',
    disabled = false,
    labelInline = false,
    required = false,
    postfix,
    width = '100%',
    style = {},
    containerProps = {
    className: '',
  }, mask = '', onChange,onBlur, ...rest} = props;

  const [focused, setFocused] = useState(false);

  const onChangeInput = (e) => {
    if (!!onChange) {
      onChange(e, mask);
    }
  }

  return (
    <div className={`${styles['text-field']} ${className}`.trim()}>
      <label className={classNames(styles['text-field__label-container'], {
        [styles['text-field__label-container--inline']]: labelInline,
      })}>
        {!!label && <p className={styles['text-field__label']}>{label}{required ? <i>*</i> : null}</p>}
        <div
          style={{ width: width }}
          className={classNames(`${styles['text-field__input-container']} ${containerProps.className}`, {
          [styles['text-field__input-container--green']]: focused,
          [styles['text-field__input-container--error']]: !!error,
          [styles['text-field__input-container--disabled']]: disabled
        })}>
          {!!prefix && (
            <div className={styles['text-field__prefix']}>
              <p>{prefix}</p>
            </div>
          )}
          {!!icon && (
            <div className={styles['text-field__icon']}>
              <img src={icon} alt=""/>
            </div>
          )}
          <input
            className={styles['text-field__input']}
            type="text"
            ref={ref}
            style={{ width: width, ...style }}
            onBlur={() => {
              setFocused(false);
              if (onBlur) onBlur();
            }}
            onFocus={() => setFocused(true)}
            disabled={disabled}
            onChange={onChangeInput}
            {...rest} />
          {!!postfix && (
            <div className={styles['text-field__postfix']}>
              {postfix()}
            </div>
          )}
        </div>
      </label>
      {!!error && (
        <p className={styles['text-field__error']}>
          {error}
        </p>
      )}
    </div>
  );
});

export default TextField;
