import React, { useState, useEffect } from 'react';
import ButtonFilled from 'components/FormElements/ButtonFilled';
import styles from './commentForm.module.scss';

export default function CommentForm({ onCommentToReview, answer, reviewId, index, pending, setCommenting, t }) {
  const [comment, setComment]= useState('');

  useEffect(() => () => {
    setComment('');
  }, []);

  const onChangeComment = (e) => {
    setComment(e.target.value);
  }

  const onComment = () => {
    onCommentToReview(answer, reviewId, index, comment);
    setComment('');
    setCommenting(false);
  }

  return (
    <div className={styles.container}>
      <textarea placeholder={t('Form.enterText')} value={comment} onChange={onChangeComment}></textarea>
      <ButtonFilled
        onClick={onComment}
        loading={pending}
      >{t('save')}</ButtonFilled>
    </div>
  )
}