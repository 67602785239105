import React  from 'react';
import { useTranslation } from "react-i18next";
import InfiniteScroll from 'react-infinite-scroller';
import Rate from './components/Rate';
import DropdownSelector from 'components/Analytics/DropdownSelector/DropdownSelector';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import { useReviews } from './hooks/useReviews';
import Review from './components/Review';
import Loader from '../../components/Loader';
import styles from './reviews.module.scss';

export default function Reviews() {
  const { t, i18n } = useTranslation();
  const {
    info,
    reviews,
    hasMoreReviews,
    startDate,
    endDate,
    pending,
    onLoadMoreReviews,
    onChangePeriod,
    onSetDate,
    onCommentToReview,
    pendingComment,
  } = useReviews(t, i18n);

  if (!info) return <div className={styles.container} />

  return (
    <div className={styles.container}>
      <Rate info={info} />
      <div style={styles.reviews}>
        <div className={styles.reviewsHead}>
          <DropdownSelector
            trans
            status={pending}
            changePeriod={onChangePeriod}
          />
          <div style={{marginLeft: 34}}>
            <CustomDatePicker
              trans
              tStartDate={startDate}
              tEndDate={endDate}
              tSetStartDate={onSetDate(true)}
              tSetEndDate={onSetDate(false)}
            />
          </div>
        </div>
        <div className={styles.reviewsBody}>
          <InfiniteScroll
            loadMore={onLoadMoreReviews}
            hasMore={hasMoreReviews}
            loader={<div key="SagiLoader" style={{ display: 'flex', justifyContent: 'center' }}><Loader color="#4EB78B" /></div>}
          >
            {reviews.map((r, i) => (
                <Review key={r.id} review={r} onCommentToReview={onCommentToReview} index={i} pendingComment={pendingComment} />
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}
