import React from 'react';
import TextField from 'components/FormElements/TextField';
import ButtonFilled from 'components/FormElements/ButtonFilled';
import { useTranslation, Trans } from "react-i18next";
import { PhoneInputField } from "../../../../components/PhoneInput";
import { useAuthForm } from '../../hooks/useAuthForm';
import { usePasswordTypeBtn } from '../../../hooks/usePasswordTypeBtn';
import Checkbox from '../../../../components/FormElements/Checkbox';
import styles from './authForm.module.scss';

export default function AuthForm({ setForgetPassword }) {
  const { formik, pending, remembered, setRemembered } = useAuthForm();
  const { t } = useTranslation();
  const { type, passwordTypeBtn } = usePasswordTypeBtn({show: t('show'), hide: t('hide')});

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <div className={styles.authInputs}>
        <div style={{ marginBottom: 15 }}>
          <PhoneInputField
            value={formik.values.phone}
            error={formik.touched.phone && formik.errors.phone}
            pending={pending}
            onChange={(v) =>  formik.setFieldValue('phone', v)}
            label={t('NSAuth.phone')}
          />
        </div>
        <div className={styles.forgetPasswordBtn} onClick={() => setForgetPassword(true)}>{t('NSAuth.forgot')}</div>
        <div>
          <TextField
            label={t('pass')}
            placeholder={t('enterPass')}
            className={styles.authInput}
            disabled={pending}
            type={type}
            postfix={passwordTypeBtn}

            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}
          />
        </div>
      </div>
      <div className={styles.authActions}>
        <ButtonFilled
          type="submit"
          loading={pending}
        >
          {t('login')}
        </ButtonFilled>
      </div>
      <div className={`${styles.checkbox}`}>
        <Checkbox
          checked={remembered}
          onChange={() => setRemembered(!remembered)}
          label={t('remember')}
          checkedType="square"
        />
      </div>
      <div className={`${styles.takeConditionBtn}`}>
        <a href="https://sagi.kz/terms.html" target="_blank">
          <Trans i18nKey="NSAuth.accept">
            Принимаю <span>политику обработки данных</span>
          </Trans>
        </a>
      </div>
    </form>
  )
}
