import generalTypes from "./generalTypes";
import generalApi from "./generalApi";
import {getPaymentData} from "../../../tools/analyticsTools";
import { differenceInMonths, differenceInCalendarMonths  } from "date-fns";
import { Roles } from "../../../config";

const generalActions = {
    getGeneralData: (branch_id, role, groupId, type, from, to, invisParams) => async dispatch => {
        dispatch({
            type: generalTypes.GA_GET_DATA_PENDING
        })


      let dates = '';

      if (from) {
        const fromDate = new Date(from);
        let month = (fromDate.getMonth() + 1) < 10 ? `0${fromDate.getMonth() + 1}` : fromDate.getMonth() + 1;
        let fromDateString = fromDate.getDate().toString().length === 1 ? `0${fromDate.getDate()}` : fromDate.getDate();
        dates += `&from=${fromDate.getFullYear()}-${month}-${fromDateString}`;
      }

      if (to) {
        const toDate = new Date(to);
        let month = (toDate.getMonth() + 1) < 10 ? `0${toDate.getMonth() + 1}` : toDate.getMonth() + 1;
        let toDateString = toDate.getDate().toString().length === 1 ? `0${toDate.getDate()}` : toDate.getDate();
        dates += `&to=${toDate.getFullYear()}-${month}-${toDateString}`;
      }

        if (role === Roles.ANALYTIC && groupId) {
          await generalApi.getAnalyticGeneralData(groupId, type, dates, invisParams)
            .then((res) => {
              let data = res.data || []
              if (data.length) {
                let payment_total = 0
                let cashback_amount = 0
                let transactions = 0
                let payment_from_private_balance = 0

                for (let i in data) {
                  payment_total += data[i].payment_total
                  cashback_amount += parseInt(data[i].cashback_amount)
                  payment_from_private_balance += data[i].payment_from_private_balance
                  transactions += data[i].payment_count
                }
                let average_check = transactions && Math.round(payment_total / transactions)

                // ***********************************************
                const months = differenceInMonths(new Date(data[0].date), new Date(data[data.length - 1].date));
                const calendarMonths = differenceInCalendarMonths(new Date(data[0].date), new Date(data[data.length - 1].date));
                // ***********************************************

                let chart_data = []

                // getPaymentData(data, type ? type : 4)
                if (months > 1) {
                  chart_data = getPaymentData(data.reverse(), 4)
                  if (!data || !data.length) chart_data = []
                } else {
                  chart_data = getPaymentData(data.reverse(), 1)
                  if (!data || !data.length) chart_data = []
                }

                dispatch({
                  type: generalTypes.GA_GET_DATA_SUCCESS,
                  payload: {data, payment_total, cashback_amount, transactions, average_check, chart_data, payment_from_private_balance}
                })

                return
              }

              dispatch({
                type: generalTypes.GA_GET_DATA_SUCCESS,
                payload: {data: [], payment_total: 0, cashback_amount: 0, transactions: [], average_check: 0, chart_data: [], payment_from_private_balance: 0}
              })
            })
            .catch((e) => {
              dispatch({
                type: generalTypes.GA_GET_DATA_ERROR
              })
            })
        } else {
          await generalApi.getGeneralData(branch_id, type, dates)
            .then((res) => {
              let data = res.data || []
              if (data.length) {
                let payment_total = 0
                let cashback_amount = 0
                let transactions = 0
                let payment_from_private_balance = 0
                for (let i in data) {
                  payment_total += data[i].payment_total
                  cashback_amount += parseInt(data[i].cashback_amount)
                  payment_from_private_balance += data[i].payment_from_private_balance
                  transactions += data[i].payment_count
                }
                let average_check = transactions && Math.round(payment_total / transactions)

                // ***********************************************
                const months = differenceInMonths(new Date(data[0].date), new Date(data[data.length - 1].date));
                const calendarMonths = differenceInCalendarMonths(new Date(data[0].date), new Date(data[data.length - 1].date));


                // ***********************************************

                let chart_data = []

                // getPaymentData(data, type ? type : 4)
                if (months > 1) {
                  chart_data = getPaymentData(data.reverse(), 4)
                  if (!data || !data.length) chart_data = []
                } else {
                  chart_data = getPaymentData(data.reverse(), 1)
                  if (!data || !data.length) chart_data = []
                }

                dispatch({
                  type: generalTypes.GA_GET_DATA_SUCCESS,
                  payload: {data, payment_total, cashback_amount, transactions, average_check, chart_data, payment_from_private_balance}
                })

                return
              }

              dispatch({
                type: generalTypes.GA_GET_DATA_SUCCESS,
                payload: {data: [], payment_total: 0, cashback_amount: 0, transactions: [], average_check: 0, chart_data: [], payment_from_private_balance: 0}
              })
            })
            .catch(() => {
              dispatch({
                type: generalTypes.GA_GET_DATA_ERROR
              })
            })
        }
    },

    changeDataPeriod: (period) => {
        return {
            type: generalTypes.GA_CHANGE_DATA_PERIOD,
            payload: period
        }
    },

  changeDateTo: (date) => {
    return {
      type: generalTypes.GA_CHANGE_DATE_TO,
      payload: date
    }
  },

  changeDateFrom: (date) => {
    return {
      type: generalTypes.GA_CHANGE_DATE_FROM,
      payload: date
    }
  },
}

export default generalActions
