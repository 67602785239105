import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from 'react-infinite-scroller';
import MaskService from "react-masked-text/src/mask-service";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Modal from 'react-modal';
import CustomModal from '../../../../portals/Modal';
import TextField from "../../../../components/FormElements/TextField";
import { store } from "react-notifications-component";
import instance from "../../../../api";
import Loader from "../../../../components/Loader";
import { clearMask, hasResponseErrorMessage, phoneMask } from "../../../../helpers";
import PageHeader from "../../../../components/PageHeader";
import userImg from '../../../../assets/user.png';
import QuestionTooltip from "../../../../components/QuestionTooltip";
import _ from "lodash";
import styles from './profileBonusCorporate.module.scss';
import { setIn } from "formik";

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        padding: 0,
        borderRadius: 24,
    }
};

export default function ProfileBonusCorporate() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pending, info } = useSelector((s) => s.branchReducer);
    const [errorModal, setErrorModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(false);
    const [ error, setError ] = useState('');
    const [shareUrl, setShareUrl] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [input, setInput] = useState('');
    const [clients, setClients] = useState([]);
    const [hasMoreReviews, setHasMoreReviews] = useState(true);
    const [client, setClient] = useState(null);
    const [clientModal, setClientModal] = useState(false);
    const [lookingClient, setSetLookingClient] = useState(false);

    const onLoadMoreReviews = () => {
        if (!clients.length) return
        const before = clients[clients.length - 1].id;
        getClients(before, true);
    }

    const getAndAddClients = async () => {
        return instance().get(`/branches/${info.id}/corporate`)
          .then((res) => {
              setClients(_.uniqBy([...clients, ...res.data.data], "id"));
              setPhone("");
              setPhone2("");
          })
          .catch((e) => {
              if (e.response && e.response.data && e.response.data.message) {
                  if (e.response.data.message === 'no permission to this operation') {
                      store.addNotification({
                          title: t('error'),
                          message: t('Notify.notEnoughRights'),
                          type: "danger",
                          insert: "top",
                          container: "top-right",
                          animationIn: ["animate__animated", "animate__fadeIn"],
                          animationOut: ["animate__animated", "animate__fadeOut"],
                          dismiss: {
                              duration: 5000,
                              onScreen: true
                          }
                      });
                  }
              }
          });
    }

    const getClients = (before = null, loadMore = false) => {
        loadMore && setHasMoreReviews(false);
        instance().get(`/branches/${info.id}/corporate?${before ? `before=${before}` : ''}`)
            .then((res) => {
                if (res.data.data.length) setHasMoreReviews(true);
                if (loadMore) return setClients(_.uniqBy([...clients, ...res.data.data], "id"));
                if (!loadMore) setClients(res.data.data);
            })
            .catch((e) => {
                if (e.response && e.response.data && e.response.data.message) {
                    if (e.response.data.message === 'no permission to this operation') {
                        store.addNotification({
                            title: t('error'),
                            message: t('Notify.notEnoughRights'),
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            });
    }

    useEffect(() => {
        if (info) {
            setShareUrl('');
            setInput('');
            setActive(true);

            info.configuration.corporate_cashback && setShareUrl(info.configuration.corporate_cashback.share_url);
                info.rule.corporate_cashback_percentage && setInput(info.rule.corporate_cashback_percentage);

            if (!info.configuration.corporate_cashback || !info.configuration.corporate_cashback.enabled) {
                setErrorModal(true);
                setActive(false);
            }

            getClients();
        }
    }, [info])

    const onCopyShareUrl = (event) => {
        event.preventDefault();
        event.stopPropagation ? event.stopPropagation() : (event.cancelBubble=true);
        navigator.clipboard.writeText(shareUrl);

        store.addNotification({
            title: t('Notify.linkCopied'),
            message: shareUrl,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    const onUpdateCorporate = async () => {
        if (!input) return  setError(t('Form.fillField'));
        if (error) setError('');

        try {
            if (info) {
                setLoading(true);
                await instance().put(`/branches/${info.id}/contract/corporate`, {new_cashback: Number(input)})
            }
        } catch (e) {
            info.rule.corporate_cashback_percentage && setInput(info.rule.corporate_cashback_percentage);
            if (hasResponseErrorMessage(e)) {
                store.addNotification({
                    title: t('error'),
                    message: `${e.response.data.message ? e.response.data.message : t('Notify.error')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const onFindClient = async () => {
        if (!phone || lookingClient) return

        try {
            setSetLookingClient(true);
            const client = await instance().get(`/promoters/find?phone=${clearMask(phone)}`).then(res => res.data.data);
            setClient(client);
            setClientModal(true);
        } catch (e) {
            store.addNotification({
                title: t('error'),
                message: t('Notify.notReg'),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        } finally {
            setSetLookingClient(false);
        }
    }

    const onAddUser = (cId) => async () => {
        if (!cId || !info) return

        try {
            setLoading(true);
            await instance().post(`/branches/${info.id}/corporate/${cId}`).then(res => res.data.data);
            store.addNotification({
                title: t('success'),
                message: t('Notify.userSuccessAdded'),
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            getAndAddClients()
              .finally(() => {
                  setClientModal(false);
                  setClient(null);
              });
        } catch (e) {
            if (hasResponseErrorMessage(e)) {
                store.addNotification({
                    title: `Ошибка`,
                    message: `${e.response.data.message ? e.response.data.message : 'Произошла ошибка!'}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const onAddUserByPhone = async (phone) => {
        if (!phone || !info) return

        try {
            setLoading(true);
            await instance().post(`/branches/${info.id}/corporate/undefined?phone=+${clearMask(phone)}`).then(res => res.data.data);
            store.addNotification({
                title: t('success'),
                message: t('Notify.userSuccessAdded'),
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            await getAndAddClients()
              .finally(() => {
                  setClientModal(false);
                  setClient(null);
              });
        } catch (e) {
            if (hasResponseErrorMessage(e)) {
                store.addNotification({
                    title: `Ошибка`,
                    message: `${e.response.data.message ? e.response.data.message : 'Произошла ошибка!'}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const onDeleteUser = (cId) => async () => {
        if (!cId || !info) return

        try {
            setLoading(true);
            await instance().delete(`/branches/${info.id}/corporate/${cId}`);
            store.addNotification({
                title: t('success'),
                message: t('Notify.userSuccessDeleted'),
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            setClientModal(false);
            setClient(null);
            setClients(clients.filter((c) => c.id != cId));
        } catch (e) {
            if (hasResponseErrorMessage(e)) {
                store.addNotification({
                    title: `Ошибка`,
                    message: `${e.response.data.message ? e.response.data.message : t('Notify.error')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } finally {
            setLoading(false);
        }
    }

    const onChangePhone = (e) => {
        setPhone(MaskService.toMask('custom', e.target.value, {
            mask: '7 (999) 999-99-99'
        }));
    }

    const onChangePhone2 = (e) => {
        setPhone2(MaskService.toMask('custom', e.target.value, {
            mask: '7 (999) 999-99-99'
        }));
    }

    const copyBtn = () => (
        <button className={styles.btn} type="button" onClick={onCopyShareUrl}>
            <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.17454 5.6908H3.50098C2.39641 5.6908 1.50098 6.58623 1.50098 7.6908V23.1417C1.50098 24.2463 2.39641 25.1417 3.50098 25.1417H14.857C15.9615 25.1417 16.857 24.2463 16.857 23.1417V22.0706" stroke="url(#paint0_linear)" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M8.39453 2.57227C8.39453 1.88191 8.95418 1.32227 9.64453 1.32227H21.0005C21.6909 1.32227 22.2505 1.88191 22.2505 2.57227V18.0232C22.2505 18.7136 21.6909 19.2732 21.0005 19.2732H9.64453C8.95418 19.2732 8.39453 18.7136 8.39453 18.0232V2.57227Z" fill="white" stroke="url(#paint1_linear)" strokeWidth="1.5"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="9.17898" y1="5.6908" x2="9.17898" y2="25.1417" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#73D389"/>
                        <stop offset="1" stopColor="#4EB78B"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="15.3225" y1="0.572266" x2="15.3225" y2="20.0232" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#73D389"/>
                        <stop offset="1" stopColor="#4EB78B"/>
                    </linearGradient>
                </defs>
            </svg>
        </button>
    )

    return (
        <>
            <PageHeader title={t('bonuses')}>
                <div style={{ marginLeft: '50px' }}>
                    <QuestionTooltip text={t('NSCorporative.corporative')} />
                </div>
            </PageHeader>
            <div className={styles.container}>
                <form>
                    <div className={styles.block}>
                        <TextField
                            label={t('NSCorporative.corporate')}
                            placeholder="8%"
                            width="160px"
                            value={input}
                            disabled={!active}
                            error={error}
                            onChange={(e) => isFinite(e.target.value) && +e.target.value <= 100 && setInput(e.target.value)}
                            required
                        />
                        <span className={styles['block__right-text']}>{t('NSCorporative.total')}</span>
                        <p className={styles['block__text']}>{t('NSCorporative.type')}</p>
                    </div>

                    <div className={styles.block}>
                        <TextField
                            label={t('NSCorporative.link')}
                            width="405px"
                            value={shareUrl}
                            disabled={!active}
                            postfix={copyBtn}
                            placeholder={t('empty')}
                        />
                        <div style={{position: 'absolute', left: '430px', top: '40px'}}>
                            <QuestionTooltip text={t('NSCorporative.copy')} />
                        </div>
                        <p className={styles['block__text']}>{t('NSCorporative.activate')}</p>
                    </div>
                    <div className={styles.footer}>
                        <ButtonFilled disabled={!active} onClick={onUpdateCorporate} loading={loading}>{t('save')}</ButtonFilled>
                    </div>
                </form>
            </div>
            <div className={styles.clients}>
                <div className={styles.clientsTopContainer}>
                    <div>
                        <h2>{t("searchClient")}</h2>
                        <div className={styles.block}>
                            <TextField
                              label={t('NSCorporative.phone')}
                              placeholder="7 (707) 777-77-77"
                              width="224px"
                              value={phone}
                              onKeyUp={(e) => e.code === 'Enter' && onFindClient()}
                              onChange={onChangePhone}
                            />
                            <ButtonFilled disabled={pending} loading={lookingClient || pending || loading} className={styles['block__btn']}
                                          onClick={onFindClient}>{t('find')}</ButtonFilled>
                            <p className={styles['block__text']}>{t('NSCorporative.assessed')}</p>
                        </div>
                    </div>
                    <div>
                        <h2>{t("addClient")}</h2>
                        <div className={styles.block}>
                            <TextField
                              label={t('NSCorporative.phone')}
                              placeholder="7 (707) 777-77-77"
                              width="224px"
                              value={phone2}
                              onKeyUp={(e) => e.code === 'Enter' && onFindClient()}
                              onChange={onChangePhone2}
                            />
                            <ButtonFilled
                              disabled={pending}
                              loading={pending | loading}
                              className={styles['block__btn']}
                              onClick={() => onAddUserByPhone(phone2)}>
                                {t('add')}
                            </ButtonFilled>
                            <p className={styles['block__text']}>{t('NSCorporative.assessed')}</p>
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                  loadMore={hasMoreReviews ? onLoadMoreReviews : () => {
                  }}
                  hasMore={hasMoreReviews}
                >
                    <table className="table">
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>{t('client')}</th>
                            <th>{t('NSCorporative.phone')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !!clients.length ? clients.map((c, index) => (
                                <tr key={c.id}>
                                            <td>{index + 1}</td>
                                            <td>{c.first_name || c.last_name ? `${c.first_name} ${c.last_name}` : 'Клиент'}</td>
                                            <td>{c.phone}</td>
                                            <td style={{  paddingRight: '28px' }}>
                                                <button style={{ marginLeft: 'auto' }} className="btn-trash" type="button" onClick={onDeleteUser(c.id)} disabled={loading}>
                                                    <i>
                                                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.4219 1.875H8.84375V1.40625C8.84375 0.63085 8.2129 0 7.4375 0H5.5625C4.7871 0 4.15625 0.63085 4.15625 1.40625V1.875H1.57812C0.931953 1.875 0.40625 2.4007 0.40625 3.04688V4.6875C0.40625 4.94637 0.616133 5.15625 0.875 5.15625H1.13117L1.53614 13.6606C1.57191 14.4117 2.18891 15 2.94078 15H10.0592C10.8111 15 11.4281 14.4117 11.4639 13.6606L11.8688 5.15625H12.125C12.3839 5.15625 12.5938 4.94637 12.5938 4.6875V3.04688C12.5938 2.4007 12.068 1.875 11.4219 1.875ZM5.09375 1.40625C5.09375 1.14779 5.30404 0.9375 5.5625 0.9375H7.4375C7.69596 0.9375 7.90625 1.14779 7.90625 1.40625V1.875H5.09375V1.40625ZM1.34375 3.04688C1.34375 2.91765 1.4489 2.8125 1.57812 2.8125H11.4219C11.5511 2.8125 11.6562 2.91765 11.6562 3.04688V4.21875C11.5118 4.21875 1.94237 4.21875 1.34375 4.21875V3.04688ZM10.5274 13.616C10.5155 13.8664 10.3098 14.0625 10.0592 14.0625H2.94078C2.69015 14.0625 2.48448 13.8664 2.47259 13.616L2.06973 5.15625H10.9303L10.5274 13.616Z" fill="white"/>
                                                            <path d="M6.5 13.125C6.75887 13.125 6.96875 12.9151 6.96875 12.6562V6.5625C6.96875 6.30363 6.75887 6.09375 6.5 6.09375C6.24113 6.09375 6.03125 6.30363 6.03125 6.5625V12.6562C6.03125 12.9151 6.2411 13.125 6.5 13.125Z" fill="white"/>
                                                            <path d="M8.84375 13.125C9.10262 13.125 9.3125 12.9151 9.3125 12.6562V6.5625C9.3125 6.30363 9.10262 6.09375 8.84375 6.09375C8.58488 6.09375 8.375 6.30363 8.375 6.5625V12.6562C8.375 12.9151 8.58485 13.125 8.84375 13.125Z" fill="white"/>
                                                            <path d="M4.15625 13.125C4.41512 13.125 4.625 12.9151 4.625 12.6562V6.5625C4.625 6.30363 4.41512 6.09375 4.15625 6.09375C3.89738 6.09375 3.6875 6.30363 3.6875 6.5625V12.6562C3.6875 12.9151 3.89735 13.125 4.15625 13.125Z" fill="white"/>
                                                        </svg>
                                                    </i>
                                                    <span>{t('delete')}</span>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={clientModal}
                style={modalStyles}
                onRequestClose={() => setClientModal(false)}
            >
                <div className={styles.clientModal}>
                    <div>
                        <img src={client && client.avatar ? client.avatar : userImg} />
                    </div>
                    <div>
                        {client && (
                            <>
                                <span>{t('NSRespondents.name')}:</span>
                                <p>{client.first_name || client.last_name ? `${client.first_name} ${client.last_name}` : t('client')}</p>
                                <span>{t('NSCorporative.phone')}:</span>
                                <p>{client.phone}</p>
                                <ButtonFilled
                                    onClick={onAddUser(client.id)}
                                    disabled={loading}
                                >{t('add')}</ButtonFilled>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
            <CustomModal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </CustomModal>
        </>
    )
}
