import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Route, Switch, useParams, useHistory } from 'react-router-dom';
import Modal from "react-modal";
import surveyActions from "../../../../store/surveyStore/surveyActions";
import SurveyCard from "../../../../components/SurveyCard";
import { PageLayout } from "../Layout";
import SurveyTesters from "../../../../components/SurveyTesters";
import SurveyQuestions from "../../../../components/SurveyQuestions";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import closeModalImage from "../../../../components/PayModal/assets/CloseModalIcon.svg";
import phoneImg from "../../../Analytics/components/RFMClients/assets/images/phone.png";
import {store} from "react-notifications-component";
import styles from "./survey.module.scss";
import notificationsActions from "../../../../store/notificationsStore/notificationsActions";
import surveyApi from "../../../../store/surveyStore/surveyApi";

const resultsSvg = (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M15.1445 9.45312C15.1445 8.97849 14.7598 8.59375 14.2852 8.59375H5.43359C4.95896 8.59375 4.57422 8.97849 4.57422 9.45312C4.57422 9.92776 4.95896 10.3125 5.43359 10.3125H14.2852C14.7598 10.3125 15.1445 9.92776 15.1445 9.45312Z" />
            <path d="M5.43359 12.0312C4.95896 12.0312 4.57422 12.416 4.57422 12.8906C4.57422 13.3653 4.95896 13.75 5.43359 13.75H10.8095C11.2841 13.75 11.6688 13.3653 11.6688 12.8906C11.6688 12.416 11.2841 12.0312 10.8095 12.0312H5.43359Z" />
            <path d="M7.45226 20.2812H4.57812C3.63041 20.2812 2.85937 19.5102 2.85937 18.5625V3.4375C2.85937 2.48978 3.63041 1.71875 4.57812 1.71875H15.1437C16.0914 1.71875 16.8624 2.48978 16.8624 3.4375V8.72266C16.8624 9.19729 17.2472 9.58203 17.7218 9.58203C18.1964 9.58203 18.5812 9.19729 18.5812 8.72266V3.4375C18.5812 1.54206 17.0391 0 15.1437 0H4.57812C2.68269 0 1.14062 1.54206 1.14062 3.4375V18.5625C1.14062 20.4579 2.68269 22 4.57812 22H7.45226C7.92689 22 8.31164 21.6153 8.31164 21.1406C8.31164 20.666 7.92689 20.2812 7.45226 20.2812Z" />
            <path d="M20.1023 12.4426C19.0971 11.4374 17.4616 11.4373 16.457 12.4419L11.7389 17.1495C11.6387 17.2495 11.5648 17.3727 11.5236 17.5081L10.4961 20.8908C10.405 21.1907 10.4844 21.5164 10.7033 21.7407C10.8672 21.9088 11.09 22 11.3184 22C11.3949 22 11.4721 21.9898 11.5478 21.9688L15.0165 21.0079C15.1592 20.9684 15.2893 20.8927 15.3942 20.788L20.1024 16.0886C21.1076 15.0834 21.1076 13.4479 20.1023 12.4426ZM14.3399 19.4119L12.5948 19.8953L13.1055 18.214L16.289 15.0375L17.5046 16.2531L14.3399 19.4119ZM18.8876 14.8728L18.7211 15.0389L17.5058 13.8236L17.6717 13.658C18.0068 13.3229 18.552 13.3229 18.887 13.658C19.2221 13.993 19.2221 14.5382 18.8876 14.8728Z" />
            <path d="M14.2852 5.15625H5.43359C4.95896 5.15625 4.57422 5.54099 4.57422 6.01562C4.57422 6.49026 4.95896 6.875 5.43359 6.875H14.2852C14.7598 6.875 15.1445 6.49026 15.1445 6.01562C15.1445 5.54099 14.7598 5.15625 14.2852 5.15625Z" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="22" height="22" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

const testersSvg = (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.99981" cy="5.45489" r="4.20489" strokeWidth="1.5"/>
        <path d="M9 19.8268C11.0393 19.8268 12.9225 19.356 14.3219 18.5564C15.7 17.7689 16.75 16.5666 16.75 15.0768C16.75 13.5869 15.7 12.3847 14.3219 11.5972C12.9225 10.7975 11.0393 10.3268 9 10.3268C6.96067 10.3268 5.07752 10.7975 3.67815 11.5972C2.3 12.3847 1.25 13.5869 1.25 15.0768C1.25 16.5666 2.3 17.7689 3.67815 18.5564C5.07752 19.356 6.96067 19.8268 9 19.8268Z" strokeWidth="1.5"/>
    </svg>
);

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 99,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        padding: 0,
        borderRadius: 24,
    }
};

export default function Surveys() {
    const { t, i18n } = useTranslation();
    const [downloading, setDownloading] = useState(false)
    const history = useHistory();
    const dispatch = useDispatch();
    const { id, index } = useParams();
    const { currentSurvey, count, limit, status } = useSelector((s) => s.surveyReducer);
    const { info } = useSelector((s) => s.branchReducer);
    const branch = useSelector((s) => s.branchReducer);
    const [sendModal, setSendModal] = useState(false);
    const [modalPending, setModalPending] = useState(false);
    const [pushMessage, setPushMessage] = useState('');

    const navLinksContainer = () => {
        return (
            <div className={styles.navigation}>
                <NavLink to={'results'} exact activeClassName={styles.activeTab}>
                    {resultsSvg}
                    <span>{t('results')}</span>
                </NavLink>
                <NavLink to={'testers'} exact activeClassName={styles.activeTab}>
                    {testersSvg}
                    <span>{t('respondents')}</span>
                </NavLink>
            </div>
        )
    }

    useEffect(() => {
        if (id) {
            dispatch(surveyActions.getSurvey(id));
        }
    }, []);

    useEffect(() => {
        currentSurvey && setPushMessage(`${t('NSPoll.pushMessage')}: ${currentSurvey.share_url}`)
    }, [currentSurvey, i18n.language])

    const onDeleteSurvey = (id) => () => {
        if (branch.info && limit) {
            const queryParams = `limit=${limit}&branch_id=${branch.info.id}`;
            dispatch(surveyActions.deleteSurvey(id, queryParams));
            history.push('/profile/surveys')
        }
    }

    const onChangePushMessage = (e) => {
        if (e.target.value.length < 250) {
            setPushMessage(e.target.value);
        }
    }

    const onSendPushToCustomers = () => {
        if (!info || !pushMessage) return

        setModalPending(true);
        new Promise((resolve, reject) => {
            info && dispatch(notificationsActions.sendMessage(info.id, pushMessage, resolve));
        })
            .then(() => {
                store.addNotification({
                    title: t('success'),
                    message: t('successMessageSent'),
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                setModalPending(false);
                setSendModal(false);
            });
    }

    const exportExcel = () => {
        const params = {};
        setDownloading(true);
        surveyApi.getSurveyResultsExcel(id)
          .finally(() => setDownloading(false));
    }

    return (
        <PageLayout
            header={false}
            navLinksContainer={navLinksContainer}
        >
            <div>
                {currentSurvey &&
                  <div style={{display: "flex", width: '100%', justifyContent: "space-between", gap: '20px', marginTop: "20px"}}>
                      <ButtonFilled onClick={() => setSendModal(true)}>{t('NSResult.send')}</ButtonFilled>
                      <ButtonFilled
                        onClick={exportExcel}
                        loading={downloading}
                      >{t("export")}</ButtonFilled>
                  </div>
                }

                <div style={{height: '226px', marginTop: '20px'}}>
                    {
                      currentSurvey && <SurveyCard
                        linkable={false}
                        pageIndex={index}
                        survey={currentSurvey}
                        onDelete={onDeleteSurvey}
                      />
                    }
                </div>
                <div className={styles.content}>
                    <Switch>
                        <Route path={'/profile/surveys/:id/:index/results'}
                               render={() => <SurveyQuestions survey={currentSurvey}/>}/>
                        <Route path={'/profile/surveys/:id/:index/testers'} render={() => <SurveyTesters id={id}/>}/>
                    </Switch>
                </div>
            </div>
            <Modal
              ariaHideApp={false}
              isOpen={sendModal}
              style={modalStyles}
              onRequestClose={() => setSendModal(false)}
            >
                <div className={styles.modal}>
                    <div className={styles.closeModal} onClick={() => setSendModal(false)}>
                        <img src={closeModalImage}/>
                    </div>
                    <div className={styles.modalContainer}>
                        <div className={styles.header}>
                            <p>{t('NSStatistics.notification')}</p>
                        </div>

                        <div>
                            <p className={styles.p}>{t('NSClients.textNot')}</p>
                            <textarea
                                placeholder={t('Form.enterText')}
                                className={styles.textArea}
                                value={pushMessage}
                                onChange={onChangePushMessage}
                                required
                            />
                            <div className={styles.span} style={{ color: `${pushMessage.length >= 249 ? 'tomato' : '#000000'}` }}>{t('maxSym', {sym: 250})}</div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px' }}>
                                <img width="230" src={phoneImg} style={{ marginBottom: '16px' }}/>
                                <ButtonFilled
                                    className={styles.btn}
                                    onClick={onSendPushToCustomers}
                                    loading={modalPending}
                                >{t('send')}</ButtonFilled>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </PageLayout>
    )
}
