import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import backButtonIcon from '../assets/backIcon.svg';
import { ERROR, PENDING } from '../../../store/globalStatuses/globalStatuses';
import Loader from '../../Loader';
import { store } from 'react-notifications-component';

import styles from './confirmForm.module.scss';
import exclaimImg from 'assets/exclaim.png';

import kaspiImg from '../assets/kaspi.png';
import mastercardImg from '../assets/mastercard.png';
import visaImg from '../assets/visa.png';
import appleImg from '../assets/applePay.png';
import cashImg from '../assets/cash.png';
import googleImg from '../assets/googlePay.png';
import transactionsActions from "../../../store/transactionsStore/transactionsActions";
import { useDispatch } from "react-redux";
import Checkbox from "../../FormElements/Checkbox";

const paymentType = {
  'VISA': visaImg,
  'MASTERCARD': mastercardImg,
  'KASPI': kaspiImg,
  'APPLE_PAY': appleImg,
  'GOOGLE_PAY': googleImg,
  'CASH': cashImg
}

export default function ConfirmForm(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    setStep,
    amount_sum,
    bonuses,
    client_cashback,
    confirmPayment,
    payment_status,
    comment,
    payment,
    give_award,
    add_stamp,
    notRegistered,
    customer,
    type,
    stamp_count,
    increasedCashback,
    isSendedUseCashbackCode,
    isStandardBonus,
    setIsStandardBonus,
    whatsAppCode,
    setWhatsAppCode,
  } = props;
  const [error, setErrror] = useState(false);

  const renderWhatsAppField = () => {
    if (bonuses <= 0 || !customer.send_code_for_use_cashback) return null;

    return (
      <div>
        <p>{t('whatsapp_code')}</p>
        <input
          placeholder="Whatsapp code"
          value={whatsAppCode}
          onChange={(e) => setWhatsAppCode(e.target.value)}
          style={error ? {border: '1px solid red'} : {}}
        />
      </div>
    )
  }

  const onSubmit = () => {

    if (bonuses > 0 && !customer.send_code_for_use_cashback && isSendedUseCashbackCode && !whatsAppCode.length) {

      return setErrror(true);
    }

    confirmPayment();
  }

  const renderBonus = () => {
    if (!increasedCashback) {
      return (
        <div className={styles.default}>
          <p>{t('NSPayModal.bonuses')}:</p>
          <div>
          <span style={{color: '#73D389', display: "flex", alignItems: 'center', gap: "6px"}}>
            {(amount_sum - bonuses) * client_cashback / 100} {`(${client_cashback}%)`}
          </span>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.default}>
        <p>{t('NSPayModal.bonuses')}:</p>
        <div>
          <span style={{color: '#73D389', display: "flex", alignItems: 'center', gap: "6px"}}>
            <Checkbox checked={isStandardBonus} onChange={() => setIsStandardBonus(true)}/>
            <p style={{marginTop: "2px", fontSize: "15px"}}>
              {t("countOfStandardBonus", {
                bonuses: (amount_sum - bonuses) * client_cashback / 100,
                percent: client_cashback
              })}
            </p>
          </span>
          <span style={{color: '#73D389', display: "flex", alignItems: 'center', gap: "6px"}}>
            <Checkbox checked={!isStandardBonus} onChange={() => setIsStandardBonus(false)}/>
            <p style={{marginTop: "2px", fontSize: "15px"}}>
              {t("countOfIncreasedBonus", {
                bonuses: (amount_sum - bonuses) * increasedCashback / 100,
                percent: increasedCashback
              })}
            </p>
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.backButton} onClick={() => setStep(2)}>
        <img src={backButtonIcon}/>
      </div>
      <div className={styles.default}>
        <p>{t('NSPayment.account')}:</p>
        <span>{amount_sum}</span>
      </div>
      {/*{type == 'code' && (*/}
      {/*  */}
      {/*)}*/}
      <div className={styles.default}>
        <p>{t('NSPayment.takingBonus')}:</p>
        <span>{bonuses ? bonuses : 0}</span>
      </div>
      {type == 'phone' && stamp_count && (
        <div className={styles.default}>
          <p>{t('NSPayModal.stamps')}:</p>
          <span>{stamp_count}</span>
        </div>
      )}


      {/*<div className={styles.default}>*/}
      {/*  <p>Способ оплаты:</p>*/}
      {/*  <span><img style={{ height: '42px' }} src={paymentType[payment]} title={payment}/></span>*/}
      {/*</div>*/}
      <div className={styles.default}>
        <p style={{ marginRight: '21px' }}>{t('comment')}:</p>
        <span style={{ display: 'block', maxWidth: '290px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{comment.length ? comment : '-'}</span>
      </div>
      {type == 'code' && stamp_count && (
        <div className={styles.default}>
          <p>{t('NSPayModal.stamps')}:</p>
          <span>{stamp_count}</span>
        </div>
      )}
      {renderBonus()}
      <div className={styles.default}>
        <p>
          {/*{add_stamp && 'Награда:'}*/}
          {give_award && `${t('NSPayModal.gift')}:`}
        </p>
        <span>
          {/*{add_stamp && 'Начислена'}*/}
          {give_award && `${t('NSPayModal.given')}`}
        </span>
      </div>

      <div className={styles.marked}>
        <p>{t('NSClients.toSum')}</p>
        <span>{amount_sum - bonuses}</span>
      </div>
      {
        notRegistered && (
          <div className={styles.notRegistered}>
            <img src={exclaimImg} />
            <div>
              <p>{t('NSClients.sms')}</p>
              <p>{t('NSClients.recommend')}</p>
            </div>
          </div>
        )
      }

      {renderWhatsAppField()}

      <button
        onClick={onSubmit}
        disabled={payment_status === PENDING}
        style={payment_status === PENDING ? {background: 'gray'} : {}}>
        {payment_status === PENDING ? <Loader/> : t('NSPayModal.confirmPayment')}
      </button>
      {payment_status === ERROR && !notRegistered && <div className={styles.errorMessage}>
        {/*<span><i style={{ marginRight: "5px" }}>✖</i><span>{t('NSPayModal.notActive')}</span></span>*/}
        <span><i style={{ marginRight: "5px" }}>✖</i><span>{t('Errors.error')}</span></span>
      </div>
      }
    </div>
  )
}
