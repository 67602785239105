import React from 'react'
import { useTranslation } from "react-i18next";
import NotificationItem from "../NotificationItem/NotificationItem";
import styles from './NotificationsList.module.css'

export default function NotificationsList({messages, page, changePageAction, count}) {
    const { t } = useTranslation();
    const pages = []

    for (let i = 1; i <= Math.ceil(count / 20); i++) {
        pages.push(i)
    }

    const onChangePageByNumbers = (page) => {
        changePageAction(page)
    }


    const onChangePageForward = () => {
        if (page === pages.length) {
            changePageAction(1)
        } else {
            changePageAction(page + 1)
        }
    }

    const onChangePageToBack = () => {
        if (page === 1) {
            changePageAction(pages.length)
        } else {
            changePageAction(page - 1)
        }
    }


    return (
        <div className={styles.container}>
            <h3>{t('previous')}</h3>

            {messages && messages.map((item,index)=>(
                <div style={{margin: '14px 0'}} key={index}>
                    <NotificationItem item={item}/>
                </div>
            ))}


            {pages.length > 1 &&
            <div className={styles.pagination}>
                <i className="fa fa-chevron-left" aria-hidden="true" onClick={onChangePageToBack}/>
                <div className={styles.paginationNumbers}>
                    {pages.map((number, index) => (
                        <p
                            key={index}
                            style={page === number ? {background: "#E7E7E6"} : {}}
                            onClick={() => onChangePageByNumbers(number)}
                        >
                            {number}
                        </p>
                    ))}
                </div>
                <i className="fa fa-chevron-right" aria-hidden="true" onClick={onChangePageForward}/>
            </div>
            }
        </div>
    )
}