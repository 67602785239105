import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import Notifications from "./components/Notifications/Notifications";
import { useDispatch, useSelector } from "react-redux";
import notificationsActions from "../../store/notificationsStore/notificationsActions";

export default function NotificationsContainer() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const {data, page, status, message_status} = useSelector(state => state.notificationsReducer)
    const {info} = useSelector(state => state.branchReducer)


    useEffect(() => {
        if (info) {
            dispatch(notificationsActions.getNotificationsData(info && info.id, page))
        }
    }, [page, info])

    const changePageAction = (page) => {
        dispatch(notificationsActions.changePage(page))
    }

    const sendMessageWithImage = (formData, content, resolve) => {
        dispatch(notificationsActions.sendMessageWithImage(info.id, formData, content, resolve))
    }

    const sendMessage = (content, resolve) => {
        dispatch(notificationsActions.sendMessage(info.id, content, resolve))
    }

    return (
        <Notifications
            count={data.count}
            messages={data.messages}
            changePageAction={changePageAction}
            page={page}
            status={status}
            messageStatus={message_status}
            sendMessageWithImage={sendMessageWithImage}
            sendMessage={sendMessage}
        />

    )
}