import instance from 'api';
import { convertDataToTransactions, convertDateToShortString } from '../../tools/convertDateToString';

export const getBranchReviewStats = (id) => {
  return instance().get(`/branches/${id}/reviews/stats`).then(res => res.data)
};

export const getBranchReviews = (branchId, lastReviewId = '', from = '', to = '') => {
  if (convertDateToShortString(new Date()) === convertDateToShortString(new Date(from)) && convertDateToShortString(new Date()) === convertDateToShortString(new Date(to))) {
    return instance().get(`/branches/${branchId}/reviews?before=${lastReviewId ? lastReviewId : ''}`).then(response => response.data);
  }

  const fromDate = convertDataToTransactions(from);
  const toDate = convertDataToTransactions(to);

  return instance().get(`/branches/1/reviews?before=${lastReviewId ? lastReviewId : ''}&from=${fromDate}&to=${toDate}`).then(response => response.data);
};

export const answerToReview = (branchId, reviewId, comment) => {
  return instance().post(`/branches/${branchId}/reviews/${reviewId}/answer`, comment).then(response => response.data);
};

export const editAnswerCommentToReview = (branchId, reviewId, comment) => {
  return instance().put(`/branches/${branchId}/reviews/${reviewId}/answer/edit`, comment).then(response => response.data);
};