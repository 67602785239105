import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import TextField from "../../../../components/FormElements/TextField";
import PageHeader from "../../../../components/PageHeader";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import CustomSelect from "../../../../components/FormElements/Select";
import { useBonusCross } from "../../hooks/useBonusCross";
import styles from './profileBonusCross.module.scss';
import Modal from "../../../../portals/Modal";
import { StyledSelect } from "../../../../components/Analytics/DropdownSelector/DropdownSelector";
import crossActions from "../../../../store/crossStore/crossActions";
import Checkbox from "../../../../components/FormElements/Checkbox";

export default function ProfileBonusCross() {
    const { t } = useTranslation();
    const {
        formik,
        pending,
        crossPending,
        errorModal,
        setErrorModal,
        error,
        options,
        setIsAllCities,
        isAllCities,
        valueCities,
        selectedCities,
        setSelectedCities,
    } = useBonusCross();


    return (
        <>
            <PageHeader title={t('NSCrossMarketing.crossBon')}>
                <div style={{ marginLeft: '50px' }}>
                    <QuestionTooltip text={t('NSCrossMarketing.sendClCrossPart')} />
                </div>
            </PageHeader>
            <div className={styles.container}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.block}>
                        <TextField
                            label={t('NSCrossMarketing.pointBonNewCl')}
                            // type="number"
                            value={formik.values.bonus_for_new_client}
                            disabled={!formik.values.enabled}
                            error={false}
                            onChange={(e) => formik.setFieldValue('bonus_for_new_client', +e.target.value || '')}
                        />
                    </div>

                    <div className={styles.block}>
                        <TextField
                            label={t('NSCrossMarketing.pointBonLoyalCl')}
                            // type="number"
                            value={formik.values.bonus_for_loyalty_client}
                            disabled={!formik.values.enabled}
                            onChange={(e) => formik.setFieldValue('bonus_for_loyalty_client', +e.target.value || '')}
                        />
                    </div>

                    <div className={styles.block}>
                        <div style={{marginBottom: 14, fontWeight: 500, fontSize: 16, color: '#1D1F23'}}>{t('period')}</div>
                        <CustomSelect
                          options={options}
                          disabled={!formik.values.enabled}
                          value={formik.values.interval}
                          onSelect={(o) => formik.setFieldValue('interval', o)}
                        />
                    </div>

                    <div className={styles.block}>
                        <div style={{marginBottom: 14, fontWeight: 500, fontSize: 16, color: '#1D1F23'}}>{t('NSCrossMarketing.city')}</div>
                        <StyledSelect
                          style={{minHeight: 50}}
                          options={valueCities}
                          // loading={pendingCities}
                          placeholder={t('NSCrossMarketing.chooseCity')}
                          values={selectedCities.map(({id, name}) => ({id, name}))}
                          multi
                          onChange={(options) => {
                              setSelectedCities(options);
                          }}
                          color={"#62C68A"}
                          keepSelectedInList={true}
                          closeOnSelect={true}
                          labelField={'name'}
                          valueField={'id'}
                          noDataRenderer={() => <div></div>}
                          disabled={!valueCities.length || isAllCities}
                          // loading={status === PENDING}
                          searchable={false}
                        />
                    </div>

                    <div className={styles.block}>
                        <Checkbox
                          label={t('all-cities')}
                          name="isAllCities"
                          checked={isAllCities}
                          onChange={() => setIsAllCities(!isAllCities)}
                        />
                    </div>

                    <div className={styles.footer}>
                        <div className={styles.block} style={{width: '100%'}}>
                            <TextField
                              label={t('NSCrossMarketing.minCheck')}
                              // type="number"
                              value={formik.values.min_order_amount}
                              onChange={(e) => formik.setFieldValue('min_order_amount', +e.target.value || '')}
                              disabled={!formik.values.enabled}
                            />
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <ButtonFilled disabled={error} type="submit" loading={pending || crossPending}>{t('save')}</ButtonFilled>
                    </div>
                </form>
            </div>
            <Modal
              open={errorModal} size="small"
              onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                          loading={pending}
                          onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
