import React from 'react'
import styles from './Analytics.module.css'
import {Route, Switch} from "react-router-dom";
import AudienceContainer from "./components/AudienceContainer/AudienceContainer";
import GeneralContainer from "./components/GeneralContainer/GeneralContainer";
import LoyalContainer from "./components/LoyalContainer/LoyalContainer";
import RFMAnalysis from "./components/RFMAnalysis/RFMAnalysis";
import RFMClients from './components/RFMClients/RFMClients';
import TransactionsBonuses from '../Transactions/TransactionsBonusComponent';
import BusinessReport from './components/BusinessReport/BusinessReportContainer';

export default function Analytics() {
    return (
        <div className={styles.container}>
            <div className={styles.analytics}>
                <Switch>
                    <Route exact path={'/analytics/rfm'} render={() => <RFMAnalysis/>}/>
                    <Route exact path={'/analytics'} render={() => <GeneralContainer/>}/>
                    <Route exact path={'/analytics/loyal'} render={() => <LoyalContainer/>}/>
                    <Route exact path={'/analytics/audit'} render={() => <AudienceContainer/>}/>
                    <Route exact path={'/analytics/bonuses'} render={() => <TransactionsBonuses/>}/>
                    <Route exact path={'/analytics/rfm/:clientType'} render={() => <RFMClients/>}/>
                    <Route exact path={'/analytics/business-report'} render={() => <BusinessReport/>}/>
                </Switch>
            </div>
        </div>
    )
}