const generalTypes = {
    GA_GET_DATA_PENDING: "GA_GET_DATA_PENDING",
    GA_GET_DATA_SUCCESS: "GA_GET_DATA_SUCCESS",
    GA_GET_DATA_ERROR: "GA_GET_DATA_ERROR",


    GA_CHANGE_DATA_PERIOD: "GA_CHANGE_DATA_PERIOD",
    GA_CHANGE_DATE_FROM: "GA_CHANGE_DATE_FROM",
    GA_CHANGE_DATE_TO: "GA_CHANGE_DATE_TO",
}

export default generalTypes