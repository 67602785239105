import React, { useState, useEffect, useRef } from 'react';
import { computeSkipElements } from "../../tools/utils";
import styles from './pagination.module.css';

const leftArrow = (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 1L1 8L7.5 14.5" stroke="#1D1F23" strokeLinecap="round"/>
    </svg>
);

const rightArrow = (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 1L7 8L0.5 14.5" stroke="#1D1F23" strokeLinecap="round"/>
    </svg>
);

export default function Pagination({
    count,
    limit,
    max,
    loading,
    updated,
    onSkip,
    page,
    setPage
}) {
    const pages = useRef([]);
    const halfOfMax = useRef(Math.floor(max / 2));
    const halfOfMaxHalf = useRef(Math.floor(halfOfMax.current / 2));
    const [currentPages, setCurrentPages] = useState([]);

    const createArray = (c) => {
        return new Array(c).fill('').map((_, i) => i + 1);
    }

    useEffect(() => {
        if (updated) {
            setPage(1);
        }
    }, [updated])

    useEffect(() => {
        pages.current = createArray(Math.ceil(count / limit))
    }, [count, limit, page])

    useEffect(() => {
        const dotsInitial = '...';
        const dotsLeft = '... ';
        const dotsRight = ' ...';

        let tempNumberOfPages = [...pages.current];

        if (count === limit || count < limit) {
            return setCurrentPages(tempNumberOfPages);
        } else if (count > limit) {
            if (page >= 1 && page <= (max - 1)) {
                if (pages.current.length > max) {
                    tempNumberOfPages = [...createArray(max - 1)].concat([dotsInitial, pages.current.length]);
                }
                if (pages.current.length <= max) {
                    tempNumberOfPages = [...pages.current];
                }
            } else if (page === max) {
                const sliced = pages.current.slice(0, (max));
                tempNumberOfPages = [...sliced, dotsInitial, pages.current.length]
            } else if (page > max && page < pages.current.length - halfOfMax.current) {
                const slicedFirstHalf = pages.current.slice(page - halfOfMax.current, page);
                const slicedSecondHalf = pages.current.slice(page, page + halfOfMaxHalf.current);
                tempNumberOfPages = [
                    1, dotsLeft,
                    ...slicedFirstHalf, ...slicedSecondHalf,
                    dotsRight, pages.current.length
                ]
            } else if (page > pages.current.length - (max - 1)) {
                const sliced = pages.current.slice(pages.current.length - (max - 1));
                tempNumberOfPages = [1, dotsLeft, ...sliced];
            }
        }

        setCurrentPages(tempNumberOfPages);
    }, [page, count]);

    const onPageChanged = (page_index) => () => {
        setPage(page_index);
    }

    const onChangePage = (page_index, index) => () => {
        if (page_index == page || loading) return

        if (isFinite(page_index)) {
            if (!computeSkipElements({page_index, limit})) return onSkip(0, onPageChanged(1));
            return onSkip(computeSkipElements({page_index, limit}), onPageChanged(page_index));
        }
        if (index < Math.ceil(currentPages.length / 2)) {
            return onSkip((page - 1 ? page - 1 : 1) * limit, onPageChanged((page - 1 ? page - 1 : 1)));
        }
        if (index > Math.floor(currentPages.length / 2)) {
            return onSkip((page + 1 >= pages.current.length ? pages.current.length : page) * limit, onPageChanged((page + 1 >= pages.current.length ? pages.current.length : page + 1)));
        }
    }

    const renderButtons = () => {
        return currentPages.map((p, i) => (
            <button
                key={page + i}
                className={`${page == p ? styles.active : ''} ${loading ? styles.loading : ''}`}
                onClick={onChangePage(p, i)}
            >{p}</button>
        ))
    }

    return (
        pages.current.length >= 2 ? <div className={styles.pagination}>
            <button
                className={`${loading ? styles.loading : ''}`}
                onClick={() => onChangePage(page > 1 ? page - 1 : 1)()}>{leftArrow}</button>
            <div className={styles.paginationNumbers}>
                {renderButtons()}
            </div>
            <button
                className={`${loading ? styles.loading : ''}`}
                onClick={() => onChangePage(page < pages.current.length ? page + 1 : pages.current.length)()}>{rightArrow}</button>
        </div> : null
    )
}
