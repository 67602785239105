import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from './assets/calendar.svg'
import styles from './CustomDatePicker.module.css'

const ExampleCustomTimeInput = ({date, value, onChange}) => (
    <input
        value={value}
        onChange={e => onChange(e.target.value)}
    />
)


export default function CustomDatePicker({type, trans, tEndDate = new Date(), tSetEndDate, tSetStartDate, tStartDate = new Date(),changePageAction, icon = true}) {

    const [startDate, setStartDate] = useState(tStartDate);
    const [endDate, setEndDate] = useState(tEndDate);

    useEffect(() => {
        if (type === 4) {
            setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
        } else if (type === 3) {
            setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)))
        } else if (type === 2) {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - 7)))
        } else if (type === 1) {
            setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)))
        }
    }, [type])

    if (!trans) {
        return (
            <div className={styles.container}>
                {icon && <img src={calendarIcon}/>}
                <DatePicker
                    disabled
                    dateFormat="dd.MM.yyyy"
                    selected={startDate}
                    customTimeInput={<ExampleCustomTimeInput/>}
                />
                <span>-</span>
                <DatePicker
                    disabled
                    dateFormat="dd.MM.yyyy"
                    selected={endDate}
                    customTimeInput={<ExampleCustomTimeInput/>}
                />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <img src={calendarIcon}/>
            <DatePicker
                selected={tStartDate}
                onChange={date => {
                    if (changePageAction) {
                      changePageAction(1)
                    }
                    tSetStartDate(date)}
                }
                dateFormat="dd.MM.yyyy"
                placeholderText="        -- -- --"
                customTimeInput={<ExampleCustomTimeInput/>}
            />
            <span>—</span>
            <DatePicker
                selected={tEndDate}
                onChange={date => {
                    if (changePageAction) {
                      changePageAction(1)
                    }
                    tSetEndDate(date)
                }}
                placeholderText="-- -- --           "
                dateFormat="dd.MM.yyyy"
                customTimeInput={<ExampleCustomTimeInput/>}
            />
        </div>
    )
}

export function CustomSingleDatePicker({tSetDate, tDate = new Date(), icon = true, ...props}) {

    const [date, setDate] = useState(null);
    const datePickerRef = useRef(null);

    useEffect(() => {
        if (!tDate) return setDate(null);
        setDate(new Date(tDate));
    }, [tDate])

    return (
        <div className={styles.singleContainer}>
            <img src={calendarIcon} onClick={() => datePickerRef.current?.setFocus()} />
            <DatePicker
                ref={datePickerRef}
                placeholderText="-- -- --"
                selected={date}
                onChange={date => tSetDate ? tSetDate(date) : setDate(date)}
                dateFormat="dd-MM-yyyy"
                customTimeInput={<ExampleCustomTimeInput/>}
                {...props}
            />
        </div>
    )

}

