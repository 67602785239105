import React, { useMemo } from 'react'
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Select from 'react-dropdown-select';
import { PENDING } from "../../../store/globalStatuses/globalStatuses";
import styles from './DropdownSelector.module.css'

export default function DropdownSelector({changePeriod, status, type, trans}) {
    const { t } = useTranslation();

    const options = trans ? [
        {
            "name": t('NSTransactions.period'),
            "value": 5,
        },
        {
            "name": t('NSTransactions.year'),
            "value": 4,
        },
        {
            "name": t('month'),
            "value": 3,
        },
        {
            "name": t('week'),
            "value": 2,
        },
        {
            "name": t('day'),
            "value": 1,
        },
    ] : [
        {
            "name": t('NSTransactions.year'),
            "value": 4,
        },
        {
            "name": t('month'),
            "value": 3,
        },
        {
            "name": t('week'),
            "value": 2,
        },
        {
            "name": t('day'),
            "value": 1,
        },
    ]

    return (
        <div className={styles.container}>
            <StyledSelect
                options={options}
                values={type ? options.filter(item => item.value === type) : [options[0]]}
                onChange={(option) => changePeriod(option[0].value)}
                color={"#62C68A"}
                keepSelectedInList={true}
                closeOnSelect={true}
                labelField={'name'}
                valueField={'value'}
                loading={status === PENDING}
                disabled={status === PENDING}
                searchable={false}
            />
        </div>

    )
}

export const StyledSelect = styled(Select)`
  //background: #333;
    border-radius: 6px !important;
    border: 1px solid #62C68A !important;
    padding: 12px 18px 10px 24px !important;
    font-family: graphic, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    .react-dropdown-select-option {
        padding: 2px 4px;
    }
  //color: #fff;
  //.react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: white;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #62C68A;
    border-radius: 0 6px 6px 6px;
  }
    input {
        //display: none;
    }

  //.react-dropdown-select-option {
  //  border: 1px solid #fff;
  //}
  //.react-dropdown-select-item {
  //  color: #333;
  //}
  //.react-dropdown-select-input {
  //  color: #fff;
  //}
  //.react-dropdown-select-dropdown {
  //  position: absolute;
  //  left: 0;
  //  border: none;
  //  width: 500px;
  //  padding: 0;
  //  display: flex;
  //  flex-direction: column;
  //  border-radius: 2px;
  //  max-height: 300px;
  //  overflow: auto;
  //  z-index: 9;
  //  background: #333;
  //  box-shadow: none;
  //  color: #fff !important;
  //}
  //.react-dropdown-select-item {
  //  color: #f2f2f2;
  //  border-bottom: 1px solid #333;
  //     
  //  :hover {
  //     color: #ffffff80;
  //  }
  //}
  //.react-dropdown-select-item.react-dropdown-select-item-selected,
  //.react-dropdown-select-item.react-dropdown-select-item-active {
  //  //background: #111;
  //  border-bottom: 1px solid #333;
  //  color: #fff;
  //  font-weight: bold;
  //}
  //.react-dropdown-select-item.react-dropdown-select-item-disabled {
  //  background: #777;
  //  color: #ccc;
  //}
`;