import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { PageLayout } from '../Layout';
import TextField from 'components/FormElements/TextField';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import { useChangePassword } from "../../hooks/useChangePassword";
import styles from './changePassword.module.scss';

export default function ChangePassword() {
  const { t } = useTranslation();
  const {
    type,
    passwordTypeBtn,
    formik,
    pending,
    type1,
    passwordTypeBtn1,
    type2,
    passwordTypeBtn2,
  } = useChangePassword(t);


  return (
    <PageLayout
      title={t('NSSidebar.changePassword')}
    >
      <TextField
        label={t('oldPass')}
        placeholder={t('enterPass')}
        disabled={pending}
        type={type}
        postfix={passwordTypeBtn}

        value={formik.values.old_password}
        error={formik.touched.old_password && formik.errors.old_password}
        onChange={(e) => formik.setFieldValue('old_password', e.target.value)}
      />

      <TextField
        label={t('newPass')}
        placeholder={t('enterPass')}
        disabled={pending}
        type={type1}
        postfix={passwordTypeBtn1}

        value={formik.values.new_password}
        error={formik.touched.old_password && formik.errors.new_password}
        onChange={(e) => formik.setFieldValue('new_password', e.target.value)}
      />

      <TextField
        label={t('repeatNewPass')}
        placeholder={t('enterPass')}
        disabled={pending}
        type={type2}
        postfix={passwordTypeBtn2}

        value={formik.values.repeat_password}
        error={formik.touched.old_password && formik.errors.repeat_password}
        onChange={(e) => formik.setFieldValue('repeat_password', e.target.value)}
      />

      <div style={{ height: '30px' }}></div>
      <ButtonFilled
        onClick={formik.handleSubmit}
        loading={pending}
      >
        {t('save')}
      </ButtonFilled>
    </PageLayout>
  )
}