import * as Yup from 'yup';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';

export const useBonusActivate = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const { info, pending } = useSelector(s => s.branchReducer);
    // const [isBonusActivateMessagesPushing, setIsBonusActivateMessagesPushing] = useState(false);

    const options = useRef([
        {
            "label": 'NSActive.oneDay',
            "value": 8.64e+7,
        },
        {
            "label": 'NSActive.threeDay',
            "value": 2.592e+8,
        },
        {
            "label": 'week',
            "value": 6.048e+8,
        },
        {
            "label": 'NSActive.twoWeeks',
            "value": 1.21e+9,
        },
        {
            "label": "NSActive.threeWeeks",
            "value": 1.814e+9,
        },
        {
            "label": "month",
            "value": 2.628e+9,
        },
    ]);

    const formik = useFormik({
        initialValues: {
            enabled: false,
            delay_interval: {label: '', value: ''},
        },
        validationSchema: Yup.object({
            delay_interval: Yup.object().shape({label: Yup.string(), value: Yup.number()}).required(t('chooseInterval')),
        }),
        onSubmit: (async (values) => {
            // changeWhatsappPush()
            try {
                if (info.id) {
                    const delay_interval = values.delay_interval.value ? values.delay_interval.value : 0;
                    dispatch(branchActions.changeBonusDelay(info.id, {...values, delay_interval, group_id: info.group_id}))
                }
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (info.configuration.delay_bonuses) {
                formik.setFieldValue('enabled', info.configuration.delay_bonuses.enabled);
                if (info.configuration.delay_bonuses.enabled) {
                    const option = options.current.find((o) => o.value == info.configuration.delay_bonuses.delay_interval) || {label: '', value: ''};
                    setTimeout(() => {
                        formik.setFieldValue('delay_interval', option);
                    }, 100)
                }
            }
            // if (info?.configuration?.whatsapp_provider?.prohibitions) {
            //     setIsBonusActivateMessagesPushing(info?.configuration?.whatsapp_provider?.prohibitions?.bonus_activate_messages || false);
            // }
        }
    }, [info]);

    useLayoutEffect(() => {

    }, [info]);

    useEffect(() => {
        if (!formik.values.enabled) {
            formik.setFieldValue("delay_interval", {label: '', value: ''})
        }
    }, [formik.values.enabled]);

    // const changeWhatsappPush = () => {
    //     dispatch(branchActions.changeWhatsappProhibitions(info.id, { enabled: isBonusActivateMessagesPushing, type: 'bonus_activate_messages' }));
    // }

    return {
        info,
        formik,
        pending,
        errorModal,
        setErrorModal,
        // isBonusActivateMessagesPushing,
        // setIsBonusActivateMessagesPushing,
        options: options.current
    }
}
