import instance from "../../../api";
import queryString from "query-string";

const RFMApi = {
    getRFM: (branch_id) => {
        return instance().get(`/branches/${branch_id}/statistics/rfm_analysis`).then(res => res.data.data)
    },
    getAnalyticRFM: (groupId, invisParams) => {
        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `?${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery ? invisQuery + '&' : '?'}${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }

        return instance().get(`/branches/group/${groupId}/statistics/rfm_analysis${invisQuery}`).then(res => res.data.data)
    },
    saveRFM: (branch_id, data) => {
        return instance().post(`/branches/${branch_id}/configuration/rfm_analysis/change/indexes`, data).then(res => res.data.data)
    },
}

export default RFMApi