import {PENDING, ERROR, SUCCESS} from "../../globalStatuses/globalStatuses";
import loyalTypes from "./loyalTypes";

const initialState = {
    data: [],
    status: '',

    returned_amount: 0,
    returned_average_check: 0,
    returned_clients: 0,
    returned_transactions: 0,

    news_amount: 0,
    news_average_check: 0,
    news_clients: 0,
    news_transactions: 0,

    type: 4,
}


const loyalReducer = (state = initialState, action) => {
    switch (action.type) {

        case loyalTypes.LA_GET_DATA_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case loyalTypes.LA_GET_DATA_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
                returned_amount: action.payload.returned_amount,
                returned_average_check: action.payload.returned_average_check,
                returned_clients: action.payload.returned_clients,
                returned_transactions: action.payload.returned_transactions,

                news_amount: action.payload.news_amount,
                news_average_check: action.payload.news_average_check,
                news_clients: action.payload.news_clients,
                news_transactions: action.payload.news_transactions,
            }

        case loyalTypes.LA_GET_DATA_ERROR:
            return {
                ...state,
                status: ERROR
            }


        case loyalTypes.LA_CHANGE_DATA_PERIOD:
            return {
                ...state,
                type: action.payload
            }


        case loyalTypes.default:
            return {
                ...state
            }


        default:
            return {
                ...state
            }
    }
}

export default loyalReducer