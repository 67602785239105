import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';

export const useBonusNotificationWhatsapp = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const { info, pending } = useSelector(s => s.branchReducer);

    const formik = useFormik({
        initialValues: {
            locales: [],
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (async ({ enabled, ...values }) => {
            // report_phone_numbers
            try {
                if (info.id) {
                    dispatch(branchActions.changeLocales(info.id, { locales: values.locales }))
                }
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (info.configuration.whatsapp_provider && info.configuration.whatsapp_provider.enabled) {
                if (info.locales) {
                    formik.setFieldValue("locales", info.locales);
                }
            }
            // if (!info.configuration.whatsapp_provider || !info.configuration.whatsapp_provider.enabled) {
            //     setError(true);
            //     setErrorModal(true);
            // }
        }
    }, [info]);

    return {
        formik,
        pending,
        errorModal,
        setErrorModal,
        error,
        info,
    }
}
