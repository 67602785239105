import clientInfoTypes from "./clientInfoTypes";
import {PENDING, SUCCESS, ERROR} from "../globalStatuses/globalStatuses";

const initialState = {
    customer: {},
    cStatus: '',
    transactions: [],
    tStatus: '',
    aStatus: '',
    page: 1
}

const clientInfoReducer = (state = initialState, action) => {

    switch (action.type) {
        case clientInfoTypes.RESET_CLIENT_INFO:
            return {
                ...state,
                customer: {},
                cStatus: '',
                transactions: [],
                tStatus: '',
                page: 1
            }

        case clientInfoTypes.GET_CLIENT_INFO_PENDING:
            return {
                ...state,
                cStatus: PENDING
            }

        case clientInfoTypes.GET_CLIENT_INFO_SUCCESS:
            return {
                ...state,
                cStatus: SUCCESS,
                customer: action.payload
            }

        case clientInfoTypes.UPDATE_CLIENT_INFO:
            return {
                ...state,
                cStatus: SUCCESS,
                customer: {
                    ...state.customer,
                    ...action.payload,
                }
            }

        case clientInfoTypes.GET_CLIENT_INFO_ERROR:
            return {
                ...state,
                cStatus: ERROR
            }

        case clientInfoTypes.GET_CLIENT_INFO_AWARD_PENDING:
            return {
                ...state,
                aStatus: PENDING
            }

        case clientInfoTypes.GET_CLIENT_INFO_AWARD_SUCCESS:
            return {
                ...state,
                aStatus: SUCCESS,
                customer: {
                    ...state.customer,
                    activated_at: action.payload.activated_at,
                    expired_at: action.payload.expired_at,
                    received_stamp_count: action.payload.received_stamp_count,
                    stamp_count: action.payload.stamp_count,
                    award_id: action.payload.id
                }
            }

        case clientInfoTypes.GET_CLIENT_INFO_AWARD_ERROR:
            return {
                ...state,
                aStatus: ERROR
            }

        case clientInfoTypes.GET_CLIENT_TRANSACTION_PENDING:
            return {
                ...state,
                tStatus: PENDING
            }

        case clientInfoTypes.GET_CLIENT_TRANSACTION_SUCCESS:
            return {
                ...state,
                tStatus: SUCCESS,
                transactions: action.payload
            }

        case clientInfoTypes.GET_CLIENT_TRANSACTION_ERROR:
            return {
                ...state,
                tStatus: ERROR
            }

        case clientInfoTypes.CHANGE_CLIENT_TRANSACTION_PAGE:
            return {
                ...state,
                page: action.payload
            }


        default:
            return {
                ...state
            }
    }
}

export default clientInfoReducer