import React from 'react'
import styles from './SearchInput.module.css'

export default function SearchInput({changePhoneAction, phone}) {

    return (
        <div className={styles.container}>
            <input
                placeholder={"Поиск по имени или по номеру телефона"}
                value={phone}
                onChange={(e) => changePhoneAction(e.target.value)}
            />
        </div>
    )
}