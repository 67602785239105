import React, { useState, useEffect }  from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { PageLayout } from '../Layout';
import WorkingHour from '../WorkingHour';
import branchActions from 'store/branchStore/branchActions';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import styles from './schedule.module.scss';

const defaultValue = [
  {
    "day": 1,
    "start": "00:00",
    "end": "00:00",
    "dayoff": false,
    "allday": false,
  },
  {
    "day": 2,
    "start": "00:00",
    "end": "00:00",
    "dayoff": false,
    "allday": false
  },
  {
    "day": 3,
    "start": "00:00",
    "end": "00:00",
    "dayoff": true,
    "allday": false
  },
  {
    "day": 4,
    "start": "00:00",
    "end": "00:00",
    "dayoff": false,
    "allday": false
  },
  {
    "day": 5,
    "start": "00:00",
    "end": "00:00",
    "dayoff": false,
    "allday": false
  },
  {
    "day": 6,
    "start": "00:00",
    "end": "00:00",
    "dayoff": true,
    "allday": false
  },
  {
    "day": 7,
    "start": "00:00",
    "end": "00:00",
    "dayoff": true,
    "allday": false
  }
];

export default function Schedule() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { info, pending, error } = useSelector(s => s.branchReducer);
  const [workingHours, setWorkingHours] = useState(defaultValue);

  useEffect(() => {
    if (info) {
      setWorkingHours(info.working_hours);
    }
  }, [info]);

  const onChangeWorkingHours = (i) => (workingHour) => {
    const newWorkingHours = [...workingHours];
    newWorkingHours[i] = workingHour;
    setWorkingHours(newWorkingHours);
  }

  const onSaveWorkingHours = () => {
    dispatch(branchActions.changeWorkingHours(info.id, {working_hours: workingHours}));
  }

  return (
    <PageLayout
      title={t('NSWorking.hours')}
      text={t('NSBonus.information')}
    >
      <div>
        {
          workingHours.map((info, i) => (
            <WorkingHour
              key={i}
              info={info}
              onChangeWorkingHour={onChangeWorkingHours(i)}
            />
          ))
        }
      </div>
      {
        info && (
          <div>
            <ButtonFilled
              loading={pending}
              onClick={onSaveWorkingHours}
            >
              {t('save')}
            </ButtonFilled>
          </div>
        )
      }
    </PageLayout>
  )
}