import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';
import crossActions from "../../../store/crossStore/crossActions";
import { useTranslation } from "react-i18next";

export const useBonusRef = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const { info, pending } = useSelector(s => s.branchReducer);
    const { pending: crossPending } = useSelector(s => s.crossReducer);

    const handleSubmit = async (values) => {
        try {
            if (info.id) {
                dispatch(crossActions.updateBranchConfigReferral(info.id, values))
            }
        } catch (e) {
            const res = e.response ? e.response.data : null;

            store.addNotification({
                title: `Error code: ${res ? res.code : 'NULL'}`,
                message: `${res ? res.message : t('Notify.notRespond')}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });

        }
    }

    const formik = useFormik({
        initialValues: {
            enabled: false,
            bonus_percentage: '',
            // expire_date: 0,
            limit:  ''
        },
        validationSchema: Yup.object({
            bonus_percentage: Yup.string().required(t('Form.fillField')),
            // expire_date: Yup.string().required(t('Form.fillField')),
            limit: Yup.string().required(t('Form.fillField')),
        }),
        onSubmit: (handleSubmit)
    });

    const handleActivation = () => {
        handleSubmit({ enabled: true });
    }
    const handleDeactivation = () => {
        handleSubmit({ enabled: false });
    }

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (info.configuration.referal) {
                formik.setFieldValue('enabled', info.configuration.referal.enabled);
                formik.setFieldValue('expire_date', info.configuration.referal.expire_date);
                formik.setFieldValue('limit', info.configuration.referal.limit);
                formik.setFieldValue('bonus_percentage', info.rule.referal_cashback_percentage || '');
            }
            if (!info.configuration.referal || !info.configuration.referal) {
                setError(true);
                setErrorModal(true);
            }
        }
    }, [info]);

    return {
        formik,
        pending,
        crossPending,
        errorModal,
        setErrorModal,
        error,
        info,
        handleActivation,
        handleDeactivation,
    }
}
