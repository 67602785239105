import React, { useEffect } from "react";
import cookies from 'react-cookies';
import Layout from "./components/Layout/Layout";
import { Redirect, useHistory } from 'react-router-dom';
import { COOKIES } from './helpers';

function App() {
  const history = useHistory();

  // useEffect(() => {
  //   history.push('/');
  // }, []);

  const token = !!cookies.load(COOKIES.TOKEN);

  if (!token) {
    return <Redirect to="/auth" />
  }

  return <Layout/>
}

export default App;
