import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import surveyActions from "../../../store/surveyStore/surveyActions";
import { PENDING } from "../../../store/globalStatuses/globalStatuses";
import { useHistory } from 'react-router-dom';
import styles from "../components/SurveyCreate/surveyCreate.module.scss";

const radioSvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" stroke="#6C6D6C"/>
      <circle cx="12" cy="12" r="8" fill="#6C6D6C"/>
    </svg>
);

const checkBoxSvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H20C21.933 0.5 23.5 2.067 23.5 4V20C23.5 21.933 21.933 23.5 20 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z" fill="#6C6D6C" stroke="#6C6D6C"/>
      <path d="M5 13L11 19L20 6" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const lineSvg = (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 9.8125C0.5 8.98407 1.17157 8.3125 2 8.3125H15C15.8284 8.3125 16.5 8.98407 16.5 9.8125C16.5 10.6409 15.8284 11.3125 15 11.3125H2C1.17157 11.3125 0.5 10.6409 0.5 9.8125Z" fill="#6C6D6C" stroke="#6C6D6C"/>
      <path d="M0.5 17.1875C0.5 16.3591 1.17157 15.6875 2 15.6875H10C10.8284 15.6875 11.5 16.3591 11.5 17.1875C11.5 18.0159 10.8284 18.6875 10 18.6875H2C1.17157 18.6875 0.5 18.0159 0.5 17.1875Z" fill="#6C6D6C" stroke="#6C6D6C"/>
      <path d="M0.5 2.8125C0.5 1.98407 1.17157 1.3125 2 1.3125H22C22.8284 1.3125 23.5 1.98407 23.5 2.8125C23.5 3.64093 22.8284 4.3125 22 4.3125H2C1.17157 4.3125 0.5 3.64093 0.5 2.8125Z" fill="#6C6D6C" stroke="#6C6D6C"/>
    </svg>
);

export const useSurveyCreate = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [withdraw, setWithdraw] = useState(0);
  const types = useRef({
    FREE: 'FREE',
    PAID: 'PAID',
  });
  const answersType = useRef({
    STATIC: 'STATIC',
    DYNAMIC: 'DYNAMIC',
  });
  const questionAnswersType = useRef({
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    TEXT_FIELD: 'TEXT_FIELD',
  });
  const question = useRef({
    title : '',
    is_required : true,
    answers_type : questionAnswersType.current.SINGLE_CHOICE,
    answers : [{ content : '' }]
  })
  const singChoiceQuestion = useRef({
    title : '',
    is_required : true,
    answers_type : questionAnswersType.current.SINGLE_CHOICE,
    answers : [{ content : t('yes') }, { content : t('no') }, { content : t('ownAns') }]
  }, [i18n.language]);
  const options = useRef([
    { value: questionAnswersType.current.SINGLE_CHOICE, label: <div className={styles.questionType}><i>{radioSvg}</i> <span>{t('NSPoll.one')}</span></div> },
    { value: questionAnswersType.current.MULTIPLE_CHOICE, label: <div className={styles.questionType}><i>{checkBoxSvg}</i> <span>{t('NSPoll.some')}</span></div> },
    { value: questionAnswersType.current.TEXT_FIELD, label: <div className={styles.questionType}><i>{lineSvg}</i> <span>{t('text')} <em>{('NSPoll.description')}</em></span></div> }
  ], [i18n.language]);

  const branch = useSelector(s => s.branchReducer);
  const { status } = useSelector(s => s.surveyReducer);

  const formik = useFormik({
    initialValues: {
      title : '',
      type : types.current.FREE,
      bonus: '',
      max_users_count : '',
      answers_type : answersType.current.DYNAMIC,
      questions: [question.current]
    },
    validationSchema: Yup.object({
      title : Yup.string()
          .required(t('Form.enterBranchTitle'))
          .min(3, t('minSymbol', {min: 3})),
      type : Yup.string(),
      bonus: Yup.string().when("type", (type) => {
        if(type === types.current.PAID)
          return Yup.string().required(t('Form.enterBonus'))
      }),
      max_users_count : Yup.string().when("type", (type) => {
        if(type === types.current.PAID)
          return Yup.string().required(t('Form.enterUserCount'))
      }),
      answers_type : Yup.string(),
      questions: Yup.array().of(
              Yup.object().shape({
                title : Yup.string().required(t('Form.enterQuestion')),
                is_required :  Yup.boolean(),
                answers_type : Yup.string(),
                answers: Yup.array().when('answers_type', {
                  is: questionAnswersType.current.TEXT_FIELD,
                  then: (schema) => schema.of(Yup.object().shape({
                    content: Yup.string(),
                  })),
                  otherwise: (schema) => schema.of(Yup.object().shape({
                    content: Yup.string().required(t('Form.enterQuestion'))
                  })).required(t('Form.fillAnswer'))
                })
              })).required(t('Form.fillQuestion'))
    }),
    onSubmit: (async (values, { setSubmitting, resetForm }) => {
      let data = _.assign(values);

      if (data.type === types.current.FREE) {
        data = _.assign(data, { bonus: 0, max_users_count : 0, branch_id: branch.info.id });
      } else {
        data = _.assign(data, { bonus: Number(data.bonus), max_users_count : Number(data.max_users_count), branch_id: branch.info.id });
      }

      new Promise((resolve, reject) => {
        dispatch(surveyActions.createSurvey(data, resolve, reject));
      }).then((id) => {
        history.push(`/profile/surveys/${id}/1/results`);
      });
    })
  });

  useEffect(() => {
    const {type, max_users_count, bonus} = formik.values;

    if (type === types.current.FREE) {
     if (bonus) formik.setFieldValue('bonus', '');
     if (max_users_count) formik.setFieldValue('max_users_count', '');
     if (withdraw) setWithdraw(0);
     return
    }

    const twithdraw = Number(bonus) * Number(max_users_count);
    if (isFinite(twithdraw)) setWithdraw(twithdraw);
  }, [formik.values.type, formik.values.bonus, formik.values.max_users_count]);

  useEffect(() => {
    switch (formik.values.answers_type) {
      case answersType.current.STATIC:
        return formik.setFieldValue('questions', [singChoiceQuestion.current]);
      case answersType.current.DYNAMIC:
        return formik.setFieldValue('questions', [question.current]);
      default: return
    }
  }, [formik.values.answers_type])

  useEffect(() => {
    // const haveTextField = formik.values.questions.some(q => q.answers_type === questionAnswersType.current.TEXT_FIELD);
    // if (haveTextField) {
    //   options.current = [
    //     { value: questionAnswersType.current.SINGLE_CHOICE, label: <div className={styles.questionType}><i>{radioSvg}</i> <span>{t('NSPoll.one')}</span></div> },
    //     { value: questionAnswersType.current.MULTIPLE_CHOICE, label: <div className={styles.questionType}><i>{checkBoxSvg}</i> <span>{t('NSPoll.some')}</span></div> },
    //   ];
    // } else {
    //   options.current = [
    //     { value: questionAnswersType.current.SINGLE_CHOICE, label: <div className={styles.questionType}><i>{radioSvg}</i> <span>{t('NSPoll.one')}</span></div> },
    //     { value: questionAnswersType.current.MULTIPLE_CHOICE, label: <div className={styles.questionType}><i>{checkBoxSvg}</i> <span>{t('NSPoll.some')}</span></div> },
    //     { value: questionAnswersType.current.TEXT_FIELD, label: <div className={styles.questionType}><i>{lineSvg}</i> <span>{t('text')} <em>{t('NSPoll.description')}</em></span></div> }
    //   ];
    // }
    options.current = [
      { value: questionAnswersType.current.SINGLE_CHOICE, label: <div className={styles.questionType}><i>{radioSvg}</i> <span>{t('NSPoll.one')}</span></div> },
      { value: questionAnswersType.current.MULTIPLE_CHOICE, label: <div className={styles.questionType}><i>{checkBoxSvg}</i> <span>{t('NSPoll.some')}</span></div> },
      { value: questionAnswersType.current.TEXT_FIELD, label: <div className={styles.questionType}><i>{lineSvg}</i> <span>{t('text')} <em>{t('NSPoll.description')}</em></span></div> }
    ];
  }, [formik.values.questions, i18n.language])

  return {
    formik,
    types,
    answersType,
    questionAnswersType,
    withdraw,
    options,
    question,
    singChoiceQuestion,
    loading: (status === PENDING),
    disabled: branch.pending,
  }
}
