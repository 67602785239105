import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from "../../../store/branchStore/branchActions";
import { useTranslation } from "react-i18next";

export const useBonusPromocode = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const [promocodes, setPromocodes] = useState([]);
    const { info, pending } = useSelector(s => s.branchReducer);
    const [deleting, setDeleting] = useState(false);

    const formik = useFormik({
        initialValues: {
            amount: "",
            code: "",
            // count: 0,
            description: "",
            expired_at: null,
            // "id": "string",
            title: ""
        },
        validationSchema: Yup.object({
            // delay_interval: Yup.object().shape({label: Yup.string(), value: Yup.number()}).required(t('chooseInterval')),
            title: Yup.string()
              .required(t('Form.requiredField'))
              .min(3, t('Form.minSymbol', {min: 3})),
            description: Yup.string()
              .required(t('Form.requiredField'))
              .min(1, t('Form.minSymbol', {min: 1})),
            code: Yup.string()
              .required(t('Form.requiredField'))
              .min(1, t('Form.minSymbol', {min: 1})),
            amount: Yup.string()
              .required(t('Form.requiredField')),
        }),
        onSubmit: (async ({enabled, ...values}, { resetForm }) => {
            try {
                if (info.id) {
                    new Promise((resolve, reject) => {
                        dispatch(branchActions.postBonusPromocode(info.id, {
                            ...values,
                            expired_at: values.expired_at ? values.expired_at.getTime() : null
                        }, resolve, reject))
                    })
                      .then(() => {
                          resetForm();
                      })

                }
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    const editFormik = useFormik({
        initialValues: {
            amount: "",
            code: "",
            // count: 0,
            description: "",
            expired_at: null,
            id: "",
            title: ""
        },
        validationSchema: Yup.object({
            // delay_interval: Yup.object().shape({label: Yup.string(), value: Yup.number()}).required(t('chooseInterval')),
            title: Yup.string()
              .required(t('Form.requiredField'))
              .min(3, t('Form.minSymbol', {min: 3})),
            description: Yup.string()
              .required(t('Form.requiredField'))
              .min(1, t('Form.minSymbol', {min: 1})),
            code: Yup.string()
              .required(t('Form.requiredField'))
              .min(1, t('Form.minSymbol', {min: 1})),
            amount: Yup.string()
              .required(t('Form.requiredField')),
        }),
        onSubmit: (async ({enabled, ...values}, { resetForm }) => {
            try {
                if (info.id) {
                    new Promise((resolve, reject) => {
                        dispatch(branchActions.postBonusPromocode(info.id, {
                            ...values,
                            expired_at: values.expired_at ? values.expired_at.getTime() : null
                        }, resolve, reject))
                    })
                      .then(() => {
                          resetForm();
                      })

                }
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (info.configuration.promocodes && info.configuration.promocodes.length) {
                setPromocodes(info.configuration.promocodes)
            }
        }
    }, [info]);

    const deletePromocode = (codeId) => {
        if (deleting) return
        setDeleting(true);
        new Promise((resolve, reject) => {
            dispatch(branchActions.deleteBonusPromocode(info.id, codeId, resolve, reject))
        })
          .finally(() => {
              setDeleting(false);
          });
    }

    return {
        formik,
        pending,
        errorModal,
        setErrorModal,
        error,
        promocodes,
        deleting,
        editFormik,
        deletePromocode,
    }
}
