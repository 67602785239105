import adsTypes from "./adsTypes";

const initialState = {
    pending: false,
    currentAds: null,
    saleReport: {
        usersChart: [],
        revenue_amount: {NEW: 0, RETURNED: 0},
        transactions: {NEW: 0, RETURNED: 0},
        clients_count: {NEW: 0, RETURNED: 0},
    },
    ads: {
        count: 0,
        limit: 8,
        list: [],
        currentAds: {},
    },
    comparisons: [],
    revenue_chart: [],
    transaction_chart: [],
    clients_chart: [],
    comparisonsChart: [],
    chosenAds: [],
}

const adsReducer = (state = initialState, action) => {

    switch (action.type) {
        case adsTypes.CREATE_ADS_PENDING:
            return {
                ...state,
                pending: true,
            }
        case adsTypes.CREATE_ADS_ERROR:
            return {
                ...state,
                pending: false,
            }
        case adsTypes.CREATE_ADS_SUCCESS:
            return {
                ...state,
                pending: false,
            }

        case adsTypes.GET_ADS_LIST_FOR_BRANCH_GROUP_PENDING:
            return {
                ...state,
                pending: true,
                ads: {
                    ...state.ads,
                    list: [],
                }
            }
        case adsTypes.GET_ADS_LIST_FOR_BRANCH_GROUP_SUCCESS:
            return {
                ...state,
                pending: false,
                ads: {
                    ...state.ads,
                    list: action.payload.sales,
                    count: action.payload.count,
                }
            }
        case adsTypes.GET_ADS_LIST_FOR_BRANCH_GROUP_ERROR:
            return {
                ...state,
                pending: false,
            }

        case adsTypes.GET_ADS_INFO_PENDING:
            return {
                ...state,
                pending: true,
                currentAds: null,
            }
        case adsTypes.GET_ADS_INFO_SUCCESS:
            return {
                ...state,
                currentAds: action.payload,
                pending: false,
            }
        case adsTypes.GET_ADS_INFO_ERROR:
            return {
                ...state,
                pending: false,
                currentAds: null,
            }

        case adsTypes.UPDATE_ADS_INFO_PENDING:
            return {
                ...state,
                pending: true,
            }
        case adsTypes.UPDATE_ADS_INFO_SUCCESS:
            return {
                ...state,
                currentAds: action.payload,
                pending: false,
            }
        case adsTypes.UPDATE_ADS_INFO_ERROR:
            return {
                ...state,
                pending: false,
            }

        case adsTypes.GET_SALE_REPORT_PENDING:
            return {
                ...state,
                pending: true,
            }
        case adsTypes.GET_SALE_REPORT_SUCCESS:
            return {
                ...state,
                saleReport: action.payload,
                pending: false,
            }
        case adsTypes.GET_SALE_REPORT_ERROR:
            return {
                ...state,
                pending: false,
            }

        case adsTypes.GET_COMPARISONS_PENDING:
            return {
                ...state,
                comparisons: [],
                pending: true,
            }
        case adsTypes.GET_COMPARISONS_SUCCESS:
            return {
                ...state,
                pending: false,
                comparisons: action.payload.ads,
                comparisonsChart: action.payload.adsChart,
                revenue_chart: action.payload.revenue_chart,
                transaction_chart: action.payload.transaction_chart,
                clients_chart: action.payload.clients_chart,
            }
        case adsTypes.GET_COMPARISONS_ERROR:
            return {
                ...state,
                pending: false,
            }

        case adsTypes.CHOSE_ADS:
            return {
                ...state,
                comparisons: [],
                chosenAds: [...state.chosenAds, action.payload]
            }
        case adsTypes.REMOVE_ADS:
            return {
                ...state,
                comparisons: [],
                chosenAds: state.chosenAds.filter((a) => a.id !== action.payload)
            }

        case adsTypes.CLEAR_ADS:
            return {
                ...state,
                comparisons: [],
                chosenAds: []
            }

        default:
            return state
    }
}

export default adsReducer
