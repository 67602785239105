import instance from "../../api";

const profileApi = {
    getProfile: () => {
        return instance().get(`/business-users/@me`).then(res => res.data)
    },
    changePassword: (body) => {
        return instance().post(`/user/@me/reset`, body).then(res => res.data)
    },
}

export default profileApi;