import transactionsType from "./transactionsTypes";
import transactionsApi from "./transactionsApi";
import clientInfoTypes from "../clientInfoStore/clientInfoTypes";
import bonusesModalActions from '../bonusesModalStore/bonusesModalActions';
import bonusesModalTypes from '../bonusesModalStore/bonusesModalTypes';
import { store } from "react-notifications-component";
import i18n from "../../i18n";
import TransactionsTypes from "./transactionsTypes";
import { Roles } from "../../config";

const transactionsActions = {
    getAllTransactions: ({
        branch_id, page, startDate, endDate, phone, searchRef = null,
        roles = [],
        groupId = '',
        invisParams
    }) => async dispatch => {
        dispatch({
            type: transactionsType.GET_TRANSACTIONS_PENDING
        })
        if (roles.includes(Roles.ANALYTIC) && groupId) {
            await transactionsApi.getAnalyticTransactionsData(groupId, page, startDate, endDate, phone, invisParams)
              .then((res) => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_ERROR
                  })
              })
        } else {
            await transactionsApi.getTransactionsData(branch_id, page, startDate, endDate, phone)
              .then((res) => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_ERROR
                  })
              })
        }
          // .finally(() => searchRef && searchRef.current && searchRef.current.focus())
    },
    getAllBonusTransactions: ({
      branch_id, page, startDate, endDate, phone, searchRef = null,
      roles = [],
      groupId = '',
      invisParams,
    }) => async dispatch => {
        dispatch({
            type: transactionsType.GET_TRANSACTIONS_PENDING
        })
        if (roles.includes(Roles.ANALYTIC) && groupId) {
            await transactionsApi.getAnalyticBonusTransactionsData(groupId, page, startDate, endDate, phone, invisParams)
              .then((res) => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_ERROR
                  })
              })
        } else {
            await transactionsApi.getBonusTransactionsData(branch_id, page, startDate, endDate, phone)
              .then((res) => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: transactionsType.GET_TRANSACTIONS_ERROR
                  })
              })
            // .finally(() => searchRef && searchRef.current && searchRef.current.focus())
        }
    },
    sendUseCashbackCode: (userId, branchId, resolve, reject) => async dispatch => {
        dispatch({
            type: transactionsType.SEND_USE_CASHBACK_CODE_PENDING,
            payload: true
        })
        await transactionsApi.sendUseCashbackCode(userId, branchId)
          .then((res) => {
              if (res?.message === "Success") {
                  dispatch({
                      type: transactionsType.SEND_USE_CASHBACK_CODE_SUCCESS,
                      payload: res.data,
                  })
              } else {
                  dispatch({
                      type: transactionsType.SEND_USE_CASHBACK_CODE_ERROR
                  })
              }


              resolve && resolve(res);
          })
          .catch((e) => {
              const res = e.response ? e.response.data : null;

              store.addNotification({
                  title: `Error code: ${res ? res.code : 'NULL'}`,
                  message: `${res ? res.message : i18n.t('Notify.notRespond')}`,
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                      duration: 5000,
                      onScreen: true
                  }
              });

              dispatch({
                  type: transactionsType.SEND_USE_CASHBACK_CODE_ERROR
              })

              reject && reject(e);
          })
        .finally((res) => {
            dispatch({
                type: transactionsType.SEND_USE_CASHBACK_CODE_PENDING,
                payload: false
            })
        })
    },
    changePage: (page) => {
        return {
            type: transactionsType.CHANGE_TRANSACTIONS_PAGE,
            payload: page
        }
    },
    getUserInfo: (branch_id, group_id, type, value, resolve, reject) => dispatch => {
        dispatch({
            type: clientInfoTypes.GET_CLIENT_INFO_PENDING
        });
        return transactionsApi.getUserInfo(type, value, branch_id)
            .then(res => {
                resolve(true)
                let data = res.data
                transactionsApi.getUserBonuses(branch_id, group_id, data.id)
                    .then((res) => {
                        let bonuses = res.data
                        dispatch({
                            type: clientInfoTypes.GET_CLIENT_INFO_SUCCESS,
                            payload: {...data, bonuses: bonuses >= 0 ? bonuses : 0}
                        })
                    })
                    .catch(() => {
                        reject(true);
                        dispatch({
                            type: clientInfoTypes.GET_CLIENT_INFO_ERROR
                        })
                    });
            })
            .catch((e) => {
                dispatch({ type: clientInfoTypes.RESET_CLIENT_INFO });
                dispatch({ type: bonusesModalTypes.BM_RESET_STATE });
                dispatch({ type: clientInfoTypes.GET_CLIENT_INFO_ERROR });

                reject(e.response.data);
            })
    }
}

export default transactionsActions