import filtersTypes from "./filtersTypes";

const filtersActions = {
    changeFilters: (payload) => dispatch => {
        dispatch({
            type: filtersTypes.CHANGE_FILTERS,
            payload,
        });
    },
}

export default filtersActions
