import React, {useState} from 'react'
import styles from './styles.module.scss'
import closeModalImage from "../../../../components/PayModal/assets/CloseModalIcon.svg";
import Modal from "react-modal";
import branchApi from "../../../../store/branchStore/branchApi";
import {Roles} from "../../../../config";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useCurrentBranch} from "../../../../hooks/useCurrentBranch";
import { store } from "react-notifications-component";
import filtersReducer from "../../../../store/filtersStore/filtersReducer";

const SendNotificationModal = (props) => {
  const { info } = useSelector(s => s.branchReducer);
  const profile = useSelector(s => s.profileReducer);
  const { branch_ids } = useSelector(s => s.filtersReducer);

  const { t } = useTranslation();
  const [modalPending, setModalPending] = useState(false);
  const [pushMessage, setPushMessage] = useState('')
  const { currentRole, currentBranch, groupId } = useCurrentBranch();
  const {
    chosenClients,
  } = useSelector(s => s.clientsReducer);

  const onChangePushMessage = (e) => {
    if (e.target.value.length <= 625) {
      setPushMessage(e.target.value);
    }
  }

  const onSendPushToCustomers = () => {
    if (!info || !pushMessage) return

    if (!chosenClients.length) {} else {}

    setModalPending(true);
    if (currentRole === Roles.ANALYTIC && groupId) {
      branchApi.branchesAnalyticCustomersUploadedSend(groupId, {
        user_ids: chosenClients.map((id) => id),
        branch_ids:
          branch_ids.length ?
            branch_ids.map(({value}) => Number(value)) :
              profile.info.branches.map(({id}) => id),
        message: pushMessage,
      })
          .then((res) => {
            store.addNotification({
              title: t('success'),
              message: t('successMessageSent'),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
            setPushMessage('');
          })
          .finally(() => {
            setModalPending(false);
            props.onRequestClose();
          });
    } else {
      branchApi.branchesCustomersUploadedSend(info.id, { message: pushMessage })
          .then((res) => {
            store.addNotification({
              title: t('success'),
              message: t('successMessageSent'),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
            setPushMessage('');
          })
          .finally(() => {
            setModalPending(false);
            props.onRequestClose();
          });
    }

  }

  return (
    <Modal
      {...props}
    >
      <div className={styles.modal}>
        <div className={styles.closeModal} onClick={props.onRequestClose}>
          <img src={closeModalImage}/>
        </div>
        <div className={styles.leftContent}>
          <div className={styles.modalContainer}>
            <div className={styles.header}>
              <p>{t('NSClients.not')}</p>
            </div>
            <div>
              <p className={styles.p}>{t('NSClients.textNot')}</p>
              <textarea
                placeholder={t('Form.enterText')}
                className={styles.textArea}
                value={pushMessage}
                onChange={onChangePushMessage}
                required
              />
              <div className={styles.span}
                   style={{color: `${pushMessage.length >= 624 ? 'tomato' : '#000000'}`}}>
                {t('maxSym', {sym: 625})}
              </div>

              <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginTop: '16px'}}>
                {/*<ButtonFilled*/}
                {/*  className={styles.stopBtn}*/}
                {/*  onClick={onDisableAutoNotification}*/}
                {/*  loading={modalPending || disableAutonotificationStatus}*/}
                {/*>*/}
                {/*  Прервать предыдущую авторассылку*/}
                {/*</ButtonFilled>*/}
                <ButtonFilled
                  className={styles.btn}
                  onClick={onSendPushToCustomers}
                  disabled={(!chosenClients.length) || !pushMessage || !profile?.info?.branches}
                  loading={modalPending}
                >
                  {t('send')}
                </ButtonFilled>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SendNotificationModal