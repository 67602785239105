import profileTypes from "./profileTypes";
import profileApi from "./profileApi";

const profileActions = {
    getProfile: () => dispatch => {
        dispatch({
            type: profileTypes.GET_PROFILE_PENDING
        });

        profileApi.getProfile()
            .then(res => {
                dispatch({
                    type: profileTypes.GET_PROFILE_SUCCESS,
                    payload: res.data
                })
            })
            .catch(() => {
                dispatch({
                    type: profileTypes.GET_PROFILE_ERROR
                })
            });
    },
}

export default profileActions