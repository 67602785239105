import instance from "../../../api";
import queryString from "query-string";

const audienceApi = {
    getAudienceData: async (branch_id, type, dates) => {
        return await instance().get(`/branches/${branch_id}/reports?show_age=true&show_gender=true&show_visitor_hours=true&type=${type}${dates}`).then(res => res.data)
    },
    getAnalyticAudienceData: async (groupdId, type, dates, invisParams) => {
        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `&${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery}&${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }
        return await instance().get(`/branches/group/${groupdId}/reports?show_age=true&show_gender=true&show_visitor_hours=true&type=${type}${dates}${invisQuery}`).then(res => res.data)
    },
}

export default audienceApi