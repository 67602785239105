import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../FormElements/ButtonFilled";
import styles from './notFoundInfo.module.scss';

const NotFoundInfo = ({ phone, bonus, isSending, sendBonuses }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>{phone} {t('NSBonusModal.notReg')}</span>
      </div>
      <div className={styles.info}>
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.2538 0.213826C3.32581 0.861771 0.793171 3.49552 0.176146 6.53381C-1.01405 12.3955 4.01125 17.5738 9.69981 16.3474C13.4909 15.53 16.2153 11.8369 15.9866 7.82506C15.8643 5.67785 15.115 3.98593 13.5844 2.40158C11.6974 0.448186 8.92866 -0.378209 6.2538 0.213826ZM9.69981 1.06747C11.5835 1.47137 13.5594 3.08374 14.4202 4.91976C15.0078 6.17291 15.2005 7.05199 15.1847 8.40612C15.1566 10.8112 14.0562 12.9441 12.121 14.3444C8.53844 16.937 3.46766 15.6093 1.57335 11.5828C0.191684 8.64577 0.71999 5.32121 2.9302 3.045C4.73429 1.18704 7.07207 0.504226 9.69981 1.06747ZM7.45314 3.57312C6.99362 4.04675 7.36742 4.91976 8.02994 4.91976C8.65323 4.91976 9.00572 4.02932 8.56313 3.57312C8.30988 3.31216 7.70639 3.31216 7.45314 3.57312ZM6.89689 6.43749C6.73611 6.74687 6.86368 6.9483 7.25627 7.00499L7.56955 7.05031L7.60363 9.53595L7.63759 12.0216H7.08447C6.49151 12.0216 6.2454 12.281 6.51757 12.6191C6.72095 12.8715 9.29531 12.8715 9.49869 12.6191C9.76986 12.2823 9.52488 12.0216 8.93693 12.0216H8.38882L8.35511 9.14858L8.32141 6.27557L7.6713 6.23683C7.14249 6.20532 6.99801 6.24277 6.89689 6.43749Z" fill="#54BB81"/>
        </svg>
        <span>{t('NSBonusModal.regAuto')}</span>
      </div>
      <div className={styles.body}>
        <p>{t('NSClients.bonusCount')}</p>
        <span>{bonus || 0}</span>
      </div>
      <ButtonFilled loading={isSending} onClick={sendBonuses}  className={styles.btn}>{t('confirm')}</ButtonFilled>
    </div>
  )
}

export default NotFoundInfo
