import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import CommentForm from '../CommentForm';
import { formatDate } from '../../../../helpers';
import styles from './review.module.scss';

import userImg from 'assets/user.png';
import StarRatings from 'react-star-ratings';
import LazyImage from '../../../../components/LazyImage/LazyImage';

const penSvg = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.6377 15.4657C6.48149 15.6219 6.22822 15.6219 6.07201 15.4657L2.55498 11.9487C2.39877 11.7925 2.39877 11.5392 2.55498 11.383L11.8641 2.07391C12.0203 1.9177 12.2735 1.9177 12.4297 2.07391L15.9468 5.59094C16.103 5.74715 16.103 6.00041 15.9468 6.15662L6.6377 15.4657ZM1.68596 12.9992C1.77993 12.7171 2.13802 12.6325 2.34831 12.8428L5.17789 15.6724C5.38819 15.8827 5.3036 16.2408 5.02143 16.3347L0.779101 17.7475C0.466469 17.8516 0.1691 17.5542 0.273211 17.2416L1.68596 12.9992ZM17.4853 4.62344L17.06 5.04875C16.9038 5.20496 16.6506 5.20496 16.4944 5.04875L12.9719 1.52632C12.8157 1.37011 12.8157 1.11684 12.9719 0.960632L13.3972 0.535326C14.1106 -0.178442 15.2673 -0.178442 15.9807 0.535326L17.4853 2.04001C18.1935 2.75572 18.1935 3.90795 17.4853 4.62344Z" />
  </svg>
);

export default function Review({ review, onCommentToReview, index, pendingComment, rate= true, comment = true }) {
  const { t } = useTranslation();
  const [commenting, setCommenting] = useState();

  const onChangeCommenting = () => setCommenting(!commenting);

  const renderCommentLogic = (answer) => {
    if (commenting) return (
        <CommentForm
          answer={!answer}
          onCommentToReview={onCommentToReview}
          reviewId={review.id}
          index={index}
          pending={pendingComment}
          setCommenting={setCommenting}
          t={t}
        />
      )

    if (answer) {
      return (
        <>
          <div className={styles.answer}>
            <div>{t('answer')}:</div>
            <div>{String(answer)}</div>
          </div>
          <button onClick={onChangeCommenting} className={`${styles.changeBtn} ${styles.btn}`}>
            {penSvg}
            <span>{t('change')}</span>
          </button>
        </>
      )
    }

    return (
      <button onClick={onChangeCommenting} className={`${styles.answerBtn} ${styles.btn}`}>
        {penSvg}
        <span>{t('reply')}</span>
      </button>
    )
  }

  const img = review.user.avatar ? review.user.avatar : userImg;

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.logo}><LazyImage src={img} /></div>
        <div className={styles.body}>
          <div className={styles.name}>
              {!review.user.first_name && !review.user.last_name ? t('client') : `${review.user.first_name} ${review.user.last_name}`}
          </div>
          <div className={styles.date}>{formatDate(review.created_at)}</div>
            {
                rate && <StarRatings
                    rating={review.rating}
                    numberOfStars={5}
                    starRatedColor="#F5B35C"
                    starEmptyColor="#E7E7E6"
                    name="rating"
                    starSpacing="1px"
                    starDimension="20px"
                />
            }
            <div className={styles.reviewerText}>{review.comment}</div>
            {comment && renderCommentLogic(review.answer)}
        </div>
      </div>
    </div>
  )
}
