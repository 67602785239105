import React from 'react';
import { NavLink, Switch, Route } from "react-router-dom";
import { PageLayout } from '../Layout';
import ProfileBonusTerm from "../BonusTerm";
import BonusWriteOff from "../BonusWriteOff";
import ProfileBonusActivate from "../BonusActivate";
import ProfileBonusBirthday from "../BonusBirthday";
import ProfileBonusPhotos from "../BonusPhotos";
import ProfileBonusStandard from "../BonusStandard";
import ProfileBonusCorporate from "../BonusCorporate";
import ProfileBonusGradation from "../BonusGradation";
import ProfileBonusAutoMailing from "../BonusAutomailing";
import BonusNotification from "../BonusNotification";
import BonusPromocode from "../BonusPromocode";
import ProfileBonusCross from "../BonusCross";
import BonusRef from "../BonusRef";
import { useTranslation } from "react-i18next";
import styles from './profileBonus.module.scss';
import ProfileBonusTransactionGradation from "../BonusTransactionGradation";

const timeSvg = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99998 0C4.47715 0 0 4.47715 0 9.99998C0 15.5228 4.47715 20 9.99998 20C15.5228 20 20 15.5228 20 9.99998C19.9941 4.47961 15.5204 0.00590122 9.99998 0ZM9.99998 18.5714C5.26611 18.5714 1.42856 14.7338 1.42856 9.99998C1.42856 5.26611 5.26611 1.42856 9.99998 1.42856C14.7338 1.42856 18.5714 5.26611 18.5714 9.99998C18.5663 14.7318 14.7318 18.5663 9.99998 18.5714Z"/>
        <path d="M9.99998 3.57129C9.60547 3.57129 9.28568 3.89109 9.28568 4.28559V9.2856H4.28571C3.89121 9.2856 3.57141 9.60539 3.57141 9.9999C3.57141 10.3944 3.89121 10.7142 4.28571 10.7142H9.99998C10.3945 10.7142 10.7143 10.3944 10.7143 9.99986V4.28559C10.7143 3.89109 10.3945 3.57129 9.99998 3.57129Z"/>
    </svg>
);

const priceSvg = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.2422 4.6875H15.8578C16.2264 4.1975 16.4453 3.58871 16.4453 2.92969C16.4453 1.31426 15.1311 0 13.5156 0C12.5425 0 11.825 0.348633 11.2575 1.09715C10.7827 1.7234 10.4355 2.59777 10 3.70254C9.56453 2.59773 9.21734 1.7234 8.74254 1.09715C8.175 0.348633 7.45746 0 6.48438 0C4.86895 0 3.55469 1.31426 3.55469 2.92969C3.55469 3.58871 3.77355 4.1975 4.14223 4.6875H1.75781C0.788555 4.6875 0 5.47605 0 6.44531V7.61719C0 8.38105 0.489883 9.03234 1.17188 9.27422V18.2422C1.17188 19.2114 1.96043 20 2.92969 20H17.0703C18.0396 20 18.8281 19.2114 18.8281 18.2422V9.27422C19.5101 9.03234 20 8.38105 20 7.61719V6.44531C20 5.47605 19.2114 4.6875 18.2422 4.6875ZM11.0755 4.16953C11.9732 1.89227 12.3144 1.17188 13.5156 1.17188C14.4849 1.17188 15.2734 1.96043 15.2734 2.92969C15.2734 3.89895 14.4849 4.6875 13.5156 4.6875H10.8706C10.9424 4.50723 11.0109 4.33367 11.0755 4.16953ZM6.48438 1.17188C7.68559 1.17188 8.02684 1.89227 8.92445 4.16953C8.98914 4.33367 9.05762 4.50723 9.12941 4.6875H6.48438C5.51512 4.6875 4.72656 3.89895 4.72656 2.92969C4.72656 1.96043 5.51512 1.17188 6.48438 1.17188ZM7.65625 18.8281H2.92969C2.6066 18.8281 2.34375 18.5653 2.34375 18.2422V9.375H7.65625V18.8281ZM7.65625 8.20312H1.75781C1.43473 8.20312 1.17188 7.94027 1.17188 7.61719V6.44531C1.17188 6.12223 1.43473 5.85938 1.75781 5.85938H7.65625V8.20312ZM11.1719 18.8281H8.82812V5.85938C8.9509 5.85938 10.4984 5.85938 11.1719 5.85938V18.8281ZM17.6562 18.2422C17.6562 18.5653 17.3934 18.8281 17.0703 18.8281H12.3438V9.375H17.6562V18.2422ZM18.8281 7.61719C18.8281 7.94027 18.5653 8.20312 18.2422 8.20312H12.3438V5.85938H18.2422C18.5653 5.85938 18.8281 6.12223 18.8281 6.44531V7.61719Z" />
    </svg>
);

const photosSvg = (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6562 0H2.34375C1.05141 0 0 0.946266 0 2.10938V15.8906C0 17.0537 1.05141 18 2.34375 18H17.6562C18.9486 18 20 17.0537 20 15.8906V2.10938C20 0.946266 18.9486 0 17.6562 0ZM2.34375 1.40625H17.6562C18.087 1.40625 18.4375 1.72167 18.4375 2.10938V12.4353L15.7784 10.0421C14.8645 9.21966 13.3777 9.21966 12.4638 10.0421L11.6016 10.8181L9.1768 8.63585C8.26301 7.81341 6.77609 7.81341 5.86223 8.63585L1.5625 12.5056V2.10938C1.5625 1.72167 1.91297 1.40625 2.34375 1.40625ZM17.6562 16.5938H2.34375C1.91297 16.5938 1.5625 16.2783 1.5625 15.8906V14.4944L6.96707 9.63025C7.27176 9.3561 7.7673 9.3561 8.07191 9.63025L11.6016 12.8069L13.5687 11.0365C13.8732 10.7623 14.3689 10.7623 14.6735 11.0365L18.4375 14.4241V15.8906C18.4375 16.2783 18.087 16.5938 17.6562 16.5938ZM12.6562 7.03125C13.9486 7.03125 15 6.08498 15 4.92188C15 3.75877 13.9486 2.8125 12.6562 2.8125C11.3639 2.8125 10.3125 3.75877 10.3125 4.92188C10.3125 6.08498 11.3639 7.03125 12.6562 7.03125ZM12.6562 4.21875C13.087 4.21875 13.4375 4.53417 13.4375 4.92188C13.4375 5.30958 13.087 5.625 12.6562 5.625C12.2255 5.625 11.875 5.30958 11.875 4.92188C11.875 4.53417 12.2255 4.21875 12.6562 4.21875Z" />
    </svg>
);

const standardSvg = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.125 0.625C9.7925 0.625 6.25 1.72 6.25 3.75V6.25C6.25 6.595 6.53 6.875 6.875 6.875C7.22 6.875 7.5 6.595 7.5 6.25V5.59875C8.82625 6.435 11.0163 6.875 13.125 6.875C15.2338 6.875 17.4237 6.435 18.75 5.59875V6.25C18.75 6.86125 17.2125 7.83625 14.3225 8.0775C13.98 8.105 13.7237 8.40875 13.7525 8.7525C13.7787 9.07875 14.0525 9.325 14.3737 9.325C14.3925 9.325 14.4087 9.32375 14.4275 9.3225C16.055 9.18625 17.6762 8.765 18.75 8.08125V8.75C18.75 9.28375 17.6175 10.0513 15.5163 10.4225C15.1775 10.4825 14.95 10.8075 15.01 11.1463C15.0638 11.4488 15.3275 11.6625 15.6237 11.6625C15.66 11.6625 15.6975 11.66 15.7337 11.6525C17.005 11.4288 18.0225 11.0625 18.75 10.5975V11.25C18.75 11.7838 17.6175 12.5513 15.5163 12.9225C15.1775 12.9825 14.95 13.3075 15.01 13.6463C15.0638 13.9488 15.3275 14.1625 15.6237 14.1625C15.66 14.1625 15.6975 14.16 15.7337 14.1525C17.005 13.9288 18.0225 13.5613 18.75 13.0975V13.75C18.75 14.2838 17.6175 15.0513 15.5163 15.4225C15.1775 15.4825 14.95 15.8075 15.01 16.1463C15.0638 16.4488 15.3275 16.6625 15.6237 16.6625C15.66 16.6625 15.6975 16.66 15.7337 16.6525C17.005 16.4288 18.0225 16.0613 18.75 15.5975V16.25C18.75 16.8613 17.2125 17.8363 14.3225 18.0775C13.98 18.105 13.7237 18.4088 13.7525 18.7525C13.7787 19.0788 14.0525 19.325 14.3737 19.325C14.3925 19.325 14.4087 19.3238 14.4275 19.3225C17.2013 19.09 20 18.0513 20 16.25V3.75C20 1.72 16.4575 0.625 13.125 0.625ZM13.125 5.625C9.69125 5.625 7.5 4.51375 7.5 3.75C7.5 2.98625 9.69125 1.875 13.125 1.875C16.5588 1.875 18.75 2.98625 18.75 3.75C18.75 4.51375 16.5588 5.625 13.125 5.625Z"/>
        <path d="M6.875 8.125C3.5425 8.125 0 9.22 0 11.25V16.25C0 18.28 3.5425 19.375 6.875 19.375C10.2075 19.375 13.75 18.28 13.75 16.25V11.25C13.75 9.22 10.2075 8.125 6.875 8.125ZM12.5 16.25C12.5 17.0138 10.3088 18.125 6.875 18.125C3.44125 18.125 1.25 17.0138 1.25 16.25V15.5988C2.57625 16.435 4.76625 16.875 6.875 16.875C8.98375 16.875 11.1737 16.435 12.5 15.5988V16.25ZM12.5 13.75C12.5 14.5138 10.3088 15.625 6.875 15.625C3.44125 15.625 1.25 14.5138 1.25 13.75V13.0988C2.57625 13.935 4.76625 14.375 6.875 14.375C8.98375 14.375 11.1737 13.935 12.5 13.0988V13.75ZM6.875 13.125C3.44125 13.125 1.25 12.0138 1.25 11.25C1.25 10.4862 3.44125 9.375 6.875 9.375C10.3088 9.375 12.5 10.4862 12.5 11.25C12.5 12.0138 10.3088 13.125 6.875 13.125Z"/>
    </svg>
);

const gradSvg = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.86406 9.3479H0.652187C0.291992 9.3479 0 9.63993 0 10.0001V18.4238C0 18.7839 0.291992 19.076 0.652187 19.076H4.86406C5.22422 19.076 5.51625 18.784 5.51625 18.4238V10.0001C5.51621 9.63985 5.22422 9.3479 4.86406 9.3479ZM4.21187 17.7716H1.30434V10.6523H4.21187V17.7716Z" />
        <path d="M12.1057 0.924072H7.89389C7.53373 0.924072 7.2417 1.21603 7.2417 1.57626V18.4236C7.2417 18.7838 7.53369 19.0758 7.89389 19.0758H12.1058C12.4659 19.0758 12.7579 18.7838 12.7579 18.4236V1.57622C12.7579 1.21603 12.4659 0.924072 12.1057 0.924072ZM11.4536 17.7715H8.54603V2.22841H11.4536V17.7715Z" />
        <path d="M19.3479 5.13599H15.1361C14.7759 5.13599 14.4839 5.42798 14.4839 5.78817V18.4237C14.4839 18.7839 14.7759 19.0759 15.1361 19.0759H19.348C19.7081 19.0759 20.0002 18.7839 20.0002 18.4237V5.78817C20.0001 5.42802 19.7081 5.13599 19.3479 5.13599ZM18.6958 17.7716H15.7882V6.44036H18.6958V17.7716Z" />
    </svg>
);

const activateSvg = (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3912 0C7.16602 0 2.91531 4.3439 2.78835 9.75122V10.0012H0L4.22485 14.75L8.33214 10.0012H5.19113V9.75122C5.31691 5.72317 8.49201 2.50122 12.3912 2.50122C16.3716 2.50122 19.5984 5.85975 19.5984 10.0012C19.5984 14.1427 16.3716 17.5012 12.3912 17.5012C10.8566 17.5022 9.36209 16.9922 8.1276 16.0463L6.47481 17.8805C8.1617 19.2559 10.2457 20.0025 12.3912 20C17.6987 20 22 15.5244 22 10.0012C22 4.47805 17.6987 0 12.3912 0ZM11.2157 3.90366V10.0012C11.2171 10.3244 11.3408 10.634 11.5601 10.8634L15.3218 14.7658C15.6545 14.5427 15.9684 14.2915 16.247 14L13.5668 11.2207V3.90366H11.2157Z"/>
  </svg>
);

const automailingSvg = (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.00003 9.50003C0.996591 10.8199 1.30496 12.1219 1.90003 13.3C2.6056 14.7118 3.69028 15.8992 5.03258 16.7293C6.37488 17.5594 7.92179 17.9994 9.50003 18C10.8199 18.0035 12.1219 17.6951 13.3 17.1L18 18L17.1 13.3C17.6951 12.1219 18.0035 10.8199 18 9.50003C17.9994 7.92179 17.5594 6.37488 16.7293 5.03258C15.8992 3.69028 14.7118 2.6056 13.3 1.90003C12.1219 1.30496 10.8199 0.996587 9.50003 1.00003H9.00003C6.91568 1.11502 4.94699 1.99479 3.47089 3.47089C1.99479 4.94699 1.11502 6.91568 1.00003 9.00003V9.50003Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const crossSvg = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4.6C10.4774 4.6 10.9352 4.41036 11.2728 4.07279C11.6104 3.73523 11.8 3.27739 11.8 2.8C11.8 2.32261 11.6104 1.86477 11.2728 1.52721C10.9352 1.18964 10.4774 1 10 1C9.52261 1 9.06477 1.18964 8.72721 1.52721C8.38964 1.86477 8.2 2.32261 8.2 2.8C8.2 3.27739 8.38964 3.73523 8.72721 4.07279C9.06477 4.41036 9.52261 4.6 10 4.6ZM10 19C10.4774 19 10.9352 18.8104 11.2728 18.4728C11.6104 18.1352 11.8 17.6774 11.8 17.2C11.8 16.7226 11.6104 16.2648 11.2728 15.9272C10.9352 15.5896 10.4774 15.4 10 15.4C9.52261 15.4 9.06477 15.5896 8.72721 15.9272C8.38964 16.2648 8.2 16.7226 8.2 17.2C8.2 17.6774 8.38964 18.1352 8.72721 18.4728C9.06477 18.8104 9.52261 19 10 19ZM2.8 11.8C3.27739 11.8 3.73523 11.6104 4.07279 11.2728C4.41036 10.9352 4.6 10.4774 4.6 10C4.6 9.52261 4.41036 9.06477 4.07279 8.72721C3.73523 8.38964 3.27739 8.2 2.8 8.2C2.32261 8.2 1.86477 8.38964 1.52721 8.72721C1.18964 9.06477 1 9.52261 1 10C1 10.4774 1.18964 10.9352 1.52721 11.2728C1.86477 11.6104 2.32261 11.8 2.8 11.8V11.8ZM17.2 11.8C17.6774 11.8 18.1352 11.6104 18.4728 11.2728C18.8104 10.9352 19 10.4774 19 10C19 9.52261 18.8104 9.06477 18.4728 8.72721C18.1352 8.38964 17.6774 8.2 17.2 8.2C16.7226 8.2 16.2648 8.38964 15.9272 8.72721C15.5896 9.06477 15.4 9.52261 15.4 10C15.4 10.4774 15.5896 10.9352 15.9272 11.2728C16.2648 11.6104 16.7226 11.8 17.2 11.8V11.8Z" stroke="#AFAFAB" strokeLinejoin="round"/>
    <path d="M4.59897 10H15.399M9.99897 4.60003V15.4M15.9862 5.99953C15.4602 5.21378 14.7852 4.5388 13.9995 4.01278M5.99847 4.01278C5.21273 4.53881 4.53774 5.21379 4.01172 5.99953L5.99847 4.01278ZM4.01217 14.0005C4.53825 14.7862 5.21322 15.4612 5.99892 15.9873L4.01217 14.0005V14.0005ZM13.9999 15.9873C14.7856 15.4612 15.4606 14.7862 15.9867 14.0005L13.9999 15.9873Z" stroke="#AFAFAB" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const notificationSvg = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    version="1.2"
    baseProfile="tiny"
    viewBox="0 0 28 28"
    width="28px"
    height="28px"
    className="notificationSvg"
  >
    <path
      d="M18 7c.542 0 1 .458 1 1v7c0 .542-.458 1-1 1h-8.829l-.171.171v-.171h-3c-.542 0-1-.458-1-1v-7c0-.542.458-1 1-1h12m0-2h-12c-1.65 0-3 1.35-3 3v7c0 1.65 1.35 3 3 3h1v3l3-3h8c1.65 0 3-1.35 3-3v-7c0-1.65-1.35-3-3-3z"></path>
  </svg>
);

const refSvg = (
  <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 4.8V5.7L11.3 5.9C8.7 6.3 6.8 7.3 5.4 8.6C7.1 8.1 8.9 7.8 11 7.8H13V9.1L15.2 7L13 4.8ZM11 0L18 7L11 14V9.9C6 9.9 2.5 11.5 0 15C1 10 4 5 11 4M17 3V0L24 7L17 14V11L21 7"/>
  </svg>
);

const promocodeSvg = (
  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="20px" width="20px">
    <path fill="none" stroke-miterlimit="10" stroke-width="32"
          d="M366.05 146a46.7 46.7 0 0 1-2.42-63.42 3.87 3.87 0 0 0-.22-5.26l-44.13-44.18a3.89 3.89 0 0 0-5.5 0l-70.34 70.34a23.62 23.62 0 0 0-5.71 9.24 23.66 23.66 0 0 1-14.95 15 23.7 23.7 0 0 0-9.25 5.71L33.14 313.78a3.89 3.89 0 0 0 0 5.5l44.13 44.13a3.87 3.87 0 0 0 5.26.22 46.69 46.69 0 0 1 65.84 65.84 3.87 3.87 0 0 0 .22 5.26l44.13 44.13a3.89 3.89 0 0 0 5.5 0l180.4-180.39a23.7 23.7 0 0 0 5.71-9.25 23.66 23.66 0 0 1 14.95-15 23.62 23.62 0 0 0 9.24-5.71l70.34-70.34a3.89 3.89 0 0 0 0-5.5l-44.13-44.13a3.87 3.87 0 0 0-5.26-.22 46.7 46.7 0 0 1-63.42-2.32z"></path>
    <path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"
          d="m250.5 140.44-16.51-16.51m60.53 60.53-11.01-11m55.03 55.03-11-11.01m60.53 60.53-16.51-16.51"></path>
  </svg>
);

export default function ProfileBonus() {
  const {t} = useTranslation();
  const navLinksContainer = () => {
    return (
      <div className={styles.navigation}>
        <NavLink to={'/profile/bonus/term'} exact activeClassName={styles.activeTab}>
          {timeSvg}
          <span>{t('NSBonus.expiration')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/write-off'} exact activeClassName={styles.activeTab}>
          {standardSvg}
          <span>{t('writeOff')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/activate'} exact activeClassName={styles.activeTab}>
          {activateSvg}
          <span>{t('activation')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/birthday'} exact activeClassName={styles.activeTab}>
          {priceSvg}
          <span>{t('NSBonus.birthday')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/photos'} exact activeClassName={styles.activeTab}>
        {photosSvg}
          <span>{t('photos')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/standard'} exact activeClassName={styles.activeTab}>
          {standardSvg}
          <span>{t('standard')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/corporate'} exact activeClassName={styles.activeTab}>
          {standardSvg}
          <span>{t('corporative')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/gradation'} exact activeClassName={styles.activeTab}>
          {gradSvg}
          <span>{t('accumulative')}</span>
        </NavLink>
          <NavLink to={'/profile/bonus/transaction_gradation'} exact activeClassName={styles.activeTab}>
          {gradSvg}
          <span>{t('transaction_gradation')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/automailing'} exact activeClassName={styles.activeTab}>
          <svg className={styles.automailingSvg} width="19" height="19" viewBox="0 0 19 19" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.00003 9.50003C0.996591 10.8199 1.30496 12.1219 1.90003 13.3C2.6056 14.7118 3.69028 15.8992 5.03258 16.7293C6.37488 17.5594 7.92179 17.9994 9.50003 18C10.8199 18.0035 12.1219 17.6951 13.3 17.1L18 18L17.1 13.3C17.6951 12.1219 18.0035 10.8199 18 9.50003C17.9994 7.92179 17.5594 6.37488 16.7293 5.03258C15.8992 3.69028 14.7118 2.6056 13.3 1.90003C12.1219 1.30496 10.8199 0.996587 9.50003 1.00003H9.00003C6.91568 1.11502 4.94699 1.99479 3.47089 3.47089C1.99479 4.94699 1.11502 6.91568 1.00003 9.00003V9.50003Z"
              strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <span>{t('autocast')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/ref'} exact activeClassName={styles.activeTab}>
          {refSvg}
          <span>{t('NSBonus.ref')}</span>
        </NavLink>
        <NavLink to={'/profile/bonus/cross'} exact activeClassName={styles.activeTab} className={styles.stroke}>
          {crossSvg}
          <span>Cross</span>
        </NavLink>
        <NavLink to={'/profile/bonus/notification'} exact activeClassName={styles.activeTab}
                 className={styles.stroke}>
          {notificationSvg}
          <span>{t('monthlyReport')}</span>
        </NavLink>
        {/*<NavLink*/}
        {/*  to={'/profile/bonus/promocode'}*/}
        {/*  exact activeClassName={styles.activeTab}*/}
        {/*  className={styles.stroke}*/}
        {/*>*/}
        {/*  {promocodeSvg}*/}
        {/*  <span>{t('promocode')}</span>*/}
        {/*</NavLink>*/}
      </div>
    )
  }

  return (
    <PageLayout
      navLinksContainer={navLinksContainer}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <Switch>
            <Route path={'/profile/bonus/term'} render={() => <ProfileBonusTerm/>}/>
            <Route path={'/profile/bonus/write-off'} render={() => <BonusWriteOff/>}/>
            <Route path={'/profile/bonus/activate'} render={() => <ProfileBonusActivate/>}/>
            <Route path={'/profile/bonus/birthday'} render={() => <ProfileBonusBirthday/>}/>
            <Route path={'/profile/bonus/photos'} render={() => <ProfileBonusPhotos/>}/>
            <Route path={'/profile/bonus/standard'} render={() => <ProfileBonusStandard/>}/>
            <Route path={'/profile/bonus/corporate'} render={() => <ProfileBonusCorporate/>}/>
            <Route path={'/profile/bonus/gradation'} render={() => <ProfileBonusGradation/>}/>
            <Route path={'/profile/bonus/transaction_gradation'} render={() => <ProfileBonusTransactionGradation/>}/>
            <Route exact path={'/profile/bonus/automailing'} render={() => <ProfileBonusAutoMailing/>}/>
            <Route path={'/profile/bonus/cross'} render={() => <ProfileBonusCross/>}/>
            <Route path={'/profile/bonus/ref'} render={() => <BonusRef/>}/>
            <Route path={'/profile/bonus/notification'} render={() => <BonusNotification/>}/>
            <Route path={'/profile/bonus/promocode'} render={() => <BonusPromocode/>}/>
          </Switch>
        </div>
      </div>
    </PageLayout>
  )
}
