import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import branchActions from '../../../store/branchStore/branchActions';
import crossActions from "../../../store/crossStore/crossActions";

export const useBonusCross = (t) => {
    const dispatch = useDispatch();
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const [isAllCities, setIsAllCities] = useState(false);
    const [selectedCities, setSelectedCities] = useState([]);
    const { info, pending } = useSelector(s => s.branchReducer);
    const crossState = useSelector(s => s.crossReducer);
    const { pending: crossPending, valueCities } = crossState;
    const options = useRef([
        {
            "label": 'NSBonus.limit',
            "value": 0,
        },
        {
            "label": 'week',
            "value": 6.048e+8,
        },
        {
            "label": 'NSBonus.weeks',
            "value": 1.21e+9,
        },
        {
            "label": 'month',
            "value": 2.628e+9,
        },
        {
            "label": 'NSBonus.two',
            "value": 5.256e+9,
        },
        {
            "label": 'NSBonus.three',
            "value": 7.884e+9,
        },
        {
            "label": 'NSBonus.halfYear',
            "value": 1.577e+10,
        },
        {
            "label": 'year',
            "value": 3.154e+10,
        },
    ])

    const formik = useFormik({
        initialValues: {
            enabled: false,
            interval: 0,
            bonus_for_new_client: '',
            bonus_for_loyalty_client: '',
            min_order_amount: '',
            city_id_list: [],
        },
        validationSchema: Yup.object({
            // delay_interval: Yup.object().shape({label: Yup.string(), value: Yup.number()}).required(t('chooseInterval')),
        }),
        onSubmit: (async ({ enabled, ...values }) => {
            try {
                if (info.id) {
                    dispatch(crossActions.updateBranchConfigForCrossMarketing(info.id, {...values, interval: values.interval.value}))
                }
            } catch (e) {
                const res = e.response ? e.response.data : null;

                store.addNotification({
                    title: `Error code: ${res ? res.code : 'NULL'}`,
                    message: `${res ? res.message : t('Notify.notRespond')}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        })
    });

    useEffect(() => {
        formik.setErrors({});
        formik.setTouched({});

        if (info) {
            if (info.configuration.cross_marketing && info.configuration.cross_marketing.enabled) {
                formik.setFieldValue('enabled', info.configuration.cross_marketing.enabled);
                formik.setFieldValue('bonus_for_new_client', info.configuration.cross_marketing.bonus_for_new_client);
                formik.setFieldValue('bonus_for_loyalty_client', info.configuration.cross_marketing.bonus_for_loyalty_client);
                formik.setFieldValue('min_order_amount', info.configuration.cross_marketing.min_order_amount);
                const interval = info.configuration.cross_marketing.interval || 0;
                const option = options.current.find((o) => o.value == interval) || {label: '', value: ''};
                formik.setFieldValue('interval', option);
            }
            if (!info.configuration.cross_marketing || !info.configuration.cross_marketing.enabled) {
                setError(true);
                setErrorModal(true);
            }
        }
    }, [info]);

    useEffect(() => {
        if (info && valueCities && valueCities.length) {
            if (info.configuration.cross_marketing && info.configuration.cross_marketing.enabled && info.configuration.cross_marketing.city_id_list && info.configuration.cross_marketing.city_id_list.length) {
                formik.setFieldValue('enabled', info.configuration.cross_marketing.enabled);
                let foundCities = [];
                info.configuration.cross_marketing.city_id_list.forEach((id) => {
                    foundCities = [...foundCities, ...valueCities.filter((city) => city.id == id)];
                })
                setSelectedCities(foundCities);
            } else {
                setIsAllCities(true);
            }
        }
    }, [info, valueCities])

    useEffect(() => {
        dispatch(crossActions.getCities());
    }, [])

    useEffect(() => {
        formik.setFieldValue("city_id_list", selectedCities.map((item) => item.id));
    }, [selectedCities])

    useEffect(() => {
        if (isAllCities) {
            setSelectedCities([]);
        }
    }, [isAllCities])

    return {
        formik,
        pending,
        crossPending,
        errorModal,
        setErrorModal,
        error,
        setIsAllCities,
        isAllCities,
        valueCities,
        selectedCities,
        setSelectedCities,
        options: options.current
    }
}
