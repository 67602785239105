import i18n from '../../../i18n';
import businessReportTypes from "./businessReportTypes";
import { PENDING, SUCCESS, ERROR } from "../../globalStatuses/globalStatuses";

const initialState = {
    from: null,
    to: null,
    page: 1,
    data: [],
    status: '',
}


const businessReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case businessReportTypes.GET_BUSINESS_REPORT_PENDING:
            return {
                ...state,
                status: PENDING,
            }
        case businessReportTypes.GET_BUSINESS_REPORT_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                status: SUCCESS,
            }
        case businessReportTypes.GET_BUSINESS_REPORT_ERROR:
            return {
                ...state,
                status: ERROR,
            }
        case businessReportTypes.CHANGE_BUSINESS_REPORT_PAGE:
            return {
                ...state,
                page: action.payload
            }
        default:
            return {
                ...state
            }
    }
}


export default businessReportReducer