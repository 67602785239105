const crossTypes = {
    UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_PENDING: "UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_PENDING",
    UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_SUCCESS: "UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_SUCCESS",
    UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_ERROR: "UPDATE_BRANCH_CONFiG_FOR_CROSS_MARKETING_ERROR",

    GET_CITIES_PENDING: "GET_CITIES_PENDING",
    GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
    GET_CITIES_ERROR: "GET_CITIES_ERROR",

    GET_CATEGORIES_PENDING: "GET_CATEGORIES_PENDING",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",

    GET_CATEGORIES_GROUP_ID_LIST_PENDING: "GET_CATEGORIES_GROUP_ID_LIST_PENDING",
    GET_CATEGORIES_GROUP_ID_LIST_SUCCESS: "GET_CATEGORIES_GROUP_ID_LIST_SUCCESS",
    GET_CATEGORIES_GROUP_ID_LIST_ERROR: "GET_CATEGORIES_GROUP_ID_LIST_ERROR",

    GET_CROSS_MARKETING_BRANCHES_PENDING: "GET_CROSS_MARKETING_BRANCHES_PENDING",
    GET_CROSS_MARKETING_BRANCHES_SUCCESS: "GET_CROSS_MARKETING_BRANCHES_SUCCESS",
    GET_CROSS_MARKETING_BRANCHES_ERROR: "GET_CROSS_MARKETING_BRANCHES_ERROR",
    SET_CROSS_MARKETING_BRANCHES_PARAMS: "SET_CROSS_MARKETING_BRANCHES_PARAMS",
    RESET_CROSS_MARKETING_BRANCHES_PARAMS: "RESET_CROSS_MARKETING_BRANCHES_PARAMS",

    CHOSE_CMBRANCHES: "CHOSE_CMBRANCHES",
    RESET_CMBRANCHES: "RESET_CMBRANCHES",
    REMOVE_CMBRANCHES: "REMOVE_CMBRANCHES",
    CLEAR_CMBRANCHES: "CLEAR_CMBRANCHES",

    SAVE_CROSS_MARKETING_BRANCHES_PENDING: "SAVE_CROSS_MARKETING_BRANCHES_PENDING",
    SAVE_CROSS_MARKETING_BRANCHES_SUCCESS: "SAVE_CROSS_MARKETING_BRANCHES_SUCCESS",
    SAVE_CROSS_MARKETING_BRANCHES_ERROR: "SAVE_CROSS_MARKETING_BRANCHES_ERROR",

    GET_CROSS_MARKETING_BRANCHES_ANALYTIC_PENDING: "GET_CROSS_MARKETING_BRANCHES_ANALYTIC_PENDING",
    GET_CROSS_MARKETING_BRANCHES_ANALYTIC_SUCCESS: "GET_CROSS_MARKETING_BRANCHES_ANALYTIC_SUCCESS",
    GET_CROSS_MARKETING_BRANCHES_ANALYTIC_ERROR: "GET_CROSS_MARKETING_BRANCHES_ANALYTIC_ERROR",

    SET_CROSS_MARKETING_BRANCHES_ANALYTIC: "SET_CROSS_MARKETING_BRANCHES_ANALYTIC",
    RESET_CROSS_MARKETING_BRANCHES_ANALYTIC: "RESET_CROSS_MARKETING_BRANCHES_ANALYTIC",

    RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS: "RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS",
    SET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS: "SET_CROSS_MARKETING_BRANCHES_ANALYTIC_PARAMS",


    GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PENDING: "GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PENDING",
    GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_SUCCESS: "GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_SUCCESS",
    GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_ERROR: "GET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_ERROR",

    RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS: "RESET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS",
    SET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS: "SET_CROSS_MARKETING_BRANCHES_ANALYTIC_CLIENTS_PARAMS",
}

export default crossTypes;
