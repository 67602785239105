import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { store } from 'react-notifications-component';
import Review from "../../pages/Reviews/components/Review";
import styles from './surveyQuestions.module.scss';

const questions = [
    {
        "id": 1,
        "title": "Нравится ли вам обслуживание в `Starbucks`?",
        "is_required": true,
        "answer_type": "SINGLE_CHOICE",
        "answers": [
            {
                "id": 1,
                "content": "Да, очень круто.",
                "count": 0
            },
            {
                "id": 2,
                "content": "Нет, мне нагрубили.",
                "count": 0
            },
            {
                "id": 3,
                "content": "Затрудняюсь ответить.",
                "count": 0
            }
        ]
    },
    {
        "id": 2,
        "title": "Откуда вы узнали о `Starbucks`?",
        "is_required": true,
        "answer_type": "MULTIPLE_CHOICE",
        "answers": [
            {
                "id": 1,
                "content": "Instagram",
                "count": 0
            },
            {
                "id": 2,
                "content": "Facebook",
                "count": 0
            },
            {
                "id": 3,
                "content": "2GIS",
                "count": 0
            },
            {
                "id": 4,
                "content": "Другое",
                "count": 0
            }
        ]
    },
    {
        "id": 3,
        "title": "Напишите первое впечатление о `Starbucks`",
        "is_required": true,
        "answer_type": "TEXT_FIELD",
        "answers": [
            {
                "id": 1,
                "content": "",
                "count": 0
            }
        ]
    }
];

const review = {
    "id": 1384855934672572400,
    "comment": "Салем",
    "rating": 5,
    "branch_id": 1,
    "order_id": 1384855891420909600,
    "user": {
        "id": 1384848955258441700,
        "first_name": "Arystan",
        "last_name": "Amangeldiev",
        "avatar": "",
        "phone": ""
    },
    "created_at": 1619010358862,
    "recipient": {
        "type": "BRANCH",
        "branch_id": 1,
        "branch_name": "Sunday",
        "branch_category": 1149245530405081100,
        "branch_address": "Baizakov str",
        "icon": "https://acaamdmguo.cloudimg.io/v7/zumdex.ams3.digitaloceanspaces.com/public/images/branches/5b/07/75/1a/96/dc/11/eb/ba/06/26/36/26/ff/60/e1/original",
        "organization_id": 0,
        "group_id": 1
    },
    "answer": "хорошо",
    "answered_at": 1619010473650,
    "edited_at": 1619010479693
}

const arrowDown = (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5.5 5.5L10.5 1" stroke="#AFAFAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const verticalTrackStyles = {
    position: 'absolute',
    width: '6px',
    right: '2px',
    bottom: '2px',
    top: '2px',
    borderRadius: '3px',
    background: '#F6F6F5',
}

const ShowCustomAnswers = ({ answers }) => {
    const [showComments, setShowComment] = useState(false);

    return (
        <React.Fragment>
            {!showComments && answers && <button onClick={() => setShowComment(true)} className={`${styles.btn} ${styles.btnOpen}`}><span>Смотреть ответы</span></button>}
            {showComments && answers && <button onClick={() => setShowComment(false)} className={`${styles.btn} ${styles.btnClose}`}><span>Скрыть ответы</span><i>{arrowDown}</i></button>}
            {showComments && answers && answers.length && <div className={styles.comments}>
                <Scrollbars
                    style={{ width: '100%', height: 291 }}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} />}
                    renderTrackVertical={props => <div {...props} style={verticalTrackStyles} />}
                >
                    {answers.map((answer) => {
                        return answer.default_answers && answer.default_answers.map((answer) => (
                            <Review key={answer.author.id} review={{ ...answer, user: {...answer.author} }} comment={false} rate={false} />
                        ))
                    })}
                </Scrollbars>
            </div>}
        </React.Fragment>
    )

}

export const SurveyQuestion = ({ question, index }) => {
    return (
        <div className={styles.question} style={{ marginBottom: '35px' }}>
            <p>{index + 1}. {question.title}</p>
            <div>
                {question.answers.map((answer, index) => {
                    const allCounts = question.answers.reduce((prev, current) => prev += current.count, 0);
                    const percent = (answer.count / allCounts * 100) || 0;

                    return (
                        <div key={answer.id}>
                            <div className={styles.questionLine}><div style={{ width: `${percent}%` }}></div><span>{answer.content}</span> <span>{Math.round(percent)}%</span></div>
                            {!!answer.default_answers.length && answer.type === 'text' && <ShowCustomAnswers answers={question.answers} />}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const SurveyQuestions = ({ survey }) => {
    return (
        <div className={styles.container}>
            {survey && survey.questions.map((question, i) => <SurveyQuestion question={question} key={question.id} index={i} />)}
        </div>
    );
};

export default SurveyQuestions;
