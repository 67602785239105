import businessReportTypes from "./businessReportTypes";
import businessReportApi from "./businessReportApi";
import { Roles } from "../../../config";

const
  businessReportActions = {
    changePage: (page) => {
        return {
            type: businessReportTypes.CHANGE_BUSINESS_REPORT_PAGE,
            payload: page
        }
    },
    getBusinessReportData: (branch_id, from, to, branch_name, sort_type, invisParams, groupId, currentRole) => async dispatch => {
        dispatch({
            type: businessReportTypes.GET_BUSINESS_REPORT_PENDING
        })

        let params = `sort_type=${sort_type}`;

        if (branch_name) {
            params += `&branch_name=${branch_name}`
        }

        if (from) {
            const fromDate = new Date(from);
            let month = (fromDate.getMonth() + 1) < 10 ? `0${fromDate.getMonth() + 1}` : fromDate.getMonth() + 1;
            let day = (fromDate.getDate()) < 10 ? `0${fromDate.getDate()}` : fromDate.getDate();
            if (params.length) {
                params += `&from=${new Date(from).getFullYear()}-${month}-${day}`;
            } else {
                params += `from=${new Date(from).getFullYear()}-${month}-${day}`;
            }
        }

        if (to) {
            const toDate = new Date(to);
            let month = (toDate.getMonth() + 1) < 10 ? `0${toDate.getMonth() + 1}` : toDate.getMonth() + 1;
            let day = (toDate.getDate()) < 10 ? `0${toDate.getDate()}` : toDate.getDate();
            if (params.length) {
                params += `&to=${new Date(to).getFullYear()}-${month}-${day}`;
            } else {
                params += `to=${new Date(to).getFullYear()}-${month}-${day}`;
            }
        }

        // if (currentRole === Roles.ANALYTIC && groupId) {
        //     await businessReportApi.getAnalyticBusinessReportData(groupId, params, invisParams)
        //       .then((res) => {
        //           let data = res.data || []
        //           dispatch({
        //               type: businessReportTypes.GET_BUSINESS_REPORT_SUCCESS,
        //               payload: {
        //                   data
        //               }
        //           })
        //       })
        //       .catch(() => {
        //           dispatch({
        //               type: businessReportTypes.GET_BUSINESS_REPORT_ERROR
        //           })
        //       })
        // } else {
            await businessReportApi.getBusinessReportData(branch_id, params)
              .then((res) => {
                  let data = res.data || []
                  dispatch({
                      type: businessReportTypes.GET_BUSINESS_REPORT_SUCCESS,
                      payload: {
                          data
                      }
                  })
              })
              .catch(() => {
                  dispatch({
                      type: businessReportTypes.GET_BUSINESS_REPORT_ERROR
                  })
              })
        // }


    },
}

export default businessReportActions