const branchTypes = {
    GET_BRANCH_PENDING: "GET_BRANCH_PENDING",
    GET_BRANCH_SUCCESS: "GET_BRANCH_SUCCESS",
    GET_BRANCH_ERROR: "GET_BRANCH_ERROR",

    CHANGE_BRANCH_PENDING: "CHANGE_BRANCH_PENDING",
    CHANGE_BRANCH_SUCCESS: "CHANGE_BRANCH_SUCCESS",
    CHANGE_BRANCH_ERROR: "CHANGE_BRANCH_ERROR",

    DELETE_BRANCH_PENDING: "DELETE_BRANCH_PENDING",
    DELETE_BRANCH_SUCCESS: "DELETE_BRANCH_SUCCESS",
    DELETE_BRANCH_ERROR: "DELETE_BRANCH_ERROR",

    CHANGE_BRANCH_WORKING_HOURS_PENDING: "CHANGE_BRANCH_WORKING_HOURS_PENDING",
    CHANGE_BRANCH_WORKING_HOURS_SUCCESS: "CHANGE_BRANCH_WORKING_HOURS_SUCCESS",
    CHANGE_BRANCH_WORKING_HOURS_ERROR: "CHANGE_BRANCH_WORKING_HOURS_ERROR",

    CHANGE_WHATSAPP_HEADER_PENDING: "CHANGE_WHATSAPP_HEADER_PENDING",
    CHANGE_WHATSAPP_HEADER_SUCCESS: "CHANGE_WHATSAPP_HEADER_SUCCESS",
    CHANGE_WHATSAPP_HEADER_ERROR: "CHANGE_WHATSAPP_HEADER_ERROR",

    CHANGE_REPORTS_CONTACTS_PENDING: "CHANGE_REPORTS_CONTACTS_PENDING",
    CHANGE_REPORTS_CONTACTS_SUCCESS: "CHANGE_REPORTS_CONTACTS_SUCCESS",
    CHANGE_REPORTS_CONTACTS_ERROR: "CHANGE_REPORTS_CONTACTS_ERROR",

    CHANGE_BRANCH_INFO_PENDING: "CHANGE_BRANCH_INFO_PENDING",
    CHANGE_BRANCH_INFO_SUCCESS: "CHANGE_BRANCH_INFO_SUCCESS",
    CHANGE_BRANCH_INFO_ERROR: "CHANGE_BRANCH_INFO_ERROR",

    ADD_BRANCH_BANNER_PENDING: "ADD_BRANCH_BANNER_PENDING",
    ADD_BRANCH_BANNER_SUCCESS: "ADD_BRANCH_BANNER_SUCCESS",
    ADD_BRANCH_BANNER_ERROR: "ADD_BRANCH_BANNER_ERROR",

    DELETE_BRANCH_BANNER_PENDING: "DELETE_BRANCH_BANNER_PENDING",
    DELETE_BRANCH_BANNER_SUCCESS: "DELETE_BRANCH_BANNER_SUCCESS",
    DELETE_BRANCH_BANNER_ERROR: "DELETE_BRANCH_BANNER_ERROR",

    CHANGE_BRANCH_ICON_PENDING: "CHANGE_BRANCH_ICON_PENDING",
    CHANGE_BRANCH_ICON_SUCCESS: "CHANGE_BRANCH_ICON_SUCCESS",
    CHANGE_BRANCH_ICON_ERROR: "CHANGE_BRANCH_ICON_ERROR",

    CHANGE_BRANCH_SOCIALS_PENDING: "CHANGE_BRANCH_SOCIALS_PENDING",
    CHANGE_BRANCH_SOCIALS_SUCCESS: "CHANGE_BRANCH_SOCIALS_SUCCESS",
    CHANGE_BRANCH_SOCIALS_ERROR: "CHANGE_BRANCH_SOCIALS_ERROR",

    CHANGE_BRANCH_PRIVATE_CASHBACK_PENDING: "CHANGE_BRANCH_PRIVATE_CASHBACK_PENDING",
    CHANGE_BRANCH_PRIVATE_CASHBACK_SUCCESS: "CHANGE_BRANCH_PRIVATE_CASHBACK_SUCCESS",
    CHANGE_BRANCH_PRIVATE_CASHBACK_ERROR: "CHANGE_BRANCH_PRIVATE_CASHBACK_ERROR",

    CHANGE_BRANCH_AUTOMAILING_PENDING: "CHANGE_BRANCH_AUTOMAILING_PENDING",
    CHANGE_BRANCH_AUTOMAILING_SUCCESS: "CHANGE_BRANCH_AUTOMAILING_SUCCESS",
    CHANGE_BRANCH_AUTOMAILING_ERROR: "CHANGE_BRANCH_AUTOMAILING_ERROR",

    UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_PENDING: 'UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_PENDING',
    UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_SUCCESS: 'UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_SUCCESS',
    UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_ERROR: 'UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_ERROR',

    POST_BRANCH_BONUS_PROMOCODE_PENDING: 'POST_BRANCH_BONUS_PROMOCODE_PENDING',
    POST_BRANCH_BONUS_PROMOCODE_SUCCESS: 'POST_BRANCH_BONUS_PROMOCODE_SUCCESS',
    POST_BRANCH_BONUS_PROMOCODE_ERROR: 'POST_BRANCH_BONUS_PROMOCODE_ERROR',

    DELETE_BRANCH_BONUS_PROMOCODE_PENDING: 'DELETE_BRANCH_BONUS_PROMOCODE_PENDING',
    DELETE_BRANCH_BONUS_PROMOCODE_SUCCESS: 'DELETE_BRANCH_BONUS_PROMOCODE_SUCCESS',
    DELETE_BRANCH_BONUS_PROMOCODE_ERROR: 'DELETE_BRANCH_BONUS_PROMOCODE_ERROR',

    ACTIVATED_BRANCH_AWARD_CONFIG_PENDING: "ACTIVATE_BRANCH_AWARD_CONFIG_PENDING",
    ACTIVATED_BRANCH_AWARD_CONFIG_SUCCESS: "ACTIVATE_BRANCH_AWARD_CONFIG_SUCCESS",
    ACTIVATED_BRANCH_AWARD_CONFIG_ERROR: "ACTIVATE_BRANCH_AWARD_CONFIG_ERROR",

    DEACTIVATED_BRANCH_AWARD_CONFIG_PENDING: "DEACTIVATE_BRANCH_AWARD_CONFIG_PENDING",
    DEACTIVATED_BRANCH_AWARD_CONFIG_SUCCESS: "DEACTIVATE_BRANCH_AWARD_CONFIG_SUCCESS",
    DEACTIVATED_BRANCH_AWARD_CONFIG_ERROR: "DEACTIVATE_BRANCH_AWARD_CONFIG_ERROR",

    CHANGE_BRANDED_BONUS_CARD_BANNER_PENDING: "CHANGE_BRANDED_BONUS_CARD_BANNER_PENDING",
    CHANGE_BRANDED_BONUS_CARD_BANNER_SUCCESS: "CHANGE_BRANDED_BONUS_CARD_BANNER_SUCCESS",
    CHANGE_BRANDED_BONUS_CARD_BANNER_ERROR: "CHANGE_BRANDED_BONUS_CARD_BANNER_ERROR",

    CHANGE_CONTRACT_BASIC_PENDING: "CHANGE_CONTRACT_BASIC_PENDING",
    CHANGE_CONTRACT_BASIC_SUCCESS: "CHANGE_CONTRACT_BASIC_SUCCESS",
    CHANGE_CONTRACT_BASIC_ERROR: "CHANGE_CONTRACT_BASIC_ERROR",

    CHANGE_CONTRACT_INCREASED_PENDING: "CHANGE_CONTRACT_INCREASED_PENDING",
    CHANGE_CONTRACT_INCREASED_SUCCESS: "CHANGE_CONTRACT_INCREASED_SUCCESS",
    CHANGE_CONTRACT_INCREASED_ERROR: "CHANGE_CONTRACT_INCREASED_ERROR",

    CHANGE_BRANCH_BONUS_DELAY_PENDING: "CHANGE_BRANCH_BONUS_DELAY_PENDING",
    CHANGE_BRANCH_BONUS_DELAY_SUCCESS: "CHANGE_BRANCH_BONUS_DELAY_SUCCESS",
    CHANGE_BRANCH_BONUS_DELAY_ERROR: "CHANGE_BRANCH_BONUS_DELAY_ERROR",

    CHANGE_BRANCH_CONFIGURATION_PENDING: "CHANGE_BRANCH_CONFIGURATION_PENDING",
    CHANGE_BRANCH_CONFIGURATION_SUCCESS: "CHANGE_BRANCH_CONFIGURATION_SUCCESS",
    CHANGE_BRANCH_CONFIGURATION_ERROR: "CHANGE_BRANCH_CONFIGURATION_ERROR",

    CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_PENDING: "CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_PENDING",
    CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_SUCCESS: "CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_SUCCESS",
    CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_ERROR: "CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_ERROR",


    CHANGE_BRANCH_WHATSAPP_HEADER_PENDING: "CHANGE_BRANCH_WHATSAPP_HEADER_PENDING",
    CHANGE_BRANCH_WHATSAPP_HEADER_SUCCESS: "CHANGE_BRANCH_WHATSAPP_HEADER_SUCCESS",
    CHANGE_BRANCH_WHATSAPP_HEADER_ERROR: "CHANGE_BRANCH_WHATSAPP_HEADER_ERROR",

    CHANGE_BRANCH_BALANCE: "CHANGE_BRANCH_BALANCE",
}

export default branchTypes;
