import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import Modal from "../../../../portals/Modal";
import branchActions from "../../../../store/branchStore/branchActions";
import PageHeader from "../../../../components/PageHeader";
import CustomSelect from "../../../../components/FormElements/Select";
import styles from './profileBonusTerm.module.scss';
import Checkbox from "../../../../components/FormElements/Checkbox";

export default function ProfileBonusTerm() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { info, pending: branchPending } = useSelector((s) => s.branchReducer);
    const [state, setState] = useState(null);
    const [errorModal, setErrorModal] = useState(false);
    const [error, setError] = useState(false);
    const [pending, setPending] = useState(false);
    const [expireInterval, setExpireInterval] = useState({label: t('NSAwards.limit'), value: 0});
    const [bonusPercentage, setBonusPercentage] = useState({label: '', value: ''});
    const [expirePercentage, setExpirePercentage] = useState({label: '', value: ''});
    // const [isExpireMessagesPushing, setIsExpireMessagesPushing] = useState(false);

    const options = [
        {
            "label": 'NSBonus.limit',
            "value": 0,
        },
        {
            "label": 'NSBonus.weeks',
            "value": 1.21e+9,
        },
        {
            "label": 'month',
            "value": 2.628e+9,
        },
        {
            "label": 'NSBonus.two',
            "value": 5.256e+9,
        },
        {
            "label": 'NSBonus.three',
            "value": 7.884e+9,
        },
        {
            "label": 'NSBonus.halfYear',
            "value": 1.577e+10,
        },
        {
            "label": 'year',
            "value": 3.154e+10,
        },
    ]
    const optionsPercentage = [
        {
            "label": <span>10%</span>,
            "value": 10,
        },
        {
            "label": <span>20%</span>,
            "value": 20,
        },
        {
            "label": <span>30%</span>,
            "value": 30,
        },
        {
            "label": <span>40%</span>,
            "value": 40,
        },
        {
            "label": <span>50%</span>,
            "value": 50,
        },
        {
            "label": <span>100%</span>,
            "value": 100,
        },
    ]

    useEffect(() => {
        setState(null);
        setError(false);

        if (info && !info.configuration.private_cashback) {
            setError(true);
            return setErrorModal(true);
        }

        if (info && info.configuration.private_cashback) {
            let currentPercentage = optionsPercentage.find(o => o.value === info.configuration.private_cashback.access_withdraw_bonus_percentage);

            if (currentPercentage) {
                setExpirePercentage(currentPercentage);
            }

            setState({
                access_withdraw_bonus_percentage: info.configuration.private_cashback.access_withdraw_bonus_percentage,
            });
        }

        if (info && info.configuration.private_cashback) {
            let currentPercentage = optionsPercentage.find(o => o.value === info.configuration.private_cashback.bonus_percentage);
            let currentOption = options.find(o => o.value === info.configuration.private_cashback.expire_interval);

            if (currentOption) {
                setExpireInterval(currentOption);
            }
            if (currentPercentage) {
                setBonusPercentage(currentPercentage);
            }

            setState({
                expire_interval: info.configuration.private_cashback.expire_interval,
            });
        }

        // if (info?.configuration?.whatsapp_provider?.prohibitions) {
        //     setIsExpireMessagesPushing(info?.configuration?.whatsapp_provider?.prohibitions?.bonus_expire_messages || false);
        // }
    }, [info])

    useEffect(() => {
        setState({
            expire_interval: expireInterval.value
        });
    }, [expireInterval])

    const onSubmit = () => {
        if (state && info) {
            setPending(true);
            const { banner, expire_date, ...otherParams } = info.configuration.private_cashback;
            let body = {
                ...otherParams,
                access_withdraw_bonus_percentage: expirePercentage?.value ? expirePercentage.value : 0,
                expire_interval: expireInterval.value,
            };
            if (bonusPercentage.value) {
                body = { ...otherParams, ...body, bonus_percentage: bonusPercentage.value };
            }
            new Promise((resolve, reject) => {
                dispatch(branchActions.changePrivateCashbackExpireDate(info.id, body, resolve, reject));
            }).finally(() => {
                setPending(false);
            })
            // changeWhatsappPush()
        }
    }

    // const changeWhatsappPush = () => {
    //     dispatch(branchActions.changeWhatsappProhibitions(info.id, { enabled: isExpireMessagesPushing, type: 'bonus_expire_messages' }));
    // }

    return (
        <>
          <PageHeader title={t('bonuses')}>
          <p>{t('NSBonus.information')}</p>
            </PageHeader>
            <div className={styles.container}>
                <div className={styles.block}>
                    <div className={styles['block__title']}>{t('writeOff')} <span>*</span></div>
                    <CustomSelect
                      options={optionsPercentage}
                      value={expirePercentage}
                      onSelect={(o) => setExpirePercentage(o)}
                    />
                </div>
                {/*<Checkbox*/}
                {/*  label={t("pushWhatsapp") || ""}*/}
                {/*  checked={isExpireMessagesPushing}*/}
                {/*  disabled={branchPending || !info}*/}
                {/*  onChange={() => setIsExpireMessagesPushing(!isExpireMessagesPushing)}*/}
                {/*/>*/}
                <div style={{ height: 20 }} />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '18px' }}>
                        <ButtonFilled disabled={!state || error} loading={pending} onClick={onSubmit}>
                            {t('save')}
                        </ButtonFilled>
                    </div>
                </div>
            </div>
            <Modal
                open={errorModal} size="small"
                onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                            onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
