import RFMApi from "./RFMApi";
import RFMTypes from "./RFMTypes";
import branchTypes from '../../branchStore/branchTypes';
import { Roles } from "../../../config";
import { API_URL } from "../../../api";
import cookies from "react-cookies";
import { COOKIES } from "../../../helpers";
import queryString from "query-string";

const RFMActions = {
    getRFM: (branch_id, currentRole, invisParams, groupId, resolve, reject) => async dispatch => {
        dispatch({
            type: RFMTypes.GET_RFM_PENDING
        })
      if (currentRole === Roles.ANALYTIC && groupId) {
        await RFMApi.getAnalyticRFM(groupId, invisParams)
          .then((customers) => {
            const TOP_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'TOP_CUSTOMERS');
            const ACTIVE_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'ACTIVE_CUSTOMERS');
            const EMERGING_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'EMERGING_CUSTOMERS');
            const UNSTEADY_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'UNSTEADY_CUSTOMERS');
            const POTENTIAL_LOST_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'POTENTIAL_LOST_CUSTOMERS');
            const INACTIVE_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'INACTIVE_CUSTOMERS');
            const LOST_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'LOST_CUSTOMERS');

            dispatch({
              type: RFMTypes.GET_RFM_SUCCESS,
              payload: {
                ALL_CUSTOMERS: customers,
                TOP_CUSTOMERS,
                ACTIVE_CUSTOMERS,
                EMERGING_CUSTOMERS,
                UNSTEADY_CUSTOMERS,
                POTENTIAL_LOST_CUSTOMERS,
                INACTIVE_CUSTOMERS,
                LOST_CUSTOMERS,
              }
            });
            resolve();
          })
          .catch((e) => {
            reject(e);
            dispatch({
              type: RFMTypes.GET_RFM_ERROR
            });
          })
      } else {
        await RFMApi.getRFM(branch_id)
          .then((customers) => {
            const TOP_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'TOP_CUSTOMERS');
            const ACTIVE_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'ACTIVE_CUSTOMERS');
            const EMERGING_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'EMERGING_CUSTOMERS');
            const UNSTEADY_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'UNSTEADY_CUSTOMERS');
            const POTENTIAL_LOST_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'POTENTIAL_LOST_CUSTOMERS');
            const INACTIVE_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'INACTIVE_CUSTOMERS');
            const LOST_CUSTOMERS = customers.filter((customer) => customer.cluster.Type == 'LOST_CUSTOMERS');

            dispatch({
              type: RFMTypes.GET_RFM_SUCCESS,
              payload: {
                ALL_CUSTOMERS: customers,
                TOP_CUSTOMERS,
                ACTIVE_CUSTOMERS,
                EMERGING_CUSTOMERS,
                UNSTEADY_CUSTOMERS,
                POTENTIAL_LOST_CUSTOMERS,
                INACTIVE_CUSTOMERS,
                LOST_CUSTOMERS,
              }
            });
            resolve();
          })
          .catch((e) => {
            reject(e);
            dispatch({
              type: RFMTypes.GET_RFM_ERROR
            });
          })
      }
    },
    getRFMAnalyticExport: (groupId, invisParams) => {
    let invisQuery = ``;

    if (invisParams.city_ids?.length) {
      invisQuery = `?${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
    }
    if (invisParams.branch_ids?.length) {
      invisQuery = `${invisQuery ? invisQuery + '&' : '?'}${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
    }

    return fetch(`${API_URL}/branches/group/${groupId}/statistics/rfm_analysis/excel${invisQuery}`, {
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        Authorization: `Bearer ${cookies.load(COOKIES.TOKEN)}`
      },
      method: 'GET'
    }).then((response) => {
      response.blob().then((blob) => {
        const filename = decodeURI(response.headers.get('Content-Disposition')?.split('filename=')[1] || '')
        if ('msSaveOrOpenBlob' in window.navigator) {
          navigator.msSaveBlob(blob, filename)
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = window.URL.createObjectURL(blob)
          a.download = filename.split('.')[0]
          a.target = '_blank'
          a.click()
          a.remove()
        }
      });

      return {};
    })
  },
    saveRFM: (branch_id, data) => async dispatch => {
    dispatch({
      type: RFMTypes.SAVE_RFM_PENDING
    })
    await RFMApi.saveRFM(branch_id, data)
      .then((branch) => {
        dispatch({
          type: branchTypes.GET_BRANCH_SUCCESS,
          payload: branch
        });
        dispatch({
          type: RFMTypes.SAVE_RFM_SUCCESS,
        });
      })
      .catch(() => {
        dispatch({
          type: RFMTypes.SAVE_RFM_ERROR
        })
      })
  },
}

export default RFMActions