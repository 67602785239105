const clientInfoTypes = {
    GET_CLIENT_INFO_PENDING: "GET_CLIENT_INFO_PENDING",
    GET_CLIENT_INFO_SUCCESS: "GET_CLIENT_INFO_SUCCESS",
    UPDATE_CLIENT_INFO: "UPDATE_CLIENT_INFO",
    GET_CLIENT_INFO_ERROR: "GET_CLIENT_INFO_ERROR",

    GET_CLIENT_INFO_AWARD_PENDING: "GET_CLIENT_INFO_AWARD_PENDING",
    GET_CLIENT_INFO_AWARD_SUCCESS: "GET_CLIENT_INFO_AWARD_SUCCESS",
    GET_CLIENT_INFO_AWARD_ERROR: "GET_CLIENT_INFO_AWARD_ERROR",

    GET_CLIENT_TRANSACTION_PENDING: "GET_CLIENT_TRANSACTION_PENDING",
    GET_CLIENT_TRANSACTION_SUCCESS: "GET_CLIENT_TRANSACTION_SUCCESS",
    GET_CLIENT_TRANSACTION_ERROR: "GET_CLIENT_TRANSACTION_ERROR",

    CHANGE_CLIENT_TRANSACTION_PAGE: "CHANGE_CLIENT_TRANSACTION_PAGE",


    RESET_CLIENT_INFO:'RESET_CLIENT_INFO'
}

export default clientInfoTypes