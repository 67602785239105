import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import styles from './select.module.scss';

export default function CustomSelect(props) {
  const { t } = useTranslation();
    const {
        options = [],
        value = {value: '', label: t('choose1Var')},
        onSelect = () => {}
    } = props;

    const [opened, setOpened] = useState(false);

    return (
        <div className={styles.container} onClick={() => setOpened((prev) => !prev)}>
            <button type="button">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.80584 7.40334C6.40623 7.94715 5.59376 7.94716 5.19416 7.40334L1.10774 1.84214C0.622459 1.18172 1.09404 0.25 1.91358 0.25L10.0864 0.250001C10.906 0.250001 11.3775 1.18172 10.8923 1.84214L6.80584 7.40334Z" fill="#6C6D6C"/>
                </svg>
            </button>
            <div>{typeof value.label === 'string' ? t(value.label) : value.label}</div>
            <ul className={`${opened ? styles.opened : ''}`}>
                {options.filter(o => o.value !== value.value).map(o => (
                    <li key={o.value} onClick={() => onSelect(o)}>{typeof o.label === 'string' ? t(o.label) : o.label}</li>
                ))}
            </ul>
        </div>
    )
}
