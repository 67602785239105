import React, { useState } from 'react';
import AuthForm from '../AuthForm';
import ForgetPasswordForm from '../ForgetPasswordForm';
import  { useTranslation } from "react-i18next";
import styles from './auth.module.scss';

export default function Auth() {
  const [forgetPassword, setForgetPassword] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t('NSAuth.login')}</h1>
      {forgetPassword ?
        <ForgetPasswordForm setForgetPassword={setForgetPassword} /> :
        <AuthForm setForgetPassword={setForgetPassword} />}
    </div>
  )
}