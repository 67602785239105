import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import branchActions from '../../../../../store/branchStore/branchActions';
import {COOKIES} from "../../../../../helpers";
import {deleteAuth} from "../../../../../store/authState";
import { useHistory } from "react-router-dom";
import cookies from "react-cookies";
import userImg from 'assets/user.png';
import ButtonFilled from "../../../../FormElements/ButtonFilled";
import Modal from "../../../../../portals/Modal";
import styles from './CarouselComponent.module.css';


const leftArrowSvg = (
  <svg width="8" height="14" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 0L0 6L6 12V0Z" />
  </svg>
);

const rightArrowSvg = (
  <svg width="8" height="14" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0L6 6L0 12V0Z" />
  </svg>
);

export default function CarouselComponent({ branches }) {
    const history = useHistory();
    const { t } = useTranslation();
    const branch = useSelector(s => s.branchReducer.info)
    const pending = useSelector(s => s.branchReducer.pending)
    const [current, setCurrent] = useState(0);
    const [branchEnabled, setBranchEnabled] = useState(true);
    const [branchEnabledModal, setBranchEnabledModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (branches.length) {
            history.push('/');
            dispatch(branchActions.getBranch(branches[0].id, setBranchEnabledModal))
        }
    }, [branches]);

    useEffect(() => {
        if (branches.length) {
            history.push('/');
            dispatch(branchActions.getBranch(branches[current].id, setBranchEnabledModal))
        }
    }, [current]);

    useEffect(() => {
        if (!branchEnabled && branches.length) {
            cookies.remove(COOKIES.TOKEN, {path: '/'});
            dispatch(deleteAuth());
            history.push('/auth');
        }
    }, [branchEnabled])

    const setNext = () => {
        if (current === branches.length - 1) {
            setCurrent(0)
        } else {
            setCurrent(current + 1)
        }
    }

    const setPrev = () => {
        if (current === 0) {
            setCurrent(branches.length - 1)
        } else {
            setCurrent(current - 1)
        }
    }

    const closeBranchEnabledModal = () => {
        setBranchEnabledModal(false);
        setBranchEnabled(false);
    }

    return (
        <>
            <div className={`${styles.container} ${pending ? `${styles[`container-pending`]}` : ''}`}>
                {branches.length > 1 && <span className={`${styles.arrows} ${styles['arrows-left']}`} onClick={setPrev}>{leftArrowSvg}</span>}
                {!_.isEmpty(branch) && branches.map((item, index) =>
                  index === current &&
                  <div className={styles.item} key={index}>
                      <img src={branch.icon ? branch.icon : userImg} />
                      <p>{branch.name}</p>
                      <span>{branch.address}</span>
                  </div>
                )}
                {branches.length > 1 && <span className={`${styles.arrows} ${styles['arrows-right']}`} onClick={setNext}>{rightArrowSvg}</span>}
                <div className={styles.dots} style={{width: branches.length * 10}}>
                    {branches.map((item, index) => (
                      <span style={index === current ? {color: '#4EB78B'} : {color: "white"}}
                            key={index}
                            onClick={() => setCurrent(index)}
                      >•</span>
                    ))}
                </div>
            </div>
            <Modal
              open={branchEnabledModal} size="small"
              onClickClose={closeBranchEnabledModal}
            >
                <div className={styles.modal}>
                    <p>
                        <Trans i18nKey="disabledBranch">
                            Ваша подписка отключена. <br/> Для возобновления работы в Sagi <br/>
                            свяжитесь по номеру
                        </Trans> &nbsp;
                        <a href="tel:+77757000030">+77757000030</a>
                    </p>
                    <div>
                        <ButtonFilled
                          onClick={closeBranchEnabledModal}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
