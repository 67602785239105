const surveyTypes = {
    CREATE_SURVEY_PENDING: "CREATE_SURVEY_PENDING",
    CREATE_SURVEY_SUCCESS: "CREATE_SURVEY_SUCCESS",
    CREATE_SURVEY_ERROR: "CREATE_SURVEY_ERROR",

    GET_SURVEYS_PENDING: "GET_SURVEYS_PENDING",
    GET_SURVEYS_SUCCESS: "GET_SURVEYS_SUCCESS",
    GET_SURVEYS_ERROR: "GET_SURVEYS_ERROR",
    CLEAR_SURVEYS: "CLEAR_SURVEYS",

    GET_SURVEY_PENDING: "GET_SURVEY_PENDING",
    GET_SURVEY_SUCCESS: "GET_SURVEY_SUCCESS",
    GET_SURVEY_ERROR: "GET_SURVEY_ERROR",

    DELETE_SURVEY_PENDING: "DELETE_SURVEY_PENDING",
    DELETE_SURVEY_SUCCESS: "DELETE_SURVEY_SUCCESS",
    DELETE_SURVEY_ERROR: "DELETE_SURVEY_ERROR",

    CHANGE_ANSWER_SURVEY_PENDING: "CHANGE_ANSWER_SURVEY_PENDING",
    CHANGE_ANSWER_SURVEY_SUCCESS: "CHANGE_ANSWER_SURVEY_SUCCESS",
    CHANGE_ANSWER_SURVEY_ERROR: "CHANGE_ANSWER_SURVEY_ERROR",

    GET_SURVEY_TESTERS_PENDING: "GET_SURVEY_TESTERS_PENDING",
    GET_SURVEY_TESTERS_SUCCESS: "GET_SURVEY_TESTERS_SUCCESS",
    GET_SURVEY_TESTERS_ERROR: "GET_SURVEY_TESTERS_ERROR",

    CLEAR_SURVEY_TESTERS: "CLEAR_SURVEY_TESTERS",
}

export default surveyTypes
