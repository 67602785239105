import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleWare from "redux-thunk";
import authState from './authState';
import clientsReducer from "./clientsStore/clientsReducer";
import clientInfoReducer from "./clientInfoStore/clientInfoReducer";
import branchReducer from './branchStore/branchReducer';
import profileReducer from './profileStore/profileReducer';
import bonusesModalReducer from "./bonusesModalStore/bonusesModalReducer";
import generalReducer from "./analyticsStores/generalStore/generalReducer";
import loyalReducer from "./analyticsStores/loyalStore/loyalReducer";
import RFMReducer from "./analyticsStores/RFMStore/RFMReducer";
import audienceReducer from "./analyticsStores/audienceStore/audienceReducer";
import businessReportReducer from "./analyticsStores/businessReportStore/businessReportReducer";
import transactionsReducer from "./transactionsStore/transactionsReducer";
import notificationsReducer from "./notificationsStore/notificationsReducer";
import surveyReducer from "./surveyStore/surveyReducer";
import adsReducer from "./adsStore/adsReducer";
import crossReducer from "./crossStore/crossReducer";
import directoryReducer from "./directoryStore/directoryReducer";
import filtersReducer from "./filtersStore/filtersReducer";

const reducers = combineReducers({
    authState,
    businessReportReducer,
    clientsReducer,
    clientInfoReducer,
    branchReducer,
    profileReducer,
    bonusesModalReducer,
    generalReducer,
    loyalReducer,
    RFMReducer,
    audienceReducer,
    transactionsReducer,
    notificationsReducer,
    surveyReducer,
    adsReducer,
    crossReducer,
    directoryReducer,
    filtersReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    composeWithDevTools) ||
  compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleWare)));

export default store;
