import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import TextField from 'components/FormElements/TextField';
import ButtonFilled from 'components/FormElements/ButtonFilled';
import { COOKIES, phoneMask } from 'helpers.js';
import { useForgetPassword } from '../../hooks/useForgetPasswordForm';
import { usePasswordTypeBtn } from '../../../hooks/usePasswordTypeBtn';
import ButtonOutlined from '../../../../components/FormElements/ButtonOutlined';
import Timer from '../../../../components/Timer';
import cookies from 'react-cookies';
import Modal from '../../../../portals/Modal';
import styles from './forgetPassword.module.scss';
import { PhoneInputField } from "../../../../components/PhoneInput";

export default function ForgetPasswordForm({ setForgetPassword }) {
  const { t } = useTranslation();
  const { type, passwordTypeBtn } = usePasswordTypeBtn({show: t('show'), hide: t('hide')});
  const [modal, setModal] = useState(false);

  const {
    askCodeFormik,
    confirmCodeFormik,
    resetPasswordFormik,
    step,
    isCodeAsked,
    setIsCodeAsked,
    setStep,
    pending
  } = useForgetPassword(setModal);

  useEffect(() => {
    if (cookies.load(COOKIES.NONCE)) {
      setIsCodeAsked(true);
      setStep(2);
    }
  }, []);

  const onTimerExpired = () => {
    setIsCodeAsked(false);
    cookies.remove(COOKIES.NONCE, {path: '/'});
  }

  const onChangeSecond = () => {
    if (!cookies.load(COOKIES.NONCE)) {
      setIsCodeAsked(false);
      setStep(1);
    }
  }

  const ShowTimer = () => {
    if (isCodeAsked)

    return () => {
      switch (isCodeAsked) {
        case true:
          return <Timer initialMinute={5} onChangeSecond={onChangeSecond} onTimerExpired={onTimerExpired} />
        default: return null
      }
    }
  }

  const onFinishSuccessResetPassword = () => {
    setForgetPassword(false);
    setModal(false);
  }

  const onClickCancel = () => {
    cookies.remove(COOKIES.NONCE);
    cookies.remove(COOKIES.PHONE);
    setForgetPassword(false);
  }

  const askCode = step <= 2;
  const confirmCode = step === 2;
  const resetPassword = step === 3;

  return (
    <>
      <Modal open={modal} size="small" onClickClose={onFinishSuccessResetPassword}>
        <div className={styles.resetPasswordSuccess}>
          <p>{t('NSAuth.passUpdated')}</p>
          <ButtonFilled onClick={onFinishSuccessResetPassword}>{t('proceed')}</ButtonFilled>
        </div>
      </Modal>
      <form className={styles.form}>
        <div className={styles.authInputs}>
        {
          askCode && (
            <>
              <PhoneInputField
                label={t('NSAuth.phone')}
                disabled={pending || isCodeAsked}
                postfix={ShowTimer()}
                value={askCodeFormik.values.phone}
                error={askCodeFormik.touched.phone && askCodeFormik.errors.phone}
                onChange={(v) => askCodeFormik.setFieldValue('phone', v)}
              />
              <p className={styles.sms}>{t('NSAuth.sendEmail')}</p>
            </>
          )
        }
        {
          confirmCode && (
            <>
              <TextField
                label={t('Form.codeConfirm')}
                placeholder={t('Form.enterCode')}
                className={styles.authInput}
                disabled={pending}

                value={confirmCodeFormik.values.confirmCode}
                error={confirmCodeFormik.touched.confirmCode && confirmCodeFormik.errors.confirmCode}
                onChange={(e) => confirmCodeFormik.setFieldValue('confirmCode', e.target.value)}
              />
            </>
          )
        }
        {
          resetPassword && (
            <>
              <TextField
                label={t('pass')}
                placeholder={t('enterPass')}
                className={styles.authInput}
                disabled={pending}
                type={type}
                postfix={passwordTypeBtn}

                value={resetPasswordFormik.values.password}
                error={resetPasswordFormik.touched.password && resetPasswordFormik.errors.password}
                onChange={(e) => resetPasswordFormik.setFieldValue('password', e.target.value)}
              />
              <TextField
                label={t('pass')}
                placeholder={t('enterPass')}
                className={styles.authInput}
                disabled={pending}
                type={type}
                postfix={passwordTypeBtn}

                value={resetPasswordFormik.values.passwordConfirmation}
                error={resetPasswordFormik.touched.passwordConfirmation && resetPasswordFormik.errors.passwordConfirmation}
                onChange={(e) => resetPasswordFormik.setFieldValue('passwordConfirmation', e.target.value)}
              />
            </>
          )
        }
      </div>
        <div className={styles.authActions}>
      {
        askCode && !confirmCode && (
          <ButtonFilled
            onClick={askCodeFormik.handleSubmit}
            loading={pending}
            style={{ padding: '22px 42px' }}
          >
            {t('proceed')}
          </ButtonFilled>
        )
      }
      {
        confirmCode && (
          <ButtonFilled
            onClick={confirmCodeFormik.handleSubmit}
            loading={pending}
            style={{ padding: '22px 42px' }}
          >
            {t('confirm')}
          </ButtonFilled>
        )
      }
      {
        resetPassword && (
          <ButtonFilled
            onClick={resetPasswordFormik.handleSubmit}
            loading={pending}
            style={{ padding: '22px 42px' }}
          >
            {t('change')}
          </ButtonFilled>
        )
      }
      <ButtonOutlined onClick={onClickCancel} type="button">{t('cancel')}</ButtonOutlined>
    </div>
      </form>
    </>
  )
}