import i18n from '../i18n';

export function getAgeData(data) {
    let ageData = [
        ['', `${i18n.t('clientsTo')} %`],
        ['0-17', 0],
        ['18-24', 0],
        ['25-34', 0],
        ['35-44', 0],
        ['45-54', 0],
        ['55+', 0],
    ]

    for (let i in data) {
        if (data[i].age) {
            let keys = Object.keys(data[i].age);
            for (let j in keys) {
                if (keys[j] >= 0 && keys[j] <= 17) {
                    ageData[1][1] += data[i].age[keys[j]];
                } else if (keys[j] >= 18 && keys[j] <= 24) {
                    ageData[2][1] += data[i].age[keys[j]];
                } else if (keys[j] >= 25 && keys[j] <= 34) {
                    ageData[3][1] += data[i].age[keys[j]];
                } else if (keys[j] >= 35 && keys[j] <= 44) {
                    ageData[4][1] += data[i].age[keys[j]];
                } else if (keys[j] >= 45 && keys[j] <= 54) {
                    ageData[5][1] += data[i].age[keys[j]];
                } else if (keys[j] >= 55) {
                    ageData[6][1] += data[i].age[keys[j]];
                }
            }
        }
    }
    let count = 0
    for (let i in ageData) {
        if (i > 0) {
            count += ageData[i][1];
        }
    }
    ageData.map((item, index) => {
        if (index) {
            item[1] = (item[1] / count * 100)
        }
    })
    return ageData
}


export function getGenderData(data) {
    let genders = [
        {x: "Мужчины", y: 0},
        {x: "Женщины", y: 0},
        {x: "неизвестно", y: 0},
    ];

    let genders_array = [
        {x: "Мужчины", y: 0},
        {x: "Женщины", y: 0},
        {x: "неизвестно", y: 0},
    ];

    for (let i in data) {
        if (data[i].gender) {
            let keys = Object.keys(data[i].gender);
            for (let j in keys) {
                if (keys[j] === "male") {
                    genders[0].y += data[i].gender[keys[j]];
                    genders_array[0].y += data[i].gender[keys[j]];
                } else if (keys[j] === "female") {
                    genders[1].y += data[i].gender[keys[j]];
                    genders_array[1].y += data[i].gender[keys[j]];
                } else if (keys[j] === "unknown") {
                    genders[2].y += data[i].gender[keys[j]];
                    genders_array[2].y += data[i].gender[keys[j]];
                }
            }
        } else {
            genders[2].y += 1;
            genders_array[2].y += 1;
        }
    }


    let genders_amount = 0
    genders_array.map((item) => (genders_amount += item.y));



    genders.map((item, index) => {
        item.y = Math.floor(item.y / genders_amount * 100)
    })




    return {genders, genders_amount, genders_array}
}


function getPaymentFromHours(data) {
    // let chart_data = [
    //     ['', ''],
    //     ['0-1', 0],
    //     ['1-2', 0],
    //     ['3-4', 0],
    //     ['5-6', 0],
    //     ['7-8', 0],
    //     ['9-10', 0],
    //     ['11-12', 0],
    //     ['13-14', 0],
    //     ['15-16', 0],
    //     ['17-18', 0],
    //     ['19-20', 0],
    //     ['21-22', 0],
    //     ['23-24', 0],
    // ]
    //
    // for (let i in data) {
    //     if (data[i].payment_hours) {
    //         let keys = Object.keys(data[i].payment_hours);
    //         for (let j in keys) {
    //             if (keys[j] >= 0 && keys[j] <= 1) {
    //                 chart_data[1][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 1 && keys[j] <= 2) {
    //                 chart_data[2][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 3 && keys[j] <= 4) {
    //                 chart_data[3][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 5 && keys[j] <= 6) {
    //                 chart_data[4][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 7 && keys[j] <= 8) {
    //                 chart_data[5][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 9 && keys[j] <= 10) {
    //                 chart_data[6][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 11 && keys[j] <= 12) {
    //                 chart_data[7][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 13 && keys[j] <= 14) {
    //                 chart_data[8][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 15 && keys[j] <= 16) {
    //                 chart_data[9][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 17 && keys[j] <= 18) {
    //                 chart_data[10][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 19 && keys[j] <= 20) {
    //                 chart_data[11][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 21 && keys[j] <= 22) {
    //                 chart_data[12][1] += data[i].payment_hours[keys[j]]
    //             } else if (keys[j] >= 23 && keys[j] <= 24) {
    //                 chart_data[13][1] += data[i].payment_hours[keys[j]]
    //             }
    //         }
    //     }
    // }

    let chart_data = [
        ['', ''],
    ]

    for (let i in data) {
        const date = new Date(data[i].date);
        let options = {
            month: "short",
            day: "numeric",
        };

        const label = new Intl.DateTimeFormat(i18n.language, options).format(date);

        chart_data.push([label || "", data[i].payment_total])
    }
    return chart_data
}

export function getPaymentData(data, type) {
    let chart_data = [];

    if (type === 1) {
        chart_data = getPaymentFromHours(data)
    } else if (type === 2) {
        chart_data = [
            ['', ''],
            [i18n.t('mn'), 0],
            [i18n.t('tu'), 0],
            [i18n.t('wd'), 0],
            [i18n.t('th'), 0],
            [i18n.t('fr'), 0],
            [i18n.t('sn'), 0],
            [i18n.t('st'), 0],
        ]
        for (let i in data) {
            let week_day = new Date(data[i].date).getDay()
            if (week_day == 0) {
                chart_data[7][1] += data[i].payment_total
            } else if (week_day == 1) {
                chart_data[1][1] += data[i].payment_total
            } else if (week_day == 2) {
                chart_data[2][1] += data[i].payment_total
            } else if (week_day == 3) {
                chart_data[3][1] += data[i].payment_total
            } else if (week_day == 4) {
                chart_data[4][1] += data[i].payment_total
            } else if (week_day == 5) {
                chart_data[5][1] += data[i].payment_total
            } else if (week_day == 6) {
                chart_data[6][1] += data[i].payment_total
            }
        }

    } else if (type === 3) {
        let daysArr = [];
        for (let i in data) {
            daysArr.push(new Date(data[i].date).getDate())
        }
        let maxDay = Math.max.apply(null, daysArr)
        let minDay = Math.min.apply(null,daysArr)

        chart_data.push(['', ''])
        for (let i = 1; i <= maxDay; i++) {
            chart_data.push([i, 0])
        }
        for (let i in data) {
            let day = new Date(data[i].date).getDate()
            chart_data[day][1] += data[i].payment_total
        }
    } else if (type === 4) {
        chart_data = [
            ['', ''],
            [i18n.t('jan'), 0],
            [i18n.t('feb'), 0],
            [i18n.t('march'), 0],
            [i18n.t('apr'), 0],
            [i18n.t('may'), 0],
            [i18n.t('june'), 0],
            [i18n.t('july'), 0],
            [i18n.t('august'), 0],
            [i18n.t('sep'), 0],
            [i18n.t('oct'), 0],
            [i18n.t('nov'), 0],
            [i18n.t('dec'), 0],
        ]
        for (let i in data) {
            let month = new Date(data[i].date).getMonth() + 1
            chart_data[month][1] += data[i].payment_total
        }
    }
    return chart_data
}


function getVisitorsFromHours(data) {
    let audience_chart = [
        ['x', i18n.t('clientsTo'), {role: "tooltip", type: "string", p: {html: true}}],
        ['0-1', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['1-2', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['3-4', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['5-6', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['7-8', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['9-10', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['11-12', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['13-14', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['15-16', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['17-18', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['19-20', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['21-22', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ['23-24', 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
    ]

    for (let i in data) {
        if (data[i].visitor_hours) {
            let keys = Object.keys(data[i].visitor_hours);
            for (let j in keys) {
                if (keys[j] >= 0 && keys[j] <= 1) {
                    audience_chart[1][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 1 && keys[j] <= 2) {
                    audience_chart[2][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 3 && keys[j] <= 4) {
                    audience_chart[3][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 5 && keys[j] <= 6) {
                    audience_chart[4][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 7 && keys[j] <= 8) {
                    audience_chart[5][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 9 && keys[j] <= 10) {
                    audience_chart[6][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 11 && keys[j] <= 12) {
                    audience_chart[7][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 13 && keys[j] <= 14) {
                    audience_chart[8][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 15 && keys[j] <= 16) {
                    audience_chart[9][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 17 && keys[j] <= 18) {
                    audience_chart[10][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 19 && keys[j] <= 20) {
                    audience_chart[11][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 21 && keys[j] <= 22) {
                    audience_chart[12][1] += data[i].visitor_hours[keys[j]]
                } else if (keys[j] >= 23 && keys[j] <= 24) {
                    audience_chart[13][1] += data[i].visitor_hours[keys[j]]
                }
            }
        }
    }
    return audience_chart
}

export function getVisitorsData(data, type) {
    let audience_chart = [];

    if (type === 1) {
        audience_chart = getVisitorsFromHours(data)
        for (let i in audience_chart) {
            if (i > 1) {
                audience_chart[i][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[i][1]}</p></div>`
            }
        }

    } else if (type === 2) {
        audience_chart = [
            ['x', i18n.t('clientsTo'), {role: "tooltip", type: "string", p: {html: true}}],
            [i18n.t('mn'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('tu'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('wd'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('th'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('fr'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('sn'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('st'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ]
        for (let i in data) {
            let week_day = new Date(data[i].date).getDay() + 1
            audience_chart[week_day][1] += data[i].visitors
        }

        for (let i in data) {
            let week_day = new Date(data[i].date).getDay()
            if (week_day == 0) {
                audience_chart[7][1] += data[i].visitors
            } else if (week_day == 1) {
                audience_chart[1][1] += data[i].visitors
            } else if (week_day == 2) {
                audience_chart[2][1] += data[i].visitors
            } else if (week_day == 3) {
                audience_chart[3][1] += data[i].visitors
            } else if (week_day == 4) {
                audience_chart[4][1] += data[i].visitors
            } else if (week_day == 5) {
                audience_chart[5][1] += data[i].visitors
            } else if (week_day == 6) {
                audience_chart[6][1] += data[i].visitors
            }
        }

        for (let i in data) {
            let week_day = new Date(data[i].date).getDay()
            if (week_day == 0) {
                audience_chart[7][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[7][1]}</p></div>`
            } else if (week_day == 1) {
                audience_chart[1][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[1][1]}</p></div>`
            } else if (week_day == 2) {
                audience_chart[2][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[2][1]}</p></div>`
            } else if (week_day == 3) {
                audience_chart[3][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[3][1]}</p></div>`
            } else if (week_day == 4) {
                audience_chart[4][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[4][1]}</p></div>`
            } else if (week_day == 5) {
                audience_chart[5][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[5][1]}</p></div>`
            } else if (week_day == 6) {
                audience_chart[6][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[6][1]}</p></div>`
            }
        }


    } else if (type === 3) {
        let daysArr = [];
        for (let i in data) {
            daysArr.push(new Date(data[i].date).getDate())
        }
        let maxDay = Math.max.apply(null, daysArr)
        console.log(maxDay, daysArr)
        audience_chart.push(['x', i18n.t('clientsTo'), {role: "tooltip", type: "string", p: {html: true}}])
        for (let i = 1; i <= maxDay; i++) {
            audience_chart.push([i, 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`])
        }

        for (let i in data) {
            let day = new Date(data[i].date).getDate()
            audience_chart[day][1] += data[i].visitors

        }
        for (let i in data) {
            let day = new Date(data[i].date).getDate()
            audience_chart[day][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[day][1]}</p></div>`
        }
    } else if (type === 4) {
        audience_chart = [
            ['x', i18n.t('clientsTo'), {role: "tooltip", type: "string", p: {html: true}}],
            [i18n.t('jan'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('feb'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('march'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('apr'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('may'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('june'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('july'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('august'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('sep'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('oct'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('nov'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
            [i18n.t('dec'), 0, `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>0</p></div>`],
        ]
        for (let i in data) {
            let month = new Date(data[i].date).getMonth() + 1
            audience_chart[month][1] += data[i].visitors
        }
        for (let i in data) {
            let month = new Date(data[i].date).getMonth() + 1
            audience_chart[month][2] = `<div class='lineChartContainer'><span>${i18n.t('clientsTo')}</span><p>${audience_chart[month][1]}</p></div>`
        }

    }
    return audience_chart
}
