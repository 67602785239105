import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Trans, useTranslation } from "react-i18next";
import PageHeader from "../../components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import styles from "./security.module.scss";
import TextField from "../../components/FormElements/TextField";
import ButtonFilled from "../../components/FormElements/ButtonFilled";
import Checkbox from "../../components/FormElements/Checkbox";
import Pagination from "../../components/Pagination";
import crossActions from "../../store/crossStore/crossActions";
import branchApi from "../../store/branchStore/branchApi";
import instance from "../../api";
import { clearMask } from "../../helpers";
import { store } from "react-notifications-component";
import MaskService from "react-masked-text/src/mask-service";
import CustomModal from "../../portals/Modal";
import branchActions from "../../store/branchStore/branchActions";

const arrowSvg = (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.4 13.5C4.4 13.8314 4.66863 14.1 5 14.1C5.33137 14.1 5.6 13.8314 5.6 13.5L4.4 13.5ZM5.42426 1.07574C5.18995 0.841421 4.81005 0.841421 4.57574 1.07574L0.757359 4.89411C0.523045 5.12843 0.523045 5.50833 0.757359 5.74264C0.991674 5.97696 1.37157 5.97696 1.60589 5.74264L5 2.34853L8.39411 5.74264C8.62843 5.97696 9.00833 5.97696 9.24264 5.74264C9.47696 5.50833 9.47696 5.12843 9.24264 4.89411L5.42426 1.07574ZM5.6 13.5L5.6 1.5L4.4 1.5L4.4 13.5L5.6 13.5Z" fill="black"/>
    <path d="M11.4 1.5C11.4 1.16863 11.6686 0.9 12 0.9C12.3314 0.9 12.6 1.16863 12.6 1.5L11.4 1.5ZM12.4243 13.9243C12.19 14.1586 11.8101 14.1586 11.5757 13.9243L7.75736 10.1059C7.52304 9.87157 7.52304 9.49167 7.75736 9.25736C7.99167 9.02304 8.37157 9.02304 8.60589 9.25736L12 12.6515L15.3941 9.25736C15.6284 9.02304 16.0083 9.02304 16.2426 9.25736C16.477 9.49167 16.477 9.87157 16.2426 10.1059L12.4243 13.9243ZM12.6 1.5L12.6 13.5L11.4 13.5L11.4 1.5L12.6 1.5Z" fill="black"/>
  </svg>
);

const Security = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pending, info } = useSelector(s => s.branchReducer);
  const { pending: profilePending, info: profileInfo } = useSelector(s => s.profileReducer);
  const [usersLoading, setUsersLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllCheckedWhite, setIsAllCheckedWhite] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersWhite, setUsersWhite] = useState([]);
  const [phone, setPhone] = useState("");
  const [whitePhone, setWhitePhone] = useState("");
  const [dayLimit, setDayLimit] = useState("");
  const [txCashbackLimit, setTxCashbackLimit] = useState("");

  const [errorModal, setErrorModal] = useState(false);
  const [client, setClient] = useState(null);
  const [clientModal, setClientModal] = useState(false);
  const [lookingClient, setSetLookingClient] = useState(false);

  const onDeleteUsers = () => {
    if (!info.id) return
    setDeleting(true);
    branchApi.removeUserFromWhiteList(info.id, users)
      .then((res) => {
        dispatch(branchActions.getBranch(info.id));
        // getUsersAfterDeleting(info.id);
      })
      .catch((res) => {
        setUsers([]);
      })
      .finally(() => setDeleting(false));
  }

  const onSave = (id) => {
    if (profilePending) return
    setSaving(true);
    branchApi.addUserToWhiteList(info.id, "+"+id)
      .then((res) => {
        setPhone("");
        dispatch(branchActions.getBranch(info.id));
      })
      .catch((res) => {
      })
      .finally(() => setSaving(false));
  }

  const onDeleteUsersFromExclusion = () => {
    if (!info.id) return
    setDeleting(true);
    branchApi.excludeUserFromWhiteList(info.id, usersWhite)
      .then((res) => {
        dispatch(branchActions.getBranch(info.id));
        // getUsersAfterDeleting(info.id);
      })
      .catch((res) => {
        setUsers([]);
      })
      .finally(() => setDeleting(false));
  }

  const onSaveExclusion = (id) => {
    if (profilePending) return
    setSaving(true);
    branchApi.addUserToExcludeList(info.id, "+"+id)
      .then((res) => {
        setWhitePhone("");
        dispatch(branchActions.getBranch(info.id));
      })
      .catch((res) => {
      })
      .finally(() => setSaving(false));
  }

  useEffect(() => {
    if (info?.id) {
      // getUsers(info.id);
      if (info?.configuration
        && info.configuration.security
        && info.configuration.security.enabled
      ) {
        setDayLimit(info.configuration.security.daily_tx_limit === 0 ? 0 : info.configuration.security.daily_tx_limit || "");
        setTxCashbackLimit(info.configuration.security.tx_cashback_limit === 0 ? 0 : info.configuration.security.tx_cashback_limit || "");
      } else {
        setErrorModal(true);
      }
    }
  }, [info])

  const onFindClient = async (e, type) => {
    if ((type !== "white" && !phone) || lookingClient || (type === "white" && !whitePhone)) return

    if (type === "white") {
      onSaveExclusion(clearMask(whitePhone))
    } else {
      onSave(clearMask(phone));
    }

    // try {
    //   setSetLookingClient(true);
    //   if (type === "white") {
    //     const client = await instance().get(`/promoters/find?phone=${clearMask(whitePhone)}`).then(res => res.data.data);
    //     onSaveExclusion(clearMask(whitePhone))
    //   } else {
    //     const client = await instance().get(`/promoters/find?phone=${clearMask(phone)}`).then(res => res.data.data);
    //     onSave(clearMask(phone));
    //   }
    // } catch (e) {
    //   store.addNotification({
    //     title: t('error'),
    //     message: t('Notify.notReg'),
    //     type: "danger",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animate__animated", "animate__fadeIn"],
    //     animationOut: ["animate__animated", "animate__fadeOut"],
    //     dismiss: {
    //       duration: 5000,
    //       onScreen: true
    //     }
    //   });
    // } finally {
    //   setSetLookingClient(false);
    // }
  }

  const onChangePhone = (e) => {
    setPhone(MaskService.toMask('custom', e.target.value, {
      mask: '7 (999) 999-99-99'
    }));
  }

  const onChangePhoneWhite = (e) => {
    setWhitePhone(MaskService.toMask('custom', e.target.value, {
      mask: '7 (999) 999-99-99'
    }));
  }

  const onUpdateLimit = () => {
    if (!info.id) return
    dispatch(branchActions.updateDateLimitForSecurity(info.id, { daily_tx_limit: +dayLimit, limit: +txCashbackLimit }));
  }

  const onUpdateTxCashbackLimit = () => {
    if (!info.id) return
    dispatch(branchActions.updateTxCashbackLimitForSecurity(info.id, { limit: +txCashbackLimit }));
  }

  useEffect(() => {
    if (pending) return
    if (isAllChecked && info && info.configuration && info?.configuration?.security?.black_list_phones) {
      setUsers(info?.configuration?.security?.black_list_phones || []);
    } else {
      setUsers([]);
    }
  }, [isAllChecked, info, pending]);

  const onChangeCheckUser = (phone) => {
    let copiedUsers = [...users];
    if (copiedUsers.some((v) => v === phone)) {
      copiedUsers = copiedUsers.filter(((v) => v !== phone))
    } else {
      copiedUsers.push(phone);
    }
    setUsers(copiedUsers);
  }

  useEffect(() => {
    if (pending) return
    if (isAllCheckedWhite && info && info.configuration && info?.configuration?.security?.white_list_phones) {
      setUsersWhite(info?.configuration?.security?.white_list_phones || []);
    } else {
      setUsersWhite([]);
    }
  }, [isAllCheckedWhite, info, pending]);

  const onChangeCheckUserWhite = (phone) => {
    let copiedUsers = [...usersWhite];
    if (copiedUsers.some((v) => v === phone)) {
      copiedUsers = copiedUsers.filter(((v) => v !== phone))
    } else {
      copiedUsers.push(phone);
    }
    setUsersWhite(copiedUsers);
  }

  if (pending) {
    return (
      <div className={styles.container} style={{ justifyContent: 'center' }}>
        <Loader color="#73D389" style={{ marginTop: '100px', textAlign: 'center' }} />
      </div>
    )
  }

  return (
      <div className={styles.container}>
        <PageHeader title={t('NSSidebar.security')}>
          {/*<p>Lorem ipsum dolor sit amet consectetur.</p>*/}
        </PageHeader>
        <form className={styles.form}>
          <p className={styles["form__title"]}>{t("NSSecurity.limitation")}</p>
          <div className={styles.formBlock}>
            <div className={styles.left}>
              <Trans i18nKey="NSSecurity.maxBonusToOneClient">
                <p>
                  Максимальное количество раз начислений бонусов одному клиенту <b>в день</b><span
                    className={styles.danger}>*</span>
                </p>
              </Trans>
              <div className={styles.writeIn}>
                <TextField
                    placeholder={t("writeIn") || ""}
                    value={dayLimit}
                    type="number"
                    onChange={(e) => setDayLimit(e.target.value)}
                    disabled={pending}
                />
              </div>
            </div>
            <div className={styles.right}>
              <ButtonFilled
                  loading={pending}
                  onClick={onUpdateLimit}
              >
                {t("save")}
              </ButtonFilled>
            </div>
          </div>
        </form>
        <form className={styles.form}>
          <p className={styles["form__title"]}>{t("NSSecurity.limitation")}</p>
          <div className={styles.formBlock}>
            <div className={styles.left}>
              <Trans i18nKey="NSSecurity.maxCashbackFromTx">
                <p>
                  Максимальное количество бонусов, получаемое за одну транзакцию (0 - без ограничений)>
                </p>
              </Trans>
              <div className={styles.writeIn}>
                <TextField
                    placeholder={t("writeIn") || ""}
                    value={txCashbackLimit}
                    type="number"
                    onChange={(e) => setTxCashbackLimit(e.target.value)}
                    disabled={pending}
                />
              </div>
            </div>
            <div className={styles.right}>
              <ButtonFilled
                  loading={pending}
                  onClick={onUpdateTxCashbackLimit}
              >
                {t("save")}
              </ButtonFilled>
            </div>
          </div>
        </form>
        <div style={{display: "flex", flexWrap: "wrap", width: "100%", gap: "20px", justifyContent: "space-between"}}>
          <div className={styles.blackListWrapper}>
            <div className={styles.removeList}>
              <TextField
                  label={t("NSSecurity.restrictNumber") || ""}
                  placeholder={t("search") || ""}
                  className={styles.removeListInput}
                  width="224px"
                  value={phone}
                  onKeyUp={(e) => e.code === 'Enter' && onFindClient()}
                  onChange={onChangePhone}
              />
              <ButtonFilled
                  onClick={onFindClient}
                  loading={saving || lookingClient || pending}
              >{t("add")}</ButtonFilled>
            </div>

            <p className={styles.exclusionListTitle}>{t("NSSecurity.blackList")}</p>
            {!!users.length ? (
                <ButtonFilled variant="red" onClick={onDeleteUsers}
                              loading={pending || deleting}>{t("delete")}</ButtonFilled>
            ) : null}
            <div style={{width: '100%'}}>
              <table className={`table ${styles.table}`}>
                <thead>
                <tr>
                  <th>
                    {info?.configuration?.security?.black_list_phones?.length ? (
                        <Checkbox
                            onChange={(e) => setIsAllChecked(!isAllChecked)}
                            checked={isAllChecked}
                        />
                    ) : null}
                  </th>
                  {/*<th>{t('client')}</th>*/}
                  <th>{t('NSAssessment.phone')}</th>
                  {/*<th>{t('status')}</th>*/}
                  {/*<th>*/}
                  {/*  <div>*/}
                  {/*    <span>{t('transactions')}</span>*/}
                  {/*    <i>{arrowSvg}</i>*/}
                  {/*  </div>*/}
                  {/*</th>*/}
                  {/*<th>*/}
                  {/*  <div>*/}
                  {/*    <span>{t('NSStatistics.total')}</span>*/}
                  {/*    <i>{arrowSvg}</i>*/}
                  {/*  </div>*/}
                  {/*</th>*/}
                </tr>
                </thead>
                <tbody>
                {users ? info?.configuration?.security?.black_list_phones?.map((phone) => (
                    <tr key={phone}>
                      <td onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            onChange={(e) => onChangeCheckUser(phone)}
                            checked={users.some((v) => v === phone)}
                        />
                      </td>
                      {/*<td>*/}
                      {/*  {item.first_name} {item.last_name}*/}
                      {/*</td>*/}
                      <td>{phone}</td>
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                    </tr>
                )) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                    </tr>
                )}
                </tbody>
              </table>
              {/*{pendingCrossMarketingBranches && (*/}
              {/*  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '580px' }}>*/}
              {/*    <Loader color="#73D389" style={{ margin: 'auto' }}/>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*<Pagination*/}
              {/*  count={valueCrossMarketingBranchesCount}*/}
              {/*  limit={crossMarketingBranchesParams.limit}*/}
              {/*  max={5}*/}
              {/*  loading={pendingCrossMarketingBranches}*/}
              {/*  updated={info && info.id}*/}
              {/*  page={crossMarketingBranchesParams.page}*/}
              {/*  setPage={(page) => dispatch(crossActions.setCrossMarketingListParams({page}))}*/}
              {/*  onSkip={onSkip}*/}
              {/*/>*/}
            </div>
          </div>
          <div className={styles.blackListWrapper}>
            <div className={styles.removeList}>
              <TextField
                  label={t("NSSecurity.excludeNumber") || ""}
                  placeholder={t("search") || ""}
                  className={styles.removeListInput}
                  width="224px"
                  value={whitePhone}
                  onKeyUp={(e) => e.code === 'Enter' && onFindClient()}
                  onChange={onChangePhoneWhite}
              />
              <ButtonFilled
                  onClick={(e) => onFindClient(e, "white")}
                  loading={saving || lookingClient || pending}
              >{t("add")}</ButtonFilled>
            </div>

            <p className={styles.exclusionListTitle}>{t("NSSecurity.whiteList")}</p>
            {!!usersWhite.length ? (
                <ButtonFilled variant="red" onClick={onDeleteUsersFromExclusion}
                              loading={pending || deleting}>{t("delete")}</ButtonFilled>
            ) : null}
            <div style={{width: '100%'}}>
              <table className={`table ${styles.table}`}>
                <thead>
                <tr>
                  <th>
                    {info?.configuration?.security?.white_list_phones?.length ? (
                        <Checkbox
                            onChange={(e) => setIsAllCheckedWhite(!isAllCheckedWhite)}
                            checked={isAllCheckedWhite}
                        />
                    ) : null}
                  </th>
                  {/*<th>{t('client')}</th>*/}
                  <th>{t('NSAssessment.phone')}</th>
                  {/*<th>{t('status')}</th>*/}
                  {/*<th>*/}
                  {/*  <div>*/}
                  {/*    <span>{t('transactions')}</span>*/}
                  {/*    <i>{arrowSvg}</i>*/}
                  {/*  </div>*/}
                  {/*</th>*/}
                  {/*<th>*/}
                  {/*  <div>*/}
                  {/*    <span>{t('NSStatistics.total')}</span>*/}
                  {/*    <i>{arrowSvg}</i>*/}
                  {/*  </div>*/}
                  {/*</th>*/}
                </tr>
                </thead>
                <tbody>
                {usersWhite ? info?.configuration?.security?.white_list_phones?.map((phone) => (
                    <tr key={phone}>
                      <td onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            onChange={(e) => onChangeCheckUserWhite(phone)}
                            checked={usersWhite.some((v) => v === phone)}
                        />
                      </td>
                      {/*<td>*/}
                      {/*  {item.first_name} {item.last_name}*/}
                      {/*</td>*/}
                      <td>{phone}</td>
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                    </tr>
                )) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                      {/*<td></td>*/}
                    </tr>
                )}
                </tbody>
              </table>
              {/*{pendingCrossMarketingBranches && (*/}
              {/*  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '580px' }}>*/}
              {/*    <Loader color="#73D389" style={{ margin: 'auto' }}/>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*<Pagination*/}
              {/*  count={valueCrossMarketingBranchesCount}*/}
              {/*  limit={crossMarketingBranchesParams.limit}*/}
              {/*  max={5}*/}
              {/*  loading={pendingCrossMarketingBranches}*/}
              {/*  updated={info && info.id}*/}
              {/*  page={crossMarketingBranchesParams.page}*/}
              {/*  setPage={(page) => dispatch(crossActions.setCrossMarketingListParams({page}))}*/}
              {/*  onSkip={onSkip}*/}
              {/*/>*/}
            </div>
          </div>
        </div>

        <CustomModal
            open={errorModal} size="small"
            onClickClose={() => setErrorModal(false)}>
          <div className={styles.modal}>
            <p>
              {t('optionForbidden')} <br/>
              {t('touchModerator')}
            </p>
            <div>
              <ButtonFilled
                  onClick={() => setErrorModal(false)}
              >{t('close')}</ButtonFilled>
            </div>
          </div>
        </CustomModal>
      </div>
  )
}

export default Security;