import React from 'react'
import { useTranslation } from "react-i18next";
import { formatNum } from "../../../tools/formatNum";
import styles from './Numbers.module.css'

export default function Numbers(props) {
    const { t } = useTranslation();
    const {purple, average_check, cashback_amount, payment_total, transactions, payment_from_private_balance, loyal} = props

    return (
        <div className={styles.container}>
            <div className={styles.tab}>
                <div className={styles.item}>
                    <span>{t('NSGeneral.sales')}</span>
                    <div>
                        <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(payment_total, ' ')}</p>
                        <span></span>
                    </div>
                </div>
                <div className={styles.item}>
                    <span>{loyal ? t('NSGeneral.clients') : t('NSGeneral.bonuses')}</span>
                    <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(cashback_amount, ' ')}</p>
                </div>

            </div>
            <div className={styles.tab}>
                <div className={styles.item}>
                    <span>{t('NSGeneral.average')}</span>
                    <div>
                        <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(average_check, ' ')}</p>
                        <span></span>
                    </div>
                </div>
                <div className={styles.item}>
                    <span>{t('NSGeneral.trans')}</span>
                    <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(transactions, ' ')}</p>
                </div>
            </div>
            {payment_from_private_balance ? (
              <div className={styles.tab}>
                  <div className={styles.item}>
                      <span>{t('NSGeneral.used')}</span>
                      <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(payment_from_private_balance, ' ')}</p>
                  </div>
              </div>
            ) : null}
        </div>
    )
}


export function AllNumbers(props) {
    const { t } = useTranslation();
    const {purple, average_check, cashback_amount, payment_total, transactions, payment_from_private_balance, loyal} = props

    return (
      <div className={styles.container}>
          <div className={styles.tab}>
              {/*<div className={styles.item}>*/}
              {/*    <span>{t('NSGeneral.sales')}</span>*/}
              {/*    <div>*/}
              {/*        <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(payment_total, ' ')}</p>*/}
              {/*        <span></span>*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div className={styles.item}>
                  <span>{loyal ? t('NSGeneral.clients') : t('NSGeneral.bonuses')}</span>
                  <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(cashback_amount, ' ')}</p>
              </div>

          </div>
          {/*<div className={styles.tab}>*/}
          {/*    <div className={styles.item}>*/}
          {/*        <span>{t('NSGeneral.average')}</span>*/}
          {/*        <div>*/}
          {/*            <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(average_check, ' ')}</p>*/}
          {/*            <span></span>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*    <div className={styles.item}>*/}
          {/*        <span>{t('NSGeneral.trans')}</span>*/}
          {/*        <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(transactions, ' ')}</p>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*{payment_from_private_balance ? (*/}
          {/*  <div className={styles.tab}>*/}
          {/*      <div className={styles.item}>*/}
          {/*          <span>{t('NSGeneral.used')}</span>*/}
          {/*          <p className={purple ? styles.purple_p : styles.green_p}>{formatNum(payment_from_private_balance, ' ')}</p>*/}
          {/*      </div>*/}
          {/*  </div>*/}
          {/*) : null}*/}
      </div>
    )
}