import transactionsType from "./transactionsTypes";
import {PENDING, SUCCESS, ERROR} from "../globalStatuses/globalStatuses";

const initialState = {
    data: [],
    isSendUseCashbackCodePending: false,
    isSendedUseCashbackCode: false,
    page: 1,
    status: ''
}

const transactionsReducer = (state = initialState, action) => {
    switch (action.type) {


        case transactionsType.CLEAR_TRANSACTIONS:
            return {
                data: [],
                isSendUseCashbackCodePending: false,
                isSendedUseCashbackCode: false,
                page: 1,
                status: ''
            }

        case transactionsType.GET_TRANSACTIONS_PENDING:
            return {
                ...state,
                status: PENDING,
                data: [],
            }

        case transactionsType.GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                status: SUCCESS,
                data: action.payload
            }

        case transactionsType.GET_TRANSACTIONS_ERROR:
            return {
                ...state,
                status: ERROR
            }


        case transactionsType.SEND_USE_CASHBACK_CODE_PENDING:
            return {
                ...state,
                isSendUseCashbackCodePending: action.payload,
            }

        case transactionsType.SEND_USE_CASHBACK_CODE_SUCCESS:
            return {
                ...state,
                isSendedUseCashbackCode: true,
                isSendUseCashbackCodePending: false,
            }

        case transactionsType.SEND_USE_CASHBACK_CODE_ERROR:
            return {
                ...state,
                isSendedUseCashbackCode: false,
                isSendUseCashbackCodePending: false,
            }

        case transactionsType.CLEAR_USE_CASHBACK_CODE_PENDING:
            return {
                ...state,
                isSendedUseCashbackCode: false,
                isSendUseCashbackCodePending: false,
            }

        case transactionsType.CHANGE_TRANSACTIONS_PAGE:
            return {
                ...state,
                page: action.payload
            }

        case transactionsType.CLEAR_TRANSACTIONS_PAGE:
            return {
                ...state,
                page: 1
            }

        default:
            return {
                ...state
            }
    }
}

export default transactionsReducer