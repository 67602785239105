import * as Yup from 'yup';
import cookies from 'react-cookies';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { COOKIES, onlyDigits } from '../../../helpers';
import {
  askCodeForgetPassword,
  confirmCodeForgetPassword,
  resetPasswordForgetPassword,
} from '../../../api';

export const useForgetPassword = (setModal) => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [step, setStep] = useState(1);
  const [isCodeAsked, setIsCodeAsked] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const showError = (res) => {
    store.addNotification({
      title: `Error code: ${res ? res.code : 'NULL'}`,
      message: `${res ? res.message : t('Notify.notRespond')}`,
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }
  const phone = cookies.load(COOKIES.PHONE);

  const askCodeFormik = useFormik({
    initialValues: {
      phone: phone ? phone : '',
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .required(t('Form.enterPhone'))
        .min(11, t('Form.correctPhone'))
        .max(11)
    }),
    onSubmit: (async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        setPending(true);
        const { data } = await askCodeForgetPassword({
          phone: '+' + values.phone
        });

        cookies.save(COOKIES.NONCE, data.nonce, {path: '/', maxAge: '300'});
        cookies.save(COOKIES.PHONE, values.phone, {path: '/', maxAge: '300'});

        setStep(2);
        setIsCodeAsked(true);
        setPending(false);
      } catch (e) {
        const res = e.response ? e.response.data : null;
        showError(res);
        setPending(false);
      }
    })
  });

  const confirmCodeFormik = useFormik({
    initialValues: {
      confirmCode: '',
    },
    validationSchema: Yup.object({
      confirmCode: Yup.string()
        .required(t('Form.enterCodeConfirm'))
    }),
    onSubmit: (async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        setPending(true);

        const { data } = await confirmCodeForgetPassword({
          phone: '+' + askCodeFormik.values.phone,
          code: values.confirmCode,
          nonce: cookies.load(COOKIES.NONCE),
        });


        cookies.save(COOKIES.NONCE, data.nonce, {path: '/', maxAge: '300'});

        setStep(3);
        setPending(false);
      } catch (e) {
        const res = e.response ? e.response.data : null;
        showError(res);
        setPending(false);
      }
    })
  });

  const resetPasswordFormik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t('Form.enterPass'))
        .min(6, t('Form.minSymbol', { min: 6 })),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], t('Form.passNotMatch'))
    }),
    onSubmit: (async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        setPending(true);
        await resetPasswordForgetPassword({
          nonce: cookies.load(COOKIES.NONCE),
          password: values.password
        });

        cookies.remove(COOKIES.NONCE, {path: '/'});
        cookies.remove(COOKIES.PHONE, {path: '/'});

        setPending(false);
        setModal(true);
      } catch (e) {
        const res = e.response ? e.response.data : null;
        showError(res);
        setPending(false);
      }
    })
  });

  return {
    askCodeFormik,
    confirmCodeFormik,
    resetPasswordFormik,
    step,
    isCodeAsked,
    setIsCodeAsked,
    setStep,
    pending
  };
};
