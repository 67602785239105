import branchTypes from "./branchTypes";
import i18n from '../../i18n';

const initialState = {
    pending: false,
    error: '',
    info: null
}

const branchReducer = (state = initialState, action) => {

    switch (action.type) {
        case branchTypes.GET_BRANCH_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.GET_BRANCH_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.GET_BRANCH_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorProfile')
            }

        case branchTypes.CHANGE_BRANCH_WORKING_HOURS_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_WORKING_HOURS_SUCCESS:
            return {
                ...state,
                pending: false,
            }
        case branchTypes.CHANGE_BRANCH_WORKING_HOURS_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave')
            }

        case branchTypes.UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    configuration: {
                        ...state.info.configuration,
                        birthday_bonus: {
                            ...state.info.configuration.birthday_bonus,
                            ...action.payload.configuration.birthday_bonus,
                        }
                    }
                },
                pending: false,
            }
        case branchTypes.UPDATE_BRANCH_BONUS_FOR_BIRTHDAY_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave')
            }

        case branchTypes.POST_BRANCH_BONUS_PROMOCODE_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.POST_BRANCH_BONUS_PROMOCODE_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    configuration: {
                        ...state.info.configuration,
                        promocodes: action.payload || [],
                    }
                },
                pending: false,
            }
        case branchTypes.POST_BRANCH_BONUS_PROMOCODE_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave')
            }

        case branchTypes.DELETE_BRANCH_BONUS_PROMOCODE_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.DELETE_BRANCH_BONUS_PROMOCODE_SUCCESS:
            return {
                ...state,
                info: {
                    ...state.info,
                    configuration: {
                        ...state.info.configuration,
                        promocodes: state.info.configuration.promocodes?.filter((p) => p.id !== action.payload) || [],
                    }
                },
                pending: false,
            }
        case branchTypes.DELETE_BRANCH_BONUS_PROMOCODE_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave')
            }

        case branchTypes.CHANGE_REPORTS_CONTACTS_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_REPORTS_CONTACTS_SUCCESS:
            return {
                ...state,
                pending: false,
            }
        case branchTypes.CHANGE_REPORTS_CONTACTS_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave')
            }

        case branchTypes.CHANGE_BRANCH_INFO_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_INFO_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    ...action.payload,
                    configuration: {
                        ...state.info.configuration,
                        security: {
                            ...state.info.configuration.security,
                            ...action.payload,
                        }
                    }
                }
            }
        case branchTypes.CHANGE_BRANCH_INFO_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorData')
            }


        case branchTypes.CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    configuration: {
                        ...state.info.configuration,
                        whatsapp_provider: {
                            ...state.info.configuration.whatsapp_provider,
                            prohibitions: {
                                ...state.info.configuration.whatsapp_provider.prohibitions,
                                birthday_messages: action.payload.birthday_messages,
                            }
                        }
                    }
                }
            }
        case branchTypes.CHANGE_BRANCH_WHATSAPP_PROHIBITIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorData')
            }

        case branchTypes.ADD_BRANCH_BANNER_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.ADD_BRANCH_BANNER_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    banners: [...state.info.banners, action.payload]
                }
            }
        case branchTypes.ADD_BRANCH_BANNER_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorBanner')
            }

        case branchTypes.CHANGE_BRANDED_BONUS_CARD_BANNER_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANDED_BONUS_CARD_BANNER_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    configuration: {
                        ...state.info.configuration,
                        private_cashback: {
                            ...state.info.configuration.private_cashback,
                            banner: {
                                ...state.info.configuration.private_cashback.banner,
                                file: action.payload
                            }
                        }
                    }
                }
            }
        case branchTypes.CHANGE_BRANDED_BONUS_CARD_BANNER_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorBanner')
            }

        case branchTypes.DELETE_BRANCH_BANNER_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.DELETE_BRANCH_BANNER_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    banners: state.info.banners.filter(b => b.id !== action.payload)
                }
            }
        case branchTypes.DELETE_BRANCH_BANNER_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorBanner')
            }

        case branchTypes.CHANGE_BRANCH_ICON_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_ICON_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    icon: action.payload
                }
            }
        case branchTypes.CHANGE_BRANCH_ICON_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorAddIcon')
            }

        case branchTypes.CHANGE_BRANCH_SOCIALS_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_SOCIALS_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    social_networks: action.payload.social_networks,
                    site: action.payload.website
                }
            }
        case branchTypes.CHANGE_BRANCH_SOCIALS_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorAddIcon'),
                info: {
                    ...state.info,
                    social_networks: state.info.social_networks,
                    site: state.info.site,
                }
            }

        case branchTypes.CHANGE_BRANCH_BALANCE:
            return {
                ...state,
                info: {...state.info, balance: state.info.balance - action.payload}
            }


        case branchTypes.ACTIVATED_BRANCH_AWARD_CONFIG_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.ACTIVATED_BRANCH_AWARD_CONFIG_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.ACTIVATED_BRANCH_AWARD_CONFIG_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorActivateAward'),
            }

        case branchTypes.DEACTIVATED_BRANCH_AWARD_CONFIG_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.DEACTIVATED_BRANCH_AWARD_CONFIG_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    configuration: {
                        ...state.info.configuration,
                        award: {
                            ...state.info.configuration.award,
                            enabled: false
                        }
                    }
                },
            }
        case branchTypes.DEACTIVATED_BRANCH_AWARD_CONFIG_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorDeactivateAward'),
            }

        case branchTypes.CHANGE_CONTRACT_BASIC_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_CONTRACT_BASIC_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.CHANGE_CONTRACT_BASIC_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorDeactivateAward'),
            }

        case branchTypes.CHANGE_CONTRACT_INCREASED_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_CONTRACT_INCREASED_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.CHANGE_CONTRACT_INCREASED_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorDeactivateAward'),
            }

        case branchTypes.CHANGE_BRANCH_BONUS_DELAY_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_BONUS_DELAY_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.CHANGE_BRANCH_BONUS_DELAY_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.error'),
            }

        case branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.CHANGE_BRANCH_PRIVATE_CASHBACK_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave'),
            }


        case branchTypes.CHANGE_BRANCH_AUTOMAILING_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_AUTOMAILING_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.CHANGE_BRANCH_AUTOMAILING_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave'),
            }

        case branchTypes.CHANGE_BRANCH_WORKING_HOURS_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_CONFIGURATION_SUCCESS:
            return {
                ...state,
                pending: false,
                info: action.payload,
            }
        case branchTypes.CHANGE_BRANCH_CONFIGURATION_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave'),
            }

        case branchTypes.CHANGE_BRANCH_WHATSAPP_HEADER_PENDING:
            return {
                ...state,
                pending: true,
                error: '',
            }
        case branchTypes.CHANGE_BRANCH_WHATSAPP_HEADER_SUCCESS:
            return {
                ...state,
                pending: false,
                info: {
                    ...state.info,
                    configuration: {
                        ...state.info.configuration,
                        whatsapp_header: {
                            ...state.info.configuration.whatsapp_header,
                            custom_message: action.payload.custom_message,
                            text: action.payload.text,
                        },
                    },
                }
            }
        case branchTypes.CHANGE_BRANCH_WHATSAPP_HEADER_ERROR:
            return {
                ...state,
                pending: false,
                error: i18n.t('Errors.errorSave'),
            }

        default:
            return state
    }
}

export default branchReducer
