import React from 'react';
import { useHistory } from 'react-router-dom';
import IntroLayout from '../../components/IntroLayout';
import ButtonFilled from '../../components/FormElements/ButtonFilled';
import styles from './notFound.module.scss';

import notFound from './assets/images/notFound.png';

export default function NotFound() {
  const history = useHistory();
  return (
    <IntroLayout info={<img src={notFound} className={styles.img} />}>
      <h1 className={styles.title}>Упссс...</h1>
      <b className={styles.b}>Что-то пошло не так..</b>
      <p className={styles.p}>Страница, которую вы ищите не найдена либо еще <br/> не создана</p>
      <ButtonFilled onClick={() => history.goBack()}>Вернуться</ButtonFilled>
    </IntroLayout>
  )
}