import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { store } from 'react-notifications-component';
import { phoneMask, onlyDigits } from 'helpers.js';
import branchActions from '../../../store/branchStore/branchActions';

export const useCommonInfo = (t) => {
  const dispatch = useDispatch();
  const [phones, setPhones] = useState(['', '', '']);
  const [phonesError, setPhonesError] = useState('');
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0
  });


  const branch = useSelector(s => s.branchReducer);

  useEffect(() => {
    if (branch.info && !branch.pending) {
      // Не изменять phoneMask!
      const phones = [...branch.info.phones];

      setPhones(phones);

      setLocation({
        latitude: branch.info.location.lat,
        longitude: branch.info.location.long,
      });

      formik.setFieldValue('name', branch.info.name);
      formik.setFieldValue('description', branch.info.description);
      formik.setFieldValue('address', branch.info.address);

    }
  }, [branch]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      address: '',
      phones: ['', '', '']
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('Form.enterBranchTitle'))
        .min(3, t('Form.minSymbol', {min: 3})),
      description: Yup.string(),
      address: Yup.string()
        .required(t('Form.enterAddress'))
        .min(3, t('Form.minSymbol', {min: 3})),
    }),
    onSubmit: (async (values, { setSubmitting }) => {
      if (!validatePhones()) return
      if (phonesError) setPhonesError('');

      const phonesForParams = phones.map((p) => p.length ? `+${onlyDigits(p)}` : '');

      try {
        let data = {
          ...branch.info,
          ...values,
          phones: phonesForParams,
        }

        if (!!location.longitude) {
          data = {
            ...data,
            latitude: Number(location.latitude),
            longitude: Number(location.longitude),
          }
        }

        dispatch(branchActions.changeInfo(branch.info.id, data));

        store.addNotification({
          title: t('success'),
          message: t('changesSaved'),
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } catch (e) {
        const res = e.response ? e.response.data : null;

        store.addNotification({
          title: `Error code: ${res ? res.code : 'NULL'}`,
          message: `${res ? res.message : t('Notify.notRespond')}`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      }
    })
  });

  const onChangePhone = (i) => (value) => {
    if (phonesError.length) setPhonesError('');

    const phonesArray = [...phones];
    phonesArray[i] = value;
    setPhones(phonesArray);
  }

  const validatePhones = () => {
    const hasNoError = phones.some((p) => p.length);

    if (!hasNoError) setPhonesError(t('Form.requiredOnePhoneField'));

    return hasNoError;
  }

  return {
    formik,
    phones,
    onChangePhone,
    phonesError,
    pending: branch.pending,
    ...location,
    setLocation,
  }
}