import React from 'react'
import { useTranslation } from "react-i18next";
import styles from './ChartBar.module.css'
import ChartBarComponent from "../../../../../../components/Analytics/ChartBarComponent/ChartBarComponent";
import CustomDatePicker from "../../../../../../components/CustomDatePicker/CustomDatePicker";

export default function ChartBar({ chart_data, type, changePeriod, dinamicSalesDateFrom, dinamicSalesDateTo, changeDate,
 average_check,  cashback_amount , payment_total , transactions
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
          <h2>{t('NSGeneral.dynamics')}</h2>
          <div className={styles.periods}>
              {/*<p  style={type === 1 ? {color: '#4EB78B'} : {}} onClick={()=>changePeriod(1)}>{t('day')}</p>*/}
              {/*<p style={type === 2 ? {margin: '0 12px 0 24px', color: '#4EB78B'} : {margin: '0 12px 0 24px'}} onClick={()=>changePeriod(2)}>{t('week')}</p>*/}
              {/*<p style={type === 3 ? {margin: '0 24px 0 12px', color: '#4EB78B'} : {margin: '0 24px 0 12px'}} onClick={()=>changePeriod(3)}>{t('month')}</p>*/}
              {/*<p style={type === 4 ? {color: '#4EB78B'} : {}} onClick={()=>changePeriod(4)}>{t('year')}</p>*/}
              <div style={{marginBottom: "1rem"}}>
                  <CustomDatePicker
                    trans
                    type={type}
                    tStartDate={dinamicSalesDateFrom}
                    tEndDate={dinamicSalesDateTo}
                    tSetStartDate={(v) => changeDate(1, v)}
                    tSetEndDate={(v) => changeDate(2, v)}
                  />
              </div>
          </div>
        {average_check && cashback_amount && payment_total && transactions ? <ChartBarComponent width={46} data={chart_data}/> : null}
      </div>
  )
}