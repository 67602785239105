import i18n from '../i18n';
import { intersection } from 'lodash';
import { Roles } from "../config";

export const computeSkipElements = ({page_index,limit}) => {
    if (page_index == 1) return 0;
    return (page_index - 1) * limit;
}

export function isArrayWithLength(arr) {
    return (Array.isArray(arr) && arr.length)
}

export function getAllowedRoutes(routes, roles = [Roles.ADMINISTRATOR]) {
    return routes.filter(({ permission }) => {
        if(!permission) return true;
        else if(!isArrayWithLength(permission)) return true;
        else return intersection(permission, roles).length;
    });
}

const getDateForLineChart = (data, i, label) => {
    let dateString = data[i][label];
    // let newData = null;
    // if (dateString) {
    //     newData = dateString.replace(/(\d+[-])(\d+[-])/, '$2$1');
    // }
    let date = new Date(dateString);

    return date;
}

function getValuesFromHours(data, title, value) {
    let chart = [
        ['x', title, {role: "tooltip", type: "string", p: {html: true}}],
        ['0-1', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['1-2', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['3-4', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['5-6', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['7-8', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['9-10', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['11-12', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['13-14', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['15-16', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['17-18', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['19-20', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['21-22', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
        ['23-24', 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
    ]

    for (let i in data) {
        if (data[i][value]) {
            let keys = Object.keys(data[i][value]);
            for (let j in keys) {
                if (keys[j] >= 0 && keys[j] <= 1) {
                    chart[1][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 1 && keys[j] <= 2) {
                    chart[2][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 3 && keys[j] <= 4) {
                    chart[3][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 5 && keys[j] <= 6) {
                    chart[4][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 7 && keys[j] <= 8) {
                    chart[5][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 9 && keys[j] <= 10) {
                    chart[6][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 11 && keys[j] <= 12) {
                    chart[7][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 13 && keys[j] <= 14) {
                    chart[8][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 15 && keys[j] <= 16) {
                    chart[9][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 17 && keys[j] <= 18) {
                    chart[10][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 19 && keys[j] <= 20) {
                    chart[11][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 21 && keys[j] <= 22) {
                    chart[12][1] += data[i][value][keys[j]]
                } else if (keys[j] >= 23 && keys[j] <= 24) {
                    chart[13][1] += data[i][value][keys[j]]
                }
            }
        }
    }

    return chart
}

export const prepareForLineChart = (data, value, label, title, type) => {

    let chart = [];

    switch (type) {
        case 1:
            chart = getValuesFromHours(data, value, title);
            for (let i in chart) {
                if (i > 1) {
                    chart[i][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[i][1]}</p></div>`
                }
            }
            break;
        case 2:
            chart = [
                ['x', i18n.t('clients'), {role: "tooltip", type: "string", p: {html: true}}],
                [i18n.t('mn'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('tu'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('wd'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('th'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('fr'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('sn'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('st'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
            ]

            for (let i in data) {
                const date = getDateForLineChart(data, i, label);
                let week_day = new Date(date).getDay() + 1
                chart[week_day][1] = data[i][value]
            }

            for (let i in data) {
                const date = getDateForLineChart(data, i, label);
                let week_day = new Date(date).getDay() + 1
                if (week_day == 0) {
                    chart[7][1] = data[i][value]
                } else if (week_day == 1) {
                    chart[1][1] = data[i][value]
                } else if (week_day == 2) {
                    chart[2][1] = data[i][value]
                } else if (week_day == 3) {
                    chart[3][1] = data[i][value]
                } else if (week_day == 4) {
                    chart[4][1] = data[i][value]
                } else if (week_day == 5) {
                    chart[5][1] = data[i][value]
                } else if (week_day == 6) {
                    chart[6][1] = data[i][value]
                }
            }

            for (let i in data) {
                const date = getDateForLineChart(data, i, label);
                let week_day = new Date(date).getDay() + 1
                if (week_day == 0) {
                    chart[7][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[7][1]}</p></div>`
                } else if (week_day == 1) {
                    chart[1][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[1][1]}</p></div>`
                } else if (week_day == 2) {
                    chart[2][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[2][1]}</p></div>`
                } else if (week_day == 3) {
                    chart[3][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[3][1]}</p></div>`
                } else if (week_day == 4) {
                    chart[4][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[4][1]}</p></div>`
                } else if (week_day == 5) {
                    chart[5][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[5][1]}</p></div>`
                } else if (week_day == 6) {
                    chart[6][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[6][1]}</p></div>`
                }
            }

            break;
        case 3:
            let daysArr = [];

            for (let i in data) {
                const date = getDateForLineChart(data, i, label);
                daysArr.push(new Date(date).getDate())
            }

            let maxDay = Math.max.apply(null, daysArr.length ? daysArr : [new Date().getDate()])
            chart.push(['x', title, {role: "tooltip", type: "string", p: {html: true}}])

            for (let i = 1; i <= maxDay; i++) {
                chart.push([i, 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`])
            }

            for (let i in data) {
                const date = getDateForLineChart(data, i, label);
                let day = new Date(date).getDate()
                chart[day][1] += data[i][value]
            }

            for (let i in data) {
                const date = getDateForLineChart(data, i, label);
                let day = new Date(date).getDate()
                chart[day][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[day][1]}</p></div>`
            }

            break;
        case 4:
            chart = [
                ['x', title, {role: "tooltip", type: "string", p: {html: true}}],
                [i18n.t('jan'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('feb'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('march'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('apr'), 0,` "<div class='lineChartContainer'><span>${title}</span><p>0</p></div>"`],
                [i18n.t('may'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('june'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('july'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('august'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('sep'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('oct'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('nov'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
                [i18n.t('dec'), 0, `<div class='lineChartContainer'><span>${title}</span><p>0</p></div>`],
            ]
            for (let i in data) {
                const date = getDateForLineChart(data, i, label);

                let month = new Date(date).getMonth() + 1
                chart[month][1] += data[i][value]
            }

            for (let i in data) {
                const date = getDateForLineChart(data, i, label);
                let month = new Date(date).getMonth() + 1
                chart[month][2] = `<div class='lineChartContainer'><span>${title}</span><p>${chart[month][1]}</p></div>`
            }

            break;
        default:
            return []
    }

    return chart;
}