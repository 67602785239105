import React from 'react';
import { useTranslation } from "react-i18next";
import { PageLayout } from '../Layout';
import TextField from '../../../../components/FormElements/TextField';
import Checkbox from '../../../../components/FormElements/Checkbox';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import { usePrizes } from '../../hooks/usePrizes';

import styles from './prizes.module.scss';
import { set } from "2gis-maps/gulp/util/projectList";

export default function Prizes() {
  const { t } = useTranslation();
  const {
    formik,
    onDeactivateAward,
    pending,
    isAwardMessagesPushing,
    setIsAwardMessagesPushing,
    info,
    changeWhatsappPush,
  } = usePrizes(t);

  return (
    <PageLayout
      title={t('awards')}
      text={t('NSBonus.information')}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.container}>
          <TextField
            label={t('NSAwards.name')}
            placeholder={t('NSPoll.text')}
            required

            value={formik.values.title}
            error={formik.touched.title && formik.errors.title}
            onChange={(e) => formik.setFieldValue('title', e.target.value)}
          />
          <TextField
            label={t('description')}
            placeholder={t('Form.enterDesc')}

            value={formik.values.description}
            error={formik.touched.description && formik.errors.description}
            onChange={(e) => formik.setFieldValue('description', e.target.value)}
          />
          <div className={styles.block} style={{display: 'flex', alignItems: 'flex-end'}}>
            <TextField
              label={t('NSAwards.number')}
              placeholder="8"
              width="105px"
              required

              value={formik.values.stamp_count}
              error={formik.touched.stamp_count && formik.errors.stamp_count}
              onChange={(e) => formik.setFieldValue('stamp_count', e.target.value)}
            />
            {
              formik.values.stamp_count > 0 && (
                <div style={{
                  transform: formik.touched.stamp_count && formik.errors.stamp_count ? 'translate(-30px, -38px)'
                    : 'translate(-30px, -12px)',
                  minWidth: '200px',
                  textAlign: 'right',
                  fontFamily: 'graphic',
                  fontSize: '16px'
                }}>
                  {t('NSAwards.position', {number: +formik.values.stamp_count + 1})}
                </div>
              )
            }
          </div>
          <div className={styles.block}>
            <div className={styles['block__title']}>{t('period')} <span>*</span></div>
            <Checkbox
              label={t('limit1')}
              name="deadline"
              checked={formik.values.deadline}
              onChange={formik.handleChange}
            />
            {
              !formik.values.deadline && (
                <div style={{width: '234px', marginTop: '19px'}}>
                  <CustomDatePicker
                    trans={true}
                    tStartDate={formik.values.from}
                    tEndDate={formik.values.to}
                    tSetStartDate={(value) => formik.setFieldValue('from', value)}
                    tSetEndDate={(value) => formik.setFieldValue('to', value)}
                  />
                </div>
              )
            }
          </div>
        </div>
        <div style={{height: 20}}/>
        {!formik.values.enabled && (
          <ButtonFilled type="submit" loading={pending} disabled={pending}>
            {t('NSAwards.turn1')}
          </ButtonFilled>
        )}
        {formik.values.enabled && (
          <ButtonFilled onClick={onDeactivateAward} loading={pending} disabled={pending}>
            {t('NSAwards.turn')}
          </ButtonFilled>
        )}
      </form>
      <div style={{height: 40}}></div>
      <Checkbox
        label={t("notPushWhatsapp") || ""}
        checked={isAwardMessagesPushing}
        disabled={pending || !info}
        onChange={(e) => setIsAwardMessagesPushing(e.target.checked)}
      />
      <div style={{height: 20}}></div>
      <ButtonFilled onClick={() => changeWhatsappPush(isAwardMessagesPushing)} loading={pending} disabled={pending}>
        {t('save')}
      </ButtonFilled>
    </PageLayout>
  )
}