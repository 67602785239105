import notificationsTypes from "./notificationsTypes";
import {PENDING, SUCCESS, ERROR} from "../globalStatuses/globalStatuses";
import {act} from "@testing-library/react";

const initialState = {
    data: {},
    page: 1,
    message_status: '',
    status: ''
}


const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {

        case notificationsTypes.GET_NOTIFICATIONS_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case notificationsTypes.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                status: SUCCESS
            }

        case notificationsTypes.GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case notificationsTypes.CHANGE_NOTIFICATIONS_PAGE:
            return {
                ...state,
                page: action.payload
            }

        case notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_PENDING:
            return {
                ...state,
                message_status: PENDING
            }

        case notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_SUCCESS:
            return {
                ...state,
                message_status: SUCCESS,
                data: {...state.data, messages: [action.payload, ...state.data.messages]}
            }

        case notificationsTypes.SEND_MESSAGE_IN_NOTIFICATION_ERROR:
            return {
                ...state,
                message_status: ERROR
            }


        default:
            return {
                ...state
            }
    }
}

export default notificationsReducer