import React from 'react'
import { Route, Switch } from "react-router-dom";
import Common from "./components/Common";
import Form from "./components/Form";
import Sale from "./components/Sale";
import Comparison from "./components/Comparison";
import styles from './Ads.module.css'

export default function Ads() {
    return (
        <div className={styles.container}>
            <div className={styles.analytics}>
                <Switch>
                    <Route exact path={'/ads'} render={() => <Common />}/>
                    <Route exact path={'/ads/form'} render={() => <Form />}/>
                    <Route exact path={'/ads/sale/:id'} render={() => <Sale />}/>
                    <Route exact path={'/ads/comparison'} render={() => <Comparison />}/>
                </Switch>
            </div>
        </div>
    )
}