import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { PageLayout } from '../Layout';
import TextField from 'components/FormElements/TextField';
import ButtonFilled from '../../../../components/FormElements/ButtonFilled';
import { useCommonInfo } from '../../hooks/useCommonInfo';
import Modal from '../../../../portals/Modal';
import Map from '../Map';
import { PhoneInputField } from "../../../../components/PhoneInput";
import styles from './commonInfo.module.scss';

const locationSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.27243 10.3599C5.366 6.42516 8.36118 3.25883 12.0832 3.15991C15.8053 3.25883 18.8005 6.42516 18.8941 10.3599C18.8262 11.5245 18.5175 12.6592 17.9897 13.6839L17.9368 13.7799C17.8989 13.8519 16.9605 15.4519 15.867 17.0519C13.7822 20.1519 12.9005 20.7599 12.0832 20.7599C11.5611 20.7599 10.3313 20.7599 6.21459 13.7839L6.16162 13.6839C5.63909 12.6579 5.33558 11.5233 5.27243 10.3599ZM12.0832 19.5599C13.2184 19.5599 17.0022 13.1599 17.0022 13.1599H16.9908C17.4347 12.296 17.6967 11.3408 17.7589 10.3599C17.7589 7.0462 15.2178 4.35991 12.0832 4.35991C8.94865 4.35991 6.40757 7.0462 6.40757 10.3599C6.46631 11.3399 6.72444 12.295 7.16432 13.1599C7.16432 13.1599 10.9481 19.5599 12.0832 19.5599ZM9.05621 9.95991C9.05621 8.1926 10.4115 6.75991 12.0832 6.75991C13.755 6.75991 15.1103 8.1926 15.1103 9.95991C15.1103 11.7272 13.755 13.1599 12.0832 13.1599C10.4115 13.1599 9.05621 11.7272 9.05621 9.95991ZM10.1913 9.95991C10.1913 11.0645 11.0384 11.9599 12.0832 11.9599C13.1281 11.9599 13.9751 11.0645 13.9751 9.95991C13.9751 8.85534 13.1281 7.95991 12.0832 7.95991C11.0384 7.95991 10.1913 8.85534 10.1913 9.95991Z" fill="url(#paint0_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="12.0832" y1="3.15991" x2="12.0832" y2="20.7599" gradientUnits="userSpaceOnUse">
        <stop stopColor="#73D389"/>
        <stop offset="1" stopColor="#4EB78B"/>
      </linearGradient>
    </defs>
  </svg>
);

export default function CommonInfo() {
  const { t } = useTranslation();
  const {
    formik,
    phones,
    onChangePhone,
    phonesError,
    pending,
    latitude,
    longitude,
    setLocation,
  } = useCommonInfo(t);

  const [modal, setModal] = useState(false);
  const mapRef = useRef(null);

  const phoneOne = phones[0] ? phones[0] : '';
  const phoneTwo = phones[1] ? phones[1] : '';
  const phoneThree = phones[2] ? phones[2] : '';

  const onChooseLocation = (location) => {
    setLocation({
      latitude: location.lat,
      longitude: location.lng
    });
    setModal(false);
  }

  const onCloseLocationModal = () => {
    setModal(false);
  }

  const onOpenMap = () => {
    setModal(true);
  }

  const chooseOnMap = () => {
    return (
      <div
        style={{ color: '#73D389', display: 'flex', alignItems: 'center', cursor: 'pointer', width: '154px' }}
        onClick={onOpenMap}
      >
        {locationSvg}
        <span style={{ marginLeft: '3px' }}>{t('chooseMap')}</span>
      </div>
    )
  }

  return (
    <PageLayout
      title={t('NSSidebar.info')}
      text={t('NSBonus.information')}
    >
      <TextField
        label={t('NSInformation.company1')}
        placeholder={t('NSInformation.branch')}
        required

        value={formik.values.name}
        error={formik.touched.name && formik.errors.name}
        onChange={(e) => formik.setFieldValue('name', e.target.value)}
      />
      <TextField
        label={t('description')}
        placeholder={t('NSInformation.branchDesc')}

        value={formik.values.description}
        error={formik.touched.description && formik.errors.description}
        onChange={(e) => formik.setFieldValue('description', e.target.value)}
      />
      <TextField
        label={t('adress')}
        placeholder={t('adress')}
        required
        postfix={chooseOnMap}

        value={formik.values.address}
        error={formik.touched.address && formik.errors.address}
        onChange={(e) => formik.setFieldValue('address', e.target.value)}
      />
      <div style={{ height: 16 }}></div>
      <PhoneInputField
        label={t('contacts')}
        error={phonesError}
        onChange={onChangePhone(0)}
        value={phoneOne}
        required
      />
      <div style={{ height: 16 }}></div>
      <PhoneInputField
        error={phonesError}
        onChange={onChangePhone(1)}
        value={phoneTwo}
        required
      />
      <div style={{ height: 16 }}></div>
      <PhoneInputField
        error={phonesError}
        onChange={onChangePhone(2)}
        value={phoneThree}
        required
      />

      <div style={{ height: '30px' }}></div>
      <ButtonFilled
        onClick={formik.handleSubmit}
        loading={pending}
      >
        {t('save')}
      </ButtonFilled>

      <Modal
        open={modal} size="large"
        onClickClose={onCloseLocationModal}>
        <Map
          lat={latitude}
          lng={longitude}
          onChooseLocation={onChooseLocation}
        />
      </Modal>
    </PageLayout>
  )
}