import React from 'react';
import { useTranslation } from "react-i18next";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import TextField from "../../../../components/FormElements/TextField";
import PageHeader from "../../../../components/PageHeader";
import QuestionTooltip from "../../../../components/QuestionTooltip";
import Modal from "../../../../portals/Modal";
import { useBonusPromocode } from "../../hooks/useBonusPromocode";
import { CustomSingleDatePicker } from "../../../../components/CustomDatePicker/CustomDatePicker";
import { format } from "date-fns"
import styles from './profileBonusPromocode.module.scss';

export default function ProfileBonusPromocode() {
    const { t } = useTranslation();
    const {
        formik,
        pending,
        errorModal,
        setErrorModal,
        error,
        promocodes,
        deleting,
        editFormik,
        deletePromocode,
    } = useBonusPromocode(t);

    return (
        <>
            <PageHeader title={t('promocode')}>
                <div style={{ marginLeft: '50px' }}>
                    <QuestionTooltip text={t('NSPromocode.tooltip')} />
                </div>
            </PageHeader>
            <div className={styles.container}>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.block}>
                        <TextField
                          label={t('NSPromocode.comeUpWithAName')}
                          placeholder={t('Form.enterText')}
                          value={formik.values.title}
                          error={formik.touched.title && formik.errors.title}
                          onChange={(e) => formik.setFieldValue('title', e.target.value)}
                          required
                        />
                    </div>

                    <div className={styles.block}>
                        <p style={{marginTop: 0}}>
                            {t('description')} <i style={{color: 'tomato'}}>*</i>
                        </p>
                        <textarea
                          placeholder={t('Form.enterText')}
                          value={formik.values.description}
                          onChange={(e) => formik.setFieldValue("description", e.target.value)}
                          required
                        />
                    </div>

                    <div className={styles.block}>
                        <TextField
                          label={t('NSPromocode.promotionalCode')}
                          error={formik.touched.code && formik.errors.code}
                          placeholder={t('Form.enterText')}
                          value={formik.values.code}
                          onChange={(e) => formik.setFieldValue('code', e.target.value)}
                          required
                        />
                    </div>

                    <div className={styles.block}>
                        <TextField
                          type="number"
                          label={t('NSPromocode.sumOfPromotionalCode')}
                          placeholder={t('Form.enterText')}
                          error={formik.touched.amount && formik.errors.amount}
                          value={formik.values.amount}
                          onChange={(e) => formik.setFieldValue('amount', +e.target.value || '')}
                          required
                        />
                    </div>

                    <div className={styles.block}>
                        <p>{t("choosePeriod")}</p>
                        <CustomSingleDatePicker
                          tSetDate={(date) => formik.setFieldValue("expired_at", date)}
                          tDate={formik.values.expired_at}
                        />
                    </div>

                    <div className={styles.footer}>
                        <ButtonFilled
                          disabled={error}
                          type="submit"
                          loading={pending}>
                            {t('save')}
                        </ButtonFilled>
                    </div>
                </form>

                <h2 style={{
                    fontWeight: 500,
                    fontSize: 18,
                    fontFamily: "graphic",
                    marginTop: "2rem"
                }}>{t("NSPromocode.createdPromocodes")}</h2>
                <table className={`table ${deleting || pending ? "loading" : ""}`}>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>{t('NSPromocode.nameOfPromocode')}</th>
                        <th>{t('NSPromocode.promotionalCode')}</th>
                        <th>{t('NSPromocode.sumOfPromotionalCode')}</th>
                        <th>{t("deadline")}</th>
                        <th>{t("NSPromocode.link")}</th>
                        <th>{t("action")}</th>
                    </tr>
                    </thead>
                    <tbody>
                        {promocodes.map((promocode, index) => (
                          <tr key={promocode.id}>
                              <td>{index + 1}</td>
                              <td style={{ maxWidth: 150 }}>{promocode.title}</td>
                              <td>{promocode.code}</td>
                              <td>{promocode.amount}</td>
                              <td>{promocode.expired_at ? format(promocode.expired_at, "DD.MM.YY", new Date()) : null}</td>
                              <td>{promocode.link}</td>
                              <td>
                                  <div style={{ minWidth: "120px", display: "flex" }}>
                                      <button className={styles.open}>
                                          <svg width="18" height="18" viewBox="0 0 18 18"
                                               fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M4.61719 3.42188C4.30017 3.42188 3.99614 3.54781 3.77197 3.77197C3.54781 3.99614 3.42188 4.30017 3.42188 4.61719V13.3828C3.42188 13.6998 3.54781 14.0039 3.77197 14.228C3.99614 14.4522 4.30017 14.5781 4.61719 14.5781H13.3828C13.6998 14.5781 14.0039 14.4522 14.228 14.228C14.4522 14.0039 14.5781 13.6998 14.5781 13.3828V10.7266C14.5781 10.5504 14.6481 10.3815 14.7726 10.257C14.8972 10.1325 15.0661 10.0625 15.2422 10.0625C15.4183 10.0625 15.5872 10.1325 15.7118 10.257C15.8363 10.3815 15.9062 10.5504 15.9062 10.7266V13.3828C15.9062 14.0521 15.6404 14.6939 15.1672 15.1672C14.6939 15.6404 14.0521 15.9062 13.3828 15.9062H4.61719C3.94793 15.9062 3.30608 15.6404 2.83285 15.1672C2.35961 14.6939 2.09375 14.0521 2.09375 13.3828V4.61719C2.09375 3.94793 2.35961 3.30608 2.83285 2.83285C3.30608 2.35961 3.94793 2.09375 4.61719 2.09375H7.27344C7.44956 2.09375 7.61846 2.16371 7.743 2.28825C7.86754 2.41279 7.9375 2.58169 7.9375 2.75781C7.9375 2.93393 7.86754 3.10284 7.743 3.22738C7.61846 3.35191 7.44956 3.42188 7.27344 3.42188H4.61719ZM10.0625 2.75781C10.0625 2.39125 10.36 2.09375 10.7266 2.09375H15.2422C15.6088 2.09375 15.9062 2.39125 15.9062 2.75781V7.27344C15.9062 7.44956 15.8363 7.61846 15.7118 7.743C15.5872 7.86754 15.4183 7.9375 15.2422 7.9375C15.0661 7.9375 14.8972 7.86754 14.7726 7.743C14.6481 7.61846 14.5781 7.44956 14.5781 7.27344V4.36112L11.1962 7.74306C11.1345 7.80473 11.0613 7.85366 10.9807 7.88703C10.9001 7.92041 10.8138 7.93759 10.7266 7.93759C10.6393 7.93759 10.553 7.92041 10.4724 7.88703C10.3918 7.85366 10.3186 7.80473 10.2569 7.74306C10.1953 7.68139 10.1463 7.60818 10.113 7.5276C10.0796 7.44702 10.0624 7.36065 10.0624 7.27344C10.0624 7.18622 10.0796 7.09986 10.113 7.01928C10.1463 6.9387 10.1953 6.86548 10.2569 6.80381L13.6389 3.42188H10.7266C10.36 3.42188 10.0625 3.12437 10.0625 2.75781Z"
                                                fill="#7A7A7A"/>
                                          </svg>
                                      </button>

                                      <button className={styles.edit} onClick={() => {
                                          editFormik.setValues({ ...promocode, expired_at: promocode.expired_at ? new Date(promocode.expired_at) : null });
                                      }}>
                                          <svg width="14" height="15" viewBox="0 0 14 15"
                                               fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M5.14772 12.5289C5.02623 12.6504 4.82929 12.6504 4.7078 12.5289L1.97165 9.79341C1.85012 9.6719 1.85012 9.47488 1.97165 9.35338L9.21376 2.11296C9.33525 1.9915 9.5322 1.9915 9.65369 2.11296L12.3898 4.84847C12.5114 4.96997 12.5114 5.167 12.3898 5.2885L5.14772 12.5289ZM1.29559 10.6104C1.36868 10.391 1.64716 10.3252 1.81072 10.4887L4.0121 12.6896C4.1757 12.8531 4.1099 13.1317 3.89041 13.2047L0.589936 14.3036C0.346772 14.3845 0.115501 14.1532 0.196493 13.9101L1.29559 10.6104ZM13.5868 4.096L13.2559 4.42687C13.1344 4.54833 12.9374 4.54833 12.8159 4.42687L10.0756 1.68716C9.95406 1.56566 9.95406 1.36863 10.0756 1.24713L10.4064 0.916363C10.9614 0.361212 11.8613 0.361212 12.4162 0.916363L13.5868 2.08667C14.1377 2.64333 14.1377 3.53951 13.5868 4.096Z"
                                                fill="#7A7A7A"/>
                                          </svg>
                                      </button>

                                      <button className={styles.delete} onClick={() => deletePromocode(promocode.id)}>
                                          <svg width="13" height="16" viewBox="0 0 13 16"
                                               fill="none"
                                               xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M8.4375 2.375H11.25C11.8125 2.375 12.1875 2.75 12.1875 3.3125V4.25H0V3.3125C0 2.75 0.46875 2.375 0.9375 2.375H3.75C3.9375 1.34375 4.96875 0.5 6.09375 0.5C7.21875 0.5 8.25 1.34375 8.4375 2.375ZM4.6875 2.375H7.5C7.3125 1.8125 6.65625 1.4375 6.09375 1.4375C5.53125 1.4375 4.875 1.8125 4.6875 2.375ZM0.9375 5.1875H11.25L10.4062 14.6562C10.4062 15.125 9.9375 15.5 9.46875 15.5H2.71875C2.25 15.5 1.875 15.125 1.78125 14.6562L0.9375 5.1875Z"
                                                fill="#7A7A7A"/>
                                          </svg>
                                      </button>
                                  </div>
                              </td>
                          </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            <Modal open={!!editFormik.values.id} onClickClose={() => {
                editFormik.resetForm();
            }}>
                <form onSubmit={editFormik.handleSubmit}>
                    <div className={styles.block}>
                        <TextField
                          label={t('NSPromocode.comeUpWithAName')}
                          placeholder={t('Form.enterText')}
                          value={editFormik.values.title}
                          error={editFormik.touched.title && editFormik.errors.title}
                          onChange={(e) => editFormik.setFieldValue('title', e.target.value)}
                        />
                    </div>

                    <div className={styles.block}>
                        <p style={{marginTop: 0}}>
                            {t('description')}
                        </p>
                        <textarea
                          placeholder={t('Form.enterText')}
                          value={editFormik.values.description}
                          onChange={(e) => editFormik.setFieldValue("description", e.target.value)}
                          style={{
                              fontFamily: "graphic, serif",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "-0.02em",
                              color: "black",
                              margin: 0,
                              width: "100%",
                              maxWidth: "535px",
                              height: "100px",
                              padding: "11px 20px",
                              border: "1px solid #E7E7E6",
                              borderRadius: "6px",
                              outline: "none",
                              resize: "none",
                          }}
                          required
                        />
                    </div>

                    <div className={styles.block}>
                        <TextField
                          label={t('NSPromocode.promotionalCode')}
                          error={editFormik.touched.code && editFormik.errors.code}
                          placeholder={t('Form.enterText')}
                          value={editFormik.values.code}
                          onChange={(e) => editFormik.setFieldValue('code', e.target.value)}
                        />
                    </div>

                    <div className={styles.block}>
                        <TextField
                          type="number"
                          label={t('NSPromocode.sumOfPromotionalCode')}
                          placeholder={t('Form.enterText')}
                          error={editFormik.touched.amount && editFormik.errors.amount}
                          value={editFormik.values.amount}
                          onChange={(e) => editFormik.setFieldValue('amount', +e.target.value || '')}
                        />
                    </div>

                    <div className={styles.block}>
                        <p>{t("choosePeriod")}</p>
                        <CustomSingleDatePicker
                          tSetDate={(date) => editFormik.setFieldValue("expired_at", date)}
                          tDate={editFormik.values.expired_at}
                        />
                    </div>

                    <div className={styles.footer} style={{ justifyContent: "center" }}>
                        <ButtonFilled
                          disabled={error}
                          type="submit"
                          loading={pending}>
                            {t('edit')}
                        </ButtonFilled>
                    </div>
                </form>
            </Modal>

            <Modal
              open={errorModal} size="small"
              onClickClose={() => setErrorModal(false)}>
                <div className={styles.modal}>
                    <p>
                        {t('optionForbidden')} <br/>
                        {t('touchModerator')}
                    </p>
                    <div>
                        <ButtonFilled
                          loading={pending}
                          onClick={() => setErrorModal(false)}
                        >{t('close')}</ButtonFilled>
                    </div>
                </div>
            </Modal>
        </>
    )
}
