import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import NotificationsList from "../NotificationsList/NotificationsList";
import { useHistory } from "react-router-dom";
import { PENDING } from "../../../../store/globalStatuses/globalStatuses";
import styles from './Notifications.module.css';

import backButton from '../../assets/backButton.png';
import uploadFiles from '../../assets/uploadFiles.png';
import deleteFileIcon from '../../assets/deleteFile.png';
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import { store } from "react-notifications-component";

export default function Notifications({
  changePageAction,
  count,
  messages,
  page,
  status,
  messageStatus,
  sendMessageWithImage,
  sendMessage
}) {
    const { t } = useTranslation();
    const [photoName, setPhotoName] = useState('')
    const [photo, setPhoto] = useState(new FormData())
    const [notification, setNotification] = useState('')
    const [error, setError] = useState('')
    const ref = useRef()
    const history = useHistory()


    const onSelectImageHandler = (files) => {
        const file = files[0];
        if (file && file.size <= 800000) {
            setError('')
            setPhotoName(file.name)
            const formData = new FormData();
            formData.append('file', file)
            setPhoto(formData)


        } else if (file && file.size > 800000) {
            setError(t('NSNotifications.fileLimit', {limit: '800kb'}))
        }

    }

    const deleteFile = () => {
        setPhotoName('')
        setPhoto(new FormData())
    }

    const onSendMessage = () => {
        if (notification.length) {
            setError('')
            if (photoName.length) {
                const promise = new Promise(resolve => {
                    sendMessageWithImage(photo, notification, resolve)
                })
                promise.then(() => {
                    setNotification('')
                    setPhoto(new FormData())
                    setPhotoName('')
                    store.addNotification({
                      title: t('success'),
                      message: t('mailingSuccess'),
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true
                      }
                    });
                })
            } else {
                const promise = new Promise(resolve => {
                    sendMessage(notification, resolve)
                })
                promise.then(() => {
                    setNotification('')
                    setPhoto(new FormData())
                    setPhotoName('')
                    store.addNotification({
                      title: t('success'),
                      message: t('mailingSuccess'),
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true
                      }
                    });
                })
            }
        } else {
            setError(t('NSNotifications.notAllowEmptyFile'))
        }

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setNotification('')
            onSendMessage()
        }
    }


    return (
        <div className={styles.container}>
            <div className={styles.backButton}>
                <img src={backButton} onClick={() => history.push('/clients')}/>
            </div>

            <h2>{t('NSPurchase.notifications')}</h2>

            <textarea
                placeholder={t('NSBonuses.type')}
                onChange={(e) => {
                    if (e.target.value.length <= 125) setNotification(e.target.value)
                }}
                value={notification}
                onKeyDown={handleKeyDown}
            />
            <p style={{ color: "#77797B", fontSize: "12px", marginTop: "-20px" }}>{t("Form.availableSymbol", { count: 125 - notification.length })}</p>
            {photoName.length ?
                <div className={styles.photosName}>
                    <span>{photoName}</span>
                    <img src={deleteFileIcon} onClick={deleteFile}/>
                </div> : null}

            {error.length ?
                <div className={styles.photosName}>
                    <span style={{color: 'red', border: 'none'}}>{error}</span>
                </div> : null
            }

            <div className={styles.buttons}>
                <ButtonFilled
                    onClick={onSendMessage}
                    disabled={status === PENDING}
                    loading={messageStatus === PENDING }
                >
                    {t('send')}
                </ButtonFilled>
                <div className={styles.uploadFiles}>
                    <img src={uploadFiles} onClick={() => ref.current.click()}/>
                    <input type="file" id="photo"
                           onChange={(e) => onSelectImageHandler(e.target.files)}
                           accept={'image/*'}
                           ref={ref}
                    />
                </div>
            </div>

            <NotificationsList
                count={count}
                changePageAction={changePageAction}
                messages={messages}
                page={page}
            />
        </div>
    )
}
