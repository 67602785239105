import React from 'react'
import styles from './DonutChart.module.css'
import {Chart} from "react-google-charts";
import Legend from "../../../pages/Analytics/components/LoyalContainer/components/Legend/Legend";

export default function DonutChart({title, data, legendData, subTitleData,marginTitle}) {

    const amount = () => {
        let amountSum = 0;
        let subTitle = ''
        for (let i in subTitleData) {
            if (i == subTitleData.length - 1) {
                amountSum += subTitleData[i]
                subTitle += `${subTitleData[i]}`
            } else {
                amountSum += subTitleData[i]
                subTitle += `${subTitleData[i]}/`
            }
        }
        return [amountSum, subTitle]
    }

    return (
        <div className={styles.container}>
            <h3 style={{margin:marginTitle}}>{title}</h3>
            <Chart
                width={350}
                height={450}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    tooltip: {isHtml: true},
                    pieHole: 0.7,
                    // pieSliceText: 'none',
                    legend: 'none',
                    colors: ['#73D389', '#705AFB', '#E7E7E6']
                }}
            />
            <div className={styles.subTitle}>
                <p>{amount()[0]}</p>
                <span>{amount()[1]}</span>
            </div>
            <div className={styles.legends}>
                {legendData.map((legend, index) => (
                    <div key={index}>
                        <Legend title={legend.title} color={legend.color}/>
                    </div>

                ))}

            </div>
        </div>
    )
}